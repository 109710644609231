import React, { useState } from 'react';
import Spinner from '../../../../../spinner/Spinner';
import instanceOfAxios from '../../../../../../app/axios/axios';
import { Button, Form, InputGroup, Modal, FormControl } from 'react-bootstrap';
import { FaPencilAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { show } from '../../../../Alerter/alerterSlice';
import '../../../bookingTab.scss'
import { useDispatch } from 'react-redux';

export default function CreateOrEditOption(props) {
    const [isSubmit, setIsSubmit] = useState(false);
    const [visibility, setVisibility] = useState(false);
    const [payment, setPayment] = useState(props.payment || {});
    const [error, setError] = useState([]);
    const [param, setParam] = useState(Object.assign({}, props.payment) || {});
    const dispatch = useDispatch();

    function handleChange(event) {
        const values = Object.assign({}, param);
        values[event.target.name] = event.target.value;
        setParam(values);
    }

    function autoCalculate() {
        const values = Object.assign({}, param);
        if(props.payable.rate){
            values['amount'] = (props.payable.rate - props.payable.amount_allready_payed).toFixed(2);
            setPayment({ amount: (props.payable.rate - props.payable.amount_allready_payed).toFixed(2) });
        }else{
            values['amount'] = (props.payable.amount);
            setPayment({ amount: props.payable.amount });
        }
        setParam(values);
    }

    function handleSubmit() {
        setIsSubmit(true);
        const parameter = new FormData();
        let baseUrl = '/' + props.type + '/' + props.payable.id;

        if (props.payment) {
            setFormData(parameter, 'update');
            parameter.set('_method', 'PATCH');
            instanceOfAxios.post(baseUrl + '/payment/' + props.payment.id, parameter)
                .then((res) => {
                    setVisibility(false);
                    setIsSubmit(false);
                    props.setPayment(res.data.Payment);
                    instanceOfAxios.get('/apartment/' + props.booking.apartment_id + '/booking/' + props.booking.id)
                        .then(res => {
                            props.BookingDetailsOnChange(res.data.booking);
                            dispatch(show({ message: 'Paiement mis à jour', variant: 'success' }));
                        })
                }).catch((error) => {
                    handleError(error);
                });
        } else {
            setFormData(parameter, 'post');
            instanceOfAxios.post(baseUrl + '/payment', parameter)
                .then((res) => {
                    setVisibility(false);
                    setIsSubmit(false);
                    setParam({});
                    instanceOfAxios.get('/apartment/' + props.booking.apartment_id + '/booking/' + props.booking.id)
                        .then(res => {
                            props.BookingDetailsOnChange(res.data.booking);
                            dispatch(show({ message: 'Paiement ajouté', variant: 'success' }));
                        })
                }).catch((error) => {
                    handleError(error);
                });
        }
    }

    function setFormData(formData, typeRequest) {
        if (typeRequest === 'update') {
            Object.keys(props.payment).forEach((key) => {
                formData.set(key, param[key] || props.payment[key] || '');
            });
            formData.set('_method', 'PATCH');
        } else {
            Object.keys(param).forEach((key) => {
                formData.set(key, param[key] || '');
            });
        }
    }

    function handleError(error) {
        const logError = [];
        Object.keys(error.response.data).forEach(function (key) {
            logError[key] = error.response.data[key];
        });
        setIsSubmit(false);
        setError(logError);
        dispatch(show({ message: 'Erreur lors de l\'opération', variant: 'danger' }));
    }

    return (
        <>
            {props.for === 'create' ? 
                <Button className="addPaymentButton whiteSVG" variant="secondary" size="sm" onClick={() => setVisibility(true)}> <MdAdd/></Button>
             :
                <Button className="modifyPaymentButton whiteSVG" variant="secondary" size="sm" onClick={() => setVisibility(true)}><FaPencilAlt/></Button>}
            <Modal
                show={visibility}
                showMask={false}
                onHide={() => {
                    setVisibility(false);
                    setIsSubmit(false);
                }}
                centered
                backdropClassName="backdropFourth"
                style={{zIndex:'20003'}}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.payment ? 'Modification d\'un paiement' : 'Ajout d\'un paiement'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label htmlFor='amount'>Montant *</Form.Label>
                        {error['amount'] ? <label htmlFor="amount"><small style={{ color: "red" }}>{error['amount']}</small></label> : <></>}
                        <InputGroup className="mb-3">
                            <Form.Control name='amount' id='amount' placeholder="Montant" aria-describedby="basic-addon1" onChange={(e) => handleChange(e)} value={param.amount || ""} />
                            <Button variant="secondary" onClick={autoCalculate}>Auto</Button>
                        </InputGroup>
                    </Form.Group>
                    <div className="form-group">
                        <label htmlFor="payed_at">Payé le *</label>
                        {error['payed_at'] ? <label htmlFor="payed_at"><small style={{ color: "red" }}>{error['payed_at']}</small></label> : <></>}
                        <InputGroup className="mb-3">
                            {
                                param.payed_at &&
                                <Button 
                                variant="secondary"
                                onClick={() => handleChange({target: {name: 'payed_at', value: null}})}>Reset</Button>
                            }
                            <FormControl 
                            type='date'
                            name='payed_at'
                            onChange={(e) => handleChange(e)}
                            value={param.payed_at || new Date()}/>
                        </InputGroup>
                    </div>
                    <Form.Group>
                        <Form.Label>Mode de paiement *</Form.Label>
                        {error['payment_mode'] ? <label htmlFor="payment_mode"><small style={{ color: "red" }}>{error['payment_mode']}</small></label> : <></>}
                        <Form.Control as="select" name="payment_mode" value={param.payment_mode || ''} onChange={(e) => handleChange(e)} required>
                            <option>Selectionner une option</option>
                            <option>Carte Bancaire</option>
                            <option>Virement</option>
                            <option>Chèque</option>
                            <option>Espèces</option>
                            <option>Swikly</option>
                            <option>Site Externe</option>
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {!isSubmit ? <button className="btn btn-secondary" onClick={handleSubmit}>Valider</button> : <Spinner/>}
                </Modal.Footer>
            </Modal>
        </>
    )

}