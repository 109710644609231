// ici on set le cookie consent à true
export function AccepteCookies(setInOrOut) {
    setCookies('consent', true);
    setInOrOut(false);
}

// ici on set le cookie consent à false
export function RefuseCookies(setInOrOut) {
    setCookies('consent', false);
    setInOrOut(false);
}

//ici on set n'importe quel cookie
export function setCookies(name, value = null) {
    let expireAt = new Date();
    expireAt.setDate(expireAt.getDate() + 60);
    document.cookie = name + '=' + value + '; expires=' + expireAt.toUTCString() +'; path=/';
}

// ici on retire n'importe quel cookie => à tester!!!!!!!
export function removeCookies(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

// ici on verifie si l'utilisateur à accepté ou non les cookies
export function VerifyCookies(setInOrOut){
    let cookies = ParseCookieToArray(document.cookie);
    if(cookies[' consent']){
        setInOrOut(false);
    }else{
        setTimeout(() => {
            setInOrOut(true);
        }, 5000)
    }
}

// ici on récupère un cookie spécifique
export function getCookie(name){
    let cookies = ParseCookieToArray(document.cookie);
    return cookies && cookies[name] ? cookies[name] : false;
}

// ici on verifie la présence d'un cookie spécifique
export function hasCookie(name){
    let cookies = ParseCookieToArray(document.cookie);
    return cookies && cookies[name] ? true : false;
}

// ici on parse la string des cookies en tableau
export function ParseCookieToArray(cookiesString){
    let cookiesArray = cookiesString.split(';');
    let CookiesObject = {};
    for(let i = 0; i < cookiesArray.length; i++){
        let cookie = cookiesArray[i].split('=');
        CookiesObject[cookie[0].replace(/\s/, '')] = cookie[1];
    }
    return CookiesObject;
}