import { Image, Row, Col } from 'react-bootstrap';

export default function ArticleCard(props) {
    const updatedAt = new Date(props.article.updated_at.replace(/\s/, 'T'));
    var options = { year: "numeric", month: "long", day: "numeric"};

    return (
        <Col xs={12} lg={9} className='article-shadow mt-2 noPadding'>
            <Row className='d-flex flex-wrap-reverse'>
                <Col xs={12} lg={8} className='article-card-preview'>
                    <small style={{ color: '#b7a57e' }}>Le {updatedAt.toLocaleDateString('fr-FR', options)}</small>
                    <h3 className='text-lg-left text-center'>{props.article.name}</h3>
                    <div className='separator mb-3 mt-2'
                        style={{
                            margin: 'unset',
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '10%',
                        }}></div>
                    <small style={{ color: '#b7a57e' }}>{props.article.category}</small>
                    <p>{props.article.description}</p>
                    <div  className='ml-auto mt-auto dancing-script-text p-2'>
                        <a href={'/Magazine/article/' + props.article.id}>Lire la suite...</a>
                    </div>
                </Col>
                <Col xs={12} lg={4} className='d-flex align-items-center mb-2'>
                    <Image src={props.article.first_media} fluid alt={props.article.name}/>
                </Col>
            </Row>
        </Col>
    )
}