import { Col, Row, Modal, Button } from 'react-bootstrap';
import ContactCard from '../../contact/contactCard';
import Spinner from '../../spinner/Spinner';
import { BsFillPersonLinesFill } from 'react-icons/bs'
import { useState } from 'react';
import ContactInformations from '../../formulaire/contactInformations';

export default function ContactTab(props) {

    const [visibility, setVisibility] = useState(false);

    function mapContacts() {
        const contactsData = props.user.contacts;
        return (contactsData.map((contact, i) =>
            <ContactCard key={i + '/contact'} contact={contact} user={props.user} role={props.role} handleChange={props.handleChange}/>
        ));
    }

    return (
        <>
            <Col
                sm={12}
                md={6}
                className='menuItem d-flex justify-content-center align-items-end'
                onClick={() => setVisibility(true)}>
                <div className='container-text flex-column d-flex align-items-center justify-content-center'>
                    <BsFillPersonLinesFill/>
                    <h2>Mes Coordonnées</h2>
                </div>
            </Col>
            <Modal
                backdropClassName="backdrop"
                show={visibility}
                onHide={() => setVisibility(false)}
                centered
                scrollable
                size='xl'
                style={{ zIndex: '20000' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Mes Coordonnées</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row style={{ padding: '5px' }}>
                        <Col style={{ textAlign: 'center' }}>
                            {props.user && (props.user.contacts.length < 1 || props.user.is_society) ?
                                <div>
                                    <ContactInformations model={props.user} handleChange={props.handleChange} />
                                </div>
                                : <></>}
                        </Col>
                    </Row>
                    <Row className='contactContainer'>
                        {props.user ? mapContacts() : <Spinner/>}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='goldenHover' onClick={() => setVisibility(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}