import { useState } from 'react';
import { Badge, Col, Collapse, Form, Row, Card } from 'react-bootstrap';
import { useStore } from 'react-redux';
import BookingCard from "../../../booking/bookingCard/bookingCard";
import { FiArrowDownCircle, FiArrowUpCircle } from 'react-icons/fi';

export default function CardTab(props) {
    const store = useStore();
    const type = {
        'activateAutoCancel': {
            'true': 'Auto annulation activé',
            'false': 'Auto annulation désactivé'
        },
        'activateRecallPayment': {
            'true': 'Rappel des paiements activé',
            'false': 'Rappel des paiements désactivé'
        },
        'report': {
            'true': 'Report de 3 jours activé',
            'false': 'Report de 3 jours désactivé'
        }
    };
    const [open, setOpen] = useState(false);

    function gettingArrow(){
        return (!open ?
        <FiArrowDownCircle onClick={() => setOpen(!open)} aria-controls={"collapse-option_" + props.booking.id} aria-expanded={open} />
        :
        <FiArrowUpCircle onClick={() => setOpen(!open)} aria-controls={"collapse-option_" + props.booking.id} aria-expanded={open} />)
    }

    return (
        <Row className='noPadding mb-2'>
            <Col md={12} className='noPadding'>
                <Card>
                    <Row>
                        <Col md={12}>
                            <Badge variant={props.firstChecked ? 'success' : 'danger'}>{type[props.tabType][props.firstChecked]}</Badge>
                        </Col>
                    </Row>
                    <Card.Body className='noPadding'>
                        <Col md={12}>
                            <BookingCard key={props.booking.id} booking={props.booking} role={store.getState().header.role}></BookingCard>
                            {props.secondChange ? 
                            <>
                            {gettingArrow()}
                            <Collapse in={open} id={"collapse-option" + props.booking.id}>
                                <div>
                                    <Form.Group className="mb-3" id="formGridCheckbox">
                                        <Form.Check type="checkbox" label={props.firstText} onClick={(e) => props.firstChange(e, props.booking)} defaultChecked={props.firstChecked} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" id="formGridCheckbox">
                                        <Form.Check type="checkbox" label={props.secondText} onClick={(e) => props.secondChange(e, props.booking)} defaultChecked={props.secondChecked} />
                                    </Form.Group>
                                </div>
                            </Collapse>
                            </>
                                :
                                <Form.Group className="mb-3" id="formGridCheckbox">
                                    <Form.Check type="checkbox" label={props.firstText} onClick={(e) => props.firstChange(e, props.booking)} defaultChecked={props.firstChecked} />
                                </Form.Group>
                            }
                        </Col>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}