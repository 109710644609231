import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Row, Image, Col, Button, Form } from 'react-bootstrap'
import { ShowCreate } from '../usersSlice'
import UserCard from '../userCard/userCard';
import '../userDetails/userDetails.scss';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import instanceOfAxios from '../../../../app/axios/axios';
import SkeletonCard from '../userCard/skeletonCard';
import { AiOutlineSearch } from 'react-icons/ai';

export default function UsersList(props) {
    const dispatch = useDispatch();
    const [resultSortAndSearch, setResultSortAndSearch] = useState();
    const [activePage, setActivePage] = useState(1);
    const [pageNumber, setPageNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isPending, setIsPending] = useState(true);
    const [params, setParams] = useState({});
    const state = useStore().getState();
    const skeleton = [{}, {}, {}, {}, {}, {}, {}, {}];
    const role = state.header.role;

    useEffect(() => {
        if (isPending || props.reloadFromCreate) {
            FetchUsers(activePage);
        }
    }, [isPending, props.reloadFromCreate]);

    function reload(){
        setIsPending(true);
    }

    function FetchUsers(activePage) {
        setIsLoading(true);
        let query = new URLSearchParams(params).toString();
        instanceOfAxios.get(`/admin/account?page=${activePage}&${query}`)
            .then((res) => {
                setResultSortAndSearch(res.data.accounts.data);
                setPageNumber(res.data.accounts.last_page);
                setIsLoading(false);
                setIsPending(false);
            });
    };

    function prevPage(active = null) {
        if (active > 1) {
            setActivePage(parseInt(active) - 1);
            FetchUsers(activePage - 1);
        }
    }

    function nextPage(active = null) {
        if (active < pageNumber) {
            setActivePage(parseInt(active) + 1);
            FetchUsers(activePage + 1);
        }
    }

    function search() {
        setIsLoading(true);
        let query = new URLSearchParams(params).toString();
        instanceOfAxios.get('/admin/account?page=1&' + query)
            .then((res) => {
                setActivePage(1);
                setResultSortAndSearch(res.data.accounts.data);
                setPageNumber(res.data.accounts.last_page);
                setIsLoading(false);
            })
            .catch((error) => {
                setActivePage(1);
                setResultSortAndSearch([]);
                setPageNumber(0);
                setIsLoading(false);
            });
    }

    function handleChange(event) {
        let param = Object.assign(params);
        if(event.target.value == 'Selectionner un role' || event.target.value == ''){
            delete param[event.target.id];
        }else{
            param[event.target.id] = event.target.value;
        }
        setParams(param);
    }

    function mapUser() {
        if (resultSortAndSearch.length > 0 && !isLoading) {
            return resultSortAndSearch.map((value) => {
                return <UserCard key={value.username} user={value} role={role} handleChange={reload} loading={setIsLoading} />
            })
        } else {
            return (
                <div className='auto-margin d-flex flex-column mb-3'>
                    <Image loading='lazy' src="oops.png" className="center-block size-oops" alt="404" fluid></Image>
                    <span>Aucun utilisateur à afficher </span>
                </div>
            )
        }
    }

    return (
        <div className={props.className + ' users'}>
            <Col xs={12}>
                <h2>Gestion des utilisateurs</h2>
            </Col>
            <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%'
                        }}></div>
            </Col>
            <Col md={12} className='d-flex justify-content-center'>
                <Button variant="secondary" className="btn-rounded button-margin-top" onClick={() => dispatch(ShowCreate())}>
                    Ajouter un compte
                </Button>
            </Col>
            <Row>
                <Col md={12} lg={4}>
                    <div className='searchAdminForm text-center'
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                search();
                            }
                        }}>
                        <Form.Group>
                            <Form.Label>Role</Form.Label>
                            <Form.Control name="param" as="select" id='role' onChange={(e) => handleChange(e)}>
                                <option>Selectionner un role</option>
                                <option value='admin'>Administrateur</option>
                                <option value='super-admin'>Super Administrateur</option>
                                <option value='trainee'>Stagiaire</option>
                                <option value='owner'>Propriétaire</option>
                                <option value='tenant'>Locataire</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control name='param' id='email' onChange={(e) => handleChange(e)} type="text" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Nom ou Prénom</Form.Label>
                            <Form.Control name='param' id='contact' onChange={(e) => handleChange(e)} type="text" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Téléphone</Form.Label>
                            <Form.Control name='param' id='phone' onChange={(e) => handleChange(e)} type="text" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Reference de logement</Form.Label>
                            <Form.Control name='param' id='reference' onChange={(e) => handleChange(e)} type="text" />
                        </Form.Group>
                        <Button
                            variant="secondary"
                            id="button-addon2"
                            className='text-center whiteSVG'
                            onClick={search}>
                            Rechercher <AiOutlineSearch/>
                        </Button>
                    </div>

                </Col>
                <Col md={12} lg={8} className='mt-3 noPadding'>
                    <Row id="centerPagination" noGutters>
                        <Col xs={1} className='text-center'>
                            <Button className="goldenHover" onClick={() => prevPage(activePage)} size="sm" variant="light">
                                <GrLinkPrevious />
                            </Button>
                        </Col>
                        <Col xs={10} className='text-center'>
                            <strong>{activePage}</strong> / {pageNumber}
                        </Col>
                        <Col xs={1} className='text-center'>
                            <Button className="goldenHover" onClick={() => nextPage(activePage, resultSortAndSearch.length)} size="sm" variant="light">
                                <GrLinkNext />
                            </Button>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-3  align-items-center" style={{ minHeight: '70vh' }}>
                        {isLoading ?
                            skeleton.map((value) => {
                                return <SkeletonCard />
                            })
                            :
                            mapUser()
                        }
                    </Row>
                    <Row id="centerPagination" noGutters>
                        <Col xs={1} className='text-center'>
                            <Button className="goldenHover" onClick={() => prevPage(activePage)} size="sm" variant="light">
                                <GrLinkPrevious />
                            </Button>
                        </Col>
                        <Col xs={10} className='text-center'>
                            <strong>{activePage}</strong> / {pageNumber}
                        </Col>
                        <Col xs={1} className='text-center'>
                            <Button className="goldenHover" onClick={() => nextPage(activePage, resultSortAndSearch.length)} size="sm" variant="light">
                                <GrLinkNext />
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>)
}
