import { Form, Col, Row, Button } from "react-bootstrap";
import { FaRegSave } from 'react-icons/fa';

export default function RecommandStep(props) {
    return (
        <>
            <Row className='d-flex flex-column align-items-center justify-content-center'>
                <h2>Equipement</h2>
                <Col className='text-center'>
                    <dl>
                        <dt>Informations utiles :</dt>
                        <dd>Si vous ne changez pas les scores ils prendront la note maximal par default</dd>
                        <dd>Les champs marqués d'une * sont obligatoire</dd>
                    </dl>
                </Col>
                <Col xs={12} sm={7}>
                    <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                        <Form.Group as={Col}>
                            <Form.Label>5/ Recommanderiez-vous notre agence à vos proches ?</Form.Label>
                            <Form.Control as="select" defaultValue={props.defaultValue.recommand} name="recommand" onChange={(e) => props.handleJsonChange(e)}>
                                <option>Bien sûr</option>
                                <option>Non je ne pense pas</option>
                                <option>Je ne me prononce pas</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                        <Form.Group as={Col}>
                            <Form.Label>6/ Avez-vous d'autres remarques spécifiques concernant la qualité ?</Form.Label>
                            <Form.Control defaultValue={props.defaultValue.remark} as="textarea" name="remark" rows={2} onChange={(e) => props.handleJsonChange(e)} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} className='d-flex flex-column align-items-center justify-content-center'>
                        <Form.Group as={Col}>
                            <Form.Label>7/ Donnez nous votre avis global sur votre séjour ? *</Form.Label>
                            <Form.Control defaultValue={props.defaultValue.global} as="textarea" name="global" rows={2} onChange={(e) => props.handleJsonChange(e)} />
                        </Form.Group>
                    </Col>
                </Col>
                <Col xs={12} sm={7}>
                    {
                        Object.values(props.errors).length > 0 ?
                            <ul>
                                {
                                    Object.values(props.errors).map(error => error.map(text => <li style={{ color: 'red' }}>{text}</li>))
                                }
                            </ul>
                            :
                            <></>
                    }
                </Col>
                <Col xs={12} sm={7} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '60%',
                            marginBottom: '40px',
                            marginTop: '40px'
                        }}></div>
                </Col>
            </Row>
            <Row>
                <Col xs={6} className='d-flex justify-content-start'>
                    <Button className='goldenHover' onClick={() => props.SetActiveStep(3)}>Précedent</Button>
                </Col>
                <Col xs={6} className='d-flex justify-content-end'>
                    <Button className='goldenHover' onClick={() => props.handleSubmit()}><FaRegSave/> Enregistrer</Button>
                </Col>
            </Row>
        </>
    )
}