import { useRef, useState } from 'react';
import { Row, Col, Form, Button, Card, ProgressBar } from 'react-bootstrap';
import { setReload } from '../list/apartmentListSlice';
import { setUpdater } from '../apartmentSlice';
import { show } from '../../Alerter/alerterSlice';
import { connect, useDispatch, useStore } from 'react-redux';
import { getApartmentForUpdate } from '../apartmentSlice'
import instanceOfAxios from '../../../../app/axios/axios';
import DeleteModal from '../../../generiqueModal/deleteModal/deleteModal';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import { MdDeleteForever } from 'react-icons/md'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Spinner from '../../../spinner/Spinner';
import "../Apartments.scss"
import { FaRegSave } from 'react-icons/fa';

function StepSeven(props) {
    const interval = useRef(null);
    const [urlsFromReader, setUrlsFromReader] = useState([]);
    const [principaleUrl, setPrincipalUrl] = useState({ 'mini': props.apartment.principal_picture_url ? props.apartment.principal_picture_url.mini : null })
    const [files, setFiles] = useState([]);
    const role = useStore().getState().header.role;
    const [totalSize, setTotalSize] = useState(0);
    const [uploaded, setUploaded] = useState(0);
    const [pending, setPending] = useState(false);
    const [reorderPending, setReorderPending] = useState(false);
    const [items, setItems] = useState(loadItems(props.apartment.pictures_url));

    function loadItems(items) {
        items = Object.assign([], items);
        return items.map((item, index) => {
            if (item) {
                return ({
                    id: `${item.id}`,
                    index: index,
                    content:
                        <Card style={{ width: '200px', height: '200px' }}>
                            <Card.Header className='noPadding'>
                                <Row noGutters>
                                    <Col xs={6} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button className="goldenHover" onClick={() => updatePrincipalePicture(item.id)}>
                                            <AiOutlineStar />
                                        </Button>
                                    </Col>
                                    <Col xs={6} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <DeleteModal as={Col} role={role} deleteFunction={() => handleDelete(item.id)} />
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body style={{ padding: '0' }}>
                                <Card.Img variant="top" src={item.mini} style={{ height: '100%', borderRadius: '3px' }} />
                            </Card.Body>
                        </Card>,
                })
            }
        });
    }

    const dispatch = useDispatch();
    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const NewItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(NewItems);
    }

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        result.map((item) => {
            item.index = result.indexOf(item);
        })
        return result;
    };

    function handleOrderSubmit() {
        let data = {};
        data.order = {};
        items.map((item, index) => {
            data.order[index] = { id: item.id, index: item.index + 1 };
        })
        setReorderPending(true);
        instanceOfAxios.post('/apartment/' + props.apartment.id + '/reorderPictures', data)
            .then((res) => {
                instanceOfAxios.get('/apartment/' + props.apartment.id)
                    .then((res) => {
                        dispatch(setUpdater(res.data.apartment));
                        dispatch(setReload(true));
                        setReorderPending(false);
                    })
                dispatch(show({ message: res.data.succes, variant: 'success' }));
            })
    }

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => {
        draggableStyle.top = draggableStyle.top;
        draggableStyle.left = draggableStyle.left;
        return {
            // some basic styles to make the items look a bit nicer
            userSelect: 'none',
            margin: `${grid}px`,
            borderRadius: '5px',

            // change background colour if dragging
            // background: isDragging ? 'lightgreen' : 'rgba(255, 255, 255, 0)',
            boxShadow: isDragging ? '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' : '',
            // opacity: 0,

            // styles we need to apply on draggables
            ...draggableStyle,
        }
    };

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? 'lightgrey' : 'grey',
        display: 'flex',
        overflow: 'auto',
        minHeight: '10vh',
        borderRadius: '5px',
        padding: grid * 2,
        justifyContent: items.length > 2 ? '' : 'center',
        margin: ` ${grid}px 0 ${grid}px 0`
    });

    function handleChange(event) {
        if (window.FileReader) {
            let urls = Object.assign([], urlsFromReader);
            let files = [];
            let newTotalSize = 0;
            Array.from(event.target.files).forEach(photo => {
                let reader = new FileReader()
                newTotalSize += photo.size / 1000000;
                reader.onload = function (r) {
                    urls.push({ url: r.target.result, name: photo.name, size: photo.size / 1000000 });
                }
                reader.readAsDataURL(photo);
                setTimeout(() => {
                    setUrlsFromReader(urls);
                }, 1000)
                files.push(photo);
            });
            setTotalSize(newTotalSize);
            setFiles(files);
        }
    }

    function removeFileFromReader(url, name) {
        let pictures = Object.assign([], urlsFromReader);
        let filesUpdate = Object.assign([], files);
        let newTotalSize = 0;
        pictures = pictures.filter(picture => picture.url != url);
        pictures.map(picture => {
            newTotalSize += picture.size;
        });
        filesUpdate = filesUpdate.filter(file => file.name != name);
        setUrlsFromReader(pictures);
        setFiles(filesUpdate);
        setTotalSize(newTotalSize);
    }

    function handleDelete(id) {
        instanceOfAxios.delete('/apartment/' + props.apartment.id + '/pictures/' + id)
            .then((res) => {
                instanceOfAxios.get('/apartment/' + props.apartment.id)
                    .then((res) => {
                        dispatch(setUpdater(res.data.apartment));
                        setItems(loadItems(res.data.apartment.pictures_url));
                        dispatch(show({ message: 'Action Reussi', variant: 'success' }));
                    })
            })
            .catch((error) => {
                dispatch(show({ message: 'Erreur lors de la suppression', variant: 'danger' }));
            })
    }

    function handleFilesSubmit() {
        if (files.length > 0) {
            setPending(true);
            setUploaded(0);
            let pictures = new FormData();
            files.map((photo, index) => {
                pictures.set('pictures' + index, photo);
            })

            let timing = totalSize / 2.5 * 100;

            interval.current = setInterval(() => {
                setUploaded(value => {
                    if (value >= 99) {
                        clearInterval(interval.current);
                        return uploaded;
                    }
                    return value + 1;
                })
            }, timing);

            instanceOfAxios.post('/apartment/' + props.apartment.id + '/pictures', pictures,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then((res) => {
                    instanceOfAxios.get('/apartment/' + props.apartment.id)
                        .then((res) => {
                            setPending(false);
                            setTotalSize(0);
                            setUploaded(100);
                            dispatch(setUpdater(res.data.apartment));
                            setItems(loadItems(res.data.apartment.pictures_url));
                            setPrincipalUrl(res.data.apartment.principal_picture_url);
                            let message = 'Action Reussi';
                            if (res.data == 'Picture add succesfully') {
                                message = 'Photos ajoutées';
                            }
                            setUrlsFromReader([]);
                            dispatch(show({ message: message, variant: 'success' }));
                        })
                })
                .catch((error) => {
                    let message = 'Erreur lors de l\'ajout';
                    if (error.response.data.error == 'Picture with same name allready saved') {
                        message = 'Photos avec le même nom déjà existante';
                    }
                    setPending(false);
                    dispatch(show({ message: message, variant: 'danger' }));
                })
        } else {
            dispatch(show({ message: 'Selectionnez des Photos à envoyer', variant: 'danger' }));
        }
    }

    function updatePrincipalePicture(id) {
        instanceOfAxios.post('/apartment/' + props.apartment.id + '/pictures/' + id)
            .then((res) => {
                instanceOfAxios.get('/apartment/' + props.apartment.id)
                    .then((res) => {
                        dispatch(setUpdater(res.data.apartment));
                        setItems(loadItems(res.data.apartment.pictures_url));
                        setPrincipalUrl(res.data.apartment.principal_picture_url);
                        dispatch(show({ message: 'Action Reussi', variant: 'success' }));
                    })
            })
            .catch((error) => {
                dispatch(show({ message: 'Erreur lors de la mise à jour', variant: 'danger' }));
            })
    }

    return (

        <>
            <strong>Importer des photos</strong>
            <Row className="mb-4 d-flex justify-content-center">
                <Col sm={12} className="d-flex justify-content-center" style={{ marginTop: '5px' }}>
                    <Form.File
                        id="custom-file-translate-scss"
                        label={'Format jpeg, jpg, png'}
                        lang="fr"
                        custom
                        accept='image/png, image/jpeg, image/jpg'
                        data-browse="Fichier"
                        name='photos'
                        onChange={(e) => handleChange(e)}
                        multiple
                    />
                </Col>
                <Col sm={12} className="d-flex justify-content-center" style={{ marginTop: '10px' }}>
                    {
                        pending ?
                            <Spinner />
                            :
                            <Button variant='secondary' className='whiteSVG' onClick={() => handleFilesSubmit()} disabled={totalSize.toFixed(2) > 50 ? true : false}><FaRegSave/> Enregistrer les photos</Button>
                    }
                </Col>
                {
                    uploaded > 0 && pending
                        ?
                        <Col sm={12} className="d-flex justify-content-center" style={{ padding: '1vh' }}>
                            <ProgressBar now={uploaded} variant="success" animated label={`${uploaded}%`} style={{ minWidth: '50%' }} />
                        </Col>
                        :
                        <></>
                }
                {
                    totalSize.toFixed(2) > 50 &&
                    <Col sm={12} className="d-flex justify-content-center">
                        <p style={{ color: 'red' }}>Tailles des fichiers trop importante</p>
                    </Col>
                }

            </Row>
            <Row className="mb-4">
                <Col xs={12} style={{ marginBottom: '1vh' }} className='d-flex justify-content-center'>
                    <p>{(totalSize.toFixed(2))} / 50 Mo  -  {urlsFromReader.length} fichiers</p>
                </Col>
            </Row>
            <Row className="mb-4">
                {urlsFromReader.map((url, index) => {
                    return (<Col sm={12} lg={2} key={url + index} className="d-flex mb-4">
                        <Card style={{ width: '10vw' }}>
                            <Card.Header>
                                <Row noGutters>
                                    <Col sm={12} className='d-flex justify-content-center'>
                                        <Button variant="danger" className="icon-button-sad" onClick={() => removeFileFromReader(url.url, url.name)} size="sm">
                                            <MdDeleteForever />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Img variant="top" src={url.url} />
                        </Card>
                    </Col>)
                })}
            </Row>
            {principaleUrl &&
                <Row className='d-flex justify-content-center'>
                    <Col xs={12} md={4} lg={3} style={{ marginBottom: '1vh' }}>
                        <Card>
                            <Card.Header>
                                <AiFillStar /><strong>Image principale</strong>
                            </Card.Header>
                            <Card.Img variant="top" src={principaleUrl.mini ? principaleUrl.mini : ''} />
                        </Card>
                    </Col>
                    <Col md={12} lg={9} className='d-flex align-items-center justify-content-center'>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable" direction="horizontal">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                        {...provided.droppableProps}
                                    >
                                        {items.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        {item.content}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Col>
                    <Col md={12} style={{ display: 'flex', justifyContent: 'right' }} className='pb-3'>
                        {
                            reorderPending ?
                                <Spinner />
                                :
                                <Button variant='secondary' className='whiteSVG' onClick={() => handleOrderSubmit()}><FaRegSave/> Enregistrer L'ordre</Button>
                        }
                    </Col>
                </Row>}
        </>
    )
}

const mapStateToProps = state => {
    return {
        apartment: getApartmentForUpdate(state)
    };
}

export default connect(mapStateToProps)(StepSeven);
