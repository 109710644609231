import { now } from "moment";
import { useDispatch, useStore } from "react-redux";
import instanceOfAxios from "../../../../app/axios/axios";
import DeleteModal from '../../../generiqueModal/deleteModal/deleteModal';
import { show } from "../../Alerter/alerterSlice";
import EditBlocked from "./editBlocked";

export default function BlockedCard(props) {
    const role = useStore().getState().header.role;
    const dispatch = useDispatch();

    function handleDelete(){
        instanceOfAxios.delete('/apartment/' + props.date.apartment_id + '/calendar/' + props.date.id)
        .then((res) => {
            props.handleChange();
            dispatch(show({ message: 'Blocage supprimé', variant: 'success' }));
        })
        .catch((error) => {
            dispatch(show({ message: 'Erreur lors de la suppression', variant: 'danger' }));
        })
    }

    const beginDate = new Date(props.date.begin_date_hour.replace(/\s/, 'T'));
    const endDate = new Date(props.date.end_date_hour.replace(/\s/, 'T'));
    var options = {year: "numeric", month: "long", day: "numeric", hour: '2-digit'};
    
    return (
        <tr>
            <td>{props.date.reason}</td>
            {(role === 'super-admin' || role === 'admin') || (role === 'owner' && props.date.reason === 'Blocage propriétaire') ? <td>{props.date.endtitle} </td> : <td></td>}
            <td>{beginDate.toLocaleDateString('fr-FR', options)}</td>
            <td>{endDate.toLocaleDateString('fr-FR', options)}</td>
            {(role === 'super-admin' || role === 'admin' || role === 'trainee') || (role === 'owner' && props.date.reason === 'Blocage propriétaire') ? <td style={{maxWidth: '40px'}}><DeleteModal deleteFunction={handleDelete}/> </td> : <td></td>}
            {(role === 'super-admin' || role === 'admin' || role === 'trainee') || (role === 'owner' && props.date.reason === 'Blocage propriétaire' && endDate > now()) ? <td style={{maxWidth: '30px'}}><EditBlocked apartment={props.date.apartment_id} date={props.date} handleChange={props.handleChange}/></td> : <td></td>}
        </tr>
    )
}