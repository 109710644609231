import { useState } from "react"
import instanceOfAxios from "../../../../app/axios/axios";
import { Row, Col, Card, Button } from 'react-bootstrap';
import BookingCard from "../../booking/bookingCard/bookingCard";
import { useStore } from "react-redux";
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import SkeletonCard from "../../booking/bookingCard/skeletonCard";

export default function TabInProgress(props) {
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [pageNumber, setPageNumber] = useState(0);
    const skeleton = [{}, {}, {}, {}, {}, {}];
    const store = useStore();

    if (pending) {
        fetchBooking(activePage);
    }

    function fetchBooking(page) {
        setLoading(true);
        instanceOfAxios.get('admin/bookingInProgress?page=' + page)
            .then(res => {
                setData(res.data.booking_in_progress.data);
                setPageNumber(res.data.booking_in_progress.last_page);
                setLoading(false);
            })
        setPending(false);
    }

    function next() {
        if (activePage < pageNumber) {
            setActivePage(parseInt(activePage) + 1);
            fetchBooking(parseInt(activePage) + 1)
        }
    }

    function prev() {
        if (activePage > 1) {
            setActivePage(parseInt(activePage) - 1);
            fetchBooking(parseInt(activePage) - 1)
        }
    }
    
    return (
        <>
           <Row className='d-flex justify-content-between mt-2 mb-2'>
                {data.length > 0 ?
                    <>
                        <Col xs={1} className='text-center'>
                            <Button variant='light' onClick={(e) => prev(e)} size="sm">
                                <GrLinkPrevious />
                            </Button>
                        </Col>
                        <Col xs={4} className='text-center'>
                            <strong>{activePage}</strong> / {pageNumber}
                        </Col>
                        <Col xs={1} className='text-center'>
                            <Button variant='light' onClick={(e) => next(e)} size="sm">
                                <GrLinkNext />
                            </Button>
                        </Col>
                    </>
                    :
                    <></>}
            </Row>
            <Col xs={12}>
                <div>
                    {loading ? skeleton.map(skel => {
                        return (
                            <SkeletonCard/>
                        )
                    })
                    :
                        data.map(booking => {
                            return <BookingCard key={booking.id} booking={booking} role={store.getState().header.role}></BookingCard>
                        })
                    }
                </div>
            </Col>
        </>
    )
}