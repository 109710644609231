import { useEffect, useState } from "react"
import { Button, Modal, Row, Col } from "react-bootstrap";
import InvoiceList from "../../../../invoice/invoiceList";
import instanceOfAxios from "../../../../../../app/axios/axios";
import Spinner from "../../../../../spinner/Spinner";
import AskProForma from "../../../../booking/bookingDetails/proforma/askProFormat";
import { useDispatch } from "react-redux";
import { show } from "../../../../Alerter/alerterSlice";
import { MdOutlineFormatListNumberedRtl } from 'react-icons/md';
import { TiDocumentAdd } from 'react-icons/ti'; 
import GenerateInvoieOrBillRider from "../../../../invoice/generate/genrateInvoiceOrBillRider";

export default function InvoiceModal(props) {
    const [visibility, setVisibility] = useState(false);
    const [pending, setPending] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (pending) {
            instanceOfAxios.get(`admin/year_rent/${props.yearRent.id}/getInvoiceUrl/${props.month}`)
                .then(res => {
                    setPending(false);
                    setInvoices(res.data.urls)
                })
        }
    }, [pending]);

    return (
        <>
            <Button variant='secondary' className='whiteSVG' onClick={() => setVisibility(!visibility)}><MdOutlineFormatListNumberedRtl /> Liste des factures</Button>
            <Modal
                scrollable
                centered
                backdropClassName="backdropFourth"
                style={{ zIndex: '20004' }}
                size='lg'
                show={visibility}
                onHide={() => setVisibility(!visibility)}>
                <Modal.Header closeButton>
                    <Modal.Title className='d-flex justify-content-around' style={{ width: '100%' }}>
                         Liste des factures
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} className='text-center'>
                            <p style={{color: 'red'}}> Après avoir ajouter une retenue ou un ajout n'oubliez pas d'enregistrer sinon il n'apparaitra pas dans la facture.</p>
                        </Col>
                        <Col className="d-flex justify-content-around align-items-center reducePadding mb-3" xs={12} lg={6}>
                            <GenerateInvoieOrBillRider
                            invoiceUrl={`admin/year_rent/${props.yearRent.id}/generateInvoice/${props.month}`}
                            billRiderUrl={`admin/year_rent/${props.yearRent.id}/generateBillRider/${props.month}`}
                            reloadFrom={`admin/year_rent/${props.yearRent.id}/getInvoiceUrl/${props.month}`}
                            set={setInvoices}/>
                        </Col>
                        {
                            props.proForma &&
                            <AskProForma url={`admin/year_rent/${props.yearRent.id}/desactivateProForma/${props.month}`} handleChange={() => setPending(true)} />
                        }
                        <Col xs={12}>
                            <small>
                                <ul>
                                    <li>
                                        En cliquant sur générer un facture vous créerez une nouvelle facture pro-forma.
                                    </li>
                                    <li>
                                        En cliquant sur Désactivation du pro forma ceci créeras une nouvelle facture.
                                    </li>
                                </ul>
                            </small>
                        </Col>
                    </Row>
                    <InvoiceList invoices={invoices} isBooking={false}/>
                </Modal.Body>
            </Modal>
        </>
    )
}