import { useState } from 'react'
import { Modal, Button, Card, Row, Col } from 'react-bootstrap'
import RateHistoric from '../rateHistoric/rateHistoric';
import ArchiveRate from './archiveRate';
import { MdOutlineFormatListNumberedRtl } from 'react-icons/md';

export default function ListArchivedRate({ apartment, rates, updateParent }) {
    const [visibility, setVisibility] = useState(false);

    return (
        <>
            <Button className='whiteSVG' variant='secondary' onClick={() => setVisibility(true)}><MdOutlineFormatListNumberedRtl /> Liste des Tarifs archivés</Button>
            <Modal
                scrollable
                centered
                show={visibility}
                backdrop="static"
                size='xl'
                onHide={() => setVisibility(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Liste des Tarifs archivés
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {
                            rates.filter(rate => rate.is_archived == 1).length > 0 ?
                                rates.filter(rate => rate.is_archived == 1).map(rate => {
                                    let begin = new Date(`2023-${rate.begin_period.split('-')[1]}-${rate.begin_period.split('-')[0]}`);
                                    let end = new Date(`2023-${rate.end_period.split('-')[1]}-${rate.end_period.split('-')[0]}`);
                                    var options = { month: 'long', day: 'numeric' };
                                    return (
                                        <Col xs={12} lg={6}>
                                            <Card>
                                                <Card.Header>
                                                    <Row className='d-flex justify-content-center align-items-center'>
                                                        {rate.name ? <Col xs={8}><strong>{rate.name}</strong> </Col> : <strong></strong>}
                                                        <Col xs={2}>
                                                            <RateHistoric apartment={apartment} rate={rate} />
                                                        </Col>
                                                        <Col xs={2}>
                                                            <ArchiveRate apartment={apartment} rate={rate} updateParent={updateParent} />
                                                        </Col>
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        <Col className='noPadding'>
                                                            <Row className='noPadding'>
                                                                <Col className='noPadding d-flex justify-content-center' xs={12}>
                                                                    Début de période
                                                                </Col>
                                                                <Col className='noPadding d-flex justify-content-center' xs={12}>
                                                                    <strong>{begin.toLocaleDateString('fr-CA', options)}</strong>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className='noPadding'>
                                                            <Row className='noPadding'>
                                                                <Col className='noPadding d-flex justify-content-center' xs={12}>
                                                                    Fin de période
                                                                </Col>
                                                                <Col className='noPadding d-flex justify-content-center' xs={12}>
                                                                    <strong>{end.toLocaleDateString('fr-CA', options)}</strong>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-1 mb-1'>
                                                            <div className='separator'
                                                                style={{
                                                                    border: '1px solid #B7A57E',
                                                                    color: '#b7a57e',
                                                                    width: '90%'
                                                                }}></div>
                                                        </Col>
                                                        <Col className='noPadding' xs={12}>
                                                            <h5>Loyers</h5>
                                                        </Col>
                                                        <Col className='noPadding'>
                                                            <Row className='noPadding'>
                                                                <Col className='noPadding d-flex justify-content-center' xs={12}>
                                                                    Nuitée
                                                                </Col>
                                                                <Col className='noPadding d-flex justify-content-center'>
                                                                    <strong>{rate.rent_by_night} €</strong>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className='noPadding'>
                                                            <Row className='noPadding'>
                                                                <Col className='noPadding d-flex justify-content-center' xs={12}>
                                                                    Semaine
                                                                </Col>
                                                                <Col className='noPadding d-flex justify-content-center'>
                                                                    <strong>{rate.rent_by_week} €</strong>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className='noPadding'>
                                                            <Row className='noPadding'>
                                                                <Col className='noPadding d-flex justify-content-center' xs={12}>
                                                                    Mois
                                                                </Col>
                                                                <Col className='noPadding d-flex justify-content-center'>
                                                                    <strong>{rate.rent_by_month} €</strong>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-1 mb-1'>
                                                            <div className='separator'
                                                                style={{
                                                                    border: '1px solid #B7A57E',
                                                                    color: '#b7a57e',
                                                                    width: '90%'
                                                                }}></div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className='noPadding'>
                                                            <Row className='noPadding'>
                                                                <Col className='noPadding d-flex justify-content-center' xs={12}>
                                                                    Minimum de nuit
                                                                </Col>
                                                                <Col className='noPadding d-flex justify-content-center' xs={12}>
                                                                    <strong>{rate.min_night}</strong>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })
                                :
                                <Col>
                                    <h2>Aucuns tarifs archivés</h2>
                                </Col>
                        }
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}