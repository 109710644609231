import React from 'react';
import { Carousel } from 'react-bootstrap';
import "../../../pages/home/Home.scss";
import Skeleton from '@material-ui/lab/Skeleton';

export default function HomeCarousel(props) {
    if (props.page) {
      return (
        <Carousel fade>
          {
            props.page.pictures_url['carousel'].map((picture, index) =>
              <Carousel.Item interval={3000} key={index}>
                <img 
                alt='Photo de logement en location sur montpellier'
                className="d-block w-100 carousselImage"
                loading="lazy"
                srcset={`${picture.medium} 320w, ${picture.medium} 640w, ${picture.url} 900w`}/>
                <div className="overlay"></div>
              </Carousel.Item>
              )
          }
        </Carousel>);
    }
    
    return (
      <Skeleton className="carousel" animation='wave' variant='rect' style={{width: '100%', height: window.innerWidth > 990 ? '80vh' : '30vh'}}/>
    );
}