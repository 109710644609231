import React from 'react';
import { GrCircleInformation } from 'react-icons/gr';
import { Popover, OverlayTrigger, Image } from 'react-bootstrap';
import { useState } from 'react';

function Wrapper({ isShowing }) {
  return (
    <OverlayTrigger
      placement="top"
      show={isShowing}
      overlay={
        <Popover id="popover-basic">
          <Popover.Title>
            <ul>
                <li>Les tarifs renseignés courent du 1er janvier de l'année en cour au 1er janvier de l'année suivante</li>
                <li>la date de début du tarifs prend effet à compter de 18h minimum</li>
                <li>la date de fin du tarifs prend effet à compter de 10h maximum</li>
            </ul>
          </Popover.Title>
        </Popover>}>
        <GrCircleInformation className="apart-marker" />
    </OverlayTrigger>
  )
};
function CustomOver(props) {
  return (
    <Wrapper/>
  )
};

export default CustomOver;
