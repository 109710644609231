import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import instanceOfAxios from '../../../../app/axios/axios';
import { Col, Row, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { items } from './items';
import DeleteModal from '../../../generiqueModal/deleteModal/deleteModal';
import { useDispatch } from 'react-redux';
import { show } from '../../Alerter/alerterSlice';
import EditOrCreateTourism from './createOrEditTourism';
import './tourism.scss';
import { AiFillStar, AiOutlineReload } from 'react-icons/ai';
import Spinner from '../../../spinner/Spinner';

export default function TourismTable(props) {
    const [pending, setPending] = useState(true);
    const [data, setData] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        if (pending) {
            instanceOfAxios.get('admin/tourism')
                .then(res => {
                    setData(Object.assign([], res.data.tourism_values));
                    setPending(false);
                })
                .catch(err => {
                    setPending(false);
                })
        }
    }, [pending])

    function deleteRow(id) {
        instanceOfAxios.delete('/admin/tourism/' + id)
            .then(res => {
                setPending(true);
                dispatch(show({ message: 'Taxe supprimée', variant: 'success' }));
            })
            .catch(error => {
                dispatch(show({ message: 'Suppression impossible', variant: 'danger' }));
            })
    }

    function calculFormater(cell, row) {
        let calcul = '';

        cell.forEach(element => {

            items.forEach(item => {
                if (item.value == element) {
                    calcul += ' ' + item.content + ' ';
                }
            });

            if (element == '$tourism->value_tax') {
                calcul += ' taxe '
            } else if (element == '$total_period') {
                calcul += ' Nombre de nuit '
            }
        });

        return calcul;
    }

    function postalCodeFormater(cell, row) {
        return cell.join(', ');
    }

    function amountFormater(cell, row) {
        return cell + '€';
    }

    function EditFormateur(cell, row) {
        return (
            <EditOrCreateTourism tourism={row} handleChange={handleChange} for='edit' />
        );
    }

    function DeleteFormateur(cell, row) {
        return (
            <DeleteModal deleteFunction={() => deleteRow(row.id)} model={row}></DeleteModal>
        );
    }

    function handleChange(bool) {
        setPending(bool);
    }

    function createCustomExportCSVButton(onClick){
        return (
            <Button variant='secondary' onClick={onClick}>Exporter au format CSV</Button>
        );
    }

    const options = {
        exportCSVBtn: createCustomExportCSVButton
    };

    return (
        <>
            <Row className='mt-3 mb-3'>
                <Col md={12} className='text-center'>
                    {
                        pending ?
                            <Spinner />
                            :
                            <Button onClick={() => setPending(true)} variant='secondary' className='whiteSVG'>Recharger <AiOutlineReload/></Button>
                    }
                </Col>
            </Row>
            <BootstrapTable data={data} options={options} hover pagination exportCSV>
                <TableHeaderColumn isKey dataFormat={amountFormater} width='100' dataField='value_tax' className='text-center'>Nuité</TableHeaderColumn>
                <TableHeaderColumn dataFormat={calculFormater} width='500' dataField='calcul' className='text-center'>Calcul</TableHeaderColumn>
                <TableHeaderColumn dataFormat={postalCodeFormater} width='200' dataField='postales_codes' className='text-center'>Codes postaux</TableHeaderColumn>
                <TableHeaderColumn dataFormat={amountFormater} width='100' dataField='min_amount' className='text-center'>Planché</TableHeaderColumn>
                <TableHeaderColumn dataFormat={amountFormater} width='100' dataField='max_amount' className='text-center'>Plafond</TableHeaderColumn>
                <TableHeaderColumn dataFormat={amountFormater} width='130' dataField='one_star' className='text-center'><AiFillStar /></TableHeaderColumn>
                <TableHeaderColumn dataFormat={amountFormater} width='130' dataField='two_stars' className='text-center'><AiFillStar /><AiFillStar /></TableHeaderColumn>
                <TableHeaderColumn dataFormat={amountFormater} width='130' dataField='three_stars' className='text-center'><AiFillStar /><AiFillStar /><AiFillStar /></TableHeaderColumn>
                <TableHeaderColumn dataFormat={amountFormater} width='130' dataField='four_stars' className='text-center'><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /></TableHeaderColumn>
                <TableHeaderColumn dataFormat={amountFormater} width='130' dataField='five_stars' className='text-center'><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /><AiFillStar /></TableHeaderColumn>
                <TableHeaderColumn dataFormat={amountFormater} width='130' dataField='palace' className='text-center'>Palace</TableHeaderColumn>
                <TableHeaderColumn dataFormat={EditFormateur} width='100' className='text-center'>Modifier</TableHeaderColumn>
                <TableHeaderColumn dataFormat={DeleteFormateur} width='120' className='text-center'>Supprimer</TableHeaderColumn>
            </BootstrapTable>
            <EditOrCreateTourism handleChange={handleChange} />
        </>
    )
}