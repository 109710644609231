import { useState } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { FaPencilAlt } from 'react-icons/fa'
import { useDispatch } from "react-redux";
import instanceOfAxios from "../../../../app/axios/axios";
import { show } from '../../Alerter/alerterSlice';
import Spinner from '../../../spinner/Spinner.jsx'
import { FaRegSave } from 'react-icons/fa';

export default function EditOrCreateElectricity(props) {
    const [visibility, setVisibility] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [params, setParams] = useState({ taxe_type_id: props.taxe_type_id });
    const dispatch = useDispatch();

    function handleChange(event) {
        params[event.target.name] = event.target.value;
        setParams(params);
    }

    function handleSave() {
        setIsSubmit(true);
        let functionName = 'post';
        let path = `admin/agency/${props.agency}/electricity`
        if (props.for == 'edit') {
            path = `admin/agency/${props.agency}/electricity/${props.electricity.id}`;
            functionName = 'patch'
        }
        let paramsToSend = Object.assign({}, params);

        if(paramsToSend.type){
            paramsToSend.type = `T${params.type}`;
        }

        instanceOfAxios[functionName](path, paramsToSend)
            .then(res => {
                setVisibility(false);
                props.handleChange(true);
                dispatch(show({ message: 'Tarif mis à jour', variant: 'success' }));
                setIsSubmit(true);
            })
            .catch(error => {
                dispatch(show({ message: 'Erreur lors de la mise à jour', variant: 'danger' }));
                setIsSubmit(true);
            })
    }

    return (
        <>
            {props.for == 'edit' ?
                <Button size="md" className='goldenHover' style={{ marginLeft: "10px", marginBottom: "5px" }} onClick={() => setVisibility(!visibility)}>
                    <FaPencilAlt />
                </Button>
                :
                <Button size="md" variant="secondary" style={{ marginLeft: "10px", marginBottom: "5px" }} onClick={() => setVisibility(!visibility)}>
                    Ajouter un Tarif
                </Button>
            }
            <Modal
                show={visibility}
                onHide={() => setVisibility(false)}
                scrollable
                backdropClassName="backdropFifth"
                backdrop="static"
                centered
                style={{ zIndex: '20004' }}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.for ? 'Electricité pour type' : 'Création d\'un tarif'} {props.electricity && props.electricity.type}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Nombre de pièce du bien *</Form.Label>
                        <Form.Control name="type" type="number" defaultValue={props.electricity && props.electricity.type.replace('T', '')} placeholder="2" onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Tarif journalier</Form.Label>
                        <InputGroup>
                            <Form.Control type="text" defaultValue={props.electricity && (props.electricity.value).toFixed(2)} name="value" placeholder="2.56" onChange={(e) => handleChange(e)} />
                            <InputGroup.Text>€</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {
                        isSubmit ?
                            <Spinner />
                            :
                            <>
                                <Button variant="danger" onClick={() => setVisibility(!visibility)}>
                                    Fermer
                                </Button>
                                <Button 
                                    className='whiteSVG'
                                    variant="secondary" 
                                    onClick={handleSave}>
                                    <FaRegSave/> Enregistrer
                                </Button>
                            </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}