import React from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import SendInvoiceOrBillrider from './send/sendInvoiceOrBillrider';
import { useStore } from 'react-redux';

export default function InvoiceList({ invoices, isBooking = true }) {
    const role = useStore().getState().header.role;

    function urlFormatter(cell, row) {
        return (
            cell ? <a href={cell} target='_blank'><AiOutlineDownload /></a> : <></>
        )
    }

    function SendFormatter(cell, row) {
        return cell ? <SendInvoiceOrBillrider urls={row} isBooking={isBooking} /> : <></>
    }

    function dateFormatter(cell, row) {
        let date = new Date(cell.replace(/\s/, 'T'));
        var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('fr-CA', options);
    }

    function typeFormatter(cell, row) {
        return (
            row['url'].includes('fr') ? "Facture FR" : "Facture EN"
        )
    }
    
    function getInvoiceList(){
        if(role == 'admin' || role == 'super-admin' || role == 'trainee'){
            return <TableHeaderColumn dataField="url" dataFormat={SendFormatter}>Envoi</TableHeaderColumn>
        }
        return;
    }

    return (
        invoices &&
        <div className='shadowForCard'>
            <BootstrapTable data={invoices} striped={true} hover={true}>
                <TableHeaderColumn isKey dataField="type" dataFormat={typeFormatter}>Type</TableHeaderColumn>
                <TableHeaderColumn dataField="created_at" dataFormat={dateFormatter}>Date de Création</TableHeaderColumn>
                <TableHeaderColumn dataField="url" dataFormat={urlFormatter}>Télécharger</TableHeaderColumn>
                <TableHeaderColumn dataField="avoir" dataFormat={urlFormatter}>Avoir</TableHeaderColumn>
                {getInvoiceList()}
            </BootstrapTable>
        </div>
    )
}
