import { useState } from 'react';
import { Row, Button, Col, Modal } from 'react-bootstrap';
import { HiOutlineUserGroup } from 'react-icons/hi';
import CommentCard from '../../card/commentCard';

export default function UserCheckInStatsCard(props) {
    const [visibility, setVisibility] = useState(false);

    return (
        <Col xs={11} md={4} lg={3} className='total-not-attributed text-center pt-2 pb-2 m-2'>
            <Row>
                <Col xs={12} className='text-center'>
                    <h4>{props.index}</h4>
                </Col>
                <Col xs={12} className='text-center'>
                    <HiOutlineUserGroup size={100} />
                </Col>
                <Col xs={12} className='text-center'>
                    <Button className='goldenHover' onClick={() => setVisibility(true)}>Ouvrir</Button>
                </Col>
            </Row>
            {
                visibility &&
                <Modal
                    scrollable
                    centered
                    show={visibility}
                    size='xl'
                    onHide={() => setVisibility(!visibility)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {props.index}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='d-flex justify-content-center'>
                        <Col xs={12} className='text-center justify-content-center'>
                                <h4>Statistiques globales</h4>
                            </Col>
                            <Col xs={12} className='text-center justify-content-center'>
                                <h5>Moyenne calculé sur un total de {props.dataSort.check_in_attributed[props.index].total} commentaires</h5>
                            </Col>
                            <Col xs={12} md={4} className='text-center total-not-attributed m-2 pt-3 d-flex flex-column justify-content-center'>
                                <p>Indice de satisfaction</p>
                                <p>{(props.dataSort.check_in_attributed[props.index].score / props.dataSort.check_in_attributed[props.index].total).toFixed(2)}/10</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                                <div className='separator'
                                    style={{
                                        border: '1px solid #B7A57E',
                                        color: '#b7a57e',
                                        width: '90%',
                                        marginBottom: '20px',
                                        marginTop: '20px'
                                    }}></div>
                            </Col>
                            <Col xs={12} className='text-center justify-content-center'>
                                <h4>Liste des commentaires rattachés</h4>
                            </Col>
                            <Col xs={12} className='text-center justify-content-center'>
                                {
                                    props.dataSort.check_in_attributed[props.index].comments.map(comment =>
                                        <CommentCard comment={comment} displayButton={false} setReload={props.setReload}/>
                                    )
                                }
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            }
        </Col>
    )
}