import moment from 'moment';
import { useEffect, useState } from 'react';
import { Row, Button, Col, Modal } from 'react-bootstrap';
import instanceOfAxios from '../../../../../app/axios/axios';
import '../AccountingRent.scss';
import Spinner from '../../../../spinner/Spinner';
import environnement from '../../../../../app/environnement';
import { AiOutlineCloudDownload } from 'react-icons/ai';

export default function VerifyIBAN(props) {
    const [pending, setPending] = useState(true);
    const [visibility, setVisibility] = useState(false);
    const [owners, setOwners] = useState([]);

    useEffect(() => {
        instanceOfAxios.get(`/admin/year_rent/verifyOwnerIBAN/${props.month}`)
            .catch(err => {
                setOwners(err.response.data.owners);
            })
        setPending(false);
    }, [props.month]);

    return (
        pending ?
            <Spinner />
            :
            <>
                {
                    owners.length == 0 ?
                        <Button
                            href={environnement.axios + '/admin/year_rent/xml/' + props.month}
                            className='whiteSVG'
                            variant='secondary'>
                            <AiOutlineCloudDownload/> Télécharger les loyers du mois de {moment(new Date(moment().year(), props.month, 1)).format('MMMM')}
                        </Button>
                        :
                        <>
                            <Button
                                onClick={() => setVisibility(true)}
                                className='whiteSVG'
                                variant='secondary'>
                                <AiOutlineCloudDownload/> Télécharger les loyers du mois de {moment(new Date(moment().year(), props.month, 1)).format('MMMM')}
                            </Button>
                            <Modal
                                scrollable
                                centered
                                show={visibility}
                                onHide={() => setVisibility(!visibility)}
                                size='lg'>
                                <Modal.Header closeButton>
                                    <Modal.Title className='d-flex justify-content-around' style={{ width: '100%' }}>
                                        Attention
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col xs={12}>
                                            <h3>Attention vous êtes sur le point de télécharger un fichier non complet</h3>
                                            <p>Les ou le propriétaire(s) suivant(s) ne possède(nt) pas de coordonnées bancaires renseignées, ils ne seront donc pas présents dans le document XML</p>
                                        </Col>
                                        <Col>
                                            <ul>
                                                {
                                                    owners.map(owner => <li>{`${owner.reference}, ${owner.contact.civility} ${owner.contact.first_name} ${owner.contact.last_name}`}</li>)
                                                }
                                            </ul>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        href={environnement.axios + '/admin/year_rent/xml/' + props.month}
                                        className='whiteSVG'
                                        variant='secondary'>
                                        <AiOutlineCloudDownload/> Télécharger tout de même les loyers
                                    </Button>
                                    <Button
                                        variant='danger'
                                        onClick={() => setVisibility(false)}>
                                        Fermer
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </>
                }
            </>
    )
}