import { Container, Col, Image, Row, Button } from 'react-bootstrap';
import { HiCursorClick } from 'react-icons/hi'
import { TbHandClick, TbSearch } from 'react-icons/tb'
import { FiCheckCircle } from 'react-icons/fi'
import { BsCreditCard } from 'react-icons/bs'
import SearchBar from '../../components/searchBar/SearchBar';
import './statics.scss'

export default function LongRent(props) {
    return (
        <Container style={{ paddingTop: '5vh', paddingBottom: '50px', maxWidth: '80vw', height: 'unset' }} className='noPadding'>
            <Row>
                <Col xs={12}>
                <p style={{ color: '#B7A57E' }}><a href='/' className='golden'>Accueil</a> > <a href='/Locataires' className='golden'>Locataires</a></p>
                </Col>
                <Col sm={12} className="noPadding">
                <SearchBar className="searchBar" />
            </Col>
            <Col xs={12} sm={12}>
                <hr className="page-separator" />
            </Col>
                <Col sm={12}>
                    <Row className="d-flex justify-content-center">
                        <Col xs={12} md={8} className="d-flex flex-column-reverse flex-lg-column align-items-end">
                            <div className="d-flex align-items-center step">
                                <TbSearch size='85' className='noDisplay' />
                                <div className='step-content-right'>
                                    <p>Recherchez un logement en renseignant vos critères dans la barre de recherche</p>
                                    <p className='step-number'>1</p>
                                </div>
                            </div>
                            <strong className='step-name'>RECHERCHEZ</strong>
                        </Col>
                        <Col xs={12} md={8} className="d-flex flex-column-reverse flex-lg-column justify-content-start">
                            <div className="d-flex align-items-center step">
                                <div className='step-content-left'>
                                    <p className='step-number'>2</p>
                                    <p>Bingo, vous l'avez trouvé ! Réservez votre logement en moins de 3 clics</p>
                                </div>
                                <TbHandClick size='85' className='noDisplay' />
                            </div>
                            <strong className='step-name'>SELECTIONNEZ</strong>
                        </Col>
                        <Col xs={12} md={8} className="d-flex flex-column-reverse flex-lg-column align-items-end">
                            <div className="d-flex align-items-center step">
                                <BsCreditCard size='85' className='noDisplay' />
                                <div className='step-content-right'>
                                    <p>Payez en toute sécurité grâce à notre système sécurisé du Crédit Agricole</p>
                                    <p className='step-number'>3</p>
                                </div>
                            </div>
                            <strong className='step-name'>RÉSERVEZ</strong>
                        </Col>
                        <Col xs={12} md={8} className="d-flex flex-column-reverse flex-lg-column justify-content-start">
                            <div className="d-flex align-items-center step">
                                <div className='step-content-left'>
                                    <p className='step-number'>4</p>
                                    <p>Vous recevrez un mail de confirmation et toutes les instructions nécessaires pour faire de votre séjour une réussite. A très bientôt !</p>
                                </div>
                                <FiCheckCircle size='85' className='noDisplay' />
                            </div>
                            <strong className='step-name'>C'EST VALIDÉ</strong>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-around'>

                        </Col>
                    </Row>
                </Col>
            </Row>
            <Col xs={12} sm={12}>
                <hr className="page-separator" />
            </Col>
            <Row>
                <Col xs={12} md={4} className='d-flex justify-content-center'>
                    <Image loading='lazy' className="images-footer-horizontal" src="/images/footerPages/Serviettes.webp" />
                </Col>
                <Col xs={12} md={8} className='d-flex align-items-center'>
                    <div>
                        <h3>Services inclus</h3>
                        <ul>
                            <li><strong>Préparation du logement selon un protocole COVID</strong></li>
                            <li><strong>L'accueil</strong> directement au logement</li>
                            <li><strong>Un kit d’accueil</strong></li>
                            <li><strong>Une cuisine équipée</strong> : préparez de bons petits plats et profitez de vos déjeuner grâce à un équipement complet</li>
                            <li><strong>Internet gratuit</strong></li>
                            <li><strong>Le linge de maison</strong></li>
                            <li><strong>Le ménage de fin de séjour</strong> (à l'exception de la cuisine et barbecue / plancha)</li>
                            <li><strong>Une assistance technique</strong>: pendant les heures d'ouverture</li>
                        </ul>
                    </div>
                </Col>
            </Row>
            <Row className='d-flex  flex-wrap-reverse'>
                <Col xs={12} md={8} className='d-flex align-items-center'>
                    <div>
                        <h3> Services à la carte</h3>
                        <ul>
                            <li>Ménage supplémentaire : nous consulter</li>
                            <li>Lit bébé 50€/séjour maximum 15 jours</li>
                            <li>Chaise haute 25€/séjour maximum 15 jours</li>
                            <li>Et plus encore : Une conciergerie à votre écoute pour vous offrir un service personnalisé</li>
                        </ul>
                    </div>
                </Col>
                <Col xs={12} md={4} className='d-flex justify-content-center'>
                    <Image loading='lazy' className="images-footer-horizontal" src="/images/footerPages/Services.webp" />
                </Col>
            </Row>
            <Col xs={12} sm={12}>
                <hr className="page-separator" />
            </Col>
            <Row>
                <Col sm={12} className="centered">
                    <h2>Nos avantages</h2>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col sm={12} md={4}>
                    <Image loading='lazy' className="images-footer-horizontal" src="/images/footerPages/5074.webp" />
                </Col>
                <Col sm={12} md={6} className='d-flex justify-content-center flex-column'>
                    <h3 >Pourquoi choisir Class Appart</h3>
                    <p>Des logements sélectionnés de hauts standing</p>
                    <p>Un concept économique</p>
                    <p>Un service "all inclusive" sans frais additionnels</p>
                </Col>
                <Col xs={12} className="d-flex justify-content-center">
                    <a href="/Contact">
                        <Button variant="secondary" >
                            Demander un devis
                        </Button>
                    </a>
                </Col>
            </Row>
        </Container>
    )
}