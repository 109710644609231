import React, { useEffect, useState } from 'react';
import SearchBar from '../../components/searchBar/SearchBar'
import HomeCarousel from '../../components/home/homeCaroussel/HomeCarousel'
import HomeSelections from "../../components/home/homeLinks/HomeSelection/HomeSelections"
import Title from "../../components/home/homeTitle/HomeTitle";
import HomeRating from '../../components/home/homeRating/HomeRating';
import instanceOfAxios from '../../app/axios/axios';
import Informations from '../../components/home/information/Informations';

export default function Home() {
    const [apartments, setApartments] = useState(null);
    const [page, setPage] = useState(null);

    useEffect(() => {
        if (apartments == null) {
            FetchApartmentForHomePage();
        }
    }, [])

    useEffect(() => {
        if (page == null) {
            FetchDataPage();
        }
    }, [page])

    function FetchApartmentForHomePage() {
        instanceOfAxios.get('/apartment/forHomePage')
            .then(res => {
                setApartments(res.data.apartments);
            });
    }

    function FetchDataPage() {
        instanceOfAxios.get('/admin/page?type=home')
            .then(res => {
                setPage(res.data.page[0]);
            });
    }

    return (
        <div>
            <div>
                <Title page={page} />
                <HomeCarousel page={page} className="homeCaroussel"/>
                <div className='displaySearchBarHome'>
                    <SearchBar className="searchBar"/>
                </div>
            </div>
            <HomeSelections apartments={apartments} />
            <HomeRating />
            <Informations/>
        </div>
    );
}
