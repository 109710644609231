import React, { Component, useState } from 'react';
import { Container, Col, Image, Row } from 'react-bootstrap';
import '../statics.scss'

export default class CGU extends Component {
    render() {
        return (
            <Container style={{ paddingTop: '5vh', paddingBottom: '50px', maxWidth: '80vw', height: 'unset' }} className='noPadding'>
                <a id="presentation"></a>
                <a id="agency"></a>
                <Row>
                    <Col sm={12}>
                        <h2>Conditions générales de location</h2>
                        <p>
                            <strong>1. Champ d’application</strong><br/><br/>

                            Les présentes Conditions Générales de Location (CGL) sont applicables à tout contrat de location conclu entre :
                        </p>
                        <ul>
                            <li>
                                la société CLASS APPART - société à responsabilité limitée au capital de 3.000 € immatriculée au RCS de Montpellier sous le numéro B790259287 ayant son siège social sis  15 PASSAGE LONJON, 34000 MONTPELLIER, dument représentée par son gérant, Madame Bérengère BOUSCAREN ;
                            </li>
                            <li>
                                et toute personne louant un logement référencé sur le site Internet de la société CLASS APPART auprès de la société CLASS APPART.
                            </li>
                        </ul>
                        <p>
                            Les présentes CGL ne régissent pas :

                            les contrats de location conclus entre un propriétaire de bien immobilier et la société CLASS APPART en application d’une convention CLASS PREMIUM ;
                            les contrats de location conclus directement entre un locataire et le propriétaire d’un bien immobilier référencé sur le site Internet de la société CLASS APPART.
                            <br/><br/>
                            <strong>2. Définitions</strong>
                            <br/><br/>
                            Est entendu par « <strong>Bailleur</strong> » dans le cadre des présentes CGL : la société CLASS APPART.
                            <br/><br/>
                            Est entendu par « <strong>Locataire</strong> » dans le cadre des présentes CGL : le client souscrivant un contrat de location, sur le site Internet de la société CLASS APPART.
                            <br/><br/>
                            Est entendu par les « <strong>Parties</strong> » dans le cadre des présentes CGL : le Bailleur et le Locataire.
                            <br/><br/>
                            Est entendu par le terme « <strong>Bien immobilier</strong> » : le bien immobilier loué par le Locataire au Bailleur dans le cadre des présentes.
                            <br/><br/>
                            Est entendu par le terme « <strong>Contrat de location</strong> » : le contrat de location conclu entre les Parties concernant le Bien immobilier.
                            <br/><br/>
                            Est entendu par le terme « <strong>Contrat</strong> » : le contrat de location conclu entre le Bailleur et le Locataire concernant le Bien immobilier, lequel comprend les présentes CGL, ainsi que le contrat de location signé par le Locataire et ses annexes.
                            <br/><br/>
                            En cas de divergence entre les CGL et le contrat de location, les dispositions du contrat de location primeront sur celles des CGL.

                            <br/><br/>
                            <strong>
                                3. Nature du Contrat
                            </strong>
                            <br/><br/>
                            Le Contrat ne constitue : <br/>
                            </p>
                            
                            <ul>
                                <li>
                                    ni une location louée à usage d’habitation principale ou usage mixte professionnel et d’habitation principale au sens de la Loi du 6 juillet 1989 ;<br/>
                                </li>
                                <li>
                                    ni une location meublée au sens des articles L.632-1 et suivants du Code de la Construction et de l’Habitation,<br/>
                                </li>
                                <li>
                                    ni un bail commercial au sens du Code de Commerce ;<br/>
                                </li>
                                <li>
                                    ni une location meublée de tourisme au sens du Code du tourisme.<br/><br/>
                                </li>
                            </ul>


                            <p>
                            En conséquence, le Contrat - qui porte sur une location temporaire non destiné à usage commercial ou d’habitation principal - sera régi par les dispositions du Contrat ainsi que par les dispositions du Code Civil.
                            <br/><br/>
                            <strong>
                                4. Caractéristiques du Bien immobilier
                            </strong>
                            <br/><br/>

                            Le Bien immobilier présente les caractéristiques définies au sein du contrat de location. Les biens mobiliers corporels présents dans le Bien immobilier sont décrits en annexe 2 (inventaire) au contrat de location.<br/><br/>

                            <strong>Toute personne désireuse de louer le Bien immobilier est invitée à prendre connaissance de ces éléments avant de signer le Contrat.</strong>
                            <br/><br/>
                            <strong>
                                5. Durée du Contrat
                            </strong>
                            <br/><br/>
                            Sauf dispositions contraires figurant dans le contrat de location :
                        </p>
                        <ul>
                            <li>
                                le Contrat prend effet de plein droit à compter de la date de signature du contrat de location par les parties et est conclu pour la durée indiquée au sein du contrat de location, telle que convenue entre les parties ;
                            </li>
                            <li>
                                le Contrat prendra fin de plein droit à l’arrivé de son terme, sans qu’un congé n’ait à être donné par le Bailleur au Locataire ;
                            </li>
                            <li>
                                toute prolongation de la durée du Contrat devra obligatoirement être effectuée avec l’accord préalable et écrit du Bailleur, sous réserve que le Bien immobilier soit toujours disponible. Le Locataire devra informer le Bailleur de toute volonté de prolongation dans les délais suivants indiqués au sein du contrat de location.
                            </li>
                        </ul>

                        <p>
                            Les dates et horaires de départ et d’arrivée du Locataire convenues entre les parties sont indiquées au sein du contrat de location.

                            Sauf dispositions contraires figurant au sein du contrat de location, les horaires d’arrivée sont les suivantes :
                        </p>
                        <ul>
                            <li>
                                pour une arrivée un lundi, mardi, mercredi, jeudi, vendredi ou samedi – hors jours fériés - le Locataire pourra entrer dans les lieux entre 16 heures et 18 heures 30 ;
                            </li>
                            <li>
                                pour une arrivée un dimanche ou un jour férié, le Locataire pourra entrer dans les lieux entre 18 heures et 19 heures 30.
                            </li>
                        </ul>

                        <p>
                            Toute arrivée tardive - en dehors de ces horaires - entraînera la facturation par le Bailleur d’un honoraire de 50€ TTC payable sur place par le Locataire, correspondant aux frais découlant d’une telle arrivée tardive. <br/><br/>

                            En tout état de cause et pour respecter la tranquillité du voisinage, aucune arrivée ne pourra être effectuée après 23 heures. Le cas échéant, l’arrivée sera reportée au lendemain, le Locataire restant redevable du loyer afférent à la première nuit.<br/><br/>

                            Les départs devront être effectués le dernier jour de la location, avant 11 heures.<br/><br/>

                            Le Locataire devra informer le Bailleur suffisamment à l’avance de ses dates et horaires d’arrivée et de départ.<br/><br/>

                            <strong>6. Loyer et charges</strong><br/><br/>

                            En contrepartie de la location et sous réserve de la mise à disposition effective du Bien immobilier pour la période considérée par le Bailleur, le Locataire s’engage à lui verser le loyer convenu entre les parties et tel qu’indiqué au sein du contrat de location<br/><br/>

                            Ce loyer comprend une provision sur les consommations du Locataire de gaz et électricité Le Locataire s’engage au respect d’une consommation responsable et raisonnable de l’électricité et du gaz (éteindre les lumières en son absence…etc.).<br/><br/>

                            L’accès internet et la ligne téléphonique ne sont pas comprises dans le montant du loyer et ne sont pas obligatoires, mais si celles-ci sont présentes dans le bien immobilier, le Locataire pourra passer et recevoir des appels à destination ou émis par des lignes téléphoniques fixes situées en France métropolitaine, et utiliser Internet. Le cas échéant, le Locataire prendra à sa charge tout appel passé ou reçu entraînant un supplément de facturation au Bailleur ou au propriétaire du bien immobilier, sur simple présentation de la facture téléphonique détaillée afférente.<br/><br/>

                            Le Locataire ne pourra effectuer aucune réclamation concernant les problèmes de fonctionnement de l’accès internet ou de la ligne téléphonique, ceux-ci n’étant pas compris dans la location et ne conditionnant pas la signature du contrat de location.<br/><br/>

                            Sauf dispositions particulières convenues au sein du contrat de location, le Loyer est payable de la manière suivante :
                        </p>

                        <ul>
                            <li>
                                50 % à titre d’acompte, payables par virement bancaire, chèque ou sur le site Internet CLASS APPART, au plus tard au jour de la réservation de la Location ;
                            </li>
                            <li>
                                50 % au titre du solde restant dû, payables virement bancaire, chèque ou sur le site Internet CLASS APPART, au plus tard 15 jours avant l’entrée dans les lieux du Locataire, en même temps que le dépôt de garantie.
                            </li>
                        </ul>
                        <p>
                            Les montants de l’acompte et du loyer dus sont indiqués au sein du contrat de location.<br/><br/>

                            Les parties étant engagées du fait de la signature du contrat de location, aucune somme ne sera remboursée au Locataire s’il souhaite finalement ne plus louer le bien immobilier postérieurement à la signature du contrat de location. Les sommes versées à titre d’acompte sont ainsi définitivement acquises au Bailleur, et ne feront l’objet d’aucun remboursement par le Bailleur, hormis le cas où la location est annulée du fait du Bailleur dans les conditions prévues à l’article 7 des CGL ou en raison d’un cas de force majeure. Le Locataire est en outre tenu des loyers jusqu’au terme du contrat, hormis le cas où la location est annulé ou résilié du fait du Bailleur dans les conditions prévues à l’article 7 des CGL ou en raison d’un cas de force majeure.<br/><br/>

                            Sera considéré comme un cas de force majeure tout évènement extérieur, irrésistible, et imprévisible, indépendant de la volonté des deux parties, empêchant l’exécution du Contrat, et notamment les cas de guerre, attentats, éruptions volcaniques, séismes, …etc. de nature à empêcher l’exécution du contrat de location.<br/><br/>

                            <strong>7. Annulation</strong><br/><br/>

                            Le Contrat est conclu pour une durée déterminée. La résiliation anticipée ou l’annulation du Contrat n’est possible que dans les cas limitativement énumérés par le présent article.<br/><br/>

                            <strong>7.1.Annulation par le Bailleur en cas d’indisponibilité du bien immobilier ou d’état hors d’usage du bien immobilier</strong><br/><br/>

                            Dans l’hypothèse où – postérieurement à la conclusion du Contrat - le Bien immobilier serait finalement indisponible ou hors d’usage (en raison notamment d’un incendie, d’un dégât des eaux, de travaux non prévus au jour de la réservation ou de tout autre évènement rendant le bien immobilier indisponible à la location pour la période considérée), le Bailleur pourra proposer au Locataire de bénéficier à la place d’un autre bien immobilier d’une catégorie équivalente ou supérieure au bien immobilier, disponible à la même période.<br/><br/>

                            En cas d’acceptation du Locataire de bénéficier d’un autre bien que le Bien immobilier, le loyer initialement convenu entre les parties s’appliquera à la location du bien immobilier de remplacement, et ce même s’il s’agit d’un bien de catégorie supérieure. Néanmoins, et dans le cas où le montant loyer du bien de remplacement serait inférieur à celui du bien immobilier, le Locataire ne sera tenu que du loyer le moins élevé.<br/><br/>

                            En cas de refus du Locataire de bénéficier d’un autre bien que le bien immobilier, le Bailleur reversera sans délai au Locataire les sommes déjà versées au titre de la location et le Contrat sera résilié.<br/><br/>

                            <strong>7.2. Annulation du fait du Locataire</strong><br/><br/>

                            Le Contrat étant conclu pour une durée déterminée ferme et non révisable, le Locataire sera tenu des loyers jusqu’au terme du Contrat s’il souhaite annuler le Contrat ou le résilier de manière anticipée.<br/><br/>

                            Toutefois, le Locataire a la possibilité de souscrire une assurance annulation auprès de la compagnie d’assurances partenaire du le Bailleur (ADUCIEL). Les conditions de cette assurance, son étendue, et ses modalités lui seront remises par le Bailleur sur simple demande.<br/><br/>

                            Le Locataire pourra en outre souscrire une assurance annulation auprès de tout tiers de son choix. Tout contrat d’assurance souscrit en ce sens sera passé directement entre la compagnie d’assurances et le Locataire, le Bailleur n’étant en aucun cas partie à ces contrats d’assurance ni tenu des obligations de la compagnie d’assurances partie au contrat.<br/><br/>

                            <strong>8. Obligations du Bailleur et du Locataire</strong><br/><br/>

                            Les obligations du Bailleur et du Locataire sont définies au sein du contrat de location.<br/><br/>

                            <strong>9. Dépôt de garantie</strong><br/><br/>

                            A titre de garantie de l'entière exécution de ses obligations et en cautionnement des dégâts qui pourraient être causés au Bien immobilier, ainsi qu’aux mobiliers, équipements, matériels et objets garnissant le Bien immobilier , le Locataire versera au Bailleur – un dépôt de garantie dont les modalités de paiement, le montant et les modalités de restitution sont indiquées au sein du contrat de location.<br/><br/>

                            <strong>10. Inventaire, état des lieux et dommages</strong><br/><br/>

                            La location objet du Contrat fera l’objet d’une visite d’état des lieux au début et d’une visite d’état des lieux en fin de location en présence des deux parties dans les conditions précisées au sein du contrat de location.<br/><br/>

                            Le Locataire s’engage à indemniser le Bailleur de tout dommage occasionné au Bien immobilier, ou aux biens et équipements recensés au sein de l’état des lieux et/ou de l’inventaire, survenu pendant la durée d’un contrat de location ou postérieurement à celle-ci, de son fait ou du fait d’une personne invitée par lui.<br/><br/>

                            Le Locataire est tenu de remettre en l’état ou remplacer à l’identique tout bien ou équipement endommagé par lui ou par une personne invitée par lui, ou à défaut de rembourser le Bailleur des frais de réparation ou de remplacement dudit bien ou équipement endommagé.<br/><br/>

                            Le Locataire s’engage à signaler au Bailleur tout dommage ou dégât occasionné au bien immobilier ainsi qu’aux biens mobiliers et équipements le garnissant, et ce sans délai dès la survenance dudit dommage ou dégât ou sa connaissance par le Locataire.<br/><br/>

                            Dans le cas où un état des lieux ne pourrait être réalisé du fait du Locataire (par exemple si celui-ci est trop pressé pour prendre le temps de le faire en fin de bail), le Bailleur procèdera à l’état des lieux seul et l’adressera au Locataire sans délai par e-mail. Le Locataire disposera alors d’un délai de 2 jours pour émettre des réserves concernant l’état des lieux, lesquelles devront être adressées au Bailleur par e-mail ou LRAR. Toute réserve ainsi formulée par le Locataire devra être justifiée par celui-ci, qui devra ainsi indiquer avec ses réserves pourquoi il conteste tel ou tel point de l’état des lieux.<br/><br/>

                            A défaut de réserves exprimées dans ce délai et dument justifiées, l’état des lieux réalisé par le Bailleur sera seul pris en considération pour déterminer le montant des sommes éventuellement dues par le Locataire au titre des dommages et dégradations occasionnés de son fait.<br/><br/>

                            La restitution des clés du bien immobilier à la fin de la période de location n’entraine aucune présomption de bonne remise en état de l’logement, seul l’état des lieux faisant foi.<br/><br/>

                            Le Locataire est informé du fait que le Bailleur n’est pas propriétaire du Bien immobilier, et que le Bailleur répond devant le propriétaire des dommages et dégradations occasionnés par le Locataire au bien immobilier et/ou à ses équipements et mobiliers.<br/><br/>

                            En conséquence, le Locataire s’engage à indemniser le Bailleur de toute somme versée par lui au propriétaire afin d’indemniser un dommage causé par le Locataire ou ses accompagnants.<br/><br/>

                            <strong>11. Sous-location - cession</strong><br/><br/>

                            Le Locataire n’est en aucun cas autorisé à sous-louer le Bien immobilier à un tiers, à souscrire le Contrat au nom d’un tiers, pas plus qu’à céder ou transférer le Contrat à un tiers.<br/><br/>

                            La location est consentie au bénéfice du seul Locataire.<br/><br/>

                            <strong>12. Clause résolutoire</strong><br/><br/>

                            A défaut de paiement du loyer et des charges dans les délais convenus entre les Parties ou d'inexécution d'une des clauses du Contrat par l’une des Parties, et huit jours francs après une simple sommation par lettre recommandée restée infructueuse, le présent contrat sera résilié de plein droit.<br/><br/>

                            <strong>13. Droit applicable</strong><br/><br/>

                            Les présentes CGL sont, de convention expresse entre les Parties, soumises au droit français.<br/><br/>
                        </p>
                    </Col>
                </Row>
            </Container>)
    } 
}