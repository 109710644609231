import React, { useState } from 'react';
import Spinner from '../../spinner/Spinner';
import { Row, Button, Col, Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { show } from '../../admin/Alerter/alerterSlice';
import instanceOfAxios from '../../../app/axios/axios';
import { setIsConnected } from '../../header/headerSlice';

const FormulaireAccount = () => {
    const [isSociety, setIsSociety] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [visible, setVisible] = useState(false);
    const [registerParam, setRegisterParam] = useState({});
    const [error, setRegisterError] = useState({});
    const dispatch = useDispatch();

    const pays = [
        "X",
        "Afghanistan",
        "Afrique du sud",
        "Albanie",
        "Algérie",
        "Allemagne",
        "Andorre",
        "Angola",
        "Anguilla",
        "Antarctique",
        "Antigua-et-barbuda",
        "Arabie saoudite",
        "Argentine",
        "Arménie",
        "Aruba",
        "Australie",
        "Autriche",
        "Azerbaïdjan",
        "Bahamas",
        "Bahreïn",
        "Bangladesh",
        "Barbade",
        "Belgique",
        "Belize",
        "Bénin",
        "Bermudes",
        "Bhoutan",
        "Biélorussie",
        "Bolivie",
        "Bosnie-herzégovine",
        "Botswana",
        "Brésil",
        "Brunéi darussalam",
        "Bulgarie",
        "Burkina faso",
        "Burundi",
        "Cabo Verde",
        "Cambodge",
        "Cameroun",
        "Canada",
        "Chili",
        "Chine",
        "Chypre",
        "Colombie",
        "Comores",
        "Congo (république démocratique du congo)",
        "Congo (république du congo)",
        "Corée (république de)",
        "Corée (république populaire démocratique de)",
        "Costa rica",
        "Côte d'ivoire",
        "Croatie",
        "Cuba",
        "Curaçao",
        "Danemark",
        "Djibouti",
        "Dominique",
        "Égypte",
        "Émirats arabes unis",
        "Équateur",
        "Érythrée",
        "Espagne",
        "Estonie",
        "Eswatini",
        "El salvador",
        "États-unis d'amérique",
        "Éthiopie",
        "Fédération de russie",
        "Fidji",
        "Finlande",
        "France",
        "Gabon",
        "Gambie",
        "Géorgie",
        "Géorgie du sud-et-les îles sandwich du sud",
        "Ghana",
        "Gibraltar",
        "Grèce",
        "Grenade",
        "Groenland",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernesey",
        "Guinée",
        "Guinée-bissau",
        "Guinée équatoriale",
        "Guyana",
        "Guyane française",
        "Haïti",
        "Honduras",
        "Hong Kong",
        "Hongrie",
        "Île bouvet",
        "Île christmas",
        "Île de man",
        "Île norfolk",
        "Îles caïmans",
        "Îles cook",
        "Îles féroé",
        "Îles heard et mcdonald",
        "Îles malouines",
        "Îles mariannes du nord",
        "Îles marshall",
        "Îles mineures éloignées des états-unis",
        "Îles turks et caïques",
        "Îles vierges britanniques",
        "Îles vierges des états-unis",
        "Îles salomon",
        "Îles cocos (keeling)",
        "Inde",
        "Indonésie",
        "Iran",
        "Irak",
        "Irlande",
        "Islande",
        "Israël",
        "Italie",
        "Jamaïque",
        "Japon",
        "Jersey",
        "Jordanie",
        "Kazakhstan",
        "Kenya",
        "Kirghizistan",
        "Kiribati",
        "Koweït",
        "Lettonie",
        "Liban",
        "Libéria",
        "Libye",
        "Liechtenstein",
        "Lituanie",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaisie",
        "Maldives",
        "Mali",
        "Malte",
        "Martinique",
        "Mauritanie",
        "Maurice",
        "Mayotte",
        "Mexique",
        "Micronésie (états fédérés de)",
        "Moldova (république de)",
        "Monaco",
        "Mongolie",
        "Monténégro",
        "Montserrat",
        "Maroc",
        "Mozambique",
        "Myanmar",
        "Namibie",
        "Nauru",
        "Népal",
        "Nouvelle-calédonie",
        "Nouvelle-zélande",
        "Nicaragua",
        "Niger",
        "Nigéria",
        "Niue",
        "Norvège",
        "Oman",
        "Ouganda",
        "Ouzbékistan",
        "Pakistan",
        "Palaos",
        "Palestine, état de",
        "Panama",
        "Papouasie-nouvelle-guinée",
        "Paraguay",
        "Pays-bas",
        "Pérou",
        "Philippines",
        "Pitcairn",
        "Pologne",
        "Polynésie française",
        "Portugal",
        "Porto rico",
        "Qatar",
        "Macédoine du nord",
        "République arabe syrienne",
        "République centrafricaine",
        "République démocratique populaire lao",
        "République dominicaine",
        "République tchèque",
        "Roumanie",
        "Royaume-uni de grande-bretagne et d'irlande du nord",
        "Rwanda",
        "Réunion",
        "Saint-barthélemy",
        "Saint-kitts-et-nevis",
        "Saint-lucie",
        "Saint-marin",
        "Saint-Siège",
        "Saint-pierre-et-miquelon",
        "Saint-vincent-et-les grenadines",
        "Samoa",
        "Samoa américaines",
        "Sénégal",
        "Serbie",
        "Seychelles",
        "Sierra leone",
        "Singapour",
        "Sint maarten (partie néerlandaise)",
        "Slovaquie",
        "Slovénie",
        "Somalie",
        "Soudan",
        "Soudan du sud",
        "Sri lanka",
        "Suriname",
        "Svalbard et île jan mayen",
        "Suède",
        "Suisse",
        "Tadjikistan",
        "Taïwan, province de chine",
        "Tanzanie, république-Unie de",
        "Tchad",
        "Territoire britannique de l'océan indien",
        "Terres australes françaises",
        "Thaïlande",
        "Timor-leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinité-et-tobago",
        "Tunisie",
        "Turkménistan",
        "Turquie",
        "Tuvalu",
        "Ukraine",
        "Uruguay",
        "Vanuatu",
        "Venezuela (république bolivarienne du)",
        "Viet nam",
        "Wallis-et-futuna",
        "Yémen",
        "Zambie",
        "Zimbabwe"
    ];

    function handleRegisterChange(event) {
        let param = Object.assign({}, registerParam);
        param[event.target.name] = event.target.value;
        setRegisterParam(param);
    }

    function handleRegister() {
        setIsSubmit(true);
        instanceOfAxios.post('/registerInRow', registerParam)
            .then((res) => {
                instanceOfAxios.post('/login', registerParam)
                    .then((res) => {
                        dispatch(show({ message: 'Création de compte réussi', variant: 'success' }));
                        dispatch(setIsConnected(true));
                        setIsSubmit(false);
                    })
            })
            .catch((error) => {
                dispatch(show({ message: 'Une erreur est survenue lors de la création de votre compte', variant: 'danger' }));
                handleError(error);
                setIsSubmit(false);
            })
    }

    function handleError(error, type) {
        let arrayError = [];
        Object.keys(error.response.data).forEach(function (key) {
            arrayError[key] = error.response.data[key];
        });
        setRegisterError(arrayError);
    }

    function handleIsSocietyChange(event) {
        let param = Object.assign({}, registerParam);
        let bool = 0;
        if (event.target.checked) {
            bool = 1;
        }
        param.is_society = bool;
        setRegisterParam(param);
        setIsSociety(event.target.checked);
    }

    return (
        <div>
            <Button variant="light" style={{ backgroundColor: "transparent", width: "100%", border: "none", textAlign: "center", boxShadow: 'none', borderRadius: "0px" }} onClick={() => setVisible(true)}>Créer un compte</Button>
            <Modal
                backdropClassName="backdrop"
                show={visible}
                onHide={() => {
                    setIsSociety(false);
                    setVisible(false);
                }}
                size='lg'
                style={{ zIndex: '20000' }}
                centered>
                <Modal.Header closeButton>
                    Enregistrement
                </Modal.Header>
                <Modal.Body>
                    <Form >
                        <Row>
                            <Col md={12}>
                                <p>Les champs marqués d'une * sont obligatoire</p>
                            </Col>
                            <Col md={12} lg={12}>
                                <Form.Group>
                                    <Form.Label>Email *</Form.Label>
                                    <Form.Control name='email' size="sm" placeholder="Exemple@exemple.com" onChange={handleRegisterChange} />
                                    {error['email'] ? <small style={{ color: "red" }}>{error['email']}</small> : <></>}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Mot de passe *</Form.Label>
                                    <Form.Control type={!passwordVisibility ? 'password' : 'text'} name='password' size="sm" placeholder="********" onChange={handleRegisterChange} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Confirmer le mot de passe *</Form.Label>
                                    <Form.Control type={!passwordVisibility ? 'password' : 'text'} name='confirm_password' size="sm" placeholder="********" onChange={handleRegisterChange} visible/>
                                </Form.Group>
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" onClick={(e) => setPasswordVisibility(e.target.checked)} label='Afficher le mot de passe' />
                                </Form.Group>
                                {
                                    !error['password'] ? 
                                    <p style={{ textAlign: 'center', color: 'red' }}>Le mot de passe doit être formé de au minimum 8 caractères, contenir un numéro, une majuscule, une minuscule et un caractère spécial</p>
                                    :
                                    <></>
                                }
                                <ul>
                                    {error['password'] ? error['password'].map(error => <li htmlFor="password"><small style={{ color: "red" }}>{error}</small></li>) : <></>}
                                </ul>
                            </Col>
                            <Col md={12} lg={6}>
                                <Form.Group id="civility">
                                    <Form.Label>Civilité *</Form.Label>
                                    <Form.Control name="civility" as="select" onChange={handleRegisterChange}>
                                        <option>Selectionner</option>
                                        <option value='Mme'>Mme</option>
                                        <option value='Mr'>Mr</option>
                                    </Form.Control>
                                    {error['civility'] ? <small style={{ color: "red" }}>{error['civility']}</small> : <></>}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Prénom *</Form.Label>
                                    <Form.Control name='first_name' size="sm" placeholder="Jean" onChange={handleRegisterChange} />
                                    {error['first_name'] ? <small style={{ color: "red" }}>{error['first_name']}</small> : <></>}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Nom *</Form.Label>
                                    <Form.Control name='last_name' size="sm" placeholder="Dupont" onChange={handleRegisterChange} />
                                    {error['last_name'] ? <small style={{ color: "red" }}>{error['last_name']}</small> : <></>}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Téléphone *</Form.Label>
                                    <Form.Control name='phone' size="sm" placeholder="0694543234" onChange={handleRegisterChange} />
                                    {error['phone'] ? <small style={{ color: "red" }}>{error['phone']}</small> : <></>}
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={6}>
                                <Form.Group>
                                    <Form.Label>Adresse *</Form.Label>
                                    <Form.Control name='address' size="sm" placeholder="450 rue des lilas" onChange={handleRegisterChange} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Deuxième partie</Form.Label>
                                    <Form.Control name='second_address' size="sm" placeholder="" onChange={handleRegisterChange} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Numéro de logement</Form.Label>
                                    <Form.Control name='third_address' size="sm" placeholder="001" onChange={handleRegisterChange} />
                                </Form.Group>
                                <Form.Row>
                                    <Col lg={12} xl={6}>
                                        <Form.Group>
                                            <Form.Label>Ville *</Form.Label>
                                            <Form.Control name='city' size="sm" placeholder="Montpellier" onChange={handleRegisterChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} xl={6}>
                                        <Form.Group>
                                            <Form.Label>Code Postale *</Form.Label>
                                            <Form.Control name='postal_code' size="sm" placeholder="34000" onChange={handleRegisterChange} />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Group>
                                    <Form.Label>Pays</Form.Label>
                                    {/* <Form.Control name='country' defaultValue='France' size="sm" onChange={handleRegisterChange} /> */}

                                    <Form.Control size="sm" name="country" as="select" defaultValue="France" onChange={(e) => handleRegisterChange(e)}>
                                        {pays.map((pays, index) => (
                                            <option key={index} selected={pays}>{pays}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            {isSociety ? <>
                                <Col xs={12}>
                                    <h3>Informations de société</h3>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Form.Group>
                                        <Form.Label>Nom de la société *</Form.Label>
                                        <Form.Control name='name' size="sm" onChange={handleRegisterChange} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Numéro de SIRET *</Form.Label>
                                        <Form.Control name='SIRET' size="sm" placeholder="443xxxxxxxxxxx" onChange={handleRegisterChange} />
                                        {error['SIRET'] ? <small style={{ color: "red" }}>{error['SIRET']}</small> : <></>}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Numero de TVA *</Form.Label>
                                        <Form.Control name='num_TVA' size="sm" placeholder="443xxxxxx " onChange={handleRegisterChange} />
                                        {error['num_TVA'] ? <small style={{ color: "red" }}>{error['num_TVA']}</small> : <></>}
                                    </Form.Group>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Form.Group>
                                        <Form.Label>Adresse de la société *</Form.Label>
                                        <Form.Control name='society_address' size="sm" placeholder="450 rue des lilas" onChange={handleRegisterChange} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Deuxième partie</Form.Label>
                                        <Form.Control name='society_second_address' size="sm" placeholder="" onChange={handleRegisterChange} />
                                    </Form.Group>
                                    <Form.Row>
                                        <Col xs={12} md={6}>
                                            <Form.Group>
                                                <Form.Label>Ville *</Form.Label>
                                                <Form.Control name='society_city' size="sm" placeholder="Montpellier" onChange={handleRegisterChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group>
                                                <Form.Label>Code Postale *</Form.Label>
                                                <Form.Control name='society_postal_code' size="sm" placeholder="34000" onChange={handleRegisterChange} />
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Group>
                                        <Form.Label>Pays</Form.Label>
                                        <Form.Control name='society_country' size="sm" defaultValue='France' onChange={handleRegisterChange} />
                                    </Form.Group>
                                </Col>
                            </>
                                :
                                <></>}
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    <div className='text-center'>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" onClick={(e) => handleIsSocietyChange(e)} label='Ce compte est une société' />
                        </Form.Group>
                        {!isSubmit ? <button className="btn btn-secondary" onClick={() => handleRegister()}>Valider</button> : <Spinner></Spinner>}
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default FormulaireAccount;