import { useState } from "react"
import instanceOfAxios from "../../../../app/axios/axios";
import { Row, Col, Form, Button } from 'react-bootstrap';
import BookingCard from "../../booking/bookingCard/bookingCard";
import { useStore } from "react-redux";
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import SkeletonCard from "../../booking/bookingCard/skeletonCard";
import moment from "moment/moment";

export default function TabBookingInComing(props) {
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [pageNumber, setPageNumber] = useState(0);
    const [searchParams, setSearchParams] = useState({ begin_date: moment().format(), end_date: moment().add(1, 'days').format() });
    const skeleton = [{}, {}, {}, {}, {}, {}];
    const store = useStore();

    if (pending) {
        fetchBooking(activePage);
    }

    function fetchBooking(page) {
        setLoading(true);
        let query = new URLSearchParams(searchParams).toString();
        instanceOfAxios.get('admin/bookingGoingOut?page=' + page + '&' + query)
            .then(res => {
                setData(res.data.bookingsInComing.data);
                setPageNumber(res.data.bookingsInComing.last_page);
                setLoading(false);
            })
        setPending(false);
    }

    function handleChange(e) {
        let params = Object.assign({}, searchParams);
        let date = moment(e.target.value).format();
        params[e.target.name] = date;
        setSearchParams(params);
        setPending(true);
    }

    function next() {
        if (activePage < pageNumber) {
            setActivePage(parseInt(activePage) + 1);
            fetchBooking(parseInt(activePage) + 1);
        }
    }

    function prev() {
        if (activePage > 1) {
            setActivePage(parseInt(activePage) - 1);
            fetchBooking(parseInt(activePage) - 1);
        }
    }

    return (
        <>
            <Row className='d-flex justify-content-center mt-2 mb-2'>
                <Col xs={12}>
                    <h2>Periode de recherche</h2>
                </Col>
                <Col xs={12} className='d-flex justify-content-center'>
                    <Row className='noPadding'>
                        <Col xs={12} md={6}>
                            <Form.Group>
                                <Form.Label>Début</Form.Label>
                                <Form.Control type='date' name='begin_date' size="sm" defaultValue={searchParams.begin_date.split('T')[0]} onChange={(e) => handleChange(e)} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group>
                                <Form.Label>Fin</Form.Label>
                                <Form.Control type='date' name='end_date' size="sm" defaultValue={searchParams.end_date.split('T')[0]} onChange={(e) => handleChange(e)} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                {data.length > 0 ?
                    <>
                        <Col xs={1} className='text-center mt-2 mb-2'>
                            <Button onClick={(e) => prev(e)} size="sm" variant="light">
                                <GrLinkPrevious />
                            </Button>
                        </Col>
                        <Col xs={4} className='text-center mt-2 mb-2'>
                            <strong>{activePage}</strong> / {pageNumber}
                        </Col>
                        <Col xs={1} className='text-center mt-2 mb-2'>
                            <Button onClick={(e) => next(e)} size="sm" variant="light">
                                <GrLinkNext />
                            </Button>
                        </Col>
                    </>
                    :
                    <></>}
            </Row>
            <Col xs={12}>
                <div>
                    {loading ? skeleton.map(skel => {
                        return (
                            <SkeletonCard />
                        )
                    })
                        :
                        data.map(booking => {
                            return <BookingCard key={booking.id} booking={booking} role={store.getState().header.role}></BookingCard>
                        })
                    }
                </div>
            </Col>
        </>
    )
}