import React, { useState } from 'react';
import { Container, Button, Col, Row, Form, InputGroup } from 'react-bootstrap';
import instanceOfAxios from '../../app/axios/axios';
import { show } from '../../components/admin/Alerter/alerterSlice';
import { useDispatch } from 'react-redux';
import { FcApproval, FcHighPriority } from 'react-icons/fc';
import './contact.scss'

export default function Contact(props) {
    const [params, setParams] = useState({});
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [createCalcul, setCreateCalcul] = useState(true);
    const [errorCalcul, setErrorCalcul] = useState(false);
    const [error, setError] = useState([]);
    const [captcha, setCaptcha] = useState({ firstValue: 0, secondValue: 0, result: 0, isEqual: false });
    const dispatch = useDispatch();

    if (createCalcul) {
        let first = Math.floor(Math.random() * 10);
        let second = Math.floor(Math.random() * 10);
        setCaptcha({ firstValue: first, secondValue: second, result: first + second, isEqual: false });
        setCreateCalcul(false);
    }

    function handleChange(event) {
        let newParams = params;
        params[event.target.name] = event.target.value;
        if (event.target.name === 'privacy_policy') {
            params[event.target.name] = event.target.checked;
            setPrivacyPolicy(event.target.checked);
        }
        setParams(newParams);
    }

    function calculate(event) {
        let data = Object.assign({}, captcha);
        if (event.target.value == captcha.result) {
            data.userData = event.target.value;
            data.isEqual = true;
            params.captcha = data;
            setParams(params);
            setErrorCalcul(false);
        } else {
            data.isEqual = false;
            setErrorCalcul(true);
        }
        setCaptcha(data);
    }

    function send() {
        instanceOfAxios.post('/sendEmailFromContact', params)
            .then((res) => {
                dispatch(show({ message: 'Message envoyé', variant: 'success' }));
                setParams({});
                setPrivacyPolicy(false);
            })
            .catch((error) => {
                dispatch(show({ message: 'Erreur Lors de l\'envoi veuillez réessayer ultérieurement', variant: 'danger' }));
                setError(error.response.data.error);
            })
    }

    return (
        <Container style={{ paddingTop: '5vh', paddingBottom: '50px', maxWidth: '80vw', height: 'unset' }} className='noPadding'>
            <h2 className='mb-5'>Nous Contacter</h2>
            <Row>
                <Col md={4}>
                    <p><strong>Class Appart</strong></p>
                    <p>15 PASSAGE LONJON</p>
                    <p>34000 MONTPELLIER</p>
                    <p>Tél. : 09 63 69 87 42</p>
                    <p>Email :</p>
                    <p>contact@class-appart.com</p>
                    <p>info@class-appart.com</p>
                </Col>
                <Col md={8} className='noPadding'>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label htmlFor="first_name">Prénom *</Form.Label><br/>
                                {error['first_name'] ? <label htmlFor="first_name"><small style={{ color: "red" }}>{error['first_name']}</small></label> : <></>}
                                <Form.Control type="text" className="form-control" name="first_name" placeholder="Prénom" onChange={(e) => handleChange(e)} ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label htmlFor="last_name">Nom *</Form.Label><br/>
                                {error['last_name'] ? <label htmlFor="last_name"><small style={{ color: "red" }}>{error['last_name']}</small></label> : <></>}
                                <Form.Control type="last_name" className="form-control" name="last_name" placeholder="Nom" onChange={(e) => handleChange(e)} ></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label htmlFor="society">Société</Form.Label>
                                <Form.Control type="society" className="form-control" name="society" placeholder="Société" onChange={(e) => handleChange(e)} ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label htmlFor="phone">Téléphone</Form.Label>
                                <Form.Control type="phone" className="form-control" name="phone" placeholder="Téléphone" onChange={(e) => handleChange(e)} ></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label htmlFor="email">Email * </Form.Label><br/>
                                {error['email'] ? <label htmlFor="email"><small style={{ color: "red" }}>{error['email']}</small></label> : <></>}
                                <Form.Control type="email" className="form-control" name="email" placeholder="Email" onChange={(e) => handleChange(e)}></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Objet *</Form.Label><br/>
                            {error['subject'] ? <label htmlFor="subject"><small style={{ color: "red" }}>{error['subject']}</small></label> : <></>}
                            <Form.Control name="subject" as="select" onChange={handleChange}>
                                <option>Selectionner une option</option>
                                <option>Demande d'informations</option>
                                <option>Demande de devis</option>
                                <option>Proposition de bien</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Message *</Form.Label><br/>
                                {error['message'] ? <label htmlFor="message"><small style={{ color: "red" }}>{error['message']}</small></label> : <></>}
                                <Form.Control as="textarea" name='message' rows={3} onChange={(e) => handleChange(e)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Je ne suis pas un robot *</Form.Label><br/>
                                <Form.Label>combien font <strong>{captcha.firstValue} + {captcha.secondValue}</strong></Form.Label>
                                <InputGroup className="mb-2">
                                    <Form.Control type="text" className="form-control" placeholder="Resultat" onChange={e => calculate(e)}></Form.Control>
                                    <InputGroup.Text>{!errorCalcul && captcha.result == captcha.userData ? <FcApproval/> : <FcHighPriority/>}</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check
                                name='privacy_policy'
                                onClick={(e) => handleChange(e)}
                                type="checkbox"
                                className='text-justify'
                                style={{padding: '2vh'}}
                                label="En soumettant ce formulaire, j'accepte que les informations saisies soient traitées par CLASS APPART dans le cadre de ma demande de contact et de la relation commerciale qui peut en découler." />
                                {<a href='/CGU'>En savoir plus</a>}
                        </Form.Group>
                    </Row>
                    <Row style={{marginBottom: '2vh'}}>
                        <Col className='d-flex justify-content-end'>
                            <Button className="btn goldenHover" variant='primary' onClick={send} disabled={privacyPolicy && captcha.isEqual ? false : true} >Valider</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}