import { Modal, Row, Col, Button, Form, InputGroup, Card } from 'react-bootstrap';
import { useDispatch, useStore } from 'react-redux';
import { useEffect, useState } from 'react';
import instanceOfAxios from '../../../../app/axios/axios';
import { show } from '../../Alerter/alerterSlice';
import Spinner from '../../../spinner/Spinner';
import { FcPlus, FcCancel } from 'react-icons/fc';
import { MdDeleteForever } from 'react-icons/md'
import { AiOutlineArrowRight } from 'react-icons/ai'
import DeleteModal from '../../../generiqueModal/deleteModal/deleteModal';
import { FaRegSave } from 'react-icons/fa';

export default function UpdateOrCreatePage(props) {
    const [visibility, setVisibility] = useState(false);
    const [page, setPage] = useState(props.page);
    const [pending, setPending] = useState(false);
    const [rerender, setRerender] = useState(false);
    const [urlsFromReaderCarousel, setUrlsFromReaderCarousel] = useState([]);
    const [urlsFromReaderBackground, setUrlsFromReaderBackground] = useState([]);
    const role = useStore().getState().header.role;
    const dispatch = useDispatch();

    useEffect(() => {
        setPage(props.page);
    }, [props.page])

    if (typeof (page.content) != 'object') {
        page.content = JSON.parse(page.content);
        setPage(page);
    }

    function getInput(data, key = null, path = []) {
        if (typeof (data[key]) == 'object') {
            path.push(key)
            return getInput(data[key], Object.keys(data[key])[0], path);
        } else {
            return (
                <>
                    <Col xs={12}>
                        <FcPlus onClick={() => addInput(path.join('.'))} />
                    </Col>
                    <Col xs={12}>
                        {
                            Object.keys(data).map(function (key) {
                                return (
                                        <Form.Group>
                                            <Form.Label>{key}</Form.Label>
                                            <InputGroup className="mb-2">
                                                <Form.Control type='text' name={path.join('.') + '.' + key} size="sm" defaultValue={data[key]} onChange={e => handleTextChange(e)} />
                                                <InputGroup.Text><FcCancel onClick={() => removeInput(path.join('.') + '.' + key)} /></InputGroup.Text>
                                            </InputGroup>
                                        </Form.Group>
                                )

                            })
                        }
                    </Col>
                </>
            )
        }
    }

    // fonction pour ajouter un champ de text
    function addInput(stringPath) {
        let path = stringPath.split('.');
        let i = -1;
        goForAddAttr(page.content, path, i);
        setPage(page);
        setRerender(!rerender);
    }

    function goForAddAttr(object, path, index) {
        index++;
        if (typeof (object[path[index]]) == 'object') {
            goForAddAttr(object[path[index]], path, index);
        } else {
            let attrName = 'text' + (Object.keys(object).length + 1);
            object[attrName] = '';
        }
    }
    /////---------------------------------------------/////

    // fonction pour retirer un champ de text
    function removeInput(stringPath) {
        let path = stringPath.split('.');
        let i = -1;
        goForRemoveAttr(page.content, path, i);
        setPage(page);
        setRerender(!rerender);
    }

    function goForRemoveAttr(object, path, index) {
        index++;
        if (typeof (object[path[index]]) == 'object') {
            goForRemoveAttr(object[path[index]], path, index);
        } else {
            delete object[path[index]];
        }
    }
    /////---------------------------------------------/////

    // fonction pour ajouter les changement
    function handleTextChange(event) {
        let path = event.target.name.split('.');
        let i = -1;
        setAttr(page.content, path, event.target.value, i);
        setPage(page);
    }

    function setAttr(object, path, value, index) {
        index++;
        if ([path[index]] != path[path.length - 1]) {
            if (!object[path[index]]) {
                object[path[index]] = {};
            }
            setAttr(object[path[index]], path, value, index);
        } else {
            object[path[index]] = value;
        }
    }
    /////---------------------------------------------/////

    function handleFileChange(event) {
        if (window.FileReader) {
            let urls = Object.assign([], event.target.name == 'background' ? [] : urlsFromReaderCarousel);
            Array.from(event.target.files).forEach(photo => {
                let reader = new FileReader()
                reader.onload = function (r) {
                    urls.push({ url: r.target.result, name: photo.name });
                }
                reader.readAsDataURL(photo);
                setTimeout(() => {
                    if (event.target.name == 'background') {
                        setUrlsFromReaderBackground(urls);
                    } else {
                        setUrlsFromReaderCarousel(urls);
                    }
                }, 1000)
                page[event.target.name] = photo;
            });
            if (event.target.name == 'carousel') {
                page['carousel'] = event.target.files;
            }
            setPage(page);
        }
    }

    function removeFileFromReader(url, name, array) {
        let pictures = Object.assign([], array == 'background' ? urlsFromReaderBackground : urlsFromReaderCarousel);
        let filesUpdate = Object.assign([], page[array]);
        pictures = pictures.filter(picture => picture.url != url);
        filesUpdate = filesUpdate.filter(file => file.name != name);
        if (array == 'background') {
            setUrlsFromReaderBackground(pictures)
        } else {
            setUrlsFromReaderCarousel(pictures)
        }
        page[array] = filesUpdate;
        setPage(page);
    }

    function handleSubmit() {
        setPending(true);
        let formData = new FormData();

        if (props.page) {
            page._method = 'PATCH';
        }

        Object.keys(page).map(key => {
            if (key == 'carousel') {
                Object.assign([], page.carousel).map(file => {
                    formData.append('carousel[]', file);
                })
            } else {
                if (typeof (page[key]) == 'object' && key != 'background') {
                    page[key] = JSON.stringify(page[key]);
                }
                formData.append(key, page[key]);
            }
        });

        instanceOfAxios.post('/admin/page/' + props.page.id, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(res => {
                dispatch(show({ message: 'Sauvegarde effectuée', variant: 'success' }));
                setPending(false);
                setPage(res.data.page);
                props.onChange();
            })
            .catch(err => {
                dispatch(show({ message: 'Erreur lors de la sauvegarde', variant: 'danger' }));
                setPending(false);
            })
    }

    function handleDelete(id) {
        setPending(true);
        instanceOfAxios.delete('/admin/page/' + props.page.id + '/pictures/' + id)
            .then((res) => {
                dispatch(show({ message: 'Element supprimé', variant: 'success' }));
                setPending(false);
                props.onChange();
            })
            .catch((error) => {
                dispatch(show({ message: 'Erreur lors de la suppression', variant: 'danger' }));
                setPending(false);
            })
    }

    return (
        <>
            <Button variant='secondary' onClick={() => setVisibility(!visibility)}> Modifier </Button>
            <Modal
                style={{ zIndex: '10000' }}
                scrollable
                centered
                size='xl'
                show={visibility}
                onHide={() => setVisibility(!visibility)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            props.page ?
                                'Modification de la page ' + props.page.type
                                :
                                'Création du contenu d\'une page'
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {
                            props.page.type == 'home' &&
                            <>
                                <Col xs={12} style={{ marginTop: '5vh' }}>
                                    <h2>Image de fond du site</h2>
                                    <Form.File
                                        id="custom-file-translate-scss"
                                        label={'Format jpeg, jpg, png'}
                                        lang="fr"
                                        custom
                                        data-browse="Fichier"
                                        name='background'
                                        onChangeCapture={(e) => handleFileChange(e)}
                                        on
                                    />
                                </Col>
                                <Col xs={12} sm={5}>
                                    <Row>
                                        <Col xs={12} className='d-flex justify-content-center'>
                                            <h4>Image enregistrée</h4>
                                        </Col>
                                        <Col xs={12} className='d-flex justify-content-center align-items-center'>
                                            <Card style={{ width: '18rem' }} key='background'>
                                                <Card.Img variant="top" src={page.pictures_url.background} />
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                                {
                                    urlsFromReaderBackground.length > 0 &&
                                    <Col xs={12} sm={2} className='d-flex justify-content-center align-items-center'>
                                        <AiOutlineArrowRight style={{ width: '100%', height: '50%' }} />
                                    </Col>
                                }
                                <Col xs={12} sm={5}>
                                    {
                                        urlsFromReaderBackground.length > 0 &&
                                        <Row>
                                            <Col xs={12} className='d-flex justify-content-center'>
                                                <h4>Image séléctionné</h4>
                                            </Col>
                                            <Col xs={12} className='d-flex justify-content-center'>
                                                {urlsFromReaderBackground.map((url, index) => {
                                                    return (
                                                        <Card style={{ width: '18rem', position: 'relative' }} key={url + index}>
                                                            <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                                                <Button variant="danger" className="icon-button-sad" onClick={() => removeFileFromReader(url.url, url.name, 'background')} size="sm">
                                                                    <MdDeleteForever />
                                                                </Button>
                                                            </div>
                                                            <Card.Img variant="top" src={url.url} />
                                                        </Card>)
                                                })}
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                <Col xs={12} style={{ marginTop: '5vh' }}>
                                    <h2>Images du caroussel</h2>
                                    <Form.File
                                        id="custom-file-translate-scss"
                                        label={'Format jpeg, jpg, png'}
                                        lang="fr"
                                        custom
                                        data-browse="Fichier"
                                        name='carousel'
                                        onChangeCapture={(e) => handleFileChange(e)}
                                        multiple
                                    />
                                </Col>
                                <Col>
                                    <h4>Images enregistrée</h4>
                                    <Row className='d-flex justify-content-center'>
                                        {
                                            page.pictures_url.carousel && page.pictures_url.carousel.map(
                                                image =>
                                                    <Col xs={4}>
                                                        <Card style={{ width: '18rem', height: '10rem' }} key={image}>
                                                            <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                                                <DeleteModal as={Col} role={role} deleteFunction={() => handleDelete(image.id)} />
                                                            </div>
                                                            <Card.Img variant="top" src={image.url} style={{ height: '100%' }} />
                                                        </Card>
                                                    </Col>
                                            )
                                        }
                                    </Row>
                                </Col>
                                <Col xs={12}>
                                    {
                                        urlsFromReaderCarousel.length > 0 &&
                                        <>
                                            <h4>Images séléctionné</h4>
                                            <Row className='d-flex justify-content-center'>
                                                {urlsFromReaderCarousel.map((url, index) => {
                                                    return (
                                                        <Col xs={4}>
                                                            <Card style={{ width: '18rem', height: '10rem' }} key={url + index}>
                                                                <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                                                    <Button variant="danger" className="icon-button-sad" onClick={() => removeFileFromReader(url.url, url.name, 'carousel')} size="sm">
                                                                        <MdDeleteForever />
                                                                    </Button>
                                                                </div>
                                                                <Card.Img variant="top" src={url.url} />
                                                            </Card>
                                                        </Col>)
                                                })}
                                            </Row>
                                        </>
                                    }
                                </Col>
                            </>
                        }
                    </Row>
                    {
                        Object.entries(page.content).map((value) =>
                            <Row style={{ marginTop: '5vh' }}>
                                <Col xs={12}>
                                    <h2>{value[0]}</h2>
                                </Col>
                                {
                                    getInput({ [value[0]]: value[1] }, value[0])
                                }
                            </Row>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        pending ?
                            <Spinner />
                            :
                            <Button 
                                className='whiteSVG'
                                variant='secondary'
                                onClick={() => {
                                    handleSubmit()
                                }}> <FaRegSave/> Enregistrer </Button>
                    }
                    <Button variant='secondary'
                        onClick={() => setVisibility(!visibility)}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}