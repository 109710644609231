import ViewerMonth from './viewerMonth/viewerMonth';
import { Row, Container, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getApartmentForUpdate } from '../../apartmentSlice'
import BlockedList from '../../blockList/blockedList';

function ViewerYear(props) {
    return (
        <Container className='reducePadding'>
            <Row className='d-flex justify-content-center align-items-center noPadding'>
                <Col md={12} lg={3} className='d-flex justify-content-center' style={{marginBottom: '2vh'}}>
                    <BlockedList dates={props.dateUse} handleChange={props.handleChange} year={props.year}/>
                </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
                    <Col style={{textAlign: 'center'}}>
                        <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-cal-ca'/>
                        <p style={{ margin: '0', fontSize: '.8rem' }}>Bloqué par ClassAppart</p>
                    </Col>
                    <Col style={{textAlign: 'center'}}>
                        <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-cal-prop'/>
                        <p style={{ margin: '0', fontSize: '.8rem' }}>Bloqué par le propriétaire</p>
                    </Col>
                    <Col style={{textAlign: 'center'}}>
                        <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-book-need'/>
                        <p style={{ margin: '0' , fontSize: '.8rem'}}>Réservation à payer</p>
                    </Col>
                    <Col style={{textAlign: 'center'}}>
                        <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-book-wait'/>
                        <p style={{ margin: '0', fontSize: '.8rem' }}>Réservation en attente</p>
                    </Col>
                    <Col style={{textAlign: 'center'}}>
                        <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-book-first'/>
                        <p style={{ margin: '0', fontSize: '.8rem' }}>Réservation acompte versé</p>
                    </Col>
                    <Col style={{textAlign: 'center'}}>
                        <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-book-payed'/>
                        <p style={{ margin: '0', fontSize: '.8rem' }}>Réservation payé</p>
                    </Col>
                    <Col style={{textAlign: 'center'}}>
                        <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-book-exed'/>
                        <p style={{ margin: '0', fontSize: '.8rem' }}>Réservation en trop perçu</p>
                    </Col>
                </Row>
            <Row noGutters>
                <ViewerMonth month={0} dateUse={props.dateUse} year={props.year} />
                <ViewerMonth month={1} dateUse={props.dateUse} year={props.year} /> 
                <ViewerMonth month={2} dateUse={props.dateUse} year={props.year} />
                <ViewerMonth month={3} dateUse={props.dateUse} year={props.year} />
                <ViewerMonth month={4} dateUse={props.dateUse} year={props.year} />
                <ViewerMonth month={5} dateUse={props.dateUse} year={props.year} />
            </Row>
            <Row noGutters>
                <ViewerMonth month={6} dateUse={props.dateUse} year={props.year} />
                <ViewerMonth month={7} dateUse={props.dateUse} year={props.year} />
                <ViewerMonth month={8} dateUse={props.dateUse} year={props.year} />
                <ViewerMonth month={9} dateUse={props.dateUse} year={props.year} />
                <ViewerMonth month={10} dateUse={props.dateUse} year={props.year} />
                <ViewerMonth month={11} dateUse={props.dateUse} year={props.year} />
            </Row>
        </Container>)
}


const mapStateToProps = state => {
    return {
        apartment: getApartmentForUpdate(state)
    };
}

export default connect(mapStateToProps)(ViewerYear);