import './OurTeam.scss';
import { Row, Col } from 'react-bootstrap';

export default function OurTeam() {
    return (
        <Row className='d-flex justify-content-center'>
            <Col xs={12}>
                <h2 style={{ textDecoration: 'underline 1px' }}>Notre équipe</h2>
            </Col>
            <div class="person">
                <div class="content-team">
                    <div class="content-team-inner">
                        <div class="circle"></div>
                        <img class="img berengere" src='/images/team/berengere.webp' loading="lazy"/>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="name">Bérengère</div>
                <div class="title">Gérante</div>
            </div>
            <div class="person">
                <div class="content-team">
                    <div class="content-team-inner">
                        <div class="circle"></div>
                        <img class="img img1" src='/images/team/nicola.webp' loading="lazy"/>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="name">Nicolas</div>
                <div class="title">Chargé de clientèle</div>
            </div>
            <div class="person">
                <div class="content-team">
                    <div class="content-team-inner">
                        <div class="circle"></div>
                        <img class="img tristan" src='/images/team/tristan.webp' loading="lazy"/>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="name">Tristan</div>
                <div class="title">Chef de projet digital</div>
            </div>
            <div class="person">
                <div class="content-team">
                    <div class="content-team-inner">
                        <div class="circle"></div>
                        <img class="img img1" src='/images/team/lilou.webp' loading="lazy"/>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="name">Lilou</div>
                <div class="title">Assistante Chargée de clientèle</div>
            </div>
            <div class="person">
                <div class="content-team">
                    <div class="content-team-inner">
                        <div class="circle"></div>
                        <img class="img diana" src='/images/team/diana.webp' loading="lazy"/>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="name">Diana</div>
                <div class="title">Gouvernante</div>
            </div>
        </Row>
    )
}