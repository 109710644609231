import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import instanceOfAxios from "../../../app/axios/axios";
import Spinner from '../../spinner/Spinner';
import PageCard from './card/pageCard';

export default function PageContentManager(props) {
    const [pages, setPages] = useState([]);
    const [pending, setPending] = useState(true);


    useEffect(() => {
        if (pending) {
            getPages();
        }
    }, [pending])

    function getPages() {
        instanceOfAxios.get('/admin/page')
            .then(res => {
                setPages(res.data.pages);
                setPending(false);
            })
    }

    return (
        <div className='users slider-list-left slide-in-list-left'>
            <Row>
                <Col md={12} className='text-center'>
                    <h2>Gestion des pages</h2>
                </Col>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%'
                        }}></div>
                </Col>
            </Row>
            <Row style={{ paddingTop: '2vh' }} className='d-flex justify-content-center'>
                {!pending ?
                    <>
                        {
                            pages.length > 0 ?
                                pages.map(page => <PageCard page={page} onChange={() => setPending(true)} />)
                                :
                                <h2>Aucune page à afficher</h2>
                        }
                    </>
                    :
                    <Spinner />}
            </Row>
        </div>
    )
}