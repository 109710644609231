import { useState } from "react"
import { Modal, Button, Form, Col } from "react-bootstrap"
import instanceOfAxios from '../../../../app/axios/axios';
import Spinner from "../../../spinner/Spinner";
import { AiOutlineSend } from 'react-icons/ai';
import { show } from "../../Alerter/alerterSlice";
import { useDispatch } from "react-redux";

export default function SendInvoiceOrBillrider(props) {
    const [visibility, setVisibility] = useState(false);
    const [isSubmit, setisSubmit] = useState(false);
    const [type, setType] = useState('invoice');
    const [email, setEmail] = useState('account');
    const dispatch = useDispatch();
    const trad = { 'account': ' du compte', 'contact': 'Contact lié à la réservation', 'invoice': 'Facture', 'billrider': 'Avoir' };
    const correspond = { 'invoice': 'url', 'billrider': 'avoir' }
    function send() {
        setisSubmit(true);
        let url = props.urls[correspond[type]].replace('invoices', 'admin/invoices/send');
        instanceOfAxios.post(url, { email: email })
            .then(res => {
                setisSubmit(false);
                dispatch(show({ message: 'E-mail envoyé', variant: 'success' }));
                setVisibility(false);
            })
            .catch(err => {
                setisSubmit(false);
                dispatch(show({ message: 'E-mail non envoyé', variant: 'danger' }));
            });
    }

    return (
        <>
            <Button
                variant="secondary"
                className="apartmentText whiteSVG"
                size='sm'
                onClick={() => setVisibility(true)}><AiOutlineSend /></Button>
            <Modal
                show={visibility}
                onHide={() => {
                    setVisibility(false);
                    setType('invoice');
                    setEmail('account');
                }}
                scrollable
                backdrop="static"
                backdropClassName="backdropFourth"
                centered
                style={{ zIndex: '20005' }}
                animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Envoi de document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <small className='text-center'>Par défault le document envoyé est une facture à l'adresse mail du compte</small>
                    <Form.Group as={Col} md={12}>
                        <Form.Label>Document à envoyer</Form.Label>
                        <Form.Control size="sm" as="select" onChange={(e) => setType(e.target.value)}>
                            <option value='invoice'>Facture</option>
                            {
                                props.urls['avoir'] &&
                                <option value='billrider'>Avoir</option>
                            }
                        </Form.Control>
                    </Form.Group>
                    {
                        props.isBooking ?
                            <>
                                <Form.Group as={Col} md={12}>
                                    <Form.Label>Envoyer à </Form.Label>
                                    <Form.Control size="sm" as="select" onChange={(e) => setEmail(e.target.value)}>
                                        <option value='account'>E-mail du compte</option>
                                        <option value='contact'>Contact lié à la réservation</option>
                                    </Form.Control>
                                </Form.Group>
                                <p className='text-center'>Le document "{trad[type]}" sera envoyé à l'email {trad[email]}</p>
                            </>
                            :
                            <></>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        isSubmit ?
                            <Spinner />
                            :
                            <Button onClick={send}>Envoyer</Button>
                    }
                </Modal.Footer>
            </Modal>
        </>)
}