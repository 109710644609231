import React from 'react';
// components:
import Marker from './markers/markers';

// examples:
import GoogleMap from './googleMap/googleMap';
import { useState } from 'react';
import { useEffect } from 'react';

export default function SearchResultMap(props) {
  const [isOver, setIsOver] = useState(0);
  const [mapProps, setMapProps] = useState({
    center: {
      lat: 43.6091,
      lng: 3.8797
    },
    zoom: 13
  });

  useEffect(() => {
    if (props.markers.length == 1 && props.markers[0].lat && props.markers[0].lng) {
      setMapProps({
        center: {
          lat: props.markers[0].lat,
          lng: props.markers[0].lng
        },
        zoom: 11
      })
    }
  }, [props.markers]);

  let urlParams = new URLSearchParams(window.location.search);

  if (isOver != props.isOver) {
    setIsOver(props.isOver);
  }

  if (urlParams.has('location') && urlParams.get('location') == 'plage') {
    setMapProps({
      center: {
        lat: 43.55647157490178,
        lng: 3.981335662627927
      },
      zoom: 11
    })
  }
  return (
    <div className={props.class} id='mapresultsearch'>
      <GoogleMap
        center={mapProps.center}
        zoom={mapProps.zoom}
        bootstrapURLKeys={{
          key: "AIzaSyAn8ZF23BJFXwMHGzL-BGiZ0zEDS9mGfdo",
        }}
        yesIWantToUseGoogleMapApiInternals
      >
        {props.markers.map((apartment) => {
          if (apartment && apartment.isOnMap) {
            return (
              <Marker
                key={apartment.id}
                text={apartment.minRate}
                lat={apartment.lat}
                lng={apartment.lng}
                apartment={apartment}
                nightPrice={props.nightPrices ? props.nightPrices[apartment.id] : 0}
                isShowing={apartment.id == isOver ? true : false}
              />
            )
          }
        })}
      </GoogleMap>
    </div>
  );
}