import moment from 'moment';
import { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Pagination } from 'react-bootstrap';
import instanceOfAxios from "../../../../app/axios/axios";
import CalendarLine from '../../calendar/calendarLine';
import Spinner from '../../../spinner/Spinner';
import { AiOutlineSearch } from 'react-icons/ai';

export default function TabApartmentsDispo(props) {
    const [calendars, setCalendars] = useState([]);
    const [params, setParams] = useState(
        {
            period: 6,
            begin_date: moment().startOf('month').set("hour", 0).set("minute", 0).format(),
            end_date: moment().endOf('month').add(1, 'month').set("hour", 18).set("minute", 0).format()
        })
    const [pending, setPending] = useState(true);
    const [month, setMonth] = useState(new Date().getMonth());

    let items = [
        <Pagination.Item key={moment().year} id={moment().year} active='true'>
            {moment(new Date(moment().year(), month, 1)).format('MMMM YYYY') + ' - ' + moment(new Date(moment().year(), month + 1, 1)).format('MMMM YYYY')}
        </Pagination.Item>
    ];

    useEffect(() => {
        if (pending) {
            getCalendar();
        }
    }, [pending]);

    function handleChange(event) {
        params[event.target.name] = event.target.value;
        if (event.target.value == '') {
            delete params[event.target.name];
        }
        setParams(params);
    }

    function getCalendar() {
        let query = new URLSearchParams(params).toString();
        instanceOfAxios.get('/admin/apartmentsDispo?' + query)
            .then(res => {
                setPending(false);
                setCalendars(res.data.apartments);
            })
            .catch(res => {
                setPending(false);
            })
    }

    function prev() {
        setMonth(month - 1);
        params.begin_date = moment(params.begin_date).subtract(1, 'month').format();
        params.end_date = moment(params.end_date).subtract(1, 'month').format();
        setParams(params);
        setPending(true);
    }

    function next() {
        setMonth(month + 1);
        params.begin_date = moment(params.begin_date).add(1, 'month').format();
        params.end_date = moment(params.end_date).add(1, 'month').format();
        setParams(params);
        setPending(true);
    }

    return (
        <div className='users'>
            <Row style={{ paddingTop: '2vh' }} className='d-flex justify-content-center'>
                <Col xs={12} className='d-flex justify-content-center'>
                    <Pagination style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                        <Pagination.Prev id={'prev'} onClick={() => prev()} disabled={pending} />
                        {items}
                        <Pagination.Next id={'next'} onClick={() => next()} disabled={pending} />
                    </Pagination>
                </Col>
                <Col xs={12} md={4} lg={2} className='d-flex flex-column justify-content-center text-center'>
                    <Form.Group>
                        <Form.Label>Nombre de nuit disponibles</Form.Label>
                        <Form.Control type='number' className='text-center' name='period' size="sm" defaultValue={params.period} onChange={(e) => handleChange(e)} />
                    </Form.Group>
                    <Button variant='secondary' className='whiteSVG' onClick={() => setPending(true)}>Rechercher <AiOutlineSearch/></Button>
                </Col>
                <Col xs={12} className='d-flex flex-column justify-content-center text-center'>
                    <Row className='mb-3 mt-4'>
                        <Col style={{ textAlign: 'center' }}>
                            <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-cal-ca' />
                            <p style={{ margin: '0', fontSize: '.8rem' }}>Bloqué par ClassAppart</p>
                        </Col>
                        <Col style={{ textAlign: 'center' }}>
                            <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-cal-prop' />
                            <p style={{ margin: '0', fontSize: '.8rem' }}>Bloqué par le propriétaire</p>
                        </Col>
                        <Col style={{ textAlign: 'center' }}>
                            <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-book-need' />
                            <p style={{ margin: '0', fontSize: '.8rem' }}>Réservation à payer</p>
                        </Col>
                        <Col style={{ textAlign: 'center' }}>
                            <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-book-wait' />
                            <p style={{ margin: '0', fontSize: '.8rem' }}>Réservation en attente</p>
                        </Col>
                        <Col style={{ textAlign: 'center' }}>
                            <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-book-first' />
                            <p style={{ margin: '0', fontSize: '.8rem' }}>Réservation acompte versé</p>
                        </Col>
                        <Col style={{ textAlign: 'center' }}>
                            <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-book-payed' />
                            <p style={{ margin: '0', fontSize: '.8rem' }}>Réservation payé</p>
                        </Col>
                        <Col style={{ textAlign: 'center' }}>
                            <div style={{ width: '20px', height: '20px', margin: '0 auto' }} className='in-progress-book-exed' />
                            <p style={{ margin: '0', fontSize: '.8rem' }}>Réservation en trop perçu</p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} className='d-flex justify-content-center'>
                    {pending ?
                        <>
                            <Spinner />
                        </>
                        :
                        <>
                            {
                                calendars.length > 0 ?
                                    <CalendarLine dateUse={calendars} year={moment().year()} month={month} />
                                    :
                                    <h2>Aucun calendrier à afficher</h2>
                            }
                        </>
                    }
                </Col>
            </Row>
        </div>
    )
}