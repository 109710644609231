import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, connect } from 'react-redux';
import { changeValue } from './searchBarSlice';

const SupplementaryFilter = (props) => {
    const dispatch = useDispatch();

    //elles vont etre definie sur le front
    const [filter, setFilter] = useState();


    useEffect(() => {
        dispatch(changeValue({ type: 'filter', payload: filter }));
    }, [filter]);


    return (
        <Col lg={12}>
            <Row className='noPadding d-flex justify-content-center'>
                <Col xs={12} className='noPadding d-flex justify-content-center'>
                    <h4>Filtres</h4>
                </Col>
                <Col xs={12} lg={2} md={4}  className='d-flex justify-content-center'>
                    <Form.Group>
                        <Form.Check type="checkbox" label="Villa" checked={filter == 'Villa'}
                            key='villa'
                            onChange={((event) => event.target.checked ? setFilter('Villa') : setFilter(undefined))} />
                    </Form.Group>
                </Col>

                <Col xs={12} lg={2} md={4}  className='d-flex justify-content-center'>
                    <Form.Group>
                        <Form.Check type="checkbox" label="Montpellier" checked={filter == 'mtp'}
                            key='mtp'
                            onChange={((event) => event.target.checked ? setFilter('mtp') : setFilter(undefined))} />
                    </Form.Group>
                </Col>

                <Col xs={12} lg={2} md={4}  className='d-flex justify-content-center'>
                    <Form.Group>
                        <Form.Check type="checkbox" label="Bord de mer" checked={filter == 'plage'}
                            key='plage'
                            onChange={((event) => event.target.checked ? setFilter('plage') : setFilter(undefined))} />
                    </Form.Group>
                </Col>

                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-4'>
                    <div className='separator'
                        style={{
                            border: '1px solid rgba(0,0,0,.125)',
                            width: '90%'
                        }}></div>
                </Col>
            </Row>
        </Col>
    )

}

export default SupplementaryFilter;