export const DataUpdate = [
    {
        version: '2.3',
        list:[
            {
                title: `Ajout d'agence et de classes de taxes C'est possible!! Rendez-vous dans l'onglet taxes et agences.`,
                items: [
                    `Il vous est maintenant possible d'ajouter des agences.`,
                    `Il est maintenant possible d'ajouter des classes de tarifs sur des agences.`,]
            },
            {
                title: `Liaison des logements sur différentes agences. Rendez-vous dans l'onglet tarifs du descriptif de logement.`,
                items: [
                    `Il est maintenant possible de relier des logements à des agences. Par default l'agence de montpellier est séléctionnée`,
                    `Il est maintenant possible de relier des logements à une classes de tarifs renseignée sur l'agence reliée`
                ]
            },
            {
                title: `Nouvelle version du calcule des loyers propriétaires`,
                items: [
                    `Une nouvelle version du calcule des loyers propriétaires est en ligne car l'ancienne version ne prenait pas en compte les tarifs spécifiques.`
                ]
            }
        ]
    },
    {
        version: '2.3.1',
        list:[
            {
                title: `Vous pouvez désormais archiver ou désarchiver des logements! oui oui c'est possible =)`,
                items: [
                    `Rendez-vous dans l'onglet logement ou vous retrouverez un nouveau bouton pour archiver`,
                    `Lors du passage en archive d'un logement ce dernier est automatiquement dépublié et retiré de la page d'accueil s'il s'y trouvait`,
                    `vous retrouverez également un nouvel onglet ou se trouvent tous les logements archivés`]
            }
        ]
    },
    {
        version: '2.3.2',
        list:[
            {
                title: `Vous pouvez désormais archiver ou désarchiver des tarifs de logement et visualiser l'historique`,
                items: [
                    `Rendez-vous dans le descriptif d'un logement à l'onglet tarifs`,
                    `Sur chaque cartouche de tarifs vous trouverez maintenant un petit bouton vous affichant l'historique des tarifs`,
                    `Vous trouverez également un autre bouton non plus pour supprimmer mais pour Archiver le tarifs en question`,]
            },
            {
                title: `Status réservation`,
                items: [
                    `Lors du passage d'une réservation en status annulé, un avoir est maintenant généré`]
            }
        ]
    }
    ,
    {
        version: '2.4.1',
        list:[
            {
                title: `Il est désormais possible de libérer le planning d'un logement loué`,
                items: [
                    `Rendez-vous dans le détail d'une réservation où vous trouverez un nouveau bouton nommé Planning.`,
                    `Lors de la relocation d'un logement sur un planning ouvert les données de loyer propriétaire sont automatiquement recalculées.`
                ]
            },
            {
                title: `Ajout de valeur supplémentaire lors de la création d'une réservation`,
                items: [
                    `Il n'est plus nécessaire d'ajouter des options sur une réservation d'un site tier pour la com et la différence, lors de la création de la réservation vous trouverez 2 nouveaux encarts si vous renseignez un site tier`,
                    `Vous pouvez également appliquer une remise / majoration directement lors de la création d'une réservation`
                ]
            },
            {
                title: `Pro Format`,
                items: [
                    `Les factures sont maintenant générées en pro format automatiquement pour les options ainsi que pour les réservations`,
                    `Dans le détail d'une réservation vous pourrez donc maintenant créer des factures pour les options et où vous trouverez un bouton pour créer la facture final`,
                    `Pour ce qui est des options vous trouverez également un nouveau bouton ouvrant une popup avec la liste des factures de l'option`,
                ]
            },
            {
                title: `Loyer Propriétaire`,
                items: [
                    `Vous pouvez maintenant générer des factures pour toutes les retenues effectuées sur un mois de loyer versé au propriétaire.`,
                    `Attention le Pro-format s'applique également sur ces factures`
                ]
            },
            {
                title: `Vous pouvez maintenant ajouter des majorations et réductions sur les logements`,
                items: [
                    `Rendez-vous dans l'onglet Tarifs du descriptif d'un logement pour gérer les Majorations / Réductions`,
                    `Ces nouvelles ressources n'affecte en rien la gestion des tarifs déjà en vigueur, il ne s'agis que d'une surcouche vous permettant d'affecter les tarifs`,
                ]
            },
            {
                title: `Envoi des factures`,
                items: [
                    `L'envoi des factures est réparé sur les interfaces ou il ne fonctionnait pas`,
                    `Avec la présence de facture sûr de nouvelles entités il est également possible d'envoyer les factures depuis ces nouveaux endroits`
                ]
            },
            {
                title: `Nouveau moyen de paiement`,
                items: [
                    `Lors de la mises à jours d'un paiement vous trouverez maintenant option de paiement 'Site Externe'`
                ]
            }
        ]
    },
    {
        version: '2.4.2',
        list: [
            {
                title: 'Comptabilité des loyers',
                items: [
                    `Ajout d'un bouton pour supprimer les loyers en doublon`
                ]
            },
            {
                title: 'Réservation',
                items: [
                    `Changement intitulé et modifiable lors de la création d'une réservation du champ référence airBnb en référence site passerelle`,
                    'Cette valeur à également été ajouté sur les factures(non retro actif)'
                ]
            }
        ]
    }
]
