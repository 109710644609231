import React from 'react';
import { useDispatch, connect } from 'react-redux';
import ApartmentsList from './list/ApartmentList';
import CreateApartment from './create/CreateApartment'
import { getShowCreate, getShowList } from './apartmentSlice';
const Apartments = ({ showCreate, showList}) => {
    let classNameForCreate = 'slider-create-right slide-out-create-right';
    let classNameForList = 'slider-list-left slide-out-list-left';
    
    if(showCreate){
        classNameForCreate = 'slider-create-right slide-in-create-right'; 
    }

    if(showList){
        classNameForList = 'slider-list-left slide-in-list-left'; 
    }

    return (
        <div id='apartments' className='pb-2' style={{width: '100%', height: '100%'}}>
            {showCreate && <CreateApartment className={classNameForCreate}/>}
            <ApartmentsList className={classNameForList}/>
        </div>)
}

function mapStateToProps(state) {
    return {
        showCreate: getShowCreate(state),
        showList: getShowList(state)
    }
}

export default connect(mapStateToProps)(Apartments);