import { Col, Card, Table } from 'react-bootstrap';
import moment from 'moment';

export default function ViewerMonth(props) {
    const month = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    const date = new Date(props.year, props.month, 1);

    function firstDayOfMonth() {
        let dateObject = date;
        let firstDay = moment(dateObject).startOf("month").format("d");
        return firstDay != 0 ? firstDay : 7;
    };

    const blanks = [];

    let datas = [...props.dateUse[0], ...props.dateUse[1]];
    datas = datas.sort((a, b) => b.end_date_hour > a.end_date_hour ? 1 : -1);

    for (let i = 1; i < firstDayOfMonth(); i++) {

        blanks.push(
            <td key={'blank' + i}></td>
        );
    }

    const daysInMonth = [];
    for (let d = 1; d <= moment(date).daysInMonth(); d++) {

        let caseDate = moment(new Date(props.year, props.month, d)).format("YYYY-MM-DD");

        let className = '';

        if (props.dateUse) {
            for (let i = 0; i < datas.length; i++) {
                // Date de debut et fin de l'objet
                let beginDateOfIndex = moment(datas[i].begin_date_hour).format("YYYY-MM-DD");
                let endDateOfIndex = moment(datas[i].end_date_hour).format("YYYY-MM-DD");
                if(datas[i].re_rent_at){
                    endDateOfIndex = moment(datas[i].re_rent_at).format("YYYY-MM-DD");
                }

                // date de l'objet d'avant
                let beginDateOfPreviousIndex = i > 0 ? moment(datas[i - 1].begin_date_hour).format("YYYY-MM-DD") : null;

                // date de l'objet d'avant
                let endDateOfNextIndex = datas[i + 1] ? moment(datas[i + 1].end_date_hour).format("YYYY-MM-DD") : null;

                if(endDateOfNextIndex && datas[i + 1].re_rent_at){
                    endDateOfNextIndex = moment(datas[i + 1].re_rent_at).format("YYYY-MM-DD");
                }

                if(caseDate > beginDateOfIndex && caseDate < endDateOfIndex){
                    className = getColor(
                        (datas[i].reason || datas[i].state), 
                        (datas[i].state ? 'book' : 'cal'), 
                        'in-progress')
                }else if(endDateOfNextIndex && caseDate == beginDateOfIndex && caseDate == endDateOfNextIndex){
                    className = getColor(
                        (datas[i + 1].reason ? datas[i + 1].reason : datas[i + 1].state), 
                        (datas[i + 1].state ? 'book' : 'cal'), 
                        'end',
                        (datas[i].reason || datas[i].state),
                        (datas[i].state ? 'book' : 'cal'), 
                        'begin')
                }else if((!endDateOfNextIndex && caseDate == beginDateOfIndex) || caseDate == beginDateOfIndex && (endDateOfNextIndex && caseDate != endDateOfNextIndex)){
                    className = getColor(
                        (datas[i].reason || datas[i].state), 
                        (datas[i].state ? 'book' : 'cal'), 
                        'begin')
                }else if((caseDate == endDateOfIndex && (beginDateOfPreviousIndex && caseDate != beginDateOfPreviousIndex)) || 
                        (caseDate == endDateOfIndex && !beginDateOfPreviousIndex)){
                    className = getColor(
                        (datas[i].reason || datas[i].state), 
                        (datas[i].state ? 'book' : 'cal'), 
                        'end')
                }
            }
        }
        daysInMonth.push(
            <td colSpan='1' key={d + ' ' + caseDate} className={'tdCalendar ' + className}>{d}</td>
        );
    }

    function getColor(firstIndex, firstEntity, firstState, secondIndex = null, secondEntity = null, secondState = null) {
        let state = { 
            'à payer': 'need', 
            'en attente': 'wait', 
            'acompte versé': 'first', 
            'payé': 'payed', 
            'no Show': 'payed', 
            'trop perçu': 'exed', 
            'Blocage ClassAppart': 'ca',
            'Blocage propriétaire': 'prop'
        };
        return secondIndex && secondState && secondEntity
            ?
            firstState + '-' + firstEntity + '-' + state[firstIndex] + '-' + secondState + '-' + secondEntity + '-' + state[secondIndex] 
            :
            firstState + '-' + firstEntity + '-' + state[firstIndex];
    }

    var totalSlots = [...blanks, ...daysInMonth];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
        if (i % 7 !== 0) {
            cells.push(row);
        } else {
            rows.push(cells);
            cells = [];
            cells.push(row);
        }
        if (i === totalSlots.length - 1) {
            rows.push(cells);
        }
    });

    let daysinmonth = rows.map((d, i) => {
        return <tr key={'rows' + i}>{d}</tr>;
    });

    return (
        <Col xs={12} lg={4}>
            <Card>
                <Card.Header>
                    <strong>{month[props.month]}</strong>
                </Card.Header>
                <Card.Body>
                    <Table striped bordered hover responsive size='sm'>
                        <thead>
                            <tr>
                                <th>Lun</th>
                                <th>Mar</th>
                                <th>Mer</th>
                                <th>Jeu</th>
                                <th>Ven</th>
                                <th>Sam</th>
                                <th>Dim</th>
                            </tr>
                        </thead>
                        <tbody>
                            {daysinmonth}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </Col>);
}