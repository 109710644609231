import { useState } from 'react';
import { Col, Container, Form, Row, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { getApartmentForUpdate, setUpdater } from '../apartmentSlice'
import instanceOfAxios from '../../../../app/axios/axios';
import { show } from '../../Alerter/alerterSlice';
import Spinner from '../../../spinner/Spinner';
import { MdOpenInNew } from 'react-icons/md';
import { FaRegSave } from 'react-icons/fa';

function StepTen(props) {
    const [errors, setErrors] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [reload, setReload] = useState(false);
    const [labels, setLabels] = useState({ inventory: 'Format PDF', place_state: 'Format PDF'});
    const [sizes, setSizes] = useState({ inventory: '0', place_state: '0'});
    const [formData, setFormData] = useState(new FormData());
    const dispatch = useDispatch();

    function handleSubmit() {
        setIsSubmit(true);
        instanceOfAxios.post('/admin/apartment/' + props.apartment.id + '/addFiles', formData)
            .then(res => {
                setIsSubmit(false);
                dispatch(setUpdater(res.data.apartment));
                dispatch(show({ message: 'fichier enregistré', variant: 'success' }));
                setErrors([]);
            })
            .catch(err => {
                setErrors(err.response.data.error);
                setIsSubmit(false);
                dispatch(show({ message: 'Erreur lors de l\'enregistrement', variant: 'danger' }));
            })
    }

    function handleChange(e) {
        formData.set(e.target.name, e.target.files[0]);
        labels[e.target.name] = e.target.files[0].name;
        sizes[e.target.name] = (e.target.files[0].size / 1000000).toFixed(2);
        setLabels(labels);
        setSizes(sizes);
        setReload(!reload);
        setFormData(formData);
    }

    return (
        <Container className='pb-3'>
            <Row className="d-flex justify-content-center">
                <Col xs={12} className="text-center">
                    <p>10Mo maximum par fichier*</p>
                </Col>
                <Col xs={12} md={6} className="d-flex justify-content-center" style={{ marginTop: '5px' }}>
                    <Form.Group>
                        <Form.Label style={{ color: sizes.inventory > 10 ? 'red' : '' }}>Inventaire - {sizes.inventory} Mo / 10Mo</Form.Label>
                        <Form.File
                            label={labels.inventory}
                            lang="fr"
                            custom
                            accept='document/pdf'
                            data-browse="Fichier"
                            name='inventory'
                            onChange={(e) => handleChange(e)}
                        />
                        <small style={{ color: 'red' }}>{errors['inventory']}</small>
                        {
                            props.apartment.inventory_url &&
                            <a target='_blank' href={props.apartment.inventory_url} className='text-center'>Inventaire <MdOpenInNew /></a>
                        }
                    </Form.Group>
                </Col>
                <Col xs={12} md={6} className="d-flex justify-content-center" style={{ marginTop: '5px' }}>
                    <Form.Group>
                        <Form.Label style={sizes.place_state > 10 ? { color: 'red' } : {}}>Etat des lieux - {sizes.place_state} Mo / 10Mo</Form.Label>
                        <Form.File
                            id="custom-file-translate-scss"
                            label={labels.place_state}
                            lang="fr"
                            custom
                            accept='document/pdf'
                            data-browse="Fichier"
                            name='place_state'
                            onChange={(e) => handleChange(e)}
                        />
                        <small style={{ color: 'red' }}>{errors['place_state']}</small>
                        {
                            props.apartment.place_state_url &&
                            <a target='_blank' href={props.apartment.place_state_url} className='text-center'>Etat des lieux <MdOpenInNew /></a>
                        }
                    </Form.Group>
                </Col>
            </Row>
            <Row className="justify-content-center">
                {
                    isSubmit ?
                        <Spinner />
                        :
                        <Button 
                        className='whiteSVG'
                        variant="secondary" 
                        onClick={() => {
                            handleSubmit()
                        }} disabled={sizes.place_state > 10 || sizes.inventory > 10}><FaRegSave/> Enregistrer</Button>
                }
            </Row>
        </Container>)
}

const mapStateToProps = state => {
    return {
        apartment: getApartmentForUpdate(state)
    };
}

export default connect(mapStateToProps)(StepTen);