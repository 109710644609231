import { useState } from "react";
import { AiOutlineStar, AiFillStar } from 'react-icons/ai'
import { Col, Row } from 'react-bootstrap';

export default function Rating(props) {
    const [score, setScore] = useState(props.defaultValue);

    function handleChange(e) {
        setScore(e.target.getAttribute('value'));
        props.handleChange(e);
    }

    function returnStar() {
        let stars = [];
        for (let i = 0; i < props.range; i++) {
            stars.push(
            <Col className='noPadding d-flex justify-content-center align-items-center overStar' style={{height: props.size + 5 + 'px', width: props.size + 5 + 'px'}} xs={1}>
                <div value={i + 1} name={props.inputName} id={props.inputId} onClick={(e) => handleChange(e)} className='absoluteOverStar'></div>
                {
                    i + 1 > score || i + 1 > props.defaultValue ? <AiOutlineStar size={props.size}/> : <AiFillStar size={props.size}/>
                }
            </Col>
            );
        }
        return stars.map(star => star)
    }

    return (
        <Row className='d-flex justify-content-lg-end justify-content-center align-items-center'>
            {
                returnStar()
            }
            <Col xs={12} sm={2}  className='d-flex justify-content-center align-items-center'>
                <h3>{score}/{props.range}</h3>
            </Col>
        </Row>
    )
}