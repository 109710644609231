import { useEffect, useState } from 'react'
import { Modal, Button, Card, Row, Col } from 'react-bootstrap'
import { FaHistory } from 'react-icons/fa'
import instanceOfAxios from '../../../../../app/axios/axios';
import Spinner from '../../../../spinner/Spinner'
export default function RateHistoric(props) {
    const [visibility, setVisibility] = useState(false);
    const [pending, setPending] = useState(false);
    const [history, setHistory] = useState({});

    useEffect(() => {
        setPending(true);
        if (visibility) {
            instanceOfAxios.get(`admin/apartment/${props.apartment.id}/rate/${props.rate.id}/history`)
                .then(res => {
                    let history = res.data.history.sort((data1, data2) => (data1.score > data2.score) ? 1 : -1);
                    setPending(false);
                    setHistory(history);
                });
        }
    }, [visibility]);

    return (
        <>
            <Button className='goldenHover' onClick={() => setVisibility(true)}><FaHistory /></Button>
            <Modal
                scrollable
                centered
                show={visibility}
                backdrop="static"
                size='lg'
                onHide={() => setVisibility(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Historique des modifications du tarif "{props.rate.name}"
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>

                        {
                            !pending ?
                                <>
                                    {
                                        Object.values(history).length > 0 ?
                                            <>
                                                <Col xs={12}>
                                                    <h5>Modifications apportées : </h5>
                                                </Col>
                                                {
                                                    Object.values(history).map(data => {
                                                        let updateAt = new Date(data.updated_at).toLocaleDateString('fr-FR', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })
                                                        return (
                                                            <Col xs={12} className='mb-3'>
                                                                <Card>
                                                                    <Card.Header>
                                                                        <Card.Title>
                                                                            Tarif modifié le {updateAt}
                                                                        </Card.Title>
                                                                    </Card.Header>
                                                                    <Card.Body>
                                                                        Voici ce qui à été modifié :
                                                                        <ul>
                                                                            {data.original_rate_name != data.new_rate_name && <li>le nom était : <strong>{data.original_rate_name}</strong> et est devenue <strong>{data.new_rate_name}</strong></li>}
                                                                            {data.original_rate_rent_by_night != data.new_rate_rent_by_night && <li>le tarif à la nuit était de <strong>{data.original_rate_rent_by_night}</strong> € et est passé à <strong>{data.new_rate_rent_by_night}</strong> €</li>}
                                                                            {data.original_rate_rent_by_week != data.new_rate_rent_by_week && <li>le tarif à la semaine était de <strong>{data.original_rate_rent_by_week}</strong> € et est passé à <strong>{data.new_rate_rent_by_week}</strong> €</li>}
                                                                            {data.original_rate_rent_by_month != data.new_rate_rent_by_month && <li>le tarif au mois était de <strong>{data.original_rate_rent_by_month}</strong> € et est passé à <strong>{data.new_rate_rent_by_month}</strong> €</li>}
                                                                            {data.original_rate_begin_period != data.new_rate_begin_period && <li>La date de début était le <strong>{data.original_rate_begin_period}</strong> et est devenue <strong>{data.new_rate_begin_period}</strong></li>}
                                                                            {data.original_rate_end_period != data.new_rate_end_period && <li>la date de fin étais le <strong>{data.original_rate_end_period}</strong> et est devenue <strong>{data.new_rate_end_period}</strong></li>}
                                                                            {data.original_rate_min_night != data.new_rate_min_night && <li>le minimum de nuit était de <strong>{data.original_rate_min_night}</strong> et est devenue <strong>{data.new_rate_min_night}</strong></li>}
                                                                        </ul>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </>
                                            :
                                            <Col xs={12} className='d-flex justify-content-center'>
                                                <h3>Aucun historique</h3>
                                            </Col>
                                    }
                                </>
                                :
                                <Col xs={12} className='d-flex justify-content-center'>
                                    <Spinner />
                                </Col>
                        }
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}