import React, { useEffect, useState } from "react";
import { Container, Col, Row, Dropdown, Button, FormControl } from "react-bootstrap";
import instanceOfAxios from "../../../../app/axios/axios";
import { useDispatch } from "react-redux";
import { show } from "../../../../components/admin/Alerter/alerterSlice";
import Spinner from "../../../../components/spinner/Spinner";
import Steppers from "../../../../components/Stepper/Stepper";
import CheckInStep from "./steps/CheckInStep";
import CleaningStep from "./steps/CleaningStep";
import StateStep from "./steps/StateStep";
import EquipmentStep from "./steps/EquipmentStep";
import RecommandStep from "./steps/RecommandStep";
import CheckAnim from "../../../checkAnim/checkAnim";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="secondary"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        &#x25bc;
    </Button>
));

const CustomMenuRef = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');
        const lang = navigator.language;
        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled" style={{ position: 'relative', overflow: 'auto', maxHeight: '300px', maxWidth: '600px' }}>
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || (child.props.children).toLowerCase().includes(value)
                    )}
                </ul>
            </div>
        );
    },
);

export default function CommentCreate(props) {
    const [errors, setErrors] = useState([]);
    const [selectedAppartment, setSelectedAppartment] = useState();
    const [apartments, setApartments] = useState([]);
    const [pending, setPending] = useState(false);
    const [activeStep, SetActiveStep] = useState(0);
    const [isSend, setIsSend] = useState(false);
    const [datas, setDatas] = useState(
        {
            content: {
                check_in_score: {
                    score: 10,
                    check_in_message: ''
                },
                cleaning_score: {
                    living_room_score: 5,
                    kitchen_score: 5,
                    floor_score: 5,
                    room_score: 5,
                    bathroom_score: 5,
                    toilet_score: 5,
                    outside_score: 5,
                    cleaning_message: ''
                },
                state_score: {
                    living_room_score: 5,
                    kitchen_score: 5,
                    floor_score: 5,
                    room_score: 5,
                    bathroom_score: 5,
                    toilet_score: 5,
                    outside_score: 5,
                    state_message: ''
                }
                ,
                equipment_score: {
                    living_room_score: 5,
                    kitchen_score: 5,
                    room_score: 5,
                    bathroom_score: 5,
                    toilet_score: 5,
                    outside_score: 5,
                    equipment_message: ''
                },
                remark: '',
                recommand: 'Bien sûr',
                global: null
            },
            last_name: null,
            first_name: null,
            apartment_id: null
        }
    );

    const steps = ['Informations et Accueil', 'Propreté', 'Etat générale du logement', 'Equipement', 'Recommandation et Remarques'];

    const dispatch = useDispatch();

    useEffect(() => {
        instanceOfAxios.get('/admin/SecondIndexApartments')
            .then((res) => {
                setApartments(res.data.apartments);
            })
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setIsSend(false);
        }, 5000);
    }, [isSend]);

    function handleSubmit() {
        let newDatas = Object.assign({}, datas);
        if (!newDatas.content.global) {
            setErrors({ global: ['Veuillez renseigner un avis global sur le séjour'] });
            dispatch(show({ message: 'Les informations renseignées ne sont pas bonnes', variant: 'danger' }))
        } else {
            setPending(true);
            newDatas.content = JSON.stringify(newDatas.content);
            instanceOfAxios.post('admin/comment/createFromAdmin', newDatas)
                .then(res => {
                    props.setReload(true);
                    setPending(false);
                    setIsSend(true);
                    reset();
                })
                .catch(err => {
                    setPending(false);
                    setErrors(err.response.data.errors);
                    dispatch(show({ message: 'Les informations renseignées ne sont pas bonnes', variant: 'danger' }))
                })
        }
    }

    function handleChange(e) {
        let newDatas = Object.assign({}, datas);

        if (e.target.getAttribute('name') == 'apartment_id') {
            setSelectedAppartment(e.target.getAttribute('eventkey'));
            newDatas[e.target.getAttribute('name')] = e.target.id;
        } else {
            newDatas[e.target.getAttribute('name')] = e.target.value;
        }
        setDatas(newDatas);
    }

    function handleJsonChange(e) {
        let newDatas = Object.assign({}, datas);
        if (!newDatas.content[e.target.getAttribute('name')]) {
            newDatas.content[e.target.getAttribute('name')] = {};
        }
        if (e.target.value) {
            if (e.target.id) {
                newDatas.content[e.target.getAttribute('name')][e.target.id] = e.target.value;
            } else {
                newDatas.content[e.target.getAttribute('name')] = e.target.value;
            }
        } else {
            newDatas.content[e.target.getAttribute('name')][e.target.id] = e.target.getAttribute('value');
        }
        setDatas(newDatas);
    }

    function returnStep() {
        switch (activeStep) {
            case 0:
                return <CheckInStep errors={errors} defaultValue={{ content: datas.content, last_name: datas.last_name, first_name: datas.first_name }} handleChange={handleChange} handleJsonChange={handleJsonChange} SetActiveStep={SetActiveStep} />
            case 1:
                return <CleaningStep defaultValue={datas.content.cleaning_score} handleJsonChange={handleJsonChange} SetActiveStep={SetActiveStep} />
            case 2:
                return <StateStep defaultValue={datas.content.state_score} handleJsonChange={handleJsonChange} SetActiveStep={SetActiveStep} />
            case 3:
                return <EquipmentStep defaultValue={datas.content.equipment_score} handleJsonChange={handleJsonChange} SetActiveStep={SetActiveStep} />
            case 4:
                return <RecommandStep errors={errors} defaultValue={{ recommand: datas.content.recommand, remark: datas.content.remark }} handleJsonChange={handleJsonChange} SetActiveStep={SetActiveStep} handleSubmit={handleSubmit} />
        }
    }

    function reset(){
        setDatas({
            content: {
                check_in_score: {
                    score: 10,
                    check_in_message: ''
                },
                cleaning_score: {
                    living_room_score: 5,
                    kitchen_score: 5,
                    floor_score: 5,
                    room_score: 5,
                    bathroom_score: 5,
                    toilet_score: 5,
                    outside_score: 5,
                    cleaning_message: ''
                },
                state_score: {
                    living_room_score: 5,
                    kitchen_score: 5,
                    floor_score: 5,
                    room_score: 5,
                    bathroom_score: 5,
                    toilet_score: 5,
                    outside_score: 5,
                    state_message: ''
                }
                ,
                equipment_score: {
                    living_room_score: 5,
                    kitchen_score: 5,
                    room_score: 5,
                    bathroom_score: 5,
                    toilet_score: 5,
                    outside_score: 5,
                    equipment_message: ''
                },
                remark: '',
                recommand: 'Bien sûr',
                global: null
            },
            last_name: null,
            first_name: null,
            apartment_id: null
        });
        SetActiveStep(0);
        setSelectedAppartment('Choisir une propriété');
    }

    return (
        <div className="commentage d-flex flex-column justify-content-center align-items-center mb-3 mt-3">
            <Container style={{ paddingTop: '5vh', paddingBottom: '50px', maxWidth: '80vw', height: 'unset' }} className='noPadding'>
                {pending ?
                    <Row>
                        <Col xs={12} className='d-flex justify-content-center'>
                            <p>Le temps d'attente peut excéder 1 min</p>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center'>
                            <Spinner />
                        </Col>
                    </Row>
                    :
                    <>

                        {
                            isSend ?
                                <Row>
                                    <Col xs={12} className='text-center'>
                                        <CheckAnim />
                                        Création réussi
                                    </Col>
                                </Row>
                                :
                                <>
                                    <Row>
                                        <Col md={12} className='text-center'>
                                            <Dropdown style={{ padding: '10px' }}>
                                                <Dropdown.Toggle as={CustomToggle}>
                                                    {selectedAppartment || 'Choisir une propriété'}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu as={CustomMenuRef}>
                                                    {apartments.map((value) =>
                                                        <Dropdown.Item key={value.id} className='text-center' name='apartment_id' id={value.id} eventkey={value.reference} onClick={(e) => handleChange(e)}>{value.reference}</Dropdown.Item>)}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                    {
                                        selectedAppartment &&
                                        <>
                                            <Steppers steps={steps} activeStep={activeStep} />
                                            {returnStep()}
                                        </>
                                    }
                                </>
                        }
                    </>}
            </Container>
        </div>
    )
}