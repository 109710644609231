import { createSlice } from '@reduxjs/toolkit';
import instanceOfAxios from '../../app/axios/axios';

export const headerSlice = createSlice({
    name: 'header',
    initialState: {
        isConnected: true,
        role: window.sessionStorage.getItem('Role') || undefined
    },
    reducers: {
        logoutFront: state => {
            window.sessionStorage.setItem('Role', undefined);
            state.isConnected = false;
        },
        setIsConnected: (state, value) => {
            state.isConnected = value.payload;
        },
        setRole: (state, value) => {
            state.role = value.payload;
        } 
    },
});

export const { setRole, setIsConnected, logoutFront } = headerSlice.actions;

export function getAccount() {
    return function (dispatch) {
        return instanceOfAxios.get('/account')
            .then((res) => {
                window.sessionStorage.setItem('Role', res.data.roles[0].name);
                dispatch(setRole(res.data.roles[0].name));
                dispatch(setIsConnected(true));
            })
            .catch((error) => {
                window.sessionStorage.setItem('Role', undefined);
                dispatch(setIsConnected(false));
            });
    }
};

export function logout(){
    return function (dispatch){
        instanceOfAxios.get('/logout')
            .then((res) => {
                if(window.location.pathname !== '/'){
                    window.location.href = "/"
                }
                dispatch(logoutFront());
                dispatch(setIsConnected(false));
            });
    }
}

export const getIsConnected = state => state.header.isConnected;
export const getRole = state => state.header.role;

export default headerSlice.reducer;
