import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import AccountingBooking from './booking/AccountingBooking';
import AccountingCaution from './caution/AccountingCaution';
import AccountingOption from './option/AccountingOption';
import Accountingtourism from './tourism/AccountingTourism';

export default function HorizontalAccordion() {
  return (
      <Tabs defaultActiveKey="booking" id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="booking" title="Réservations">
          <AccountingBooking/>
        </Tab>
        <Tab eventKey="caution" title="Cautions">
          <AccountingCaution/>
        </Tab>
        <Tab eventKey="tourism" title="Taxe séjours">
          <Accountingtourism/>
        </Tab>
        <Tab eventKey="option" title="Options">
          <AccountingOption/>
        </Tab>
      </Tabs>
  )
};