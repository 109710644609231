import { configureStore } from '@reduxjs/toolkit';
import { getDefaultMiddleware } from '@reduxjs/toolkit'
import headerReducer from '../components/header/headerSlice';
import searchBarReducer from '../components/searchBar/searchBarSlice';
import dateRangeReducer from '../components/searchBar/dateRange/dateRangeSlice';
import adminReducer from '../pages/admin/adminSlice';
import bookingAdminReducer from '../components/admin/booking/bookingTabSlice';
import quoteReducer from '../components/admin/quote/quoteSlice';
import usersReducer from '../components/admin/users/usersSlice';
import apartmentReducer from '../components/admin/apartments/apartmentSlice';
import apartmentListReducer from '../components/admin/apartments/list/apartmentListSlice';
import apartmentFormReducer from '../components/admin/apartments/form/apartmentFormSlice';
import AdminCreateAccountReducer from '../components/admin/users/create/AdminCreateAccountSlice';
import createBookingAdminReducer from '../components/admin/booking/create/createBookingSlice';
import AlertReducer from '../components/admin/Alerter/alerterSlice';


export default configureStore({
  reducer: {
    header: headerReducer,
    searchBar: searchBarReducer,
    dateRange: dateRangeReducer,
    adminPannel: adminReducer,
    bookingAdmin: bookingAdminReducer,
    quoteTab: quoteReducer,
    usersTab: usersReducer,
    apartmentsTab: apartmentReducer,
    apartmentsList :apartmentListReducer,
    apartmentForm : apartmentFormReducer,
    AdminCreateAccount: AdminCreateAccountReducer,
    createBookingAdmin: createBookingAdminReducer,
    alert: AlertReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['editBooking/changeValue','createBookingAdmin/changeValue', 'dateRange/setDate'],
    }
  })
});
