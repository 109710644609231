import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Pagination } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { GiReceiveMoney } from 'react-icons/gi';
import instanceOfAxios from "../../../app/axios/axios";
import Spinner from "../../spinner/Spinner";
import AccountingDetail from "./AccountingDetail";

export default function AccountingModal(props) {
    const [visibility, setVisibility] = useState(false);
    const [pending, setPending] = useState(true);
    const [datas, setDatas] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const monthTrad = {
        January: 'Janvier',
        February: 'Février',
        March: 'Mars',
        April: 'Avril',
        May: 'Mai',
        June: 'Juin',
        July: 'Juillet',
        August: 'Aout',
        September: 'Septembre',
        October: 'Octobre',
        November: 'Novembre',
        December: 'Décembre'
    }
    
    useEffect(() => {
        setPending(true)
        instanceOfAxios.get(`/owner/apartment/${props.apartment.id}/year_rent/${year}`)
            .then(res => {
                setDatas(res.data.year_rent);
                setPending(false)
            })
            .catch(err => setPending(false));
    }, [year]);

    function prev() {
        setYear(year - 1);
    }

    function next() {
        setYear(year + 1);
    }

    function createCustomExportCSVButton(onClick) {
        return (
            <Button className='goldenHover' onClick={onClick}>Exporter au format CSV</Button>
        );
    }

    function priceFormatter(cell, row) {
        return parseFloat(cell).toFixed(2) + ' €';
    }

    function DetailFormatter(cell, row) {
        return <AccountingDetail booking={row.bookings} options={row.keeps} id={row.id} month={monthTrad[row.month]} monthIndex={row.month_index}/>;
    }

    function MonthFormatter(cell, row) {
        return monthTrad[cell];
    }

    const customBtn = {
        exportCSVBtn: createCustomExportCSVButton
    };

    return (
        <>
            <Col
                sm={12}
                lg={4}
                className='menuItem d-flex justify-content-center align-items-end'
                onClick={() => setVisibility(true)}>
                <div className='container-text d-flex flex-column align-items-center justify-content-center'>
                    <GiReceiveMoney/>
                    <h2>Détails des loyers</h2>
                </div>
            </Col>
            <Modal
                backdropClassName="backdrop"
                show={visibility}
                onHide={() => setVisibility(false)}
                centered
                scrollable
                size='xl'
                style={{ zIndex: '20000' }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Détails des loyers
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='d-flex justify-content-center'>
                    <Col xs={12} className='d-flex justify-content-center'>
                        <Pagination>
                            <Pagination.Prev id={'prev'} onClick={() => prev()}/>
                                <Pagination.Item key={moment().year} id={moment().year} active='true' style={{textAlign: 'center'}}>
                                    {year}
                                </Pagination.Item> 
                            <Pagination.Next id={'next'} onClick={() => next()}/>
                        </Pagination>
                    </Col>
                        {
                            pending ? 
                            <Col xs={12} className='d-flex justify-content-center'>
                                <Spinner/>
                            </Col>
                            :
                            <Row style={{ marginTop: '5vh' }}>
                                <BootstrapTable data={datas} options={customBtn} hover exportCSV csvFileName='Loyers.csv'>
                                    <TableHeaderColumn dataField='month' csvHeader='Mois' className='text-center' dataFormat={MonthFormatter}>Mois</TableHeaderColumn>
                                    <TableHeaderColumn dataField='amount' csvHeader='Montant' className='text-center' dataFormat={priceFormatter}>Montant</TableHeaderColumn>
                                    <TableHeaderColumn isKey dataField='electricity' csvHeader='Loyer' dataFormat={priceFormatter} className='text-center'>Électricité</TableHeaderColumn>
                                    <TableHeaderColumn dataField='keep' csvHeader='Retenues' className='text-center' dataFormat={priceFormatter}>Retenues</TableHeaderColumn>
                                    <TableHeaderColumn dataField='total' csvHeader='Total' dataFormat={priceFormatter} className='text-center'>Total</TableHeaderColumn>
                                    <TableHeaderColumn dataField='detail' dataFormat={DetailFormatter} className='text-center'>Détails</TableHeaderColumn>
                                </BootstrapTable>
                            </Row>
                        }
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}