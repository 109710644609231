import { useState } from "react"
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import { BsPencil } from 'react-icons/bs';
import { show } from '../admin/Alerter/alerterSlice';
import { useDispatch } from 'react-redux';
import instanceOfAxios from '../../app/axios/axios';
import Spinner from "../spinner/Spinner";
import { FaRegSave } from 'react-icons/fa';

export default function ContactInformations(props) {
    const [params, setParams] = useState({ contact: { has_change: false }, address: { has_change: false }, identity: { has_change: false } });
    const [addressError, setAddressError] = useState([]);
    const [contactError, setContactError] = useState([]);
    const [visible, setVisible] = useState(false);
    const [pending, setPending] = useState(false);
    const [fileName, setFileName] = useState('selectionner un fichier JPG/PNG');
    const dispatch = useDispatch();

    function handleChange(event) {
        let newParams = Object.assign({}, params);
        if (event.target.name == 'identity') {
            setFileName(event.target.files[0].name);
            newParams[event.target.name][event.target.id] = event.target.files[0];
        } else {
            newParams[event.target.name][event.target.id] = event.target.value;
            if (props.contact) {
                newParams[event.target.name]['_method'] = 'PATCH';
            }
        }
        newParams[event.target.name]['has_change'] = true;
        setParams(newParams);
    }

    function renderError(array) {
        return Object.keys(array).map(key =>
            array[key].map(message => {
                return (<li key={message}>
                    <small style={{ color: 'red' }}>{message}</small> <br></br>
                </li>)
            })
        )
    }

    function trigerHandleChange(index, secondIndex = null) {
        let newParams = Object.assign({}, params);
        newParams[index]['has_change'] = false;
        setParams(newParams);

        if (!params.contact.has_change && !params.address.has_change && !params.identity.has_change) {
            props.handleChange();
        }
    }

    function handleSubmit() {
        setPending(true);
        if (params.contact.has_change && props.contact) {
            setContactError([]);
            let contactUrl = props.contact ? '/contact/' + props.contact.id : '/contact';
            instanceOfAxios.post(contactUrl, params.contact)
                .then((res) => {
                    setPending(false);
                    dispatch(show({ message: 'Action réussi', variant: 'success' }));
                    trigerHandleChange('contact');
                }).catch((errorResponse) => {
                    dispatch(show({ message: 'Erreur lors de l\'action', variant: 'danger' }));
                    if (errorResponse.response) {
                        setContactError(errorResponse.response.data.error);
                        setPending(false);
                    }
                });
        } else if (params.contact.has_change && !props.contact) {
            setContactError([]);
            setAddressError([]);
            let sendParam = Object.assign({}, params);
            sendParam.contact.model = 'account';
            sendParam.contact.model_id = props.model.id;
            instanceOfAxios.post('/contact', sendParam)
                .then((res) => {
                    setPending(false);
                    dispatch(show({ message: 'Action réussi', variant: 'success' }));
                    props.handleChange();
                }).catch((errorResponse) => {
                    dispatch(show({ message: 'Erreur lors de l\'action', variant: 'danger' }));
                    if (errorResponse.response) {
                        setContactError(errorResponse.response.data.error.contact);
                        setAddressError(errorResponse.response.data.error.address);
                        setPending(false);
                    }
                });
        }

        if (params.address.has_change && props.contact) {
            setAddressError([]);
            let addressUrl = props.contact ? '/address/' + props.contact.address.id : '/address';
            instanceOfAxios.post(addressUrl, params.address)
                .then((res) => {
                    setPending(false);
                    dispatch(show({ message: 'Action réussi', variant: 'success' }));
                    trigerHandleChange('address')
                }).catch((errorResponse) => {
                    dispatch(show({ message: 'Erreur lors de l\'action', variant: 'danger' }));
                    if (errorResponse.response) {
                        setAddressError(errorResponse.response.data.error);
                        setPending(false);
                    }
                });
        }

        if (params.identity.has_change) {
            let data = new FormData();
            data.append('identityCard', params.identity.identityCard);
            instanceOfAxios.post('user/' + props.contact.id + '/identityCard', data)
                .then((res) => {
                    setPending(false);
                    setVisible(false);
                    dispatch(show({ message: 'Action réussi', variant: 'success' }));
                    trigerHandleChange('identity')
                })
                .catch((error) => {
                    dispatch(show({ message: 'Erreur lors de l\'action', variant: 'danger' }));
                    setPending(false);
                })
        }
    }

    return (
        <>
            <Button variant='secondary' onClick={() => setVisible(true)}>
                {props.contact ? 'Modification du contact' : 'Ajout d\'un nouveau contact'}
            </Button>
            {
                visible &&
                <Modal
                    show={visible}
                    showMask={false}
                    onHide={() => setVisible(false)}
                    centered
                    size='xl'
                    style={{ zIndex: '20000' }}
                    backdropClassName="backdrop">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {props.contact ? 'Modification du contact' : 'Ajout d\'un nouveau contact'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} lg={6}>
                                <h2>Personne physique</h2>
                                <div className="row">
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="first_name">Prénom *</label>
                                            <input type="text" className="form-control" id="first_name" name="contact" placeholder="Prénom" onChange={e => handleChange(e)} defaultValue={props.contact ? props.contact.first_name : ''} required ></input>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="last_name">Nom *</label>
                                            <input type="last_name" className="form-control" id="last_name" name="contact" placeholder="Nom" onChange={e => handleChange(e)} defaultValue={props.contact ? props.contact.last_name : ''}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {props.model.is_society ?
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="function">Fonction</label>
                                                <input type="function" className="form-control" id="function" name="contact" placeholder="Fonction" onChange={e => handleChange(e)} defaultValue={props.contact ? props.contact.function : ''}></input>
                                            </div>
                                        </div> : <></>}

                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="phone">Téléphone</label>
                                            <input type="phone" className="form-control" name="contact" id="phone" placeholder="Téléphone" onChange={e => handleChange(e)} defaultValue={props.contact ? props.contact.phone : ''} required ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="email">Email *</label><br></br>
                                            <input type="email" className="form-control" name="contact" id="email" placeholder="Email" onChange={e => handleChange(e)} defaultValue={props.contact ? props.contact.email : ''} required ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="civility">Civilité *</label>
                                            <select id="civility" name="contact" className="form-control" onChange={e => handleChange(e)}>
                                                <option value={!props.contact ? 'selected' : ''}>Selectionner</option>
                                                <option value='Mme' selected={props.contact && props.contact.civility === 'Mme' ? true : false}>Mme</option>
                                                <option value='Mr' selected={props.contact && props.contact.civility === 'Mr' ? true : false}>Mr</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <Col>
                                    {renderError(contactError)}
                                </Col>
                            </Col>
                            <Col xs={12} lg={6} className='mb-3'>
                                <h2>Adresse</h2>
                                <div className="text-center">
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="address">Adresse *</label> <br></br>
                                            <input type="text" className="form-control" id="address" name="address" placeholder="Adresse" onChange={handleChange} required defaultValue={props.contact && props.contact.address ? props.contact.address.address : ""}></input>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="second_address">Deuxième partie</label>
                                            <input type="second_address" className="form-control" id="second_address" name="address" placeholder="deuxième partie" onChange={handleChange} defaultValue={props.contact && props.contact.address ? props.contact.address.second_address : ''}></input>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="third_address">Troisième partie</label>
                                            <input type="third_address" className="form-control" id="third_address" name="address" placeholder="Troisième partie" onChange={handleChange} defaultValue={props.contact && props.contact.address ? props.contact.address.third_address : ''}></input>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="city">Ville *</label> <br></br>
                                            <input type="city" className="form-control" id="city" name="address" placeholder="Ville" onChange={handleChange} required defaultValue={props.contact && props.contact.address ? props.contact.address.city : ''}></input>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="postal_code">Code postale *</label><br></br>
                                            <input type="postal_code" className="form-control" id="postal_code" name="address" placeholder="Code postale" onChange={handleChange} required defaultValue={props.contact && props.contact.address ? props.contact.address.postal_code : ''}></input>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <label htmlFor="country">Pays *</label><br></br>
                                            <input type="country" className="form-control" id="country" name="address" onChange={handleChange} required defaultValue={props.contact && props.contact.address ? props.contact.address.country : 'France'}></input>
                                        </div>
                                    </Col>
                                </div>
                                <Col>
                                    {renderError(addressError)}
                                </Col>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            pending ?
                                <Spinner />
                                :
                                <Button className='whiteSVG' variant='secondary' onClick={handleSubmit}><FaRegSave/> Enregistrer</Button>
                        }
                    </Modal.Footer>
                </Modal>
            }
        </>
    )
}