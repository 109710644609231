import React, { useEffect, useState } from 'react';
import Header from './components/header/Header';
import { useStore } from 'react-redux';
import Home from './pages/home/Home';
import UserAccount from './pages/UserAccount/UserAccount';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import MyNeeds from './pages/statics/MyNeeds';
import SearchResult from './pages/searchResult/searchResult';
import Contact from './pages/contact/contact';
import Presentation from './pages/statics/Presentation';
import Tenant from './pages/statics/Tenant';
import Owner from './pages/statics/Owner';
import CGU from './pages/statics/legals/CGU';
import CGL from './pages/statics/legals/CGL';
import LegalMentions from './pages/statics/legals/LegalMentions';
import NoMatch from './pages/404/404'
import AdminPanel from './pages/admin/admin'
import Alerter from './components/admin/Alerter/alerter'
import ShowApartment from './components/showApartment/showApartment';
import Footer from './components/footer/Footer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import instanceOfAxios from './app/axios/axios';
import PersonalInformation from './pages/PersonalInformation/PersonalInformation';
import Comment from './pages/Comment/Comment';
import Favori from './pages/favori/favori';
import Magazine from './pages/Magazine/Magazine';
import ArticleDetails from './pages/Magazine/Articles/articleDetails';
import CookiesConsent from './components/cookies/CookiesConsent';

export default function App() {
  const [style, setStyle] = useState({});
  const [pending, setPending] = useState(true);

  if (pending) {
    // getBackground();
  }

  function HeaderView() {
    const location = useLocation();
    if (location.pathname != '/AdminPannel') {
      return (
        <>
          {/* <CookiesConsent/> */}
          <span>
            <Header />
          </span>
        </>
      );
    }
    return null;
  }

  function FooterView() {
    const location = useLocation();
    if (location.pathname != '/AdminPannel') {
      return <Footer />;
    }
    return null;
  }

  // function getBackground(){
  //   instanceOfAxios.get('/admin/page?type=home')
  //   .then( res => {
  //     setStyle(
  //       {
  //         background: 'url(' + res.data.page[0].pictures_url.background + ')',
  //         backgroundRepeat: 'no-repeat',
  //         backgroundAttachment: 'fixed',
  //         backgroundSize: 'cover'}
  //     )
  //   })
  //   setPending(false);
  // }

  return (
    <Router>
      <HeaderView />
      {/* <div 
          style={style}> */}
      <Alerter />
      <Switch>
        <AdminRoute exact path="/AdminPannel" component={AdminPanel} />
        <PrivateRoute exact path="/MonCompte">
          <UserAccount />
        </PrivateRoute>
        <PrivateRoute exact path="/Favoris">
          <Favori />
        </PrivateRoute>
        <Route exact path='/' component={Home} />
        <Route exact path='/MonBesoin' component={MyNeeds} />
        <Route exact path='/Presentation' component={Presentation} />
        <Route exact path='/Locataires' component={Tenant} />
        <Route exact path='/Proprietaires' component={Owner} />
        <Route exact path='/Agence' component={Presentation} />
        <Route exact path='/CGU' component={CGU} />
        <Route exact path='/CGL' component={CGL} />
        <Route exact path='/ML' component={LegalMentions} />
        <Route exact path='/Contact' component={Contact} />
        <Route exact path='/apartment/:id' component={ShowApartment} />
        <Route exact path='/Logements' component={SearchResult} />
        <Route exact path='/Informations' component={PersonalInformation} />
        <Route exact path='/Comments' component={Comment} />
        <Route exact path='/Magazine' component={Magazine} />
        <Route exact path='/Magazine/article/:id' component={ArticleDetails} />
        <Route exact path='*' component={NoMatch}/>
      </Switch>
      {/* </div> */}
      <FooterView/>
    </Router>
  );
}



// A wrapper for <Route> that redirects to the home
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  const state = useStore().getState();
  const auth = state.header.isConnected;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

// A wrapper for <Route> that redirects to the home
// screen if you're not yet authenticated.
function AdminRoute({ children, ...rest }) {
  const state = useStore().getState();
  const role = state.header.role;
  console.log(role);
  const auth = state.header.isConnected;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        (auth && (role === 'super-admin' || role === 'admin' || role == 'trainee' || role == 'accountant')) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

