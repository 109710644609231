import { useEffect, useState } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import instanceOfAxios from "../../app/axios/axios";
import './Comment.scss';
import { useDispatch } from "react-redux";
import { show } from "../../components/admin/Alerter/alerterSlice";
import Spinner from "../../components/spinner/Spinner";
import CheckAnim from "./checkAnim/checkAnim";
import Steppers from "../../components/Stepper/Stepper";
import CheckInStep from "./steps/CheckInStep";
import CleaningStep from "./steps/CleaningStep";
import StateStep from "./steps/StateStep";
import EquipmentStep from "./steps/EquipmentStep";
import RecommandStep from "./steps/RecommandStep";

export default function Comment() {
    const paramsUrl = new URLSearchParams(window.location.search)
    const [timer, SetTimer] = useState(20);
    const [total, SetTotal] = useState(0);
    const [errors, setErrors] = useState([]);
    const [startTimer, setStartTimer] = useState(false);
    const [datas, setDatas] = useState(
        {
            content: {
                check_in_score: {
                    score: 10,
                    check_in_message: ''
                },
                cleaning_score: {
                    living_room_score: 5,
                    kitchen_score: 5,
                    floor_score: 5,
                    room_score: 5,
                    bathroom_score: 5,
                    toilet_score: 5,
                    outside_score: 5,
                    cleaning_message: ''
                },
                state_score: {
                    living_room_score: 5,
                    kitchen_score: 5,
                    floor_score: 5,
                    room_score: 5,
                    bathroom_score: 5,
                    toilet_score: 5,
                    outside_score: 5,
                    state_message: ''
                }
                ,
                equipment_score: {
                    living_room_score: 5,
                    kitchen_score: 5,
                    room_score: 5,
                    bathroom_score: 5,
                    toilet_score: 5,
                    outside_score: 5,
                    equipment_message: ''
                },
                remark: '',
                recommand: 'Bien sûr',
                global: null
            },
            last_name: null,
            first_name: null,
            apartment_id: paramsUrl.get('apartment')
        }
    );
    const [pending, setPending] = useState(false);
    const [activeStep, SetActiveStep] = useState(0);
    const [isSend, setIsSend] = useState(false);
    const steps = ['Informations et Accueil', 'Propreté', 'Etat générale du logement', 'Equipement', 'Recommandation et Remarques'];

    const dispatch = useDispatch();

    useEffect(() => {
        if (startTimer) {
            const timeOut = setTimeout(() => {
                SetTimer(timer - 1);
            }, 1000);

            if (timer == 0) {
                clearTimeout(timeOut);
                window.location.href = '/';
            }
        }
    }, [timer, startTimer]);

    useEffect(() => {
        setPending(true);
        instanceOfAxios.get('validateTokenForComment/' + paramsUrl.get('token'))
            .then(res => {
                setPending(false);
            })
            .catch(err => {
                window.location.href = '/';
            })
    }, [])

    function calculateTotal() {
        let total = 0;
        Object.keys(datas.content).map(key => {
            if (datas.content[key] && typeof datas.content[key] == 'object') {
                Object.keys(datas.content[key]).map(dataKey => {
                    if (parseInt(datas.content[key][dataKey]) > 0) {
                        total += parseInt(datas.content[key][dataKey])
                    }
                })
            }
        })
        setStartTimer(false);
        SetTotal(total / 22);
    }

    function handleSubmit() {
        let newDatas = Object.assign({}, datas);
        if (!newDatas.content.global) {
            setErrors({ global: ['Veuillez renseigner un avis global sur votre séjour'] });
            dispatch(show({ message: 'Les informations renseignées ne sont pas bonnes', variant: 'danger' }))
        } else {
            setPending(true);
            newDatas.content = JSON.stringify(newDatas.content);
            instanceOfAxios.post('createComment/' + paramsUrl.get('token'), newDatas)
                .then(res => {
                    setPending(false);
                    setStartTimer(true);
                    setIsSend(true);
                    calculateTotal();
                })
                .catch(err => {
                    setPending(false);
                    setErrors(err.response.data.errors);
                    dispatch(show({ message: 'Les informations renseignées ne sont pas bonnes', variant: 'danger' }))
                })
        }
    }

    function handleChange(e) {
        let newDatas = Object.assign({}, datas);
        newDatas[e.target.getAttribute('name')] = e.target.value;
        setDatas(newDatas);
    }

    function handleJsonChange(e) {
        let newDatas = Object.assign({}, datas);
        if (!newDatas.content[e.target.getAttribute('name')]) {
            newDatas.content[e.target.getAttribute('name')] = {};
        }
        if (e.target.value) {
            if (e.target.id) {
                newDatas.content[e.target.getAttribute('name')][e.target.id] = e.target.value;
            } else {
                newDatas.content[e.target.getAttribute('name')] = e.target.value;
            }
        } else {
            newDatas.content[e.target.getAttribute('name')][e.target.id] = e.target.getAttribute('value');
        }
        setDatas(newDatas);
    }

    function returnStep() {
        switch (activeStep) {
            case 0:
                return <CheckInStep errors={errors} defaultValue={{ content: datas.content, last_name: datas.last_name, first_name: datas.first_name }} handleChange={handleChange} handleJsonChange={handleJsonChange} SetActiveStep={SetActiveStep} />
            case 1:
                return <CleaningStep defaultValue={datas.content.cleaning_score} handleJsonChange={handleJsonChange} SetActiveStep={SetActiveStep} />
            case 2:
                return <StateStep defaultValue={datas.content.state_score} handleJsonChange={handleJsonChange} SetActiveStep={SetActiveStep} />
            case 3:
                return <EquipmentStep defaultValue={datas.content.equipment_score} handleJsonChange={handleJsonChange} SetActiveStep={SetActiveStep} />
            case 4:
                return <RecommandStep errors={errors} defaultValue={{ recommand: datas.content.recommand, remark: datas.content.remark }} handleJsonChange={handleJsonChange} SetActiveStep={SetActiveStep} handleSubmit={handleSubmit} />
        }
    }

    return (
        <div className="commentage d-flex flex-column justify-content-center align-items-center mb-3 mt-3">
            <h2>Laissez nous votre avis sur votre séjour</h2>
            <Container style={{ paddingTop: '5vh', paddingBottom: '50px', maxWidth: '80vw', height: 'unset' }} className='noPadding'>
                {pending ?
                    <Row>
                        <Col xs={12} className='d-flex justify-content-center'>
                            <p>Le temps d'attente peut excéder 1 min</p>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center'>
                            <Spinner />
                        </Col>
                    </Row>
                    :
                    <>
                        {
                            isSend ?
                                <Row>
                                    <Col xs={12} className='text-center'>
                                        <CheckAnim />
                                        <p>
                                            Merci pour votre retour nous prendrons compte de vos remarque.
                                        </p>
                                        {
                                            total > 4.5 &&
                                            <p>
                                                Nous vous invitons également à nous laisser votre commentaire sur google en <a href='https://g.page/r/Cb-Gl1S-e0csEBM/review' target="_blank">cliquant ici</a>
                                            </p>
                                        }
                                    </Col>
                                    {
                                        total < 4.5 &&
                                        <Col xs={12} className='text-center mt-3'>
                                            Vous allez être redirigé dans {timer} sec
                                        </Col>
                                    }
                                </Row>
                                :
                                <>
                                    <Steppers steps={steps} activeStep={activeStep} />
                                    {returnStep()}
                                </>
                        }
                    </>}
            </Container>
        </div>
    )
}