import React, { useState } from 'react';
import { Button, Table, Modal } from 'react-bootstrap';
import { AiOutlineDownload } from 'react-icons/ai';

export default function InvoiceList(props){
    const [visibility, setVisibility] = useState(false);

        var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

        return (
            <>
                <Button className="goldenHover" onClick={() => setVisibility(!visibility)}>
                    Factures
                </Button>
                <Modal
                    backdropClassName="backdropFourth"
                    show={visibility}
                    onHide={() => setVisibility(!visibility)}
                    scrollable
                    centered
                    style={{ zIndex: '20003' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Factures</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modalBody">
                            <Table bordered responsive="xs" className="center">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Date de création</th>
                                        <th>Lien</th>
                                        <th>Avoir</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {props.invoices.map((invoice) => {
                                        const date = new Date(invoice['created_at'].replace(/\s/, 'T'));
                                        return (
                                            <tr key={invoice['id']}>
                                                <td>{invoice['invoice'] == 'en' ? "Invoice EN  " : "Facture FR  "}</td>
                                                <td>{date.toLocaleDateString('fr-CA', options)}
                                                </td>
                                                <td> <a href={invoice['url']} target='_blank'><AiOutlineDownload/></a></td>
                                                {invoice['avoir'] && <td><a href={invoice['avoir']} target='_blank'><AiOutlineDownload/></a></td>}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    
}
