import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap'
import { IoIosMail } from 'react-icons/io'
import { BsStarFill, BsStar, BsFillHouseFill } from 'react-icons/bs';
import { AiFillIdcard } from 'react-icons/ai';
import { FaPhoneAlt } from 'react-icons/fa';
import DeleteModal from '../generiqueModal/deleteModal/deleteModal';
import instanceOfAxios from '../../app/axios/axios';
import { show } from '../admin/Alerter/alerterSlice';
import { useDispatch } from 'react-redux';
import ContactInformations from '../formulaire/contactInformations';

export default function ContactCard(props) {

    const dispatch = useDispatch();

    function deleteContact(contact) {
        instanceOfAxios.delete('/contact/' + contact.id)
            .then((res) => {
                props.handleChange(true);
                dispatch(show({ message: 'Action réussi', variant: 'success' }));
            })
            .catch((error) => {
                dispatch(show({ message: 'Erreur lors de l\'action', variant: 'danger' }));
            });
    }

    function handleChangePrincipalContact() {
        let formData = new FormData();
        formData.set('new_principal_contact_id', props.contact.id);
        instanceOfAxios.post('/account/' + props.user.id + '/principalContact', formData)
            .then((res) => {
                props.handleChange(true);
                dispatch(show({ message: 'Action réussi', variant: 'success' }));
            })
            .catch((error) => {
                dispatch(show({ message: 'Erreur lors de l\'action', variant: 'danger' }));
            });
    }

    const date = new Date(props.contact.created_at.replace(/\s/, 'T'));

    return (
        <Col xs={12} lg={6} className='contactContainer'>
            <Card>
                <Card.Header>
                    <Card.Title className='text-center'>
                        {props.contact.is_principal ?
                            <BsStarFill />
                            : <a style={{ textDecoration: 'none', color: 'black' }} className='starHover' href='#'><BsStar onClick={handleChangePrincipalContact}></BsStar></a>}
                        {props.contact.civility} {props.contact.last_name} {props.contact.first_name}
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={12} className='mb-3 text-center'>
                            <Card.Text>
                                <span className='noReturn'><FaPhoneAlt /><strong><a href={'tel:' + props.contact.phone ? props.contact.phone : ''}> {props.contact.phone ? props.contact.phone : ''}</a></strong></span>
                            </Card.Text>
                            <Card.Text>
                                <span><IoIosMail /><strong><a href={'mailto:' + props.contact.email}> {props.contact.email}</a></strong></span>
                            </Card.Text>
                        </Col>
                        <Col xs={12}>
                            {
                                props.contact.address &&
                                <Card.Text className='d-flex flex-column text-truncate text-center'>
                                    <span className='noReturn'><BsFillHouseFill /><strong> {props.contact.address.address}</strong></span>
                                    <span className='noReturn'><strong> {props.contact.address.postal_code} {props.contact.address.city}</strong></span>
                                    <span className='noReturn'><strong> {props.contact.address.country}</strong></span>
                                </Card.Text>
                            }
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="text-center">
                    <small>créé le : {date.toLocaleDateString()}</small>
                    <Row className='d-flex justify-content-center'>
                        <Col xs={12} sm={10}>
                            <ContactInformations contact={props.contact} model={props.user} handleChange={props.handleChange} />
                        </Col>
                        {!props.contact.is_principal ?
                            <Col xs={12} sm={2}>
                                <DeleteModal deleteFunction={() => deleteContact(props.contact)} model={props.contact} role={props.role} />
                            </Col>
                            : <></>}
                    </Row>
                </Card.Footer>
            </Card>
        </Col>
    )
}