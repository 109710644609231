import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import instanceOfAxios from "../../../app/axios/axios";
import { show } from "../../../components/admin/Alerter/alerterSlice";
import '../Magazine.scss';

export default function ArticleDetails(props){
    const [article, setArticle] = useState();
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState('100%');
    const dispatch = useDispatch();


    useEffect(() =>{
        instanceOfAxios.get('Article/' + props.match.params.id)
        .then( res => {
            setArticle(res.data.article)
            document.getElementsByTagName('meta')["description"].content = res.data.article.description;
            document.getElementsByTagName('meta')["title"].content = res.data.article.name;
        })
        .catch( err => dispatch(show({message: 'Erreur lors de la récupération de l\'article', variant: 'danger'})))
    },[])

    return(
        <Container style={{ paddingTop: '5vh', paddingBottom: '50px', maxWidth: '80vw', minHeight: '70vh', height: 'unset' }} className='noPadding d-flex align-items-center justify-content-center'>
            {article ? 
            <Row>
                <Col xs={12}>
                    <p style={{ color: '#B7A57E' }}><a href='/' className='golden'>Accueil</a> > <a href='/Magazine' className='golden'>Magazine</a> > Article > {article.id}</p>
                </Col>
                <Col xs={12}>
                    <h2>{article.name}</h2>
                </Col>
                <Col xs={12} className='d-flex justify-content-center'>
                    <iframe 
                    onLoad={(iframe) => {
                        setHeight(iframe.target.contentWindow.document.body.scrollHeight)
                        setWidth(iframe.target.contentWindow.document.body.scrollWidth)
                    }}
                    width={width}
                    height={height}
                    srcdoc={article.content} 
                    frameborder="0" /> 
                </Col>
            </Row>
            : 
            <h2>Chargement</h2>}
        </Container>
    )
}