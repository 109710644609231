import { useStore, useDispatch } from "react-redux";
import DeleteModal from '../../../generiqueModal/deleteModal/deleteModal';
import instanceOfAxios from "../../../../app/axios/axios";
import { show } from "../../Alerter/alerterSlice";
import { Badge } from 'react-bootstrap';
import BookingDetails from "../../booking/bookingDetails/bookingDetails";

export default function BookCard(props){
    const role = useStore().getState().header.role;
    const dispatch = useDispatch();
    const stateColor = { ['trop perçu']: 'danger', 'annulé': 'danger', ['à payer']: 'danger', payé: 'success', ['acompte versé']: 'secondary', ['en attente']: 'dark' }

    function handleDelete(){
        instanceOfAxios.delete('/apartment/' + props.date.apartment_id + '/booking/' + props.date.id)
        .then((res) => {
            props.handleChange();
            dispatch(show({ message: 'Réservation supprimée', variant: 'success' }));
        })
        .catch((error) => {
            dispatch(show({ message: 'Erreur lors de la suppression', variant: 'danger' }));
        })
    }

    const beginDate = new Date(props.date.begin_date_hour.replace(/\s/, 'T'));
    const endDate = new Date(props.date.end_date_hour.replace(/\s/, 'T'));
    var options = {year: "numeric", month: "long", day: "numeric"};

    return(
        <tr>
            {(role === 'super-admin' || role === 'admin') ?
                        <td><BookingDetails booking={props.date} cardOnChange={props.handleChange}/></td>
                        :
                        <></>
                    }
            <td>{props.date.reference}</td>
            {props.date.contact && (role === 'super-admin' || role === 'admin') ?
                        <td>{props.date.contact.first_name + ' ' + props.date.contact.last_name}</td>
                        :
                        <td>{(props.date.rent).toFixed(2)}€</td>
                    }
            {props.date && role === 'owner' ?
                        <td>{(props.date.EDF).toFixed(2)}€</td>
                        :
                        <></>
                    }
            <td>{beginDate.toLocaleDateString('fr-FR', options)}</td>
            <td>{endDate.toLocaleDateString('fr-FR', options)}</td>
            <td><Badge variant={stateColor[props.date.state]}>{props.date.state}</Badge></td>
            {(role === 'super-admin' || role === 'admin') && props.date.state === 'en attente' ?
                        <td>
                            <DeleteModal deleteFunction={handleDelete}/>
                        </td>
                        :
                        <td></td>
                    }
        </tr>
    )
}