import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import instanceOfAxios from '../../../../app/axios/axios';
import { setUserToUpdate, ShowList } from '../usersSlice'
import { show } from '../../Alerter/alerterSlice';
export const AdminCreateAccountSlice = createSlice({
    name: 'AdminCreateAccount',
    initialState: {
        isSubmit: false,
        param: {},
        error: {},
        dropDownValue: 'Choisir un role'
    },
    reducers: {
        setIsSubmit: (state, value) => {
            state.isSubmit = value.payload
        },
        setError: (state, value) => {
            state.error = value.payload;
        },
        changeValue: (state, value) => {
            state.param[value.payload.type] = value.payload.payload
        },
        addError: (state, value) => {
            state.error[value.payload.type] = value.payload.payload[0]
        },
        resetParam: state => {
            state.param = {};
        },
        setParam: (state, value) => {
            state.param = value.payload;
        },
        setDropDownValue: (state, value) => {
            state.dropDownValue = value.payload
        }
    },
});


export const { setIsSubmit, addError, changeValue, setError, setParam, resetParam, setDropDownValue } = AdminCreateAccountSlice.actions;

export function handleChange(event) {
    return function (dispatch) {
        if (event.target.type === 'checkbox') {
            if (event.target.checked) {
                dispatch(changeValue({ type: event.target.name, payload: 1 }));
            } else {
                dispatch(changeValue({ type: event.target.name, payload: 0 }));
            }
        }else if(event.target.name === 'role'){
            const tradRole = { tenant: 'Locataire', owner: 'Propriétaire', admin: 'Administrateur', ['super-admin']: 'Super Administrateur', trainee: 'Stagiaire' }
            dispatch(setDropDownValue(tradRole[event.target.id]))
            dispatch(changeValue({ type: event.target.name, payload: event.target.id }));
        } else {
            dispatch(changeValue({ type: event.target.name, payload: event.target.value }));
        }
    }
}

function setFormData(formData, params, userToUpdate = undefined) {
    if(userToUpdate !== undefined){
        Object.keys(params).forEach((key) => {
            if(key !== 'contacts' && key !== 'roles' && key !== 'id'){
                if(params[key] !== userToUpdate[key]){
                    formData.set(key, params[key]);
                }
            }
        }); 
    }else{
        Object.keys(params).forEach((key) => {
            formData.set(key, params[key]);
        });
    }
}

export function handleAdminSubmit(params, userToUpdate, reloadList) {
    return function (dispatch) {
        dispatch(setIsSubmit(true));
        dispatch(setError({}));
        if(Object.keys(userToUpdate).length === 0){
            return register(dispatch, params, reloadList);
        }
        return update(userToUpdate, params, dispatch, reloadList);
    }
}

function register(dispatch, params, reloadList){
    let parameter = new FormData();
    setFormData(parameter, params);
    instanceOfAxios.post('/register', parameter)
    .then((res) => {
        dispatch(setIsSubmit(false));
        dispatch(resetParam());
        dispatch(setDropDownValue('Choisir un role'));
        dispatch(show({ message: 'Sauvegarde effectuée', variant: 'success' }));
        reloadList(true);
        dispatch(ShowList())
    }).catch((error) => {
        dispatch(show({ message: 'Sauvegarde échoué', variant: 'danger' }))
        dispatch(handleError(error));
    });
}

function update(userToUpdate, params, dispatch, reloadList){
    let parameter = new FormData();
    parameter.set('_method', 'PATCH');
    setFormData(parameter, params, userToUpdate);
    instanceOfAxios.post('/admin/account/'+userToUpdate.id, parameter)
    .then((res) => {
        dispatch(setIsSubmit(false));
        dispatch(setParam(res.data.success))
        dispatch(setUserToUpdate(res.data.success));
        dispatch(setParam(res.data.success)); 
        dispatch(show({ message: 'Sauvegarde effectuée', variant: 'success' }));
        reloadList(true);
        dispatch(ShowList())
    }).catch((error) => {
        dispatch(show({ message: 'Sauvegarde échoué', variant: 'danger' }))
        dispatch(handleError(error));
    });
}

export function handleError(error) {
    return function (dispatch){
        Object.keys(error.response.data).forEach(function (key) {
            dispatch(addError({ type: key, payload: error.response.data[key] }));
        });
        dispatch(setIsSubmit(false));
    }
}

// GETTER
export const getParam = state => state.AdminCreateAccount.param;
export const getError = state => state.AdminCreateAccount.error;
export const getIsSubmit = state => state.AdminCreateAccount.isSubmit;
export const getVisibility = state => state.AdminCreateAccount.succes;
export const getDropDownValue = state => state.AdminCreateAccount.dropDownValue;

export default AdminCreateAccountSlice.reducer;
