import { Button, Modal, Form, Col } from "react-bootstrap";
import { useState } from "react"
import instanceOfAxios from "../../../app/axios/axios";
import { show } from "../../admin/Alerter/alerterSlice";
import { useDispatch } from "react-redux";
import { CgFileDocument } from 'react-icons/cg';

export default function AddFile(props) {
    const [visibility, setVisibility] = useState(false);
    const [option, setOption] = useState(false);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState('Selectionner un fichier jpg, png, pdf');
    const dispatch = useDispatch();

    function handleSubmit() {
        let form = new FormData();
        form.set('diagnostic', file);
        form.set('_method', 'PATCH');
        instanceOfAxios.post('/apartment/' + props.apartment.id + '/diagnostic/' + props.apartment.diagnostic.id, form)
            .then(res => {
                dispatch(show({ message: 'Document envoyé', variant: 'success' }));
                setVisibility(false);
            })
            .catch(error => {
                dispatch(show({ message: 'Erreur lors de l\'enregistrement', variant: 'danger' }));
            })
    }

    function hideModal(){
        setVisibility(!visibility);
        setOption();
        setFile();
        setFileName('Selectionner un fichier jpg, png, pdf');
    }

    return (
        <>
            <Col
                md={12}
                lg={4}
                className='menuItem d-flex justify-content-center align-items-end'
                onClick={() => setVisibility(true)}>
                <div className='container-text d-flex flex-column align-items-center justify-content-center'>
                    <CgFileDocument/>
                    <h2>Fournir un document</h2>
                </div>
            </Col>
            <Modal
                show={visibility}
                backdropClassName="backdrop"
                aria-labelledby="example-custom-modal-styling-title"
                scrollable
                centered
                onHide={() => hideModal()}
                style={{zIndex: '20001'}}>
                <Modal.Header closeButton>
                    Fournir un document
                </Modal.Header>
                <Modal.Body>
                    <Form.Group id="nb_person">
                        <Form.Label>Type de document</Form.Label>
                        <Form.Control name="nb_person" as="select" onChange={(e) => setOption(e.target.name)}>
                            <option>Selectionner un type</option>
                            <option name='diagnostic' >Diagnostic énergétique</option>
                        </Form.Control>
                    </Form.Group>
                    {option &&
                        <Form>
                            <Form.File
                                id="custom-file-translate-scss"
                                label={fileName}
                                lang="fr"
                                custom
                                onChange={(e) => {
                                    setFile(e.target.files[0]);
                                    setFileName(e.target.files[0].name)
                                }}
                            />
                        </Form>}
                </Modal.Body>
                <Modal.Footer>
                    {file && <Button className='whiteHover' variant='berry' onClick={handleSubmit}>Envoyer</Button>}
                    <Button className='goldenHover' onClick={() => hideModal()}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}