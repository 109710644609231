import { FaFileInvoiceDollar } from 'react-icons/fa'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useState } from 'react';
import InvoiceList from '../../../invoice/invoiceList';
import AskProForma from '../proforma/askProFormat';
import GenerateInvoieOrBillRider from '../../../invoice/generate/genrateInvoiceOrBillRider';

export default function InvoiceModal(props) {
    const [visibility, setVisibility] = useState(false);

    return (
        <Col className='d-flex justify-content-center align-items-center'>
            <Button className="modifyOptionButton whiteSVG" variant="secondary" style={{ margin: "1vh" }} size="sm" onClick={() => setVisibility(!visibility)}>Facture <FaFileInvoiceDollar /></Button>
            <Modal
                scrollable
                centered
                backdropClassName="backdropFourth"
                style={{ zIndex: '20003' }}
                size='lg'
                show={visibility}
                onHide={() => setVisibility(!visibility)}>
                <Modal.Header closeButton>
                    <Modal.Title className='d-flex justify-content-around' style={{ width: '100%' }}>
                        Liste des factures
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className="d-flex justify-content-around align-items-center reducePadding mb-3" xs={12} lg={6}>
                            <GenerateInvoieOrBillRider
                            invoiceUrl={`admin/booking/${props.booking.id}/option/${props.option.id}/generateInvoice`}
                            billRiderUrl={`admin/booking/${props.booking.id}/option/${props.option.id}/generateBillRider`}
                            reloadFrom={`/apartment/${props.booking.apartment_id}/booking/${props.booking.id}`}
                            set={props.BookingDetailsOnChange}/>
                        </Col>
                        {
                            props.option.invoice_as_pro_forma == 1 &&
                            <AskProForma url={`/admin/booking/${props.booking.id}/option/${props.option.id}/disableProForma`} handleChange={props.BookingDetailsOnChange} />
                        }
                        <Col xs={12}>
                            <small>
                                <ul>
                                    <li>
                                        En cliquant sur générer un facture vous créerez une nouvelle facture pro-forma.
                                    </li>
                                    <li>
                                        En cliquant sur Désactivation du pro forma ceci créeras une nouvelle facture.
                                    </li>
                                </ul>
                            </small>
                        </Col>
                    </Row>
                    <InvoiceList invoices={props.urls} />
                </Modal.Body>
            </Modal>
        </Col >
    )
}