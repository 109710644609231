import { useDispatch, connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Col, Dropdown, FormControl, Card } from 'react-bootstrap';
import DateRange from '../../../searchBar/dateRange/dateRange'
import Spinner from '../../../spinner/Spinner';
import instanceOfAxios from '../../../../app/axios/axios';
import { show } from '../../Alerter/alerterSlice';
import { FetchResultatAdmin, hideCreate } from '../bookingTabSlice';
import { getBeginDate, getEndDate } from '../../../searchBar/dateRange/dateRangeSlice';

function setNbPersonField(nb_persons, name) {
    let options = [];
    if (nb_persons !== undefined) {
        for (let i = 0; i <= nb_persons; i++) {
            options.push(<option key={name + i} name={name}>{i}</option>);
        }
    }
    return options;
}

function getDifferenceInDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return Math.round(diffInMs / (1000 * 60 * 60 * 24));
}

function setSiteTiersField(siteTiers) {
    siteTiers = JSON.parse(siteTiers.dropDown.content)
    let options = [];

    Object.values(siteTiers).forEach(function (value) {
        options.push(<option key={value} name={'site_tiers'}>{value}</option>);
    })
    return options;
}
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button variant="secondary"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        &#x25bc;
    </Button>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');
        const lang = navigator.language;
        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled" style={{ position: 'relative', overflow: 'auto', maxHeight: '300px', maxWidth: '600px' }}>
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || (child.props.children[0] + ' ' + child.props.children[4]).toLowerCase().includes(value),
                    )}
                </ul>
            </div>
        );
    },
);

const CustomMenuRef = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');
        const lang = navigator.language;
        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ul className="list-unstyled" style={{ position: 'relative', overflow: 'auto', maxHeight: '300px', maxWidth: '600px' }}>
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || (child.props.children).toLowerCase().includes(value)
                    )}
                </ul>
            </div>
        );
    },
);
const CreateBooking = (props) => {
    const dispatch = useDispatch();
    //state d'attente de datas
    const [usersPending, setUsersPending] = useState(true);
    const [pending, setPending] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    //state datas

    const [apartments, setApartments] = useState([]);
    const [priceInfluencers, setPriceInfluencers] = useState([]);
    const [users, setUsers] = useState([]);
    const [error, setError] = useState([]);

    //data affichées
    const [selectedAppartment, setSelectedAppartment] = useState();
    const [valueUser, setValueUser] = useState();
    const [valueContact, setValueContact] = useState();
    const [simulated, setSimulated] = useState(undefined);
    const [params, setParams] = useState({ 'cgl_accepted': true });
    const [siteTiers, setSiteTiers] = useState({});

    var options = { year: "numeric", month: "long", day: "numeric" };
    var correspondTarget = {rent : 'loyer propriétaire', rate : 'tarif total', commission : 'commission class appart'};

    let nbPerson = 0;
    let nbAdult = 0;
    let contacts = undefined;

    useEffect(() => {
        let newParam = Object.assign({}, params);
        newParam.begin_date_hour = props.beginDate;
        setParams(newParam);
        setSimulated(undefined);
    }, [props.beginDate])

    useEffect(() => {
        let newParam = Object.assign({}, params);
        newParam.end_date_hour = props.endDate;
        setParams(newParam);
        setSimulated(undefined);
    }, [props.endDate])

    useEffect(() => {
        if (pending) {
            fetchApartments();
        }
    }, [pending])

    useEffect(() => {
        if (usersPending) {
            instanceOfAxios.get('/admin/account')
                .then((res) => {
                    setUsers(res.data.accounts);
                    setUsersPending(false);
                });
        }
    }, [usersPending])

    useEffect(() => {
        if (!pending && !usersPending) {
            setLoaded(true);
        }
    }, [pending, usersPending])

    function getLoadedStatus() {
        let message = 'Chargement des';
        if (pending && usersPending) {
            message += ' utilisateurs et logements'
        } else if (pending) {
            message += ' logements'
        } else if (usersPending) {
            message += ' utilisateurs'
        }
        return message;
    }

    function handleChange(event) {
        let newParam = Object.assign({}, params);

        if (event.target.type === 'checkbox') {
            if (event.target.checked) {
                newParam[event.target.name] = 1;
            } else {
                newParam[event.target.name] = 0;
            }
        } else if (event.target.value !== undefined) {
            newParam[event.target.name] = event.target.value;
        } else {
            newParam[event.target.name] = event.target.id;
            if (event.target.getAttribute('name') === 'apartment') {
                setSelectedAppartment(event.target.getAttribute('eventkey'));
            } else if (event.target.getAttribute('name') === 'account_id') {
                setValueUser(event.target.getAttribute('eventkey'));
            } else {
                setValueContact(event.target.getAttribute('eventkey'));
            }
        }

        if(event.target.value === ''){
            delete newParam[event.target.name]
        }

        if (event.target.value == 'Selectionner un site') {
            delete newParam.site_tiers;
            delete newParam.site_tiers_com;
            delete newParam.site_tiers_dif;
        }

        if (event.target.value == 'Selectionner') {
            delete newParam.price_influencer_value;
            delete newParam.price_influencer;
        }
        setParams(newParam);
        setSimulated(undefined);
    }

    function simulate() {
        setIsSubmit(true);
        let parameter = new FormData();
        setFormData(parameter, params);
        instanceOfAxios.post('/apartment/' + params.apartment + '/simulateBooking', parameter)
            .then((res) => {
                setSimulated(res.data.succes);
                setPriceInfluencers(res.data.price_influencers);
                setIsSubmit(false);

                console.log(res.data.succes);
            })
            .catch((error) => {
                setIsSubmit(false);
                // handleError(error);
            })


    }


    function setFormData(formData) {
        Object.keys(params).forEach((key) => {
            if (key === 'begin_date_hour' || key === 'end_date_hour') {
                let date = new Date(params[key]);
                formData.set(key, date.toUTCString() || undefined);
            } else {
                formData.set(key, params[key] || undefined);
            }
        });
    }

    function handleSubmit() {
        setIsSubmit(true);
        setError({});
        let parameter = new FormData();
        setFormData(parameter);
        instanceOfAxios.post('/apartment/' + params.apartment + '/booking', parameter)
            .then((res) => {
                setIsSubmit(false);
                dispatch(FetchResultatAdmin());
                dispatch(hideCreate());
            }).catch((error) => {
                setIsSubmit(false);
                handleError(error);
            });
    }

    function handleError(errors) {
        let message = 'Erreur lors de l\'action';
        if (errors.response.data.error === 'non disponible') {
            message = 'Propriété déjà réservé pour ces dates';
        }

        let error = Object.assign([], errors);
        Object.keys(error.response.data.error).forEach(function (key) {
            error[key] = error.response.data.error[key];
        });
        setError(error);
        setIsSubmit(false);
        dispatch(show({ message: message, variant: 'danger' }));
    }

    function fetchApartments() {
        instanceOfAxios.get('/admin/SecondIndexApartments')
            .then((res) => {
                setApartments(res.data.apartments);
            })

        instanceOfAxios.get('/admin/dropDown/7')
            .then((res) => {
                setSiteTiers(res.data);
            })
        setPending(false);
    }

    if (selectedAppartment !== undefined) {
        let object = apartments.find((element) => {
            return element.reference === selectedAppartment;
        })
        nbPerson = object.nb_person;
        nbAdult = object.nb_adult;
    }

    if (valueUser !== undefined) {
        let object = users.find((element) => {
            if (element.principal_contact) {
                return element.email === valueUser;
            }
        })
        contacts = object.contacts;
    }

    let content =
        <div className={props.className}>
            <Button variant="secondary" className="btn-rounded button-margin-top" onClick={() => {
                dispatch(hideCreate())
            }}>retour</Button>
            {
                loaded
                    ?
                    <Form>
                        <Row>
                            <Col md={12} className='text-center'>
                                <Dropdown style={{ padding: '10px' }}>
                                    <Dropdown.Toggle as={CustomToggle}>
                                        {valueUser || 'Choisir un compte'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu}>
                                        {users ? users.map(function (value) {
                                            if (value.principal_contact) {
                                                return (<Dropdown.Item key={value.id} name='account_id' id={value.id} eventkey={value.email} onClick={(e) => handleChange(e)}>{value.principal_contact.first_name + ' ' + value.principal_contact.last_name} <br></br> {value.email}</Dropdown.Item>)
                                            }
                                        }) : <Dropdown.Item>Aucun Locataire</Dropdown.Item>}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                        {valueUser ?
                            <Row>
                                <Col md={12} className='text-center'>
                                    <Dropdown style={{ padding: '10px' }}>
                                        <Dropdown.Toggle as={CustomToggle}>
                                            {valueContact || 'Choisir un contact'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu as={CustomMenu}>
                                            {contacts ? contacts.map((value) =>
                                                <Dropdown.Item key={value.id} name='contact_id' id={value.id} eventkey={value.first_name + ' ' + value.last_name} onClick={(e) => handleChange(e)}>{value.first_name + ' ' + value.last_name}</Dropdown.Item>)
                                                :
                                                <Dropdown.Item>Aucun contacts</Dropdown.Item>}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                            : <></>}
                        {valueContact ?
                            <Row>
                                <Col md={12} className='text-center'>
                                    <Dropdown style={{ padding: '10px' }}>
                                        <Dropdown.Toggle as={CustomToggle}>
                                            {selectedAppartment || 'Choisir une propriété'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu as={CustomMenuRef}>
                                            {apartments && apartments.map((value) =>
                                                <Dropdown.Item key={value.id} className='text-center' name='apartment' id={value.id} eventkey={value.reference} onClick={(e) => handleChange(e)}>{value.reference}</Dropdown.Item>)}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                            : <></>}
                        {params.apartment ?
                            <>
                                {error['date'] ? <label htmlFor="dateRange"><small style={{ color: "red" }}>{error['date']}</small></label> : <></>}
                                <DateRange adminUsage usage='admin' id='dateRangeBooking' minLength={1} apartment={params.apartment} />
                                <Row className='mt-3'>
                                    <Col xs={12} md={4}>
                                        <Form.Group id="nb_person">
                                            <Form.Label>Nombre de personnes {error['nb_person'] ? <small style={{ color: "red" }}>{error['nb_person']}</small> : <></>}</Form.Label>
                                            <Form.Control name="nb_person" as="select" onChange={(e) => handleChange(e)}>
                                                {setNbPersonField(nbPerson, 'nb_person')}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Group id="nb_adult">
                                            <Form.Label>Nombre de d'adultes {error['nb_adult'] ? <small style={{ color: "red" }}>{error['nb_adult']}</small> : <></>}</Form.Label>
                                            <Form.Control name="nb_adult" as="select" onChange={(e) => handleChange(e, params)}>
                                                {setNbPersonField(nbAdult, 'nb_adult')}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Group id="site_tiers">
                                            <Form.Label>Site Tier {error['site_tiers'] ? <small style={{ color: "red" }}>{error['site_tiers']}</small> : <></>}</Form.Label>
                                            <Form.Control name="site_tiers" as="select" onChange={(e) => handleChange(e)}>
                                                <option name={'site_tiers'}>Selectionner un site</option>
                                                {setSiteTiersField(siteTiers)}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group id="price_influencer_value">
                                            <Form.Label>Valeur de la remise {error['price_influencer_value'] ? <small style={{ color: "red" }}>{error['price_influencer_value']}</small> : <></>}</Form.Label>
                                            <Form.Control name='price_influencer_value' onChange={(e) => handleChange(e)} type="text" />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group id="price_influencer_target">
                                            <Form.Label>Remise appliqué sur {error['price_influencer_target'] ? <small style={{ color: "red" }}>{error['price_influencer_target']}</small> : <></>}</Form.Label>
                                            <Form.Control name="price_influencer_target" as="select" onChange={(e) => handleChange(e)}>
                                                <option>Selectionner</option>
                                                <option value = 'commission'>Commission class Appart</option>
                                                <option value = 'rent'>Loyer propriétaire</option>
                                                <option value = 'rate'>Total</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    {
                                        params.site_tiers &&
                                        <>
                                            <Col xs={12} md={6}>
                                                <Form.Group id="site_tiers_com">
                                                    <Form.Label>Site Tier commission {error['site_tiers_com'] ? <small style={{ color: "red" }}>{error['site_tiers_com']}</small> : <></>}</Form.Label>
                                                    <Form.Control name='site_tiers_com' onChange={(e) => handleChange(e)} type="text" />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Group id="prix_site_tiers">
                                                    <Form.Label>Prix site Tier {error['prix_site_tiers'] ? <small style={{ color: "red" }}>{error['prix_site_tiers']}</small> : <></>}</Form.Label>
                                                    <Form.Control name='prix_site_tiers' onChange={(e) => handleChange(e)} type="text" />
                                                </Form.Group>
                                            </Col>
                                            {
                                                params.site_tiers === 'AirBnb' &&
                                                <Col xs={12} md={6}>
                                                    <Form.Group id="ref_airbnb">
                                                        <Form.Label>Référence site passerelle {error['ref_airbnb'] ? <small style={{ color: "red" }}>{error['ref_airbnb']}</small> : <></>}</Form.Label>
                                                        <Form.Control name='ref_airbnb' onChange={(e) => handleChange(e)} type="text" />
                                                    </Form.Group>
                                                </Col>
                                            }
                                        </>
                                    }
                                    <Col xs={12} className='d-flex justify-content-center'>
                                        {!isSubmit ?
                                            <Button variant="secondary" className="btn" onClick={() => {
                                                simulate();
                                            }}>Simuler</Button>
                                            :
                                            <Spinner />}
                                    </Col>
                                </Row>

                            </>
                            :
                            <></>}
                    </Form>
                    :
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col xs={12}>
                            <h2>{getLoadedStatus()}</h2>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center align-items-center'>
                            <Spinner />
                        </Col>
                    </Row>
            }
            {simulated &&
                <div className='mt-3 pb-5'>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header className='reducePadding'>
                                    <Row className='d-flex justify-content-center'>
                                        <Col xs={12} md={6} lg={4} className='mt-2 mb-2'>
                                            <Row className='backGrayBorder shadowForCard'>
                                                <Col xs={8} className='noPadding'>
                                                    <p>Tarif séjour :</p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p> <strong>{parseFloat(simulated.rate).toFixed(2)} €</strong></p>
                                                </Col>
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">Tarif par nuit :</p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight"> <strong>{(parseFloat(simulated.rate).toFixed(2) / getDifferenceInDays(props.beginDate, props.endDate)).toFixed(2)} €</strong></p>
                                                </Col>
                                                {
                                                    simulated.site_tiers == 'AirBnb' &&
                                                    <>
                                                        <Col xs={8} className='noPadding'>
                                                            <p className="apartmentText">Référence site passerelle :</p>
                                                        </Col>
                                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                            <p className="apartmentTextAlignRight"> <strong>{simulated.ref_airbnb}</strong></p>
                                                        </Col>
                                                    </>
                                                }
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">Caution :</p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight"> <strong>{(simulated.bail_amount).toFixed(2)} €</strong></p>
                                                </Col>
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">Nuitées :</p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p>{getDifferenceInDays(props.beginDate, props.endDate)}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6} lg={4} className='mt-2 mb-2'>
                                            <Row className='backGrayBorder shadowForCard'>
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">Loyer propriétaire : </p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight"><strong>{(simulated.rent).toFixed(2)} €</strong></p>
                                                </Col>
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">Commision (HT) : </p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight"><strong>{(simulated.commission - simulated.tva_value).toFixed(2)} €</strong></p>
                                                </Col>
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">TVA :</p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight"> <strong>{(simulated.tva_value).toFixed(2)} €</strong></p>
                                                </Col>
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">Ménage : </p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight"><strong>{(simulated.cleaning).toFixed(2)} €</strong></p>
                                                </Col>
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">EDF :</p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight"><strong>{(simulated.EDF).toFixed(2)} €</strong></p>
                                                </Col>
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">Taxe de séjour : </p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight"><strong>{(simulated.tourism).toFixed(2)} €</strong></p>
                                                </Col>
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">Assurance :</p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight"><strong>{(simulated.assurance).toFixed(2)} €</strong></p>
                                                </Col>

                                                {
                                                    simulated.site_tiers &&
                                                    <>
                                                        <Col xs={8} className='noPadding'>
                                                            <p className="apartmentText">Commission site tier :</p>
                                                        </Col>
                                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                            <p className="apartmentTextAlignRight"> <strong>{parseFloat(simulated.site_tiers_com).toFixed(2)} €</strong></p>
                                                        </Col>

                                                        <Col xs={8} className='noPadding'>
                                                            <p className="apartmentText">Différence site tiers :</p>
                                                        </Col>
                                                        <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                            <p className="apartmentTextAlignRight"> <strong>{parseFloat(simulated.site_tiers_dif).toFixed(2)} €</strong></p>
                                                        </Col>

                                                    </>
                                                }

                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">Total :</p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    {/* le passer en vert si c'est égal à rent */}

                                                    <p className="apartmentTextAlignRight" > <strong>{parseFloat(simulated.cleaning + simulated.tourism + simulated.assurance + simulated.EDF + simulated.rent + simulated.commission - simulated.tva_value + simulated.tva_value).toFixed(2)} € €</strong></p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6} lg={4} className='d-flex align-items-start flex-column mt-2 mb-2'>
                                            <Row className='backGrayBorder shadowForCard mb-auto'>
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">Nombre de personnes : </p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight"><strong>{simulated.nb_person}</strong></p>
                                                </Col>
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">Nombre d'adultes : </p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight"><strong>{simulated.nb_adult}</strong></p>
                                                </Col>
                                                <Col xs={6} className='noPadding'>
                                                    <p className="apartmentText">Début :</p>
                                                </Col>
                                                <Col xs={6} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight">{props.beginDate.toLocaleDateString('fr-FR', options)}</p>
                                                </Col>
                                                <Col xs={8} className='noPadding'>
                                                    <p className="apartmentText">Fin :</p>
                                                </Col>
                                                <Col xs={4} className='d-flex justify-content-end align-items-end noPadding'>
                                                    <p className="apartmentTextAlignRight"> {props.endDate.toLocaleDateString('fr-FR', options)}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {
                                            priceInfluencers.length > 0 &&
                                            <Col xs={12} md={7} className='d-flex align-items-center flex-column mt-2 mb-2'>
                                                <Row className='backGrayBorder shadowForCard'>
                                                    <Col xs={12}>
                                                        <strong>Majorations / Réductions :</strong>
                                                    </Col>
                                                    <ul style={{ margin: 'unset' }}>
                                                        {simulated.price_influencer_target &&
                                                            <li className='p-2'>
                                                            <Col xs={12} className='text-center'>
                                                                {simulated.price_influencer_value > 0 ? 'Majoration ' : 'Réduction '}
                                                                <strong>{`${correspondTarget[simulated.price_influencer_target]} `}</strong>
                                                                de
                                                                <strong>{` ${simulated.price_influencer_value }€ `}</strong>
                                                            </Col>
                                                        </li>
                                                        }
                                                    
                                                        {priceInfluencers.map(priceInfluencer => {
                                                            let begin_period = new Date(priceInfluencer.begin_date_hour.replace(/\s/, 'T'));
                                                            let end_period = new Date(priceInfluencer.end_date_hour.replace(/\s/, 'T'));
                                                            var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
                                                            return (
                                                                <li className='p-2'>
                                                                    <Col xs={12} className='text-center'>
                                                                        {priceInfluencer.is_positive == 1 ? 'Majoration ' : 'Réduction '}
                                                                        <strong>{priceInfluencer.is_for_owner == 1 ? 'propriétaire ' : 'class appart '}</strong>
                                                                        de
                                                                        <strong>{` ${priceInfluencer.percent}% `}</strong>
                                                                        du
                                                                        <strong>{` ${begin_period.toLocaleDateString('fr-FR', options)}`} </strong>
                                                                        au
                                                                        <strong>{` ${end_period.toLocaleDateString('fr-FR', options)}`}</strong>
                                                                    </Col>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </Row>
                                            </Col>
                                        }
                                    </Row>
                                </Card.Header>
                            </Card>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center mt-3'>
                            {!isSubmit ?
                                <Button variant="secondary" className="btn" onClick={() => {
                                    handleSubmit();
                                }}>Créer</Button>
                                :
                                <Spinner />}
                        </Col>
                    </Row>
                </div>
            }
        </div>
    return content;
}

function mapStateToProps(state) {
    return {
        beginDate: getBeginDate(state),
        endDate: getEndDate(state)
    }
}

export default connect(mapStateToProps)(CreateBooking)