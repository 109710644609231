import React from 'react';
import ApartmentCard from '../card/ApartmentCard';
import { Col, Row, Button, Form, Image } from 'react-bootstrap';
import { useState } from 'react';
import instanceOfAxios from '../../../../app/axios/axios';
import { getReload, getTypeToReload, setReload } from './apartmentListSlice';
import { connect, useDispatch, useStore } from 'react-redux';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import SkeletonCard from '../card/skeletonCard';
import { AiOutlineSearch } from 'react-icons/ai';

const ListType = (props) => {
    const [activePage, setActivePage] = useState(1);
    const [resultFromApi, setResultFromApi] = useState();
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(true);
    const [param, setParam] = useState({});
    const [pageNumber, setPageNumber] = useState(0);
    const dispatch = useDispatch();
    const skeleton = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
    let reload = useStore().getState().apartmentsList.reload;

    if (pending || reload && props.typeToReload == props.type) {
        dispatch(setReload(false));
        fetchApartments(activePage);
    }

    function next() {
        if (activePage < pageNumber) {
            setLoading(true);
            setActivePage(parseInt(activePage) + 1);
            fetchApartments(parseInt(activePage) + 1)
        }
    }

    function prev() {
        if (activePage > 1) {
            setLoading(true);
            setActivePage(parseInt(activePage) - 1);
            fetchApartments(parseInt(activePage) - 1)
        }
    }

    function fetchApartments(active) {
        setLoading(true);
        instanceOfAxios.get(props.url + active)
            .then((res) => {
                setResultFromApi(res.data.apartments.data);
                setPageNumber(res.data.apartments.last_page);
                setLoading(false);
            });
        if (pending) {
            setPending(false);
        }
    }

    function handleChange(event) {
        let params = Object.assign({}, param);

        params[event.target.name] = event.target.value;
        return setParam(params);
    }

    function mapCard() {
        if (!pending && !resultFromApi) {
            return (
                <div style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                    <Image loading='lazy' src="oops.png" className="center-block size-oops" alt="" fluid></Image>
                    <p>Aucun logement à afficher </p>
                </div>
            )
        } else {
            return resultFromApi.map((value) => {
                return <ApartmentCard key={value.reference} apartment={value} type={props.type}></ApartmentCard>
            })
        }
    }

    function search() {
        setLoading(true);
        instanceOfAxios.get(props.url + 1 + '&' + new URLSearchParams(param).toString())
            .then((res) => {
                setResultFromApi(res.data.apartments.data);
                setPageNumber(res.data.apartments.last_page);
                setLoading(false);
                setActivePage(1);
            });
    }

    return (
        <div style={{ width: '100%' }}>
            <Row>
                <Col xs={12} md={3}>
                    <Row className="searchAdminForm mt-3">
                        <Col xs={12} id='formSearchAdmin' className='noPadding'>
                            <Form.Group>
                                <Form.Label>Référence de logement</Form.Label>
                                <Form.Control
                                name='reference'
                                onChange={(e) => handleChange(e)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        search();
                                    }
                                }}
                                placeholder='Référence' />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Adresse</Form.Label>
                                <Form.Control
                                name='address'
                                onChange={(e) => handleChange(e)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        search();
                                    }
                                }}
                                placeholder='rue jacques coeur' />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Nom de propriétaire</Form.Label>
                                <Form.Control
                                name='name'
                                onChange={(e) => handleChange(e)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        search();
                                    }
                                }}
                                placeholder='Nom de propriétaire' />
                            </Form.Group>
                            <Button
                                className="mt-2 whiteSVG"
                                variant="secondary"
                                id="button-addon2"
                                onClick={search}>
                                Rechercher <AiOutlineSearch/>
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={9} className='noPadding'>
                    <Row className='d-flex justify-content-between mt-3 mb-3'>
                        {resultFromApi !== undefined && resultFromApi.length > 0 ?
                            <>
                                <Col xs={2} className='text-center'>
                                    <Button onClick={(e) => prev(e)} size="sm" variant="light">
                                        <GrLinkPrevious />
                                    </Button>
                                </Col>
                                <Col xs={4} className='text-center'>
                                    <strong>{activePage}</strong> / {pageNumber}
                                </Col>
                                <Col xs={2} className='text-center'>
                                    <Button onClick={(e) => next(e)} size="sm" variant="light">
                                        <GrLinkNext />
                                    </Button>
                                </Col>
                            </>
                            :
                            <></>}
                    </Row>
                    <Row>
                        {loading ?
                            skeleton.map(skel => <SkeletonCard />)
                            :
                            mapCard()
                        }
                    </Row>
                    <Row className='d-flex justify-content-between mt-3 mb-3'>
                        {resultFromApi !== undefined && resultFromApi.length > 0 ?
                            <>
                                <Col xs={2} className='text-center'>
                                    <Button onClick={(e) => prev(e)} size="sm" variant="light">
                                        <GrLinkPrevious />
                                    </Button>
                                </Col>
                                <Col xs={4} className='text-center'>
                                    <strong>{activePage}</strong> / {pageNumber}
                                </Col>
                                <Col xs={2} className='text-center'>
                                    <Button onClick={(e) => next(e)} size="sm" variant="light">
                                        <GrLinkNext />
                                    </Button>
                                </Col>
                            </>
                            :
                            <></>}
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        reload: getReload(state),
        typeToReload: getTypeToReload(state)
    };
}

export default connect(mapStateToProps)(ListType);