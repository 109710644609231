import React, { useState, useRef, useEffect } from 'react';
import {
    getType,
    getNbPersons,
    changeDropDownValue,
    changeValueNbPersons,
    getOwners,
} from './apartmentFormSlice';
import { getApartmentForUpdate, setUpdater } from '../apartmentSlice'
import { Row, Col, Button, Form, FormControl, Dropdown } from 'react-bootstrap';
import Spinner from '../../../spinner/Spinner';
import { connect, useDispatch } from 'react-redux';
import instanceOfAxios from '../../../../app/axios/axios';
import { show } from '../../Alerter/alerterSlice';
import { setReload } from '../list/apartmentListSlice';
import { setStep } from './apartmentFormSlice';
import JoditEditor from "jodit-react";
import { FcHighPriority } from 'react-icons/fc';
import { FaRegSave } from 'react-icons/fa';

function StepOne(props) {
    const dispatch = useDispatch();
    const [apartment, setApartment] = useState(props.apartment);
    const [errorsApartment, setErrorsApartment] = useState([]);
    const nbPerson = props.nb_person;
    const [isSubmit, setIsSubmit] = useState(false);
    const [apartmentParam, setApartmentParam] = useState(props.apartment);
    const [owners, setOwners] = useState(props.owners);
    const [loadOwner, setLoadOwner] = useState(true);
    const editor = useRef(null)
    const [content, setContent] = useState(props.apartment ? props.apartment.description : '');
    const [ownerTitle, setOwnerTitle] = useState('Choisir un propriétaire');

    useEffect(() => {
        if (apartmentParam && apartmentParam.owner_id && owners) {
            let owner = owners.filter(owner => owner.id == apartmentParam.owner_id);
            setOwnerTitle(owner[0].principal_contact.first_name + ' ' + owner[0].principal_contact.last_name);
        }

        if (apartment && apartment.owner && apartment.owner.principal_contact) {
            setOwnerTitle(apartment.owner.principal_contact.first_name + ' ' + apartment.owner.principal_contact.last_name);
        }

        instanceOfAxios.get('/admin/account?role=owner')
            .then((res) => {
                setLoadOwner(false);
                setOwners(res.data.accounts);
            });
    }, []);


    function handleApartmentChange(event) {
        handleChange(apartmentParam, event, 'apartment');
    }

    function handleChange(params, event, type) {
        let param = Object.assign({}, params)
        if (event.target.type === 'checkbox') {
            if (event.target.checked) {
                param[event.target.name] = 1;
            } else {
                param[event.target.name] = 0;
            }
        } else if (event.target.getAttribute('name') == 'owner_id' || event.target.getAttribute('name') == 'district') {
            param[event.target.name] = event.target.getAttribute('id');
        } else {
            param[event.target.name] = event.target.value;
        }
        setApartmentParam(param);
    }

    function handleSubmit() {
        setIsSubmit(true);
        let url = '/apartment';
        if (apartment) {
            url = '/apartment/' + apartment.id;
        }
        let apartmentData = setFormData(apartmentParam, apartment);

        let send = 0;
        for (var pair of apartmentData.entries()) {
            send++;
        }
        if (send > 1) {
            instanceOfAxios.post(url, apartmentData)
                .then((res) => {
                    let apartment = res.data.Apartment;
                    instanceOfAxios.get('/apartment/' + res.data.Apartment.id + '/visits')
                        .then((res) => {
                            apartment.visits = res.data.visits
                            dispatch(setUpdater(apartment));
                            setApartment(apartment);
                            setIsSubmit(false);
                            dispatch(setReload(true));
                            dispatch(setStep(2));
                        })
                })
                .catch((error) => {
                    handleError(error, 'apartment');
                })
        } else {
            dispatch(setStep(2));
            setIsSubmit(false);
        }
    }

    function setFormData(params, object) {
        let formData = new FormData();
        Object.keys(params).forEach((key) => {
            if (object && object.id) {
                if (params[key] !== object[key]) {
                    formData.set(key, params[key]);
                }
            } else {
                formData.set(key, params[key] || null);
            }
        });
        if (object && object.id) {
            formData.set('_method', 'PATCH');
        }
        formData.set('description', content);
        return formData;
    }

    function handleError(errors, type) {
        let error = Object.assign([], errors);
        Object.keys(error.response.data.error).forEach(function (key) {
            error[key] = error.response.data.error[key];
        });
        setErrorsApartment(error);
        setIsSubmit(false);
        dispatch(show({ message: 'Erreur lors de l\'action', variant: 'danger' }));
    }

    function setOptionsField(attribut) {

        if (nbPerson !== undefined) {
            return (nbPerson.map(nb => {
                let selected = false;
                if (apartmentParam && apartmentParam[attribut] == nb) {
                    selected = true;
                }
                return (<option key={'key' + nb} id={nb} onClick={(e) => changeValueNbPersons(e.target.id)} selected={selected}>{nb}</option>)
            }))
        }
        return;
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button variant="secondary" className="btn-rounded"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            &#x25bc;
        </Button>
    ));

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');
            const lang = navigator.language;
            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <FormControl
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Recherche"
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled" style={{ position: 'relative', overflow: 'auto', maxHeight: '150px' }}>
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || child.props.children.toLowerCase().includes(value),
                        )}
                    </ul>
                </div>
            );
        },
    );

    return (
        <Form className='pb-3'>
            <Row>
                <Col xs={12} md={6}>
                    {errorsApartment['owner_id'] && <label htmlFor="owner_id"><small style={{ color: "red" }}> {errorsApartment['owner_id']}</small></label>}
                    <Form.Group>
                        <Form.Label>Propriétaire</Form.Label>
                        <Dropdown className="d-flex mt--2">
                            <Dropdown.Toggle as={CustomToggle}>
                                {ownerTitle}
                            </Dropdown.Toggle>
                            <Dropdown.Menu as={CustomMenu} style={{ padding: '10px', margin: '0px' }}>
                                {owners ? owners.map((value) =>
                                        {
                                            if(value.principal_contact){
                                                return <Dropdown.Item key={value.id} name='owner_id' id={value.id} eventkey={value.principal_contact.first_name + ' ' + value.principal_contact.last_name} onClick={(e) => {
                                                    handleApartmentChange(e)
                                                    setOwnerTitle(value.principal_contact.first_name + ' ' + value.principal_contact.last_name);
                                                }}>{value.principal_contact.first_name + ' ' + value.principal_contact.last_name}</Dropdown.Item>
                                            }else{
                                                return <Dropdown.Item style={{backgroundColor:'lightgrey', borderRadius: '5px'}} disabled key={value.id} name='owner_id' id={value.id} eventkey={value.email}>{value.email} <br/> <FcHighPriority/> attention le compte ne détient pas d'informations de contact</Dropdown.Item>
                                            }
                                        }
                                    )
                                    :
                                    <>
                                        {
                                            loadOwner ?
                                            <Spinner/>
                                            :
                                            <Dropdown.Item>Aucun propriétaire</Dropdown.Item>
                                        }
                                    </>
                                    }
                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Group>
                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Titre *</Form.Label>
                                {errorsApartment['title'] && <label htmlFor="title"><small style={{ color: "red" }}> {errorsApartment['title']}</small></label>}
                                <Form.Control defaultValue={apartmentParam ? apartmentParam.title : ''} name="title" placeholder="titre" onChange={(e) => handleApartmentChange(e)} />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>Type de logement *</Form.Label>
                                {errorsApartment['type'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsApartment['type']}</small></label>}
                                <Form.Control name="type" as="select" onChange={(e) => handleApartmentChange(e)}>
                                    <option onClick={(e) => dispatch(changeDropDownValue(e.target.id))}>Selectionner un type</option>
                                    <option id='Villa' onClick={(e) => changeDropDownValue(e.target.id)} selected={apartmentParam && apartmentParam.type == 'Villa'}>Villa</option>
                                    <option id='Apartment' onClick={(e) => changeDropDownValue(e.target.id)} selected={apartmentParam && (apartmentParam.type == 'Appartement' || apartmentParam.type == 'Apartment')}>Appartement</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group id="floor">
                                <Form.Label>Etage</Form.Label>
                                {errorsApartment['floor'] && <label htmlFor="floor"><small style={{ color: "red" }}>{errorsApartment['floor']}</small></label>}
                                <Form.Control defaultValue={apartmentParam ? apartmentParam.floor : ''} name="floor" placeholder="Rez" onChange={(e) => handleApartmentChange(e)} />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group id="min_night">
                                <Form.Label>Minimum nuit</Form.Label>
                                {errorsApartment['min_night'] && <label htmlFor="min_night"><small style={{ color: "red" }}>{errorsApartment['min_night']}</small></label>}
                                <Form.Control defaultValue={apartmentParam ? apartmentParam.min_night : ''} name="min_night" placeholder="3" onChange={(e) => handleApartmentChange(e)} />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group id="min_night">
                                <Form.Label>Numéro d'enregistrement</Form.Label>
                                {errorsApartment['num_register'] && <label htmlFor="num_register"><small style={{ color: "red" }}>{errorsApartment['num_register']}</small></label>}
                                <Form.Control defaultValue={apartmentParam ? apartmentParam.num_register : ''} name="num_register" onChange={(e) => handleApartmentChange(e)} />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col md={12} lg={6} xl={4}>
                            <Form.Group id="nb_room">
                                <Form.Label>Nombre de pièces *</Form.Label>
                                {errorsApartment['nb_room'] && <label htmlFor="nb_room"><small style={{ color: "red" }}>{errorsApartment['nb_room']}</small></label>}
                                <Form.Control name="nb_room" as="select" onChange={(e) => handleApartmentChange(e)}>
                                    <option onClick={(e) => dispatch(changeDropDownValue(e.target.id))}>Selectionner un nombre de pièce</option>
                                    {setOptionsField('nb_room')}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={12} lg={6} xl={4}>
                            <Form.Group id="nb_person">
                                <Form.Label>Nombre de personnes *</Form.Label>
                                {errorsApartment['nb_person'] && <label htmlFor="nb_person"><small style={{ color: "red" }}>{errorsApartment['nb_person']}</small></label>}
                                <Form.Control name="nb_person" as="select" onChange={(e) => handleApartmentChange(e)}>
                                    <option onClick={(e) => dispatch(changeDropDownValue(e.target.id))}>Selectionner un nombre de personne</option>
                                    {setOptionsField('nb_person')}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={12} lg={6} xl={4}>
                            <Form.Group id="nb_adult">
                                <Form.Label>Nombre d'adultes *</Form.Label>
                                {errorsApartment['nb_adult'] && <label htmlFor="nb_adult"><small style={{ color: "red" }}>{errorsApartment['nb_adult']}</small></label>}
                                <Form.Control name="nb_adult" as="select" onChange={(e) => handleApartmentChange(e)}>
                                    <option onClick={(e) => dispatch(changeDropDownValue(e.target.id))}>Selectionner un nombre d'adulte</option>
                                    {setOptionsField('nb_adult')}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </Col>
                <Col xs={12} md={6} className='d-flex align-items-center'>
                    <div style={{ width: '100%' }}>
                        <Form.Row>
                            <Form.Group as={Col} lg={4} xl={3} id="staircase">
                                <Form.Check name="staircase" type="checkbox" label="Cage d'escalier" checked={apartmentParam && apartmentParam.staircase} onChange={(e) => { handleApartmentChange(e); }} />
                            </Form.Group>
                            <Form.Group as={Col} lg={4} xl={3} id="elevator">
                                <Form.Check name="elevator" type="checkbox" label="Ascenseur" checked={apartmentParam && apartmentParam.elevator} onChange={(e) => {
                                    handleApartmentChange(e)
                                }} />
                            </Form.Group>
                            <Form.Group as={Col} lg={4} xl={3} id="interphone">
                                <Form.Check name="interphone" type="checkbox" label="Interphone" checked={apartmentParam && apartmentParam.interphone} onChange={(e) => {
                                    handleApartmentChange(e)
                                }} />
                            </Form.Group>
                            <Form.Group as={Col} lg={4} xl={3} id="garage">
                                <Form.Check name="garage" type="checkbox" label="Garage" checked={apartmentParam && apartmentParam.garage} onChange={(e) => {
                                    handleApartmentChange(e)
                                }} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} lg={4} xl={3} id="handicap_accessibility">
                                <Form.Check name="handicap_accessibility" type="checkbox" label="Accéssibilité handicap" checked={apartmentParam && apartmentParam.handicap_accessibility} onChange={(e) => {
                                    handleApartmentChange(e)
                                }} />
                            </Form.Group>
                            <Form.Group as={Col} lg={4} xl={3} id="air_conditioning">
                                <Form.Check name="air_conditioning" type="checkbox" label="Climatisation" checked={apartmentParam && apartmentParam.air_conditioning} onChange={(e) => {
                                    handleApartmentChange(e)
                                }} />
                            </Form.Group>
                            <Form.Group as={Col} lg={4} xl={3} id="guardian">
                                <Form.Check name="guardian" type="checkbox" label="Gardien" checked={apartmentParam && apartmentParam.guardian} onChange={(e) => {
                                    handleApartmentChange(e)
                                }} />
                            </Form.Group>
                            <Form.Group as={Col} lg={4} xl={3} id="pet">
                                <Form.Check name="pet" type="checkbox" label="Animaux non admis" checked={apartmentParam && apartmentParam.pet} onChange={(e) => {
                                    handleApartmentChange(e)
                                }} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} lg={4} xl={3} id="garden">
                                <Form.Check name="garden" type="checkbox" label="Jardin" checked={apartmentParam && apartmentParam.garden} onChange={(e) => {
                                    handleApartmentChange(e)
                                }} />
                            </Form.Group>
                            <Form.Group as={Col} lg={4} xl={3} id="acces_car">
                                <Form.Check name="acces_car" type="checkbox" label="Accés voiture" checked={apartmentParam && apartmentParam.acces_car} onChange={(e) => {
                                    handleApartmentChange(e)
                                }} />
                            </Form.Group>
                            <Form.Group as={Col} lg={4} xl={3} id="digital_code">
                                <Form.Check name="digital_code" type="checkbox" label="Digicode" checked={apartmentParam && apartmentParam.digital_code} onChange={(e) => {
                                    handleApartmentChange(e)
                                }} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} lg={4} xl={3} id="party">
                                <Form.Check name="party" type="checkbox" label="Fêtes non admises" checked={apartmentParam ? apartmentParam.party : true} onChange={(e) => {
                                    handleApartmentChange(e)
                                }} />
                            </Form.Group>
                            <Form.Group as={Col} lg={4} xl={3} id="internet">
                                <Form.Check name="internet" type="checkbox" label="Internet/wifi" checked={apartmentParam ? apartmentParam.internet : true} onChange={(e) => {
                                    handleApartmentChange(e)
                                }} />
                            </Form.Group>
                            <Form.Group as={Col} lg={4} xl={3} id="smocking">
                                <Form.Check name="smocking" type="checkbox" label="Non fumeurs" checked={apartmentParam ? apartmentParam.smocking : true} onChange={(e) => {
                                    handleApartmentChange(e)
                                }} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Group>
                            <Form.Label>Description courte *</Form.Label>
                            {errorsApartment['short_description'] && <label htmlFor="short_description"><small style={{ color: "red" }}>{errorsApartment['short_description']}</small></label>}
                            <Form.Control defaultValue={apartmentParam ? apartmentParam.short_description : ''} name="short_description" placeholder="pas plus d'une ligne" onChange={(e) => handleApartmentChange(e)} />
                        </Form.Group>
                    </div>
                </Col>
            </Row>
            <Col>
                <Form.Group>
                    {errorsApartment['description'] && <label htmlFor="description"><small style={{ color: "red" }}>{errorsApartment['description']}</small></label>}
                    <JoditEditor
                        ref={editor}
                        value={apartmentParam ? apartmentParam.description : ''}
                        onBlur={((contentFromEdit) => {
                            setContent(contentFromEdit);
                        })}
                    />
                </Form.Group>
            </Col>
            <Row className="d-flex justify-content-end">
                {!isSubmit ? <Button variant="secondary" className="whiteSVG" onClick={() => handleSubmit()}><FaRegSave/> Enregistrer</Button> : <Spinner/>}
            </Row>
        </Form>
    )
}
const mapStateToProps = state => {
    return {
        type: getType(state),
        nb_person: getNbPersons(state),
        apartment: getApartmentForUpdate(state),
        owners: getOwners(state)
    };
}

export default connect(mapStateToProps)(StepOne);