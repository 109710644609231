import { createSlice } from '@reduxjs/toolkit';
import instanceOfAxios from '../../app/axios/axios';
import { setIsConnected, setRole } from '../../components/header/headerSlice';

export const adminSlice = createSlice({
    name: 'adminPannel',
    initialState: {
        visibility: {selected: 'dashboard', search: true},
        pending: true
    },
    reducers: {
        setPending: (state, value) => {
            state.pending = value.payload;
        },
        setVisibility: (state, value) => {
            state.visibility = {selected: value.payload.selected, [value.payload.selected]: value.payload.boolean}
        }
    },
});

export const { setPending, setVisibility } = adminSlice.actions;

export function getAccount() {
    return function (dispatch) {
        return instanceOfAxios.get('/account')
            .then((res) => {
                dispatch(setRole(res.data.roles[0].name));
                dispatch(setIsConnected(true));
                if(res.data.roles[0].name !== 'super-admin' && res.data.roles[0].name !== 'admin' && res.data.roles[0].name !== 'trainee' && res.data.roles[0].name !== 'accountant'){
                    window.location.href = "/"
                }
            })
            .catch((error) => {
                dispatch(setIsConnected(false));
                window.location.href = "/"
            });
    }
};

export const getVisibility = state => state.adminPannel.visibility;
export const getPending = state => state.adminPannel.pending;

export default adminSlice.reducer;
