import React from 'react';
import { connect } from 'react-redux';
import { getVisibility } from '../../../pages/admin/adminSlice';
import BookingTab from '../booking/bookingTab';
import DashBoard from '../dashBoard/dashBoard';
import Quote from '../quote/quote';
import Users from '../users/users';
import Apartments from '../apartments/Apartments';
import Stats from '../stats/Stats';
import Accounting from '../accounting/accounting';
import Taxes from '../taxes/Taxes';
import PageContentManager from '../pageContentManager/PageContentManager';
import Calendar from '../calendar/calendar';
import CommentTab from '../comment/commentTab';
import ArticleTab from '../article/articleTab';
import SuperAccounting from '../superAccounting/SuperAccouting';
const ArrayOfOptions = [
    'accounting', 
    'dashboard', 
    'booking', 
    'quote', 
    'users', 
    'apartments', 
    'stats', 
    'compta', 
    'calendar', 
    'comment', 
    'taxes', 
    'pages', 
    'super-compta', 
    'calendar',
    'blog'];

function GetClassName(visibility) {
    let className = 'slider-left slide-out';
    if (ArrayOfOptions.find(option => option == visibility)) {
        className = 'slider-left slide-in'
    }
    return className;
}

function GetTab(entry) {
    let tab = '';
    switch (entry) {
        case 'accueil':
            return window.location.href = '/'
        case 'dashboard':
            return <DashBoard />
        case 'booking':
            return <BookingTab />
        case 'quote':
            return <Quote />
        case 'users':
            return <Users />
        case 'apartments':
            return <Apartments />
        case 'stats':
            return <Stats />
        case 'accounting':
            return <Accounting />
        case 'taxes':
            return <Taxes />
        case 'pages':
            return <PageContentManager />
        case 'calendar':
            return <Calendar />
        case 'comment':
            return <CommentTab />
        case 'blog':
            return <ArticleTab />
        case 'super-compta':
            return <SuperAccounting />
    }
    return tab;
}

const Tab = ({ visibility }) => {
    return (
        <div className={GetClassName(visibility.selected)}>
            {GetTab(visibility.selected)}
        </div>)
}

function mapStateToProps(state) {
    return {
        visibility: getVisibility(state)
    }
}
export default connect(mapStateToProps)(Tab);