import { useEffect, useState } from "react"
import instanceOfAxios from "../../../../app/axios/axios";
import { Row, Button, Col, Form } from 'react-bootstrap';
import CommentCard from "../card/commentCard";
import Spinner from '../../../spinner/Spinner';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import { AiOutlineSearch } from 'react-icons/ai';

export default function CommentHidden(props) {
    const [comments, setComments] = useState([]);
    const [pending, setPending] = useState(true);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(0);
    const [searchParams, setSearchParams] = useState({});

    useEffect(() => {
        if (pending) {
            let query = new URLSearchParams(searchParams).toString();
            let pageIndex = page;
            if(query){
                setPage(1);
                pageIndex = 1;
            }
            instanceOfAxios.get('/admin/comment/indexHidden?page=' + pageIndex + '&' + query)
                .then(res => {
                    setComments(res.data.comments.data);
                    setMaxPage(res.data.comments.last_page);
                    setPending(false);
                    props.setReload(false);
                })
        }
    }, [pending]);

    useEffect(() => {
        if (props.reload) {
            setPending(true);
        }
    }, [props.reload]);

    function handleChange(e) {
        let params = Object.assign({}, searchParams);
        if (e.target.value == '') {
            delete params[e.target.name];
        } else {
            params[e.target.name] = e.target.value;
        }
        setSearchParams(params);
    }

    function nextPage() {
        if (page < maxPage) {
            setPage(page + 1);
            setPending(true);
        }
    }

    function prevPage() {
        if (page > 1) {
            setPage(page - 1);
            setPending(true);
        }
    }

    return (
        <Row className='d-flex justify-content-center'>
            <Col xs={12} md={4}>
                <Row className="align-content-center searchAdminForm" style={{ top: '40vh' }}>
                    <Col xs={12}>
                        <Form id='formSearchAdmin'>
                            <Form.Group className='mb-2'>
                                <Form.Label>Référence logement</Form.Label>
                                <Form.Control type='text' name='apart_ref' onChange={(e) => handleChange(e)} />
                            </Form.Group>
                            <Form.Group className='mb-2'>
                                <Form.Label>Nom / Prénom</Form.Label>
                                <Form.Control type='text' name='full_name' onChange={(e) => handleChange(e)} />
                            </Form.Group>
                            <Button variant='secondary' className='whiteSVG mt-2' onClick={(() => setPending(true))}>Rechercher <AiOutlineSearch/></Button>
                        </Form>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={8} style={{ minHeight: '70vh' }} className='d-flex flex-column align-items-center justify-content-center'>
                {
                    !pending ?
                        <>
                            {
                                comments.length == 0 ?
                                    <h2>Aucun commentaire à afficher </h2>
                                    :
                                    <>
                                        {
                                            maxPage > 1 ?
                                                <Row className="justify-content-center mb-3 w-100" noGutters>
                                                    <Col lg={1} md={1} sm={1} xs={1} className='text-center'>
                                                        <Button className="goldenHover" onClick={() => prevPage()} size="sm" variant="light">
                                                            <GrLinkPrevious />
                                                        </Button>
                                                    </Col>
                                                    <Col lg={10} md={10} sm={10} xs={10} className='text-center'>
                                                        <strong>{page}</strong> / {maxPage}
                                                    </Col>
                                                    <Col lg={1} md={1} sm={1} xs={1} className='text-center'>
                                                        <Button className="goldenHover" onClick={() => nextPage()} size="sm" variant="light">
                                                            <GrLinkNext />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                :
                                                <></>
                                        }
                                        <Row>
                                            {
                                                comments.map(comment => 
                                                <CommentCard 
                                                displayButton={true} 
                                                comment={comment} 
                                                forPublishOrUnPublish={props.forPublishOrUnPublish} 
                                                forOnHome={props.forOnHome} 
                                                RemoveforOnHome={props.RemoveforOnHome}
                                                setReload={props.setReload} />)
                                            }
                                        </Row>
                                        {
                                            maxPage > 1 ?
                                                <Row className="justify-content-center mb-3 w-100" noGutters>
                                                    <Col lg={1} md={1} sm={1} xs={1} className='text-center'>
                                                        <Button className="goldenHover" onClick={() => prevPage()} size="sm" variant="light">
                                                            <GrLinkPrevious />
                                                        </Button>
                                                    </Col>
                                                    <Col lg={10} md={10} sm={10} xs={10} className='text-center'>
                                                        <strong>{page}</strong> / {maxPage}
                                                    </Col>
                                                    <Col lg={1} md={1} sm={1} xs={1} className='text-center'>
                                                        <Button className="goldenHover" onClick={() => nextPage()} size="sm" variant="light">
                                                            <GrLinkNext />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                :
                                                <></>
                                        }
                                    </>
                            }
                        </>
                        :
                        <Col xs={12} className='d-flex justify-content-center align-items-center'>
                            <Spinner />
                        </Col>
                }
            </Col>
        </Row>
    )
}