import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap'
import instanceOfAxios from '../../../../app/axios/axios';
import Graph from '../graph/graph';

export default function FormForQuery(props) {
    const [pending, setPending] = useState(false);
    const [data, setData] = useState();
    const [params, setParams] = useState({});

    if(pending){
        instanceOfAxios.post('/admin/stats', params)
        .then(res => {
            setData([{"id": res.data.bookings.id, 'data': res.data.bookings.data, 'color': "hsl(272, 70%, 50%)"}]);
        })
        setPending(false);
    }

    function handleChange(event){
        let param = Object.assign({}, params)
        param[event.target.name] = event.target.value;
        setParams(param);
        if(Object.keys(param).length == 4){
            setPending(true);
        }
    }

    return (
        <Col md={12}>
            <Row>
                <Col xs={12} md={6} lg={3} className='reducePadding mt-2 text-center'>
                    <Form.Control as="select" name="option" onChange={(e) => handleChange(e)}>
                        <option>Selectionner une option</option>
                        <option>Nombre de réservation validées</option>
                        <option>Nombre de réservation annulées</option>
                        <option>Profits</option>
                        <option>Loyer propriétaire</option>
                    </Form.Control>
                </Col>
                <Col xs={12} md={6} lg={3} className='reducePadding mt-2 text-center'>
                    <Form.Control as="select" name="divider" onChange={(e) => handleChange(e)}>
                        <option>Triage par période</option>
                        <option>Jours</option>
                        <option>Semaines</option>
                        <option>Mois</option>
                    </Form.Control>
                </Col>
                <Col xs={12} md={6} lg={3} className='reducePadding mt-2 text-center'>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                        <Form.Label column sm="2">
                            Du
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="date" name="begin_date" onChange={(e) => handleChange(e)}/>
                        </Col>
                    </Form.Group>
                </Col>
                <Col xs={12} md={6} lg={3} className='reducePadding mt-2 text-center'>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                        <Form.Label column sm="2">
                            Au
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="date" name="end_date" onChange={(e) => handleChange(e)}/>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
            <Row style={{height: '30vh'}}>
                {data &&
                    <Graph data={data}/>
                }
            </Row>
        </Col>
    );
}