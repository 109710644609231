import { useState } from "react";
import { Modal, Button, Form, InputGroup, Row, Col} from "react-bootstrap";
import { FaPencilAlt } from 'react-icons/fa'
import { useDispatch } from "react-redux";
import instanceOfAxios from "../../../../../../app/axios/axios";
import { show } from '../../../../Alerter/alerterSlice';
import Spinner from '../../../../../spinner/Spinner.jsx'
import { FaRegSave } from 'react-icons/fa';
import { IoMdAddCircleOutline } from 'react-icons/io';

export default function EditOrCreateTaxeType(props) {
    const [visibility, setVisibility] = useState(false);
    const [params, setParams] = useState({agency_id: props.agency.id});
    const [isSubmit, setIsSubmit] = useState(false);
    const [errors, setErrors] = useState([]);
    const dispatch = useDispatch();

    function handleChange(event) {
        params[event.target.name] = event.target.value;
        setParams(params);
    }

    function handleSave() {
        setIsSubmit(true);
        let functionName = 'post';
        let path = `admin/taxeType`
        if (props.for == 'edit') {
            path = `admin/taxeType/${props.taxeType.id}`;
            functionName = 'patch'
        }

        instanceOfAxios[functionName](path, params)
            .then(res => {
                dispatch(show({ message: 'Tarif mis à jour', variant: 'success' }));
                props.handleChange(true);
                setIsSubmit(false);
                setVisibility(false);
            })
            .catch(error => {
                dispatch(show({ message: 'Erreur lors de la mise à jour', variant: 'danger' }));
                setErrors(error.response.data.error)
                setIsSubmit(false);
            })
    }

    return (
        <>
            {props.for == 'edit' ?
                <Button className='goldenHover' onClick={() => setVisibility(!visibility)}>
                    <FaPencilAlt/>
                </Button>
                :
                <Button size="md" variant="secondary" className='whiteSVG' style={{ marginLeft: "10px", marginBottom: "5px" }} onClick={() => setVisibility(!visibility)}>
                    <IoMdAddCircleOutline/> Ajouter une classe de tarifs
                </Button>
            }
            <Modal
                show={visibility}
                onHide={() => setVisibility(false)}
                scrollable
                backdropClassName="backdropFourth"
                centered
                style={{ zIndex: '20003' }}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.for ? 'Modification d\'une classe de tarifs' : 'Création d\'une classe de tarifs'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Nom</Form.Label>
                                <InputGroup>
                                    <Form.Control type="text" defaultValue={props.taxeType && props.taxeType.type} name="type" placeholder="Basique" onChange={(e) => handleChange(e)} />
                                </InputGroup>
                                {errors['type'] && <label htmlFor="type"><small style={{ color: "red" }}> {errors['type']}</small></label>}
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {
                        isSubmit ?
                        <Spinner/>
                        :
                        <>
                            <Button variant="danger" onClick={() => setVisibility(!visibility)}>
                                Fermer
                            </Button>
                            <Button 
                                className='whiteSVG'
                                variant="secondary" 
                                onClick={handleSave}>
                                <FaRegSave/> Enregistrer
                            </Button>
                        </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}