import React, { Component, useState } from 'react';
import { Container, Col, Image, Row } from 'react-bootstrap';
import '../statics.scss'

export default class CGU extends Component {
    render() {
        return (
            <Container style={{ paddingTop: '5vh', paddingBottom: '50px', maxWidth: '80vw', height: 'unset' }} className='noPadding'>
                <Row>
                   <Col sm={12}>
                        <h2>Conditions générales d'utilisation</h2>
                        <p>
                            <strong>Mentions Obligatoires</strong> <br/>
                            
                            
                            <strong>La société CLASS APPART</strong>, société à responsabilité limitée au capital de 3.000 € immatriculée au RCS de Montpellier sous le numéro B790259287, TVA : FR00790259287, ayant son siège social sis  15 PASSAGE LONJON 34000 MONTPELLIER, dument représentée par son gérant, Madame Bérengère BOUSCAREN.
                            <br/>
                            <br/>

                            Les présentes Conditions Générales d'Utilisation déterminent les règles d’accès au site et ses conditions d’utilisation que l’utilisateur reconnaît accepter, sans réserve, du seul fait de sa connexion au site.
                            <br/>
                            <br/>
                            <strong>Éditeur du site :</strong> <br/>

                            CLASS APPART<br/>

                            15 PASSAGE LONJON, 34000 MONTPELLIER<br/>

                            Téléphone : + 33(0)9 63 69 87 82<br/>

                            E-mail : <a href="mailto:contact@class-appart.com">contact@class-appart.com</a><br/>

                            Directeur de publication : Bérengère Bouscaren<br/>
                            <br/>
                            <br/> 
                           <strong>Fournisseur d’hébergement :</strong><br/>

                            OVEA<br/>

                            59 rue Nelson Mandela<br/>

                            34070 MONTPELLIER<br/>
                            www.class-appart.com <br/>
                            <a href="mailto:contact@class-appart.com">contact@ovea.com</a><br/>

                            <a href="www.ovea.com">www.ovea.com</a> <br/><br/><br/>

                            <strong> 
                                Liens hypertextes :
                            </strong><br/>
                            Le site Class Appart autorise, sans autorisation préalable, la mise en place de liens hypertextes pointant vers ses pages, sous réserve que l’ouverture des liens se fassent dans une fenêtre indépendante. Les sites qui font le choix de pointer vers <a href="www.class-appart.com"> www.class-appart.com </a>engagent leur responsabilité dès lors qu'ils porteraient atteinte à l'image du site public.

                            <br/><br/>
                            <strong> 
                                Données personnelles :
                            </strong><br/>

                            Les informations recueillies dans le formulaire de contact ne font pas l'objet d'un traitement informatique automatisé. Ces informations sont destinées à l'usage exclusif de Class Appart.

                            <br/><br/>   
                            <strong> 
                                Droits d'auteurs :
                            </strong><br/>

                            La reproduction ou représentation, intégrale ou partielle, des pages, des données et de tout autre élément constitutif au site , par quelque procédé ou support que ce soit, est interdite et constitue, sans autorisation de l'éditeur, une contrefaçon. <br/>
                            <strong>
                                Crédits images :
                            </strong>
                            fotolia, iStock.
                        </p>
                    </Col>
                </Row>
            </Container>)
    }
}