import React, { useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useState } from 'react';
import instanceOfAxios from '../../app/axios/axios';
import "./searchResult.scss";
import SearchResultMap from '../../components/map/searchResultMap'
import SearchBar from '../../components/searchBar/SearchBar';
import { getParam } from '../../components/searchBar/searchBarSlice';
import ResultCard from './resultCard/ResultCard';
import SkeletonCard from './skeletonCard/skeletonCard';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import SupplementaryFilter from '../../components/searchBar/supplementaryFilter';

const axiosAdressToLatLng = axios.create({ baseURL: "https://api-adresse.data.gouv.fr/" });

function SearchResult(props) {
    const [pending, setPending] = useState(true);
    const [load, setLoad] = useState(true);
    const [total, setTotal] = useState(0);
    const [isOverID, setIsOverID] = useState(0);
    const [result, setResult] = useState([{}]);
    const [pendingLocation, setPendingLocation] = useState(false);
    const [nightPrices, setNightPrices] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [pageNumber, setPageNumber] = useState(0);
    const [favorite, setFavorite] = useState([]);
    const [reloadFav, setReloadFav] = useState(false);

    //setting new meta tag
    document.getElementsByTagName('meta')["description"].content = "Le résultat de votre recherche contient " + (total > 0 ? total : 0) + 'logements';
    document.getElementsByTagName('meta')["short_description"].content = "Voici un listing des logements que nous mettons à la location";
    document.getElementsByTagName('meta')["title"].content = "Résultat de recherche";

    useEffect(() => {
        if (reloadFav) {
            instanceOfAxios.get('indexFavori')
                .then((res) => {
                    setFavorite(res.data.data);
                    setReloadFav(false)
                });
        }
    }, [reloadFav]);

    useEffect(() => {
        if (pending) {
            fetchApartment(1);
        }
    }, [pending]);

    useEffect(() => {
        fetchApartment(1);
    }, [props.param.filter]);

    useEffect(() => {
        let i = 0;

        if (pendingLocation) {
            result.map((apartment) => {
                if (apartment) {
                    let url = apartment.address.address.replace(/\s/g, '+');
                    let url2 = "search/?q=" + url;
                    let url3 = url2 + "&postcode=" + apartment.address.postal_code;
                    axiosAdressToLatLng.get(url3)
                        .then(res => {
                            if (res.data.features[0] && res.data.features[0].properties.name.toLowerCase() != apartment.address.address.toLowerCase()) {
                                axiosAdressToLatLng.get(url2 + "&citycode=" + res.data.features[0].properties.citycode)
                                    .then(res => {
                                        if (res.data.features[0]) {
                                            apartment.lng = res.data.features[0].geometry.coordinates[0];
                                            apartment.lat = res.data.features[0].geometry.coordinates[1];
                                            apartment.isOnMap = true;
                                        } else {
                                            apartment.isOnMap = false;
                                        }
                                        i++;
                                        if (i == result.length) {
                                            setResult(result);
                                            setPendingLocation(false);
                                        }
                                    })
                            } else if (res.data.features[0]) {
                                apartment.lng = res.data.features[0].geometry.coordinates[0];
                                apartment.lat = res.data.features[0].geometry.coordinates[1];
                                apartment.isOnMap = true;
                            } else {
                                apartment.isOnMap = false;
                            }
                            i++;
                            if (i == result.length) {
                                setResult(result);
                                setPendingLocation(false);
                            }
                        })
                }
            })
        }
    }, [pendingLocation]);

    function fetchApartment(page) {
        let query = '';

        setLoad(true);
        if (window.location.search.length > 0) {
            query += window.location.search.substring(1)
        }

        for (const [key, value] of Object.entries(props.param)) {
            if (key === 'begin_date' || key === 'end_date') {
                query += '&';
                const date = new Date(value);
                if (date != 'Invalid Date') {
                    query += key + '_hour' + '=' + date.toUTCString();
                }
            } else if(value) {
                query += '&';
                query += key + '=' + value;
            }
        }

        instanceOfAxios.get('/apartment?page=' + page + query)
            .then((res) => {
                setResult(res.data.apartments.data);
                setTotal(res.data.apartments.total)
                setPendingLocation(true);
                setLoad(false);
                setPageNumber(res.data.apartments.last_page == null ? 1 : res.data.apartments.last_page);
                res.data.apartments.data.map(apartment => {
                    if (props.param.begin_date && props.param.end_date) {
                        let query = {};
                        for (const [key, value] of Object.entries(props.param)) {
                            if (key === 'begin_date' || key === 'end_date') {
                                const date = new Date(value);
                                query[key + '_hour'] = date.toUTCString();
                            } else {
                                query[key] = value;
                            }
                        }
                        instanceOfAxios.post('/apartment/' + apartment.id + '/recalculateBooking', query)
                            .then((res) => {
                                nightPrices[apartment.id] = (res.data.succes.rate / getDifferenceInDays(new Date(res.data.succes.begin_date_hour.replace(/\s/, 'T')), new Date(res.data.succes.end_date_hour.replace(/\s/, 'T')))).toFixed(2);
                                let newNightPrices = Object.assign([], nightPrices);
                                setNightPrices(newNightPrices);
                            });
                    }
                })
            })
            .catch(err => {
                setLoad(false);
            });
        setPending(false);
    }

    function getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    }

    function getCard() {
        if (load) {
            return (
                <>
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                </>
            )
        } else if (result.length > 0) {

            return result.map((apartment, index) => {
                let url = props.param.begin_date && props.param.end_date && props.param.nb_person ? '/apartment/' + apartment.id + '?begin_date_hour=' + props.param.begin_date + '&end_date_hour=' + props.param.end_date + '&nb_person=' + props.param.nb_person : '/apartment/' + apartment.id;
                return (
                    <ResultCard reloadFav={setReloadFav} inFavorite={favorite.find(apart => apart.id == apartment.id)} key={index} apartment={apartment} url={url} setIsOverID={setIsOverID} nightPrice={nightPrices[apartment.id] || apartment.minRate} />
                )
            })
        } else if (result.length == 0) {
            return (
                <h3 className='d-flex justify-content-center align-items-center text-center' style={{ height: '99%' }}> Aucune disponibilité </h3>
            )
        }
    }

    function next() {
        if (activePage < pageNumber) {
            window.scroll(0, window.innerHeight / 2);
            setLoad(true);
            setResult([{}]);
            setActivePage(parseInt(activePage) + 1);
            fetchApartment(parseInt(activePage) + 1);
        }
    }

    function prev() {
        if (activePage > 1) {
            window.scroll(0, window.innerHeight / 2);
            setLoad(true);
            setResult([{}]);
            setActivePage(parseInt(activePage) - 1);
            fetchApartment(parseInt(activePage) - 1);
        }
    }

    return (
        <Container style={{ display: 'block', paddingBottom: '5vh', position: 'relative' }} fluid className='noPadding'>
            <div
                className="beachResultHeader text-center"
                style={{ background: 'url(\'' + window.location.origin + '/images/homepage_images/beachHome.webp\') 0% 0% no-repeat padding-box', backgroundPosition: 'center', backgroundSize: 'cover', paddingTop: '2vh' }}>
                <h1>Résultats de votre recherche</h1>
            </div>
            <div className='displaySearchBarResult'>
                <SearchBar className="searchbar" onSearch={() => {
                    setPending(true);
                }} />
            </div>
            <Row>
                <SupplementaryFilter/>
            </Row>
            <Row style={{ backgroundColor: '#ffffff', padding: '15px' }}>
                <h3>{total} {result.length > 1 ? 'résultats' : 'résultat' }  </h3>
            </Row>
            <Row style={{ backgroundColor: '#ffffff', paddingBottom: '2vh' }}>

                <Col md={6} className='noPadding'>
                    <Row>
                        <Col xs={12} className='resultContent'>
                            {
                                getCard()
                            }
                        </Col>
                    </Row>
                    <Row>
                        {result.length > 0 ?
                            <>
                                <Col sm={3} xs={4} className='text-center'>
                                    <Button className="mt-3 mb-3 goldenHover" onClick={(e) => prev(e)} disabled={load}>
                                        <GrLinkPrevious style={{ margin: '1px' }} />
                                        Préc
                                    </Button>
                                </Col>
                                <Col sm={6} xs={4} className='d-flex align-items-center justify-content-center'>
                                    <p style={{ margin: 'unset' }}><strong>{activePage}</strong> / {pageNumber}</p>
                                </Col>
                                <Col sm={3} xs={4} className='text-center'>
                                    <Button className="mt-3 mb-3 goldenHover" onClick={(e) => next(e)} disabled={load}>
                                        Suiv
                                        <GrLinkNext style={{ margin: '1px' }} />
                                    </Button>
                                </Col>
                            </>
                            :
                            <></>}
                    </Row>
                </Col>
                <Col className="search-map-disapear" md={6} style={{ width: '100%' }}>
                    <SearchResultMap markers={result} nightPrices={nightPrices} isOver={isOverID} class='search-map-fixed-result' />
                </Col>
                <Col md={12} style={{ width: '100%' }}>
                    <div className="btn-Content text-center">
                        <h4>NOUS CONTACTER</h4>
                        <Row style={{ width: '100%' }} className='d-flex justify-content-around'>
                            <Col xs={12} sm={4} className="btn-secondary btn-contact-result">
                                <a href='/Contact' style={{ textDecoration: 'none', color: 'white' }}>
                                    <Col xs={12} variant='secondary'>Nous écrire</Col>
                                </a>
                            </Col>
                            <Col xs={12} sm={4} className=" btn-secondary btn-contact-result" variant='secondary'>
                                <a href='tel:0963698742' style={{ textDecoration: 'none', color: 'white' }}>
                                    <Col xs={12} variant='secondary' className='noPadding'>09 63 69 87 42</Col>
                                </a>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
const mapStateToProps = state => {
    return {
        param: getParam(state)
    };
}

export default connect(mapStateToProps)(SearchResult);