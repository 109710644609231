import { useEffect, useState } from 'react';
import './homeRating.scss';
import instanceOfAxios from '../../../app/axios/axios';

export default function HomeRating() {
    const [visibleID, setVisibleID] = useState(1);
    const [comments, setComments] = useState([]);
    const [time, setTime] = useState(6000);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (visibleID == 3) {
                setVisibleID(1);
            } else {
                setVisibleID(visibleID + 1);
            }
        }, time);
        return () => clearTimeout(timer);
    }, [visibleID]);


    useEffect(() => {
        instanceOfAxios.get('/comment/indexHome')
            .then(res => {
                if (Object.keys(res.data.comments).length == 0) {
                    setComments([
                        {
                            content: JSON.stringify({ global: 'Super belle expérience en famille à Palavas-les-flots\! L\’ agence est superbe, très disponible durant tout le séjour et l’accueil a été parfaite. Merci beaucoup! Je recommande à 200% et reviendrai clairement!' }),
                            first_name: 'Espérance',
                            last_name: 'Nguyen',
                            created_at: '2022-09-27 14:23:41'
                        },
                        {
                            content: JSON.stringify({ global: 'Excellente agence ,excellent personnel , prestations de qualité  , professionnalisme et honnêteté...un partenaire de premier choix pour une recherche d\'hébergements de qualité . Impeccable !' }),
                            first_name: 'Jean Baptiste',
                            last_name: 'Fouché',
                            created_at: '2019-09-27 14:23:41'
                        },
                        {
                            content: JSON.stringify({ global: 'Société très sérieuse, disponible et professionnelle proposant des biens de qualité à des prix très intéressants.. Bérangère BOUSCAREN et toute son équipe seront aux petits soins avec vous.' }),
                            first_name: 'Olivier',
                            last_name: 'Sanchez',
                            created_at: '2020-09-27 14:23:41'
                        },
                    ])
                } else {
                    setComments(res.data.comments);
                }
            });
    }, []);

    function handleClickCircle(index){
        setTime(15000);
        setVisibleID(index)
    }
    return (
        <div className='Opinion' style={{ background: 'url(\'' + window.location.origin + '/images/homepage_images/beachHome.webp\') 0% 0% no-repeat padding-box', backgroundPosition: 'center', backgroundSize: 'cover' }}>
            <div className="CardOpinion">
                <span className="Apostrophe">"</span>
                {
                    comments.map((comment, index) => {
                        let content = JSON.parse(comment.content);
                        let create_at = new Date(comment.created_at.replace(/\s/, 'T'));
                        var options = { year: 'numeric', month: 'long' };

                        return (
                            <div className={visibleID == index + 1 ? "opinionContent visible" : "opinionContent invisible"}>
                                <p>{content.global}</p>
                                <div className="opinionInfo">
                                    {
                                        `${comment.first_name} ${comment.last_name}`
                                    }
                                    <br />
                                    {
                                        `${create_at.toLocaleDateString('fr-CA', options)}`
                                    }
                                </div>
                            </div>
                        )
                    })
                }
                <div className='opinionDote'>
                    {
                        comments.map((comment, index) => visibleID == index + 1 ?
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                    <circle id="Slider2" cx="7" cy="7" r="7" fill="#b7a57e" />
                                </svg>
                            </div>
                            :
                            <div className='hoverDote' onClick={() => handleClickCircle(index + 1)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                    <g id="Slider3" fill="none" stroke="#b7a57e" stroke-width="1.5">
                                        <circle cx="7" cy="7" r="7" stroke="none" />
                                        <circle cx="7" cy="7" r="6.25" fill="none" />
                                    </g>
                                </svg>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}