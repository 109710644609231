import { useEffect, useState } from "react";
import { Button, Modal, Form, Row, Col, Badge } from "react-bootstrap";
import { useDispatch } from "react-redux";
import instanceOfAxios from "../../../../app/axios/axios";
import Spinner from "../../../spinner/Spinner";
import { show } from "../../Alerter/alerterSlice";
import { IoMdClose } from 'react-icons/io';
import '../bookingDetails/bookingDetails.scss';
import { FaRegSave } from 'react-icons/fa';

export default function RefundModal(props) {
    const [visibility, setVisibility] = useState(false);
    const [refundAt, setRefundAt] = useState();
    const [refundFrom, setRefundFrom] = useState();
    const [refundFromDisplayOther, setRefundFromDisplayOther] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const dispatch = useDispatch();

    var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    let refundProps = props.refundAt ? new Date(props.refundAt.replace(/\s/, 'T')) : '';

    function updateRefundFromOnSelect(value){
        if(value == 'Autre'){
            setRefundFromDisplayOther(true);
            setRefundFrom();
        }else{
            setRefundFromDisplayOther(false);
            setRefundFrom(value)
        }
    }

    function handleSubmit() {
        submit(refundAt, refundFrom);
    }

    function resetRefund() {
        submit(null, null);
    }

    function submit(refundAt, refundFrom) {
        setIsSubmit(true);
        instanceOfAxios.post(`/booking/${props.booking.id}/booking_bail/${props.booking.bail.id}`, { refund_at: refundAt, refund_from: refundFrom, '_method': 'PATCH' })
            .then(res => {
                setIsSubmit(false);
                dispatch(show({ message: 'Caution mise à jour', variant: 'success' }));
                let booking = { ...props.booking };
                booking.bail = res.data.BookingBail;
                props.BookingDetailsOnChange(booking);
                setVisibility(false);
            })
            .catch(err => {
                setIsSubmit(false);
                dispatch(show({ message: 'Erreur lors de la mise à jour', variant: 'danger' }));
            })
    }

    return (props.refundAt ?
        <div>
            {
                isSubmit ?
                    <Spinner />
                    :
                    <>
                        <Badge variant='success'><IoMdClose size={'20px'} color='black' className='reset-bail' onClick={resetRefund} /> Caution traitée le : {refundProps.toLocaleDateString('fr-CA', options)}<br /> par {props.booking.bail.refund_from}</Badge>
                    </>
            }
        </div>
        :
        <>
            <Button onClick={() => setVisibility(true)} variant='secondary'>Caution traitée?</Button>
            <Modal
                show={visibility}
                showMask={false}
                onHide={() => {
                    setVisibility(false);
                    setRefundAt();
                    setRefundFrom();
                    setRefundFromDisplayOther();
                }}
                centered
                backdropClassName="backdropFourth"
                style={{ zIndex: '20003' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Remboursement de caution</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>A quelle date la caution à été traitée?</Form.Label>
                        <Form.Control name='refund_at' type='date' onChange={(e) => setRefundAt(new Date(e.target.value))} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Méthode de remboursement</Form.Label>
                        <Form.Control size="sm" name="refund_from" as="select" onChange={(e) => updateRefundFromOnSelect(e.target.value)}>
                            <option>Selectionner une méthode de remboursement</option>
                            <option>Carte bancaire</option>
                            <option>TPE agence</option>
                            <option>Virement</option>
                            <option>Empreinte bancaire levée</option>
                            <option>Cheque</option>
                            <option>Espèce</option>
                            <option>Transférée sur une nouvelle réservation</option>
                            <option>Gérée par site passerelle</option>
                            <option>Autre</option>
                        </Form.Control>
                    </Form.Group>
                    {
                        refundFromDisplayOther &&
                        <Form.Group className="mb-3">
                            <Form.Control name='refund_from_other' type='text' onChange={(e) => setRefundFrom(e.target.value)} />
                        </Form.Group>
                    }
                    {
                        refundFrom && refundAt &&
                        <Row className='d-flex justify-content-center'>
                            <Col xs={12} className='d-flex justify-content-center'>
                                <p className='text-center'>La caution à été remboursé le " {refundAt.toLocaleDateString('fr-CA', options)} ". <br /> par "{refundFrom}" Es-ce correcte?</p>
                            </Col>
                            <Col xs={12} className='d-flex justify-content-center'>
                                {
                                    isSubmit ?
                                        <Spinner />
                                        :
                                        <Button variant='secondary' className='whiteSVG' onClick={handleSubmit}><FaRegSave /> Enregistrer</Button>
                                }
                            </Col>
                        </Row>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}