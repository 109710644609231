import { Form, Button, Modal, InputGroup, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import Spinner from '../../../../../spinner/Spinner';
import { useDispatch } from 'react-redux';
import { show } from '../../../../Alerter/alerterSlice';
import instanceOfAxios from '../../../../../../app/axios/axios';
import { setUpdater } from '../../../apartmentSlice'
import { FaRegSave } from 'react-icons/fa';
import { IoMdAddCircleOutline } from 'react-icons/io';

export default function AddFile(props) {
    const [formData, setFormData] = useState(new FormData());
    const [label, setLabel] = useState('Format PDF');
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [visibility, setVisibility] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [validate, setValidate] = useState(false);
    const [nameExist, SetNameExist] = useState(false);
    const [errors, setErrors] = useState([]);
    const dispatch = useDispatch();

    function handleFileChange(e) {
        let newFormData = new FormData();
        newFormData.set('name', name.split(' ').join('_'));
        newFormData.set('description', description);
        newFormData.set('mergedPdf', e.target.files[0]);
        newFormData.set('removeOldFile', 0);
        setLabel(e.target.files[0].name);
        setFormData(newFormData);
    }

    function handleNameChange(value) {
        SetNameExist(Object.keys(props.apartment.merged_pdf).includes(value));
        setFormData(formData);
        setName(value);
    }

    function handleDescriptionChange(value) {
        setFormData(formData);
        setDescription(value);
    }

    function handleSubmit() {
        setIsSubmit(true);
        props.reloadPdfPreview(true)
        instanceOfAxios.post('/admin/apartment/' + props.apartment.id + '/addFiles', formData)
            .then(res => {
                setIsSubmit(false);
                dispatch(setUpdater(res.data.apartment));
                dispatch(show({ message: 'fichier enregistré', variant: 'success' }));
                setVisibility(false);
                setErrors([]);
                props.reloadPdfPreview(false)
            })
            .catch(err => {
                if (err.response.status) {
                    setErrors(Object.assign([], err.response.data.error));
                }
                setIsSubmit(false);
                dispatch(show({ message: 'Erreur lors de l\'enregistrement', variant: 'danger' }));
            })
    }

    function hide() {
        setVisibility(!visibility);
        setFormData(new FormData());
        setLabel('Format PDF');
        setValidate(false);
        SetNameExist(false);
        setErrors([]);
        setName();
        setDescription();
        props.reloadPdfPreview(false)
    }

    return (
        <>
            <Button className='whiteSVG' variant='secondary' onClick={() => hide()}><IoMdAddCircleOutline /> Ajouter un document</Button>
            <Modal
                scrollable
                centered
                show={visibility}
                backdrop="static"
                onHide={() => hide()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Ajouter ou remplacer un document
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className='text-center'>
                        <InputGroup className="mb-3">
                            <Form.Control type="text" placeholder='Nom du document' disabled={validate} name='name' onChange={(e) => handleNameChange(e.target.value)} />
                        </InputGroup>
                        {
                            name ?
                                <Form.Group className="mb-3">
                                    <Form.Control type="text" as='textarea' placeholder='Description' disabled={validate} name='name' onChange={(e) => handleDescriptionChange(e.target.value)} />
                                    <small>Maximum 200 caractères</small>
                                </Form.Group>
                                :
                                <></>
                        }
                        {
                        !validate ?
                            <Button className='goldenHover' onClick={() => setValidate(true)}>Valider</Button>
                            :
                            <Button className='goldenHover' onClick={() => {
                                setFormData(new FormData());
                                setValidate(false);
                                setLabel('Format PDF');
                            }}>{'Modifier'}</Button>
                        }
                        {
                            nameExist &&
                            <small style={{ color: 'red' }} className='text-center'>Attention un fichier portant le même nom existe déjà il sera donc automatiquement supprimé</small>
                        }
                        {
                            validate &&
                            <>
                                <Form.Group className='mt-3'>
                                    <Form.File
                                        id="custom-file-translate-scss"
                                        label={label}
                                        lang="fr"
                                        custom
                                        accept='document/pdf'
                                        data-browse="Fichier"
                                        name='data'
                                        onChange={(e) => handleFileChange(e)}
                                    />
                                </Form.Group>
                            </>
                        }
                    </Form>
                    {
                        Object.keys(errors).length > 0 &&
                        <Row>
                            <ul>
                                {
                                    Object.keys(errors).map(error => errors[error].map(text => <li>{text}</li>))
                                }
                            </ul>
                        </Row>
                    }
                    <Row>
                        <Col xs={12} className='d-flex justify-content-center'>
                            {
                                formData.get('mergedPdf') &&
                                <>
                                    {
                                        !isSubmit ?
                                            <Button className='whiteSVG' variant='secondary' onClick={handleSubmit}><FaRegSave/> Enregistrer</Button>
                                            :
                                            <Spinner />
                                    }
                                </>
                            }
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}