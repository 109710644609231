import { useEffect, useState } from "react";
import { Col, Form, Modal, Button } from "react-bootstrap";
import instanceOfAxios from "../../../../../app/axios/axios";
import { useDispatch } from "react-redux";
import { show } from "../../../Alerter/alerterSlice";
import Spinner from "../../../../spinner/Spinner";
import { FaRegSave } from 'react-icons/fa';
import { AiOutlineCalendar } from 'react-icons/ai';

export default function ReRent(props) {
    const [visibility, setVisibility] = useState(false);
    const [date, setDate] = useState(props.booking.re_rent_at);
    const [pending, setPending] = useState(false);
    const dispatch = useDispatch();
    let end_date_hour = new Date(props.booking.end_date_hour.replace(/\s/, 'T'));

    useEffect(() => {
        if (pending) {
            instanceOfAxios.post(`/admin/booking/${props.booking.id}/re-rent`, { re_rent_at: date })
                .then(res => {
                    setPending(false);
                    props.BookingDetailsOnChange(res.data.booking);
                    dispatch(show({ message: 'Date de relocation prise en compte', variant: 'success' }));
                    setVisibility(!visibility)
                })
                .catch(err => {
                    setPending(false);
                    if (err.response.status === 403) {
                        dispatch(show({ message: err.response.data.erreur, variant: 'danger' }));
                    } else {
                        dispatch(show({ message: 'Date de relocation non prise en compte', variant: 'danger' }));
                    }
                })
        }
    }, [pending]);

    return (
        <>
            <Button variant="secondary" className='align-self-end p-2 mt-1 whiteSVG' onClick={() => setVisibility(!visibility)} > <AiOutlineCalendar /> Réouverture des dates</Button>
            <Modal
                scrollable
                centered
                backdropClassName="backdropFourth"
                show={visibility}
                onHide={() => setVisibility(!visibility)}
                style={{ zIndex: '20003' }}>
                <Modal.Header closeButton>
                    <Modal.Title className='d-flex justify-content-around' style={{ width: '100%' }}>
                        {props.booking.re_rent_at ? 'Rebloquer des dates sur le planning' : 'Réouvrir des dates sur le planning'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        props.booking.re_rent_at &&
                        <>
                            <p className='text-center'>Le planning est réouvert à compté du : <br />{props.booking.re_rent_at}</p>
                            <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                                <div className='separator'
                                    style={{
                                        border: '1px solid #B7A57E',
                                        color: '#b7a57e',
                                        width: '90%',
                                        marginBottom: '20px',
                                        marginTop: '20px'
                                    }}></div>
                            </Col>
                        </>
                    }
                    <Form.Group as={Col}>
                        <Form.Label>Réouvrir le planning à partir du : </Form.Label><br />
                        <small>La date de fin ou de réouverture de la réservation est rentrée par defaut</small>
                        <Form.Control type='date' name='begin_period' size="sm" defaultValue={props.booking.re_rent_at ? new Date(props.booking.re_rent_at.replace(/\s/, 'T')).toISOString().split('T')[0] : end_date_hour.toISOString().split('T')[0]} placeholder="jour-mois" onChange={(e) => setDate(e.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {
                        pending ?
                            <Spinner />
                            :
                            <>
                                {
                                    props.booking.re_rent_at ?
                                        <Button variant="secondary" onClick={() => {
                                            setPending(true);
                                        }}>Changer la réouverture du planning</Button>
                                        :
                                        <Button variant="secondary" className='whiteSVG' onClick={() => setPending(true)}><FaRegSave /> Enregistrer</Button>
                                }
                            </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}