import { useState } from 'react';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import { setReload } from '../list/apartmentListSlice';
import { setUpdater } from '../apartmentSlice';
import { show } from '../../Alerter/alerterSlice';
import { setStep } from './apartmentFormSlice';
import instanceOfAxios from '../../../../app/axios/axios';
import { connect, useDispatch } from 'react-redux';
import { getApartmentForUpdate } from '../apartmentSlice'
import Spinner from '../../../spinner/Spinner';

function StepFive(props) {
    const [observationParam, setObservationParam] = useState({});
    const [diagnosticParam, setDiagnosticParam] = useState({});
    const [diagnostic, setDiagnostic] = useState(props.apartment.diagnostic);
    const [observation, setObservation] = useState(props.apartment.observation);
    const [isSubmit, setIsSubmit] = useState(false);
    const [errorsDiagnostic, setErrorsDiagnostic] = useState([]);
    const [errorsObservation, setErrorsObservation] = useState([]);
    let inputFileTitle = 'choisir un fichier'

    if(diagnostic && diagnostic.media[0]){
        inputFileTitle = diagnostic.media[0].file_name;
    }

    if(diagnosticParam && diagnosticParam.diagnostic){
        inputFileTitle = diagnosticParam.diagnostic.name
    }

    const dispatch = useDispatch();

    function handleDiagnosticChange(event) {
        handleChange(diagnosticParam, event, 'diagnostic');
    }

    function handleObservationChange(event) {
        handleChange(observationParam, event, 'observation');
    }

    function handleChange(params, event, type) {
        let param = Object.assign({}, params)
        if(event.target.type === 'file'){
            param[event.target.name] = event.target.files[0];
        }else{
            param[event.target.name] = event.target.value;
        }
        switch (type) {
            case 'diagnostic':
                setDiagnosticParam(param);
                break;
            case 'observation':
                setObservationParam(param);
                break;
        }
    }

    function handleSubmit() {
        setIsSubmit(true);
        let urls = ['/apartment/' + props.apartment.id + '/diagnostic', '/apartment/' + props.apartment.id + '/observation'];
        if (diagnostic) {
            urls[0] = '/apartment/' + props.apartment.id + '/diagnostic/' + diagnostic.id;
        }
        if (observation) {
            urls[1] = '/apartment/' + props.apartment.id + '/observation/' + observation.id;
        }

        let diagnosticData = setFormData(diagnosticParam, diagnostic);
        let observationData = setFormData(observationParam, observation);

        let datas = [diagnosticData, observationData];
        let model = ['diagnostic', 'observation'];
        urls.map((url, index) => {
            let send = 0;
            for (var pair of datas[index].entries()) {
                send++;
            }
            if (send > 0) {
                instanceOfAxios.post(url, datas[index])
                    .then((res) => {
                        instanceOfAxios.get('/apartment/' + props.apartment.id  + '/' + [model[index]])
                        .then((res) => {
                            let stateApartment = Object.assign({}, props.apartment);
                            stateApartment[model[index]] = res.data[model[index]];
                            dispatch(setUpdater(stateApartment));
                            setIsSubmit(false);
                            setErrorsDiagnostic([]);
                            setErrorsObservation([]);
                            dispatch(show({ message: 'Action réussi', variant: 'success' }));
                            dispatch(setReload(true));
                            dispatch(setStep(6));
                            })
                    })
                    .catch((error) => {
                        handleError(error);
                    })
            } else {
                dispatch(setStep(5));
            }
        })
    }

    function setFormData(params, object) {
        let formData = new FormData();
        Object.keys(params).forEach((key) => {
            if (object && object.id) {
                if (params[key] !== object[key]) {
                    formData.set(key, params[key]);
                }
            } else {
                formData.set(key, params[key] || null);
            }
        });
        if (object && object.id) {
            formData.set('_method', 'PATCH');
        }
        return formData;
    }

    function handleError(errors, type) {
        let error = Object.assign([], errors);
        Object.keys(error.response.data.error).forEach(function (key) {
            error[key] = error.response.data.error[key];
        });
        switch (type) {
            case 'diagnostic':
                setErrorsDiagnostic(error);
                break;
            case 'observation':
                setErrorsObservation(error);
                break;
        }
        setIsSubmit(false);
        dispatch(show({ message: 'Erreur lors de l\'action', variant: 'danger' }));
    }
    return (
        <Container className='pb-3'>
            <Row>
                <Col>
                    <label><strong>Diagnostics</strong></label>
                    <Form>
                        <Form.Group>
                            <Form.Label>Statut</Form.Label>
                            {errorsDiagnostic['state'] && <label htmlFor="state"><small style={{ color: "red" }}>{errorsDiagnostic['state']}</small></label>}
                            <Form.Control size="sm" name="state" as="select" onChange={(e) => handleDiagnosticChange(e)}>
                                <option>Selectionner un statut</option>
                                <option selected={diagnostic ? diagnostic.state == 'en cour' : false}>en cour</option>
                                <option selected={diagnostic ? diagnostic.state == 'à valider' : false}>à valider</option>
                                <option selected={diagnostic ? diagnostic.state == 'achevé' : false}>achevé</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Réalisé le</Form.Label>
                            {errorsDiagnostic['achieve_at'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsDiagnostic['achieve_at']}</small></label>}
                            <Form.Control type='date' name='achieve_at' size="sm" defaultValue={diagnostic ? diagnostic.achieve_at : ''} onChange={(e) => handleDiagnosticChange(e)} />
                        </Form.Group>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Comsommation éléctrique</Form.Label>
                                {errorsDiagnostic['power_consumption'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsDiagnostic['power_consumption']}</small></label>}
                                <Form.Control size="sm" name="power_consumption" as="select" onChange={(e) => handleDiagnosticChange(e)}>
                                    <option>Selectionner une lettre</option>
                                    <option selected={diagnostic ? diagnostic.power_consumption == 'A' : false}>A</option>
                                    <option selected={diagnostic ? diagnostic.power_consumption == 'B' : false}>B</option>
                                    <option selected={diagnostic ? diagnostic.power_consumption == 'C' : false}>C</option>
                                    <option selected={diagnostic ? diagnostic.power_consumption == 'D' : false}>D</option>
                                    <option selected={diagnostic ? diagnostic.power_consumption == 'E' : false}>E</option>
                                    <option selected={diagnostic ? diagnostic.power_consumption == 'F' : false}>F</option>
                                    <option selected={diagnostic ? diagnostic.power_consumption == 'G' : false}>G</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Consommation de gaz</Form.Label>
                                {errorsDiagnostic['gaz_consumption'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsDiagnostic['gaz_consumption']}</small></label>}
                                <Form.Control size="sm" name="gaz_consumption" as="select" onChange={(e) => handleDiagnosticChange(e)}>
                                    <option>Selectionner une lettre</option>
                                    <option selected={diagnostic ? diagnostic.gaz_consumption == 'A' : false}>A</option>
                                    <option selected={diagnostic ? diagnostic.gaz_consumption == 'B' : false}>B</option>
                                    <option selected={diagnostic ? diagnostic.gaz_consumption == 'C' : false}>C</option>
                                    <option selected={diagnostic ? diagnostic.gaz_consumption == 'D' : false}>D</option>
                                    <option selected={diagnostic ? diagnostic.gaz_consumption == 'E' : false}>E</option>
                                    <option selected={diagnostic ? diagnostic.gaz_consumption == 'F' : false}>F</option>
                                    <option selected={diagnostic ? diagnostic.gaz_consumption == 'G' : false}>G</option>
                                </Form.Control>               
                            </Form.Group>
                        </Form.Row>
                        <Form.File
                            label={inputFileTitle}
                            lang="fr"
                            custom
                            data-browse="Fichier"
                            name='diagnostic'
                            onChange={(e) => handleDiagnosticChange(e)}
                        />
                    </Form>
                    {diagnostic && diagnostic.media_url && <a href={diagnostic.media_url} target='_blank'>Visualiser</a>}
                </Col>
                <Col>
                    <label><strong>Observations</strong></label>
                    <Form>
                        <Form.Group>
                            <Form.Label>Propriétaire connu par :</Form.Label>
                            {errorsObservation['content'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsObservation['content']}</small></label>}
                            <Form.Control as='textarea' row={3} name='content' size="sm" placeholder="Entrer un Nom Prénom" defaultValue={observation ? observation.content : ''} onChange={(e) => handleObservationChange(e)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Notes confidentielles</Form.Label>
                            {errorsObservation['confidential_note'] && <label htmlFor="type"><small style={{ color: "red" }}>{errorsObservation['confidential_note']}</small></label>}
                            <Form.Control as='textarea' row={3} name='confidential_note' size="sm" placeholder="Ceci est confidentielle" defaultValue={observation ? observation.confidential_note : ''} onChange={(e) => handleObservationChange(e)} />
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <Row className="justify-content-end">
                {isSubmit ? <Spinner/> : <Button variant="secondary" onClick={() => handleSubmit()}>Suivant</Button>}
            </Row>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        apartment: getApartmentForUpdate(state)
    };
}

export default connect(mapStateToProps)(StepFive);