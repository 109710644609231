import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './bookingTab.scss';
import {
    getSearchResult,
    FetchResultatAdmin,
    getCreateVisibility,
    getListVisibility
} from './bookingTabSlice';
import BookingList from './bookingList/bookingList';
import CreateBooking from './create/createBooking';

const BookingTab = ({ FetchResultatAdmin, listVisibility, createVisibility }) => {

    useEffect(() => {
        if(listVisibility){
            FetchResultatAdmin();
        }
    }, [listVisibility]);

    let classNameForCreate = 'slider-create-right slide-out-create-right';
    let classNameForList = 'slider-list-left slide-out-list-left';

    if(createVisibility){
        classNameForCreate = 'slider-create-right slide-in-create-right'; 
    }

    if(listVisibility){
        classNameForList = 'slider-list-left slide-in-list-left'; 
    }

    return (
        <div className='users'>
            <CreateBooking className={classNameForCreate}></CreateBooking>
            <BookingList className={classNameForList}></BookingList>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        resultFromSearch: getSearchResult(state),
        createVisibility: getCreateVisibility(state),
        listVisibility: getListVisibility(state)
    }
}
function mapDispatchToProps(dispatch) {
    return {
        FetchResultatAdmin: () => dispatch(FetchResultatAdmin()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BookingTab);