import Spinner from '../../spinner/Spinner';
import { useState } from 'react';
import { Col, Modal, Button } from 'react-bootstrap';
import instanceOfAxios from '../../../app/axios/axios';
import { useDispatch, useStore } from 'react-redux';
import BookingCard from './bookingCard/bookingCard';

export default function BookingTab() {
    const [bookings, setBookings] = useState([]);
    const [pending, setPending] = useState(true);
    const [visibility, setVisibility] = useState(false);
    const role = useStore().getState().header.role;
    const dispatch = useDispatch();

    if (pending) {
        instanceOfAxios.get('/user/bookings')
            .then((res) => {
                setBookings(res.data.bookings);
                setPending(false);
            })
    }

    function mapBookings() {
        if (bookings.length == 0) {
            return (
                <div className='center' style={{ padding: '1vh' }}>
                    <h2>Aucune Réservation à afficher</h2>
                </div>
            )
        } else {
            return bookings.map(booking => <BookingCard key={booking.id} booking={booking} role={role} dispatch={dispatch}/>)
        }
    }

    return (
        <>
            <Col 
            sm={12} 
            md={6} 
            className='menuItem d-flex justify-content-center align-items-end' 
            onClick={() => setVisibility(true)}>
                <div className='container-text d-flex flex-column align-items-center justify-content-center'>
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="180px" height="180px" viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    fill="#B7A57E" stroke="none">
                    <path d="M1940 4784 c-139 -30 -291 -130 -372 -244 l-42 -60 -580 0 -580 0
                    -103 -103 c-85 -85 -103 -108 -103 -133 0 -24 30 -71 130 -205 72 -96 138
                    -180 147 -186 40 -30 72 -16 157 67 l82 80 77 0 77 0 35 -70 c19 -38 44 -74
                    54 -80 12 -6 129 -10 313 -10 l294 0 42 -60 c86 -121 237 -217 390 -247 45 -9
                    82 -19 82 -23 0 -4 22 -41 48 -81 62 -95 147 -186 228 -246 35 -26 64 -50 64
                    -53 0 -3 -175 -217 -390 -474 -422 -506 -417 -500 -365 -551 23 -24 29 -25
                    160 -25 l135 0 0 -880 0 -880 -535 0 -536 0 -24 -25 c-33 -32 -33 -78 0 -110
                    l24 -25 1951 0 1951 0 24 25 c33 32 33 78 0 110 -20 21 -34 25 -80 25 l-55 0
                    0 880 0 880 135 0 c131 0 137 1 160 25 53 52 64 36 -455 659 l-480 576 0 250
                    0 250 80 0 80 0 0 -255 0 -256 25 -24 c32 -33 78 -33 110 0 l25 24 0 311 0
                    311 -25 24 c-24 25 -26 25 -213 25 -257 0 -236 22 -242 -250 l-5 -212 -255
                    305 c-186 224 -261 308 -280 312 -32 7 -75 -8 -88 -30 -8 -14 -15 -11 -58 26
                    -66 57 -147 108 -183 115 -25 5 -36 1 -61 -24 -55 -55 -43 -85 63 -156 45 -30
                    92 -65 104 -78 l21 -23 -280 -335 c-154 -184 -290 -348 -303 -364 l-23 -30
                    -44 29 c-82 55 -228 207 -228 239 0 7 21 18 48 24 78 21 190 89 261 161 118
                    119 178 252 188 416 24 421 -362 749 -777 659z m255 -159 c256 -67 415 -329
                    350 -580 -23 -87 -70 -168 -136 -230 -62 -59 -163 -113 -228 -122 -36 -5 -38
                    -4 -45 27 -12 62 -6 184 13 235 l18 50 46 -3 c63 -5 110 12 146 53 57 65 53
                    155 -8 216 -41 41 -88 55 -148 44 -50 -9 -109 -69 -118 -119 -4 -21 -23 -63
                    -42 -94 -51 -86 -74 -171 -75 -278 0 -49 3 -100 7 -113 6 -21 4 -22 -17 -16
                    -116 34 -220 114 -288 221 -22 35 -49 68 -59 74 -12 6 -128 10 -310 10 l-291
                    0 -35 70 c-19 38 -44 74 -54 80 -11 5 -84 10 -166 10 l-146 0 -58 -55 c-32
                    -30 -60 -55 -63 -55 -3 0 -36 41 -73 91 l-68 90 44 45 43 44 579 0 c381 0 585
                    4 598 10 10 6 37 39 59 74 118 186 324 273 525 221z m1805 -1532 l710 -853
                    -1090 0 -1091 0 -24 -25 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 l24 -25
                    976 0 975 0 0 -880 0 -880 -400 0 -400 0 0 535 0 536 -25 24 -24 25 -351 0
                    -351 0 -24 -25 -25 -24 0 -536 0 -535 -400 0 -400 0 0 880 0 880 55 0 c46 0
                    60 4 80 25 33 32 33 78 0 110 -24 25 -27 25 -195 25 l-170 0 341 409 340 409
                    48 -18 c192 -73 451 -65 609 20 108 57 212 169 212 227 0 34 -43 73 -80 73
                    -26 0 -42 -12 -91 -63 -96 -104 -201 -147 -357 -147 -97 0 -225 26 -220 44 5
                    19 622 756 630 754 4 -2 327 -387 718 -855z m-480 -2293 l0 -480 -240 0 -240
                    0 0 480 0 480 240 0 240 0 0 -480z"/>
                    <path d="M505 295 c-50 -49 -15 -135 55 -135 41 0 80 39 80 80 0 41 -39 80
                    -80 80 -19 0 -40 -9 -55 -25z"/>
                    </g>
                    </svg>
                    <h2>Mes Réservations</h2>
                </div>
            </Col>
            <Modal
                backdropClassName="backdrop"
                show={visibility}
                onHide={() => setVisibility(false)}
                centered
                scrollable
                size='xl'
                style={{ zIndex: '20000' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Mes Réservations</Modal.Title>
                </Modal.Header>
                <Modal.Body  className={pending && "d-flex flex-column justify-content-center align-items-center"}>
                    {pending ?
                        <Spinner/>
                        :
                        mapBookings()
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className='goldenHover' onClick={() => setVisibility(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}