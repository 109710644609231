import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import instanceOfAxios from '../../../app/axios/axios';
import './information.scss';

export default function Informations() {
    const [score, setScore] = useState('4.3');

    useEffect(() => {
        instanceOfAxios.get('/comment/getGlobaleNote')
        .then(res => {
            let score = res.data.score;
            if(score > 4){
                setScore(score);
            }
        });
    },[]);
    
    return (
        <Row className="d-flex f-direction informations">
            <Col xs={5} xl={2} className="d-flex flex-column align-items-center informationCard">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.794 86.901">
                    <path id="Icon_awesome-star" data-name="Icon awesome-star" d="M41.968,3.022,30.886,25.491,6.091,29.106a5.433,5.433,0,0,0-3,9.266l17.938,17.48L16.783,80.545a5.427,5.427,0,0,0,7.874,5.719L46.838,74.6,69.019,86.264a5.432,5.432,0,0,0,7.874-5.719L72.651,55.852l17.938-17.48a5.433,5.433,0,0,0-3-9.266L62.791,25.491,51.709,3.022a5.435,5.435,0,0,0-9.741,0Z" transform="translate(-1.441 0.001)" fill="#b7a57e" />
                </svg>
                <p>NOTE DE {score}/5</p>
            </Col>
            <Col xs={5} xl={2} className="d-flex flex-column align-items-center informationCard">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 147.619 94.898">
                    <path id="Icon_ionic-md-people" data-name="Icon ionic-md-people" d="M103.4,49.166a20.012,20.012,0,0,0,20.13-19.77,20.133,20.133,0,0,0-40.26,0A20.012,20.012,0,0,0,103.4,49.166Zm-53.679,0A20.012,20.012,0,0,0,69.849,29.4a20.133,20.133,0,0,0-40.26,0A20.012,20.012,0,0,0,49.72,49.166Zm0,14.5c-15.77,0-46.97,7.578-46.97,23.065v17.793h94.9V86.73C97.648,71.242,65.488,63.664,49.72,63.664ZM103.4,67.29a35.809,35.809,0,0,0-5.751.329c7.719,5.6,10.544,9.226,10.544,19.111v17.793h42.177V86.73C150.369,71.242,119.169,67.29,103.4,67.29Z" transform="translate(-2.75 -9.625)" fill="#b7a57e" />
                </svg>
                <p>AGENCE À TAILLE HUMAINE</p>
            </Col>
            <Col xs={5} xl={2} className="d-flex flex-column align-items-center informationCard">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.732 94.898">
                    <path id="Icon_awesome-euro-sign" data-name="Icon awesome-euro-sign" d="M65.816,83.118a2.54,2.54,0,0,0-3.055-1.985,44.5,44.5,0,0,1-9.622,1.147c-12.024,0-21.591-7.369-25.729-18.1H51.491a2.542,2.542,0,0,0,2.48-1.985l1.35-6.007a2.542,2.542,0,0,0-2.48-3.1H24.407a45.729,45.729,0,0,1,.029-8.949H55.488a2.542,2.542,0,0,0,2.483-2l1.379-6.3a2.542,2.542,0,0,0-2.483-3.085H27.576A26.6,26.6,0,0,1,52.491,16.86a41.491,41.491,0,0,1,8.018.878,2.543,2.543,0,0,0,2.966-1.827l2.54-9.4a2.54,2.54,0,0,0-1.975-3.16,61.688,61.688,0,0,0-11.16-1.1,45.269,45.269,0,0,0-43.331,30.5H2.542A2.542,2.542,0,0,0,0,35.295v6.3A2.542,2.542,0,0,0,2.542,44.14H7.111a62.883,62.883,0,0,0-.038,8.949H2.542A2.542,2.542,0,0,0,0,55.63v6.007a2.542,2.542,0,0,0,2.542,2.542H8.921c5.3,19.135,21.85,32.969,43.958,32.969A52.835,52.835,0,0,0,65.822,95.5a2.543,2.543,0,0,0,1.862-2.956l-1.868-9.424Z" transform="translate(0 -2.25)" fill="#b7a57e" />
                </svg>
                <p>MEILLEUR TARIF GARANTI</p>
            </Col>
            <Col xs={5} xl={2} className="d-flex flex-column align-items-center informationCard">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.622 94.898">
                    <path id="Icon_material-payment" data-name="Icon material-payment" d="M110.427,7.333h-94.9A11.771,11.771,0,0,0,3.726,19.2L3.667,90.369a11.821,11.821,0,0,0,11.862,11.862h94.9a11.821,11.821,0,0,0,11.862-11.862V19.2A11.821,11.821,0,0,0,110.427,7.333Zm0,83.036h-94.9V54.782h94.9Zm0-59.311h-94.9V19.2h94.9Z" transform="translate(-3.667 -7.333)" fill="#b7a57e" />
                </svg>
                <p>PAIEMENT SÉCURISÉ</p>
            </Col>
        </Row>
    )
}