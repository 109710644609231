import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import TabInProgress from './tab/tabInProgress';
import TabAutoCancelable from "./tab/tabAutoCancel";
import TabRecallPayment from "./tab/tabRecallPayment";
import TabActivateAutoCancel from "./tab/tabActivateAutoCancel";
import TabActivateRecallPayment from "./tab/tabActivateRecallPayment";
import TabBookingInComing from "./tab/tabBookingInComing";
import TabBookingGoingOut from "./tab/tabBookingGoingOut";
import TabApartmentsDispo from './tab/tabApartmentsDispo';
import TabBailToRefund from './tab/tabBailToRefund';

export default function DashBoard(props) {

    return (
        <div className='users slider-list-left slide-in-list-left'>
            <Row>
                <Col md={12} className='text-center'>
                    <h2>Tableau de bord</h2>
                </Col>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%'
                        }}></div>
                </Col>
            </Row>
            <Tabs defaultActiveKey="bookingInComing" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="bookingInComing" title="Check-in">
                    <TabBookingInComing />
                </Tab>
                <Tab eventKey="bookingOut" title="Check-out">
                    <TabBookingGoingOut />
                </Tab>
                <Tab eventKey="bookingInProgress" title="Réservation en cours">
                    <TabInProgress />
                </Tab>
                <Tab eventKey="bailToRefund" title="Cautions à traiter">
                    <TabBailToRefund />
                </Tab>
                <Tab eventKey="apartDispo" title="Disponibilité">
                    <TabApartmentsDispo/>
                </Tab>
                <Tab eventKey="activateAutoCancel" title="Activation auto annulation">
                    <TabActivateAutoCancel />
                </Tab>
                <Tab eventKey="activateRecallPayment" title="Activation rappel paiement">
                    <TabActivateRecallPayment />
                </Tab>
                <Tab eventKey="bookingAutoCancelable" title="Réservation annulées demain">
                    <TabAutoCancelable />
                </Tab>
                <Tab eventKey="bookingRecallPayment" title="Paiement rappelés demain">
                    <TabRecallPayment />
                </Tab>
            </Tabs>
        </div>
    )
}