import { useEffect, useState } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { useDispatch, useStore } from "react-redux";
import instanceOfAxios from "../../../../../app/axios/axios";
import Spinner from "../../../../spinner/Spinner";
import { show } from "../../../Alerter/alerterSlice";
import { FaRegSave } from 'react-icons/fa';

export default function AddRib(props) {
    const [visibility, setVisibility] = useState(false);
    const [pending, setPending] = useState(true);
    const [isSubmit, setIsSubmit] = useState(false);
    const [rib, setRib] = useState({});
    const dispatch = useDispatch();
    const state = useStore().getState();
    const role = state.header.role;

    useEffect(() => {
        pending && getRib();
    }, [pending]);

    function ShowAndHide() {
        setVisibility(!visibility);
    }

    function getRib() {
        instanceOfAxios.get('/admin/account/' + props.account.id + '/rib')
            .then(res => {
                setRib(res.data.rib)
                setPending(false);
            })
    }

    function updateOrCreateRib() {
        setIsSubmit(true);
        instanceOfAxios.post('/admin/account/' + props.account.id + '/rib', rib)
            .then(res => {
                setIsSubmit(false);
                dispatch(show({ message: 'les informations bancaires on été modifiées', variant: 'success' }));
                ShowAndHide();
            })
            .catch(err => {
                setIsSubmit(false);
                dispatch(show({ message: 'Une erreur est survenue lors de la sauvegarde des informations bancaires', variant: 'danger' }));
            })
    }

    function handleChange(event) {
        let copyRib = { ...rib };
        if(event.target.name === 'IBAN'){
            event.target.value = event.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
            copyRib[event.target.name] = event.target.value.replaceAll(' ', '');
        }else{
            copyRib[event.target.name] = event.target.value;
        }
        setRib(copyRib);
    }

    return (
        <>
            <Button onClick={ShowAndHide} variant='secondary'>Informations bancaires</Button>
            <Modal
                show={visibility}
                showMask={false}
                onHide={ShowAndHide}
                centered
                backdrop="static"
                style={{ zIndex: '20000' }}
                backdropClassName="backdrop">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Informations Bancaire
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {
                            !pending ?
                            <>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>IBAN *</Form.Label>
                                        <Form.Control
                                            disabled={role != 'super-admin'}
                                            defaultValue={rib && rib.IBAN ? rib.IBAN.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() : ''}
                                            name="IBAN"
                                            placeholder="IBAN"
                                            onChange={(e) => handleChange(e)} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>Nom *</Form.Label>
                                        <Form.Control
                                            disabled={role != 'super-admin'}
                                            defaultValue={rib && rib.name ? rib.name : ''}
                                            name="name"
                                            placeholder="Nom"
                                            onChange={(e) => handleChange(e)} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className='d-flex justify-content-center'>
                                    {
                                        isSubmit ?
                                            <Spinner />
                                            :
                                            <Button className='whiteSVG' variant='secondary' onClick={updateOrCreateRib}><FaRegSave/> Enregistrer</Button>
                                    }
                                </Col>
                            </>
                            :
                            <Spinner/>
                        }
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}