import { useEffect, useState } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import './ApartmentRent.scss';
import AddDetention from './AddDetention';
import { MdDeleteForever } from 'react-icons/md';
import instanceOfAxios from '../../../../../app/axios/axios';
import { useDispatch } from 'react-redux';
import { show } from '../../../Alerter/alerterSlice';
import moment from 'moment';
import Spinner from '../../../../spinner/Spinner';
import InvoiceModal from './invoices/invoiceModal';
import DeleteModal from '../../../../generiqueModal/deleteModal/deleteModal';
import { FaRegSave } from 'react-icons/fa';
import { AiOutlineWarning } from 'react-icons/ai';

export default function ApartmentRent(props) {
    const [visibility, setVisibility] = useState(false);
    const [rerender, setRerender] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [rent, setRent] = useState(props.rent);
    const [asReRent, setAsReRent] = useState(false);
    const dispatch = useDispatch();
    let color = '#F2F2F2';
    
    if (typeof (rent.content) != 'object') {
        rent.content = JSON.parse(rent.content);
        setRent(rent);
    }

    useEffect(() => {
        if(Object.entries(rent.content)[props.month][1].booking){
            Object.entries(rent.content)[props.month][1].booking.map(booking => booking.re_rent && setAsReRent(true));
        }

    }, [rent])

    if (rent.content[props.month] && rent.content[props.month].is_payed) {
        color = 'green';
    }else if (rent.content[props.month] && rent.content[props.month].is_validate) {
        color = 'orange';
    } else {
        color = '#A42141';
    }

    function PatchRent() {
        setIsSubmit(true)
        let rentSend = Object.assign({}, rent);
        rentSend._method = 'PATCH';
        rentSend.content = JSON.stringify(rentSend.content);
        instanceOfAxios.post('admin/' + props.year + '/year_rent/' + rent.id, rentSend)
            .then(res => {
                setIsSubmit(false);
                setVisibility(false);
                dispatch(show({ message: 'Sauvegarde effectuée', variant: 'success' }));
            })
            .catch(err => {
                setIsSubmit(false);
                dispatch(show({ message: 'Sauvegarde échouée', variant: 'danger' }));
            })
    }

    function deleteRent()
    {
        instanceOfAxios.delete('admin/' + props.year + '/year_rent/' + rent.id)
        .then( res => {
            setIsSubmit(false);
            setVisibility(false);
            props.onChange(true);
            dispatch(show({ message: 'Ressource supprimée', variant: 'success' }));
        })
        .catch( err => {
            setIsSubmit(false);
            dispatch(show({ message: 'Action échouée', variant: 'danger' }));
        })
    }

    function dateFormatter(cell, row) {
        if(cell){
            const date = new Date(cell.replace(/\s/, 'T'));
            var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
            return date.toLocaleDateString('fr-CA', options);
        }
        return 'Non';
    }

    function priceFormatter(cell, row) {
        return parseFloat(cell).toFixed(2) + ' €';
    }

    function DeleteFormatter(cell, row, index) {
        return (
            <Button className='goldenHover' size='sm' onClick={() => handleDelete(row, index)}>
                <MdDeleteForever/>
            </Button>
        )
    }

    function handleDelete(row, index) {
        rent.content[index].options = rent.content[index].options.filter(option => option.reason != row.reason);
        setRent(rent);
        setRerender(!rerender);
    }

    function handleCheck(e, index) {
        rent.content[index][e.target.name] = e.target.checked;
        setRent(rent);
        setRerender(!rerender);
    }

    function getArray(datas, index) {
        let total = 0;
        datas.booking.map(data => total += parseFloat(data.price.toFixed(2)) + parseFloat(data.electricity.toFixed(2)));
        datas.options.map(data => total = total + parseFloat(data.amount));
        return (
            <>
                <Col xs={12} className='d-flex justify-content-around align-items-around mb-3'>
                    <InvoiceModal yearRent={rent} month={props.month} proForma={datas.as_pro_forma}/>
                    <DeleteModal deleteFunction={deleteRent}/>
                </Col>
                <Col xs={12} lg={6} className='d-flex justify-content-center align-items-center'>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label={datas.is_validate ? 'Validé' : "À valider"} name='is_validate' onChange={(e) => handleCheck(e, index)} checked={datas.is_validate} />
                    </Form.Group>
                </Col>
                <Col xs={12} lg={6} className='d-flex justify-content-center align-items-center'>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label='Le loyer à été versé' name='is_payed' onChange={(e) => handleCheck(e, index)} checked={datas.is_payed} />
                    </Form.Group>
                </Col>
                <Col sm={12}>
                    <h5 className='text-center'>Loyers</h5>
                    <BootstrapTable data={datas.booking} hover>
                        <TableHeaderColumn dataField='begin' dataFormat={dateFormatter} className='text-center'>Début</TableHeaderColumn>
                        <TableHeaderColumn dataField='end' dataFormat={dateFormatter} className='text-center'>Fin</TableHeaderColumn>
                        <TableHeaderColumn dataField='re_rent' dataFormat={dateFormatter} className='text-center'>Relouer</TableHeaderColumn>
                        <TableHeaderColumn isKey dataField='price' dataFormat={priceFormatter} className='text-center'>Prix</TableHeaderColumn>
                        <TableHeaderColumn dataField='length' className='text-center'>Nuité</TableHeaderColumn>
                        <TableHeaderColumn dataField='electricity' dataFormat={priceFormatter} className='text-center'>Électricité</TableHeaderColumn>
                    </BootstrapTable>
                </Col>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%',
                            marginBottom: '20px',
                            marginTop: '20px'
                        }}></div>
                </Col>
                <Col sm={12}>
                    <h5 className='text-center'>Ajout ou retenues</h5>
                    <BootstrapTable data={datas.options} hover>
                        <TableHeaderColumn isKey dataField='reason' className='text-center'>Raison</TableHeaderColumn>
                        <TableHeaderColumn dataField='amount' dataFormat={priceFormatter} className='text-center'>Montant TTC</TableHeaderColumn>
                        <TableHeaderColumn dataField='tva_value' dataFormat={priceFormatter} className='text-center'>TVA</TableHeaderColumn>
                        <TableHeaderColumn dataField='amount' dataFormat={(cell, row) => DeleteFormatter(cell, row, index)} className='text-center'>Supprimer</TableHeaderColumn>
                    </BootstrapTable>
                    <AddDetention rent={rent} index={index} onSave={setRent} />
                </Col>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%',
                            marginBottom: '20px',
                            marginTop: '20px'
                        }}></div>
                </Col>
                <Col sm={12} className='d-flex justify-content-center'>
                    <p>Total : <strong>{(total).toFixed(2)}€</strong></p>
                </Col>
            </>
        )
    }

    return (
        <Col x={12} sm={6} lg={3}>
            <Button variant='none' onClick={() => setVisibility(!visibility)} style={{ width: '100%', margin: '1vh', backgroundColor: color, color: 'white' }}>
                {
                    asReRent &&
                    <AiOutlineWarning className='mr-2'/>
                }
                {props.rent.reference}
            </Button>
            {
                visibility &&
                <Modal
                    scrollable
                    centered
                    size='xl'
                    show={visibility}
                    onHide={() => {
                        props.onChange(true);
                        setVisibility(!visibility)
                    }}>
                    <Modal.Header closeButton>
                        <Modal.Title className='d-flex justify-content-around' style={{ width: '100%' }}>
                            <h2>{props.rent && props.rent.reference}</h2>
                            <h2>Loyer pour : <strong>{moment(new Date(moment().year(), props.month, 1)).format('MMMM')}</strong></h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{ marginTop: '5vh' }}>
                            {
                                rent &&
                                getArray(Object.entries(rent.content)[props.month][1], Object.entries(rent.content)[props.month][0])
                            }
                        </Row>
                        <Row className='d-flex justify-content-center'>
                            {
                                isSubmit ? 
                                <Spinner/>
                                :
                                <Button onClick={PatchRent} variant='secondary' className='whiteSVG'><FaRegSave/> Enregistrer</Button>
                            }
                        </Row>
                    </Modal.Body>
                </Modal>
            }
        </Col>
    )
}