import { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import './ArticleSearchBar.scss';

export default function ArticleSearchBar(props) {
    const [isFocus, setIsFocus] = useState(false);

    return (
        <div 
            className='custom-input'
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    props.setPending(true);
                }
            }}>
            <label
                className={isFocus || (!isFocus && props.keyWord) ? 'label-on-focus' : 'label-not-focus'}
                for="keyWord">
                Mots clés
            </label>
            <div className='border-input'>
                <input 
                    onFocus={e => setIsFocus(!isFocus)}
                    onBlur={e => setIsFocus(!isFocus)}
                    type='text' 
                    id='keyWord'
                    className='input-focus'
                    onChange={(e) => props.setKeyWord(e.target.value)} />
                <AiOutlineSearch size='25' onClick={() => props.setPending(true)}/>
            </div>
        </div>
    )
}