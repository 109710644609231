import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { changeValue } from '../../searchBar/searchBarSlice';
import { getParam } from '../../searchBar/searchBarSlice';
import instanceOfAxios from '../../../app/axios/axios';
import { Col, Row, Button } from 'react-bootstrap'
import DateRange from '../../searchBar/dateRange/dateRange';
import ApartmentModal from '../../ApartmentModal/ApartmentModal';
import Skeleton from '@material-ui/lab/Skeleton';
import Spinner from '../../spinner/Spinner';
import { show } from '../../admin/Alerter/alerterSlice';
import { getBeginDate, getEndDate } from '../../searchBar/dateRange/dateRangeSlice';
import SelectCustom from '../../searchBar/selectCustom/selectCustom';
import moment from 'moment';

function Book(props) {
    const dispatch = useDispatch();
    const [load, setLoad] = useState(true);
    const [firstRequestReturn, setFirstRequestReturn] = useState(false);
    const [unavailable, setUnavailable] = useState(false);
    const [maxNight, setMaxNight] = useState(false);
    const [nightPrice, setNightPrice] = useState(<Skeleton animation='wave' style={{ width: '50px' }} />);
    let query = '';

    function getDifferenceInDays(date1, date2) {
        const diffInMs = Math.abs(date2 - date1);
        return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    }

    useEffect(() => {
        if (window.innerWidth > 991) {
            setLoad(true);
        }

        const params = new URLSearchParams(window.location.search);

        let beginDate = params.has('begin_date_hour') ? new Date(params.get('begin_date_hour').replace('GMT ', 'GMT+')) : props.beginDate;
        let endDate = params.has('end_date_hour') ? new Date(params.get('end_date_hour').replace('GMT ', 'GMT+')) : props.endDate;

        let diffenceInDay = getDifferenceInDays(new Date(beginDate), new Date(endDate));

        if (diffenceInDay > 90) {
            setMaxNight(true);
            setUnavailable(true);
        } else {
            setNightPrice(<Skeleton animation='wave' style={{ width: '50px' }} />);
            setMaxNight(false);
            query = { begin_date_hour: new Date(beginDate).toUTCString(), end_date_hour: new Date(endDate).toUTCString(), nb_person: props.param.nb_person, nb_adult: props.param.nb_adult };
            instanceOfAxios.post('/apartment/' + props.apartment.id + '/recalculateBooking', query)
                .then((res) => {
                    props.changeAppart(res.data.apartment);
                    setNightPrice((res.data.succes.rate / getDifferenceInDays(new Date(res.data.succes.begin_date_hour.replace(/\s/, 'T')), new Date(res.data.succes.end_date_hour.replace(/\s/, 'T')))).toFixed(2))
                    setLoad(false);
                    if (window.innerWidth > 991) {
                        setLoad(false);
                    }
                    setFirstRequestReturn(true);
                    setUnavailable(false);
                })
                .catch(err => {
                    if (err.response.data.error == 'Apartment allready reserved') {
                        if (firstRequestReturn) {
                            dispatch(show({ message: 'Logement non disponible pour les dates entrées', variant: 'danger' }));
                        }
                        props.changeAppart(err.response.data.apartment);
                        setNightPrice((props.apartment.minRate).toFixed(2));
                    }
                    setUnavailable(true);
                    setFirstRequestReturn(true);
                    setLoad(false);
                });
        }
    }, [props.beginDate, props.endDate, props.param.nb_person, props.param.nb_adult]);

    useEffect(() => {
        const url = new URLSearchParams(window.location.search);
        if (url.has('nb_person') && props.param.nb_person == url.get('nb_person')) {
            dispatch(changeValue({ type: 'nb_person', payload: url.get('nb_person') }))
        }
    }, [props.param.nb_person]);

    function handleChange(event) {
        let name = event.target.getAttribute('name');
        if (name == 'nb_person' && props.param.nb_adult > event.target.value) {
            dispatch(changeValue({ type: 'nb_adult', payload: event.target.value }));
        }
        dispatch(changeValue({ type: name, payload: event.target.value }));
    }

    function listNbPerson() {
        const items = [];
        for (let index = 1; index <= props.apartment.nb_person; index++) {
            items.push(index)
        };
        return (items);
    }

    function getButton() {
        if (maxNight) {
            return (
                <Row>
                    <Col xs={12}>
                        <small className='maxNight'>Pour des séjours supérieurs à 90 nuits merci de prendre contact avec nos services ou de modifier les dates</small>
                        <Col xs={12} className='d-flex mb-1 justify-content-around noPadding' style={{ margin: 'unset' }}>
                            <a className="btn-secondary btn-contact-result" href='/Contact' style={{ textDecoration: 'none'}}>
                                Nous écrire
                            </a>
                            <ApartmentModal apartment={props.apartment} invalidPeriod/>
                        </Col>
                    </Col>
                </Row>
            )
        } else if (load) {
            return <Spinner />
        } else {
            return <ApartmentModal apartment={props.apartment} />
        }
    }

    return (
        <>
            <Col xs='12' className='noPadding bookCardDekstop bookCardMobile'>
                <Row>
                    <Col xs={12} className='noPadding marginH2Desktop noDisplay d-flex justify-content-end'>
                        <Row className='w-100'>
                            {
                                unavailable ?
                                    <Col lg={12} className=' d-flex align-items-center justify-content-center reducePadding'>
                                        <h2 className='nightPrice'>Ajoutez des dates pour voir le prix</h2>
                                    </Col>
                                    :
                                    <>
                                        <Col lg={12} xl={3} className=' d-flex align-items-center justify-content-start reducePadding'>
                                            <h2 className='nightPrice'>Prix :</h2>
                                        </Col>
                                        <Col lg={8} xl={6} className=' d-flex align-items-center justify-content-end noPadding'>
                                            <h2 className='nightPrice w-100'>
                                                <strong className='d-flex justify-content-center'>{nightPrice}€</strong>
                                            </h2>
                                        </Col>
                                        <Col lg={4} xl={3} className=' d-flex align-items-center justify-content-center justify-content-md-start reducePadding'>
                                            <h2 className='nightPrice'>/ nuit</h2>
                                        </Col>
                                    </>
                            }
                        </Row>
                    </Col>
                </Row>
                <Row className='noDisplayforLarge'>
                    {
                        unavailable ?
                            <>
                                {
                                    !maxNight ?
                                        <Col lg={12} className=' d-flex align-items-center justify-content-center reducePadding'>
                                            <h2 className='nightPrice'>Ajoutez des dates pour voir le prix</h2>
                                        </Col>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <>
                                <Col xs={12} className=' d-flex align-items-center justify-content-center reducePadding'>
                                    <h2 className='nightPrice d-flex'>Prix : <strong className='d-flex justify-content-center'>{nightPrice}€</strong> / nuit</h2>
                                </Col>
                            </>
                    }
                </Row>
                <Row className='bookCardDekstopChild'>
                    <Col xs={12} className='noDisplay justify-content-center mb-3'>
                        <DateRange usage='book' minLength={props.apartment.min_night} apartment={props.apartment} />
                    </Col>
                    <Col xs={12} className='noDisplay noPadding mb-3 select-book-nb'>
                        <p className='inputTitle'>Nombre de personnes</p>
                        <SelectCustom nbPerson={listNbPerson()} name='nb_person' handleChange={handleChange} />
                    </Col>
                    <Col xs={12} className='noDisplay noPadding mb-3 select-book-nb'>
                        <p className='inputTitle'>Nombre d'adulte</p>
                        <SelectCustom nbPerson={listNbPerson()} name='nb_adult' handleChange={handleChange} />
                    </Col>
                    <Col lg={12} className="text-center d-flex justify-content-center">
                        {getButton()}
                    </Col>
                </Row>
            </Col>
        </>
    )
}
const mapStateToProps = state => {
    return {
        param: getParam(state),
        beginDate: getBeginDate(state),
        endDate: getEndDate(state)
    };
}

export default connect(mapStateToProps)(Book);