import { useState } from "react";
import { Modal, Button, Form, InputGroup, Row, Col, } from "react-bootstrap";
import { FaPencilAlt } from 'react-icons/fa'
import { useDispatch } from "react-redux";
import instanceOfAxios from "../../../../app/axios/axios";
import { show } from '../../Alerter/alerterSlice';
import Spinner from '../../../spinner/Spinner.jsx'
import { FaRegSave } from 'react-icons/fa';

export default function EditOrCreateCommission(props) {
    const [visibility, setVisibility] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [params, setParams] = useState({ taxe_type_id: props.taxe_type_id });
    const dispatch = useDispatch();

    function handleChange(event) {
        params[event.target.name] = event.target.value;
        setParams(params);
    }

    function handleSave() {
        setIsSubmit(true);
        let functionName = 'post';
        let path = `admin/agency/${props.agency}/commission`
        if (props.for == 'edit') {
            path = `admin/agency/${props.agency}/commission/${props.commission.id}`;
            functionName = 'patch'
        }

        instanceOfAxios[functionName](path, params)
            .then(res => {
                dispatch(show({ message: 'Tarif mis à jour', variant: 'success' }));
                props.handleChange(true);
                setIsSubmit(false);
            })
            .catch(error => {
                dispatch(show({ message: 'Erreur lors de la mise à jour', variant: 'danger' }));
                setIsSubmit(false);
            })
    }

    return (
        <>
            {props.for == 'edit' ?
                <Button size="md" className='goldenHover' style={{ marginLeft: "10px", marginBottom: "5px" }} onClick={() => setVisibility(!visibility)}>
                    <FaPencilAlt>
                    </FaPencilAlt>
                </Button>
                :
                <Button size="md" variant="secondary" style={{ marginLeft: "10px", marginBottom: "5px" }} onClick={() => setVisibility(!visibility)}>
                    Ajouter un Tarif
                </Button>
            }
            <Modal
                show={visibility}
                onHide={() => setVisibility(false)}
                scrollable
                backdropClassName="backdropFifth"
                backdrop="static"
                centered
                style={{ zIndex: '20004' }}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.for ? 'Modification d\'un tarif' : 'Création d\'un tarif'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Commission à la nuité</Form.Label>
                                <InputGroup>
                                    <Form.Control type="text" defaultValue={props.commission && props.commission.commission_night_percent_value} name="commission_night_percent_value" placeholder="200" onChange={(e) => handleChange(e)} />
                                    <InputGroup.Text>%</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Commission à la semaine</Form.Label>
                                <InputGroup>
                                    <Form.Control type="text" defaultValue={props.commission && props.commission.commission_week_percent_value} name="commission_week_percent_value" placeholder="200" onChange={(e) => handleChange(e)} />
                                    <InputGroup.Text>%</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Commission au mois</Form.Label>
                                <InputGroup>
                                    <Form.Control type="text" defaultValue={props.commission && props.commission.commission_mounth_percent_value} name="commission_mounth_percent_value" placeholder="200" onChange={(e) => handleChange(e)} />
                                    <InputGroup.Text>%</InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {
                        isSubmit ?
                            <Spinner />
                            :
                            <>
                                <Button variant="danger" onClick={() => setVisibility(!visibility)}>
                                    Fermer
                                </Button>
                                <Button 
                                    className='whiteSVG'
                                    variant="secondary" 
                                    onClick={handleSave}>
                                    <FaRegSave/> Enregistrer
                                </Button>
                            </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}