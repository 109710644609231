import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import instanceOfAxios from "../../../../app/axios/axios";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import EditOrCreateCommission from './editOrCreateCommission';

export default function CommissionTable(props) {
    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        if (pending) {
            instanceOfAxios.get(`admin/agency/${props.agency}/commission?type=${props.type.type}`)
                .then(res => {
                    setData(res.data.commission_values);
                })
            setPending(false);
        }
    }, [pending]);

    function percentFormatter(cell, row) {
        return parseFloat(cell).toFixed(2) + ' %';
    }

    function EditFormateur(cell, row) {
        return (
            <EditOrCreateCommission taxe_type_id={props.type.id} commission={row} agency={props.agency} handleChange={setPending} for='edit' />
        );
    }

    return (
        <>
            <Row className='justify-content-center mt-5'>
                <h3>Commission</h3>
            </Row>
            {
                data.length == 0 &&
                <Row>
                    <Col md={12} className='text-center'>
                        <EditOrCreateCommission agency={props.agency} taxe_type_id={props.type.id} handleChange={setPending} />
                    </Col>
                </Row>
            }
            <Row className='justify-content-center mt-3 noPadding'>
                <Col xs={12} lg={8}>
                    <BootstrapTable data={data}>
                        <TableHeaderColumn dataFormat={percentFormatter} isKey dataField='commission_night_percent_value' width='180'>Commission nuité</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={percentFormatter} dataField='commission_week_percent_value' width='180'>Commission semaine</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={percentFormatter} dataField='commission_mounth_percent_value' width='180'>Commission mois</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={EditFormateur} width='100'>Modifier</TableHeaderColumn>
                    </BootstrapTable>
                </Col>
            </Row>
        </>
    )
}