import React, { useEffect, useState } from 'react';
import { Col, Button, Row, Form, Modal, Badge } from 'react-bootstrap';
import { FaPencilAlt } from 'react-icons/fa'
import DateRange from '../../../searchBar/dateRange/dateRange'
import Spinner from '../../../spinner/Spinner';
import instanceOfAxios from '../../../../app/axios/axios'
import { useDispatch, useStore } from 'react-redux';
import { show } from '../../Alerter/alerterSlice';
import {
    getBeginDate,
    getEndDate,
    resetDate
} from '../../../searchBar/dateRange/dateRangeSlice';
import { connect } from 'react-redux';

function EditBooking(props) {
    const [visibility, setVisibility] = useState(false);
    const [param, setParam] = useState(Object.assign({}, props.payment) || {});
    const [booking, setBooking] = useState(Object.assign({}, props.booking) || {});
    const [error, setError] = useState([]);
    const [siteTiers, setSiteTiers] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        instanceOfAxios.get('/admin/dropDown/7')
            .then((res) => {
                setSiteTiers(JSON.parse(res.data.dropDown.content));
            })
    }, []);

    useEffect(() => {
        let values = param;
        let changeBooking = Object.assign({}, booking);
        let date = new Date(props.begin_date);
        date.setDate(date.getDate());
        values['begin_date_hour'] = date.toUTCString() || undefined;
        changeBooking['begin_date_hour'] = date;
        setParam(values);
        setBooking(changeBooking);
    }, [props.begin_date])

    useEffect(() => {
        let values = param;
        let changeBooking = Object.assign({}, booking);
        let date = new Date(props.end_date);
        date.setDate(date.getDate());
        values['end_date_hour'] = date.toUTCString() || undefined;
        changeBooking['end_date_hour'] = date;
        setParam(values);
        setBooking(changeBooking);
    }, [props.end_date])

    function setNbPersonField(nb_persons, name) {
        let options = [];
        if (nb_persons !== undefined) {
            for (let i = 0; i <= nb_persons; i++) {
                options.push(<option key={name + i} name={name} selected={booking[name] === i}>{i}</option>);
            }
        }
        return options;
    }

    function setSiteTiersField() {
        let options = [];
        options.push(<option key={booking.id} name={'site_tiers'} value='null'>Class Appart</option>);
        Object.values(siteTiers).forEach(function (value) {
            options.push(<option key={value + booking.id} name={'site_tiers'} selected={booking.site_tiers === value}>{value}</option>);
        })
        return options;
    }

    function setFormData(formData, params) {
        Object.keys(params).forEach((key) => {
            formData.set(key, params[key] || undefined);
        });
        formData.set('_method', 'PATCH');
    }

    function handleSubmit() {
        setIsSubmit(true);
        let parameter = new FormData();
        setFormData(parameter, param);
        instanceOfAxios.post('/apartment/' + booking.apartment.id + '/booking/' + booking.id, parameter)
            .then((res) => {
                instanceOfAxios.get('/apartment/' + props.booking.apartment_id + '/booking/' + props.booking.id)
                    .then(res => {
                        setVisibility(false)
                        setIsSubmit(false);
                        setError([]);
                        props.handleChange(res.data.booking);
                        setBooking(res.data.booking);
                        dispatch(show({ message: 'Réservation mise à jour', variant: 'success' }));
                    })
            })
            .catch((error) => {
                let message = 'Erreur lors de la mise à jour';
                if (error.response.data.state) {
                    message = 'Erreur statut ' + error.response.data.state;
                }
                dispatch(show({ message: message, variant: 'danger' }));
                setIsSubmit(false);
                if (error.response.data.error) {
                    handleError(error);
                }
            });
    }

    function handleChange(event) {
        let value = param;
        if (event.target.name == 'caution') {
            if (event.target.value == 'Payé') {
                value.swik_is_payed = '1';
            } else {
                value.swik_is_payed = '0';
            }
        } else {
            value[event.target.name] = event.target.value;
        }
        setParam(value);
    }

    function handleError(error, type) {
        let log = [];
        Object.keys(error.response.data.error).forEach(function (key) {
            log[key] = error.response.data.error[key];
        });
        setError(log);
        setIsSubmit(false);
    }

    function diffInDays(begin, end){
        var date1 = new Date(begin);
        var date2 = new Date(end);
        var Difference_In_Time = date2.getTime() - date1.getTime();
        return Difference_In_Time / (1000 * 3600 * 24);
    }

    return (
        <div>
            <Button variant="secondary" className='whiteSVG' onClick={() => setVisibility(true)}><FaPencilAlt /></Button>
            <Modal
                show={visibility}
                onHide={() => {
                    setBooking(props.booking);
                    setError([]);
                    setVisibility(false);
                    dispatch(resetDate());
                }}
                className="deleteModal"
                centered
                size='xl'
                backdropClassName="backdropFourth"
                backdrop="static"
                style={{ zIndex: '20003' }}
            >
                <Modal.Header closeButton className='d-flex justify-content-center align-items-center'>
                    Modification Réservation n° <strong>{booking.reference}</strong>
                </Modal.Header>
                <Modal.Body>
                    {error['date'] ? <label htmlFor="dateRange"><small style={{ color: "red" }}>{error['date']}</small></label> : <></>}
                    {
                        visibility ?
                            <>
                            <Row>
                                <Col xs={12} className='d-flex justify-content-center mb-2'>
                                    {
                                        diffInDays(param['begin_date_hour'], param['end_date_hour']) > 91 &&
                                        <Badge variant='danger'>Attention la réservation excède 90 nuits</Badge>
                                    }
                                </Col>
                            </Row>
                            
                                <DateRange
                                    adminUsage
                                    booking={props.booking}
                                    usage='admin'
                                    id='dateRangeBooking' />
                                <Form className='mt-3'>
                                    <Row>
                                        <Col xs={12} md={6} lg={3}>
                                            <Form.Group id="nb_person">
                                                <Form.Label>Nombre de personnes</Form.Label>
                                                {error['nb_person'] ? <label htmlFor="nb_person"><small style={{ color: "red" }}>{error['nb_person']}</small></label> : <></>}
                                                <Form.Control className="" name="nb_person" as="select" onChange={(e) => handleChange(e)}>
                                                    {setNbPersonField(booking.apartment.nb_person, 'nb_person')}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <Form.Group id="nb_adult">
                                                <Form.Label>Nombre d'adultes</Form.Label>
                                                {error['nb_adult'] ? <label htmlFor="nb_adult"><small style={{ color: "red" }}>{error['nb_adult']}</small></label> : <></>}
                                                <Form.Control className="" name="nb_adult" as="select" onChange={(e) => handleChange(e)}>
                                                    {setNbPersonField(booking.apartment.nb_adult, 'nb_adult')}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <Form.Group id="site_tiers">
                                                <Form.Label>Site tier</Form.Label>
                                                {error['site_tiers'] ? <label htmlFor="site_tiers"><small style={{ color: "red" }}>{error['site_tiers']}</small></label> : <></>}
                                                <Form.Control className="" name="site_tiers" as="select" onChange={(e) => handleChange(e)}>
                                                    {setSiteTiersField()}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <Form.Group id="ref_airbnb">
                                                <Form.Label>Référence AirBnb</Form.Label>
                                                {error['ref_airbnb'] ? <label htmlFor="ref_airbnb"><small style={{ color: "red" }}>{error['ref_airbnb']}</small></label> : <></>}
                                                <Form.Control className="" name="ref_airbnb" defaultValue={booking.ref_airbnb !== null ? booking.ref_airbnb : ''} onChange={(e) => handleChange(e)}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <Form.Group id="state">
                                                <Form.Label>Statut</Form.Label>
                                                <Form.Control name="state" as="select" onChange={(e) => handleChange(e)}>
                                                    <option name='state' selected={booking.state === 'en attente'}>en attente</option>
                                                    <option name='state' selected={booking.state === 'acompte versé'}>acompte versé</option>
                                                    <option name='state' selected={booking.state === 'à payer'}>à payer</option>
                                                    <option name='state' selected={booking.state === 'payé'}>payé</option>
                                                    <option name='state' selected={booking.state === 'trop perçu'}>trop perçu</option>
                                                    <option name='state' selected={booking.state === 'annulé'}>annulé</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} lg={6}>
                                            <Form.Group id="site_tiers_dif">
                                                <Form.Label>Différence site tiers</Form.Label>
                                                {error['site_tiers_dif'] ? <label htmlFor="site_tiers_dif"><small style={{ color: "red" }}>{error['site_tiers_dif']}</small></label> : <></>}
                                                <Form.Control name="site_tiers_dif" defaultValue={booking.site_tiers_dif.toFixed(2)} onChange={(e) => handleChange(e)}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} lg={6}>
                                            <Form.Group id="site_tiers_com">
                                                <Form.Label>Commission site tiers</Form.Label>
                                                {error['site_tiers_com'] ? <label htmlFor="site_tiers_com"><small style={{ color: "red" }}>{error['site_tiers_com']}</small></label> : <></>}
                                                <Form.Control name="site_tiers_com" defaultValue={booking.site_tiers_com.toFixed(2)} onChange={(e) => handleChange(e)}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} lg={6}>
                                            <Form.Group id="price_influencer_value">
                                                <Form.Label>Remise</Form.Label>
                                                {error['price_influencer_value'] ? <label htmlFor="price_influencer_value"><small style={{ color: "red" }}>{error['price_influencer_value']}</small></label> : <></>}
                                                <Form.Control name="price_influencer_value" defaultValue={booking.price_influencer_value.toFixed(2)} onChange={(e) => handleChange(e)}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} lg={6}>
                                            <Form.Group id="price_influencer_target">
                                                <Form.Label>Remise appliqué sur {error['price_influencer_target'] ? <small style={{ color: "red" }}>{error['price_influencer_target']}</small> : <></>}</Form.Label>
                                                <Form.Control name="price_influencer_target" as="select" defaultValue={booking.price_influencer_target} onChange={(e) => handleChange(e)}>
                                                    <option value = 'commission'>Class Appart</option>
                                                    <option value = 'rent'>Loyer propriétaire</option>
                                                    <option value = 'rate'>Total</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className='d-flex justify-content-center'>
                                        <Col xs={12} md={6} lg={3}>
                                            <Form.Group id="rate">
                                                <Form.Label>Tarif total</Form.Label>
                                                {error['rate'] ? <label htmlFor="rate"><small style={{ color: "red" }}>{error['rate']}</small></label> : <></>}
                                                <Form.Control name="rate" defaultValue={booking.rate} onChange={(e) => handleChange(e)}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <Form.Group id="commission">
                                                <Form.Label>Commission TTC</Form.Label>
                                                {error['commission'] ? <label htmlFor="commission"><small style={{ color: "red" }}>{error['commission']}</small></label> : <></>}
                                                <Form.Control name="commission" defaultValue={booking.commission} onChange={(e) => handleChange(e)}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <Form.Group id="rent">
                                                <Form.Label>Loyer propriétaire</Form.Label>
                                                {error['rent'] ? <label htmlFor="rent"><small style={{ color: "red" }}>{error['rent']}</small></label> : <></>}
                                                <Form.Control name="rent" defaultValue={booking.rent} onChange={(e) => handleChange(e)}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6} lg={3}>
                                            <Form.Group id="tourism">
                                                <Form.Label>Taxe de séjour</Form.Label>
                                                {error['tourism'] ? <label htmlFor="tourism"><small style={{ color: "red" }}>{error['tourism']}</small></label> : <></>}
                                                <Form.Control name="tourism" defaultValue={booking.tourism} onChange={(e) => handleChange(e)}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <Form.Group id="EDF">
                                                <Form.Label>Tarif EDF</Form.Label>
                                                {error['EDF'] ? <label htmlFor="EDF"><small style={{ color: "red" }}>{error['EDF']}</small></label> : <></>}
                                                <Form.Control name="EDF" defaultValue={booking.EDF} onChange={(e) => handleChange(e)}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <Form.Group id="assurance">
                                                <Form.Label>Assurance</Form.Label>
                                                {error['assurance'] ? <label htmlFor="assurance"><small style={{ color: "red" }}>{error['assurance']}</small></label> : <></>}
                                                <Form.Control name="assurance" defaultValue={booking.assurance} onChange={(e) => handleChange(e)}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} lg={3}>
                                            <Form.Group id="cleaning">
                                                <Form.Label>Tarif ménage</Form.Label>
                                                {error['cleaning'] ? <label htmlFor="cleaning"><small style={{ color: "red" }}>{error['cleaning']}</small></label> : <></>}
                                                <Form.Control name="cleaning" defaultValue={booking.cleaning} onChange={(e) => handleChange(e)}>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </>
                            :
                            <></>
                    }
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    {!isSubmit ? <Button className="btn btn-secondary" variant="secondary" onClick={() => handleSubmit()}>Valider</Button> : <Spinner></Spinner>}
                </Modal.Footer>
            </Modal>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        begin_date: getBeginDate(state),
        end_date: getEndDate(state),
    };
}

export default connect(mapStateToProps)(EditBooking);
