import moment from 'moment';
import { useEffect, useState } from 'react';
import { Row, Button, Pagination, Col } from 'react-bootstrap';
import instanceOfAxios from '../../../../app/axios/axios';
import ApartmentRent from './modal/ApartmentRent';
import Spinner from '../../../spinner/Spinner';
import './AccountingRent.scss';
import VerifyIBAN from './modal/verifyIBAN';
import { AiOutlineReload, AiOutlineWarning } from 'react-icons/ai';
import AddDetentionWithoutRent from './modal/AddDetentionWithoutYearRent';

export default function AccountingRent() {
    const [rents, setRents] = useState([]);
    const [pending, setPending] = useState(true);
    const [month, setMonth] = useState(new Date().getMonth());

    let items = [
        <Pagination.Item key={moment().year} id={moment().year} active='true' style={{width: '17vw', textAlign: 'center'}}>
            {moment(new Date(moment().year(), month, 1)).format('MMMM YYYY')}
        </Pagination.Item>
    ];

    useEffect(() => {
        if(pending){
            instanceOfAxios.get('/admin/' + month + '/year_rent')
            .then(res => {
                setRents(Object.assign([], res.data.rents.sort((a, b) => (a.reference > b.reference) ? 1 : -1)));
                setPending(false);
            })
        }
    }, [pending])

    function prev() {
        setMonth(month - 1);
        setPending(true);
    }

    function next() {
        setMonth(month + 1);
        setPending(true);
    }

    function handleRegenerate(){
        instanceOfAxios.get('/admin/year_rent/regenerate')
        .then(res => {
            setRents(Object.assign([], res.data.rents.sort((a, b) => (a.reference > b.reference) ? 1 : -1)));
            setPending(true);
        })
    }

    return (
        <Row className='d-flex align-items-center justify-content-center'>
            <Col xs={12} sm={6} className='d-flex justify-content-center'>
                <VerifyIBAN month={month}/>
            </Col>
            <Col xs={12} sm={6} className='d-flex justify-content-center'>
                <Button
                    onClick={handleRegenerate}
                    className='whiteSVG'
                    variant='secondary'>
                    <AiOutlineReload/> Recalculer les loyers non validés
                </Button>
            </Col>
            <Col sm={12} className='d-flex justify-content-center'>
                <Pagination style={{ marginRight: 'auto', marginLeft: 'auto', padding:'2vh' }}>
                    <Pagination.Prev id={'prev'} onClick={() => prev()} disabled={pending}/>
                    {items}
                    <Pagination.Next id={'next'} onClick={() => next()} disabled={pending}/>
                </Pagination>
            </Col>
            <Col sm={12}>
                <AddDetentionWithoutRent 
                    date={moment(new Date(moment().year(), month, 1)).format('MMMM YYYY')} 
                    month={month} 
                    year={moment(new Date(moment().year(), month, 1)).year()}
                    reload={setPending}/>
            </Col>
            <Col xs={12} sm={9} className='text-center'>
                <p>Attention la présence de l'icone "<AiOutlineWarning/>" signifie que le planning à été réouvert et qu'il faut porter un point d'attention aux tarifs des réservations présente sur le mois</p>
            </Col>
            <Col sm={6} lg={4} className='d-flex flex-column align-items-center justify-content-center'>
                <div className='invalid-div'></div>
                <p>Loyers non validés</p>
            </Col>
            <Col sm={6} lg={4} className='d-flex flex-column align-items-center justify-content-center'>
                <div className='valid-div'></div>
                <p>Loyers validés</p>
            </Col>
            <Col sm={6} lg={4} className='d-flex flex-column align-items-center justify-content-center'>
                <div className='payed-div'></div>
                <p>Loyers payés</p>
            </Col>
            {
                !pending ?
                    <Col sm={12}>
                        <Row>
                            {
                                rents.length ? rents.map(rent => <ApartmentRent month={parseInt(moment(new Date(moment().year(), month, 1)).format('M')) - 1} rent={rent} onChange={setPending} />)
                                    :
                                    <Col sm={12}>
                                        <h3 className='text-center'>Aucune donnée à afficher</h3>
                                    </Col>
                            }
                        </Row>
                    </Col>
                    :
                    <Col sm={12} className='d-flex justify-content-center align-items-center'>
                        <Spinner />
                    </Col>
            }
        </Row>
    )
}