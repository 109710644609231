import { createSlice } from '@reduxjs/toolkit';

export const QuoteSlice = createSlice({
    name: 'quoteTab',
    initialState: {
        result: undefined,
        showList: true,
        showCreate: false,
        reload: false
    },
    reducers: {
        setResult: (state, value) => {
            state.result = value.payload
        },
        hideAndShowList: state => {
            state.showList = !state.showList
            state.showCreate = !state.showCreate
        },
        hideAndShowCreate: state => {
            state.showCreate = !state.showCreate
            state.showList = !state.showList
        },
        setReload: (state, value )=> {
            state.reload = value.payload
        }
    },
});

export const { setReload, setResult, hideAndShowCreate, hideAndShowList } = QuoteSlice.actions;


export const getResult = state => state.quoteTab.result;
export const getReload = state => state.quoteTab.reload;
export const getVisibilityCreate = state => state.quoteTab.showCreate;
export const getVisibilityList = state => state.quoteTab.showList;

export default QuoteSlice.reducer;
