import { createSlice } from '@reduxjs/toolkit';

export const AlerterSlice = createSlice({
  name: "alert",
  initialState: {
    message: undefined ,
    variant: undefined,
    visibility: false
  },
  reducers: {
    show: (state, value) => {
      state.visibility = true;
      state.message = value.payload.message;
      state.variant = value.payload.variant;
    },
    hide: state => {
      state.visibility = false
    }
  }
});

export const {show, hide} = AlerterSlice.actions;

export const getVisibility = state => state.alert.visibility;
export const getMessage = state => state.alert.message;
export const getVariant = state => state.alert.variant;

export default AlerterSlice.reducer;