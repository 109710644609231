import { useEffect, useState } from "react";
import { BiArchiveIn, BiArchiveOut } from 'react-icons/bi';
import instanceOfAxios from "../../../../../app/axios/axios";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Spinner from "../../../../spinner/Spinner";
import { useDispatch } from "react-redux";
import { show } from "../../../Alerter/alerterSlice";

export default function ArchiveRate({ apartment, rate, updateParent }) {
    const [visibility, setVisibility] = useState(false);
    const [pending, setPending] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (pending) {
            instanceOfAxios.post(`admin/apartment/${apartment.id}/rate/${rate.id}/archive`, { is_archived: rate.is_archived == 0 ? 1 : 0 })
                .then(res => {
                    setPending(false);
                    updateParent(res.data.success);
                    dispatch(show({message: 'Tarif Archivé', variant: 'success'}));
                    setVisibility(false);
                })
                .catch(res => {
                    setPending(false);
                    dispatch(show({message: 'Erreur lors de l\'archivage', variant: 'danger'}));
                });
        }
    }, [pending]);

    return (
        <>
            <Button className='goldenHover' onClick={() => setVisibility(true)}>
                {
                    rate.is_archived == 0 ?
                    <BiArchiveIn />
                    :
                    <BiArchiveOut />
                }
            </Button>
            <Modal
                backdropClassName="backdropThird"
                scrollable
                centered
                show={visibility}
                backdrop="static"
                style={{ zIndex: '20001' }}
                onHide={() => setVisibility(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            rate.is_archived == 0 ?
                            'Archivage'
                            :
                            'Retrait des archives'
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                        {
                            rate.is_archived == 0 ?
                            <p>Veuillez confirmer l'achivage du loyer : {rate.name}</p>
                            :
                            <p>Veuillez confirmer le retrait des archives du loyer : {rate.name}</p>
                        }
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {
                        pending ?
                            <Spinner />
                            :
                            <>
                                <Button className='goldenHover' onClick={() => setPending(true)}>Confirmer</Button>
                                <Button className='goldenHover' onClick={() => setVisibility(false)}>annuler</Button>
                            </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}