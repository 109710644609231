import { useState } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import GenericModifyPdf from './modifyPdf/genericModifyPdf';

export default function CheckInPlaceState(props) {
    const [visibility, setVisibility] = useState(false);

    return (
        <>
            <Button variant='secondary' onClick={() => setVisibility(true)}>Etat des lieux</Button>
            {
                visibility &&
                <Modal
                    dialogClassName='custom-dialog'
                    show={visibility}
                    onHide={() => setVisibility(false)}
                    backdropClassName="backdropThird"
                    scrollable
                    size='xl'
                    backdrop="static"
                    style={{ zIndex: '20003' }}
                    centered>
                    <Modal.Header className='d-flex align-items-center' closeButton>
                        <Row style={{ width: '100%' }}>
                            <Col xs={12}>
                                <Modal.Title>
                                    Etat des lieux
                                </Modal.Title>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body style={{ minHeight: '70vh' }}>
                        <p style={{ color: 'red' }} className='text-center'>Attention toute modification affectera le fichier global du logement</p>
                        <GenericModifyPdf
                            file={props.infos.apartment.place_state_url}
                            updateUrl={'/admin/apartment/' + props.infos.apartment.id + '/place_state'}
                            type='place_state' />
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}