import React, { useEffect, useState } from 'react';
import { DateRangePicker, START_DATE } from 'react-nice-dates';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, connect, useStore } from 'react-redux';
import { fr } from 'date-fns/locale';
import {
    setBeginDateParam,
    setEndDateParam,
    getBeginDate,
    getEndDate
} from './dateRangeSlice';
import '../../../pages/home/Home.scss';
import instanceOfAxios from '../../../app/axios/axios';
import './dateRange.scss';

const DateRange = ({ begin_date, end_date, booking, minLength, adminUsage, usage, apartment }) => {
    const dispatch = useDispatch();
    const [arrayDateRangeDisplayData, setArrayDateRangeDisplayData] = useState({});
    const [modifiers, setModifiers] = useState({});
    const role = useStore().getState().header.role;

    const modifiersClassNames = {
        block: '-block'
    }

    const params = new URLSearchParams(window.location.search);

    // ici on regarde si nous avons des paramètres date dans l'url
    useEffect(() => {
        if (params.has('begin_date_hour')) {
            begin_date = new Date(params.get('begin_date_hour').replace('GMT ', 'GMT+'));
            dispatch(setBeginDateParam(begin_date))
        }

        if (params.has('end_date_hour')) {
            end_date = new Date(params.get('end_date_hour').replace('GMT ', 'GMT+'));
            dispatch(setEndDateParam(end_date))
        }
    }, [])

    // si une réservation ou bloquage est passer dans le sprops on change les date pre-set dans le date picker
    useEffect(() => {
        if (booking) {
            begin_date = new Date(booking.begin_date_hour.replace(/\s/, 'T'));
            end_date = new Date(booking.end_date_hour.replace(/\s/, 'T'));
            dispatch(setBeginDateParam(begin_date))
            dispatch(setEndDateParam(end_date))
        }
    }, [booking])
    
    // dans ce hook on regarde ou est situé le date picker pour lui ajouter des class css bien spécifique pour complétement changer sont affichage
    useEffect(() => {
        if (usage == 'search') {
            setArrayDateRangeDisplayData({
                dateFormat: 'dd MMM yyyy',
                dateRange: 'date-range-search-bar',
                relativeCol: 'dateRelative',
                row: '',
                cover: 'dateAbsolu d-flex justify-content-center backgroundDate',
                inputTitle: 'ml-2',
                inputValueWithDate: 'noDisplay noDisplayforLarge',
                inputValueWithoutDate: 'noDisplay noDisplayforLarge',
                svg: '',
                input: ' searchbarComponent dateAbsolu backgroundTrans input-text-right-to-xxl-center',
                dateOptions: { year: 'numeric', month: 'short', day: 'numeric' }
            })
        } else if (usage == 'admin') {
            setArrayDateRangeDisplayData({
                dateFormat: 'dd MMM yyyy',
                dateRange: 'date-range-alone ',
                relativeCol: 'dateRelative',
                row: '',
                cover: 'dateAbsolu d-flex justify-content-center backgroundDate',
                inputTitle: 'ml-3 mt-2',
                inputValueWithDate: 'noDisplay noDisplayforLarge',
                inputValueWithoutDate: 'noDisplay noDisplayforLarge',
                svg: '',
                input: ' searchbarComponent dateAbsolu backgroundTrans dateRangeBoxShadow text-center dateRangeFontSizeAdmin',
                dateOptions: { year: 'numeric', month: 'short', day: 'numeric' }
            })
            if(booking || apartment){
                let id = apartment ? apartment : booking.apartment.id;
                instanceOfAxios.get('/apartment/' + id +'/datePickerDateBlock')
                .then( res => {
                    setModifiers({
                        block: date => verifyDateBlock(res.data.dates, date, booking),
                        disabled: date => verifyDateBlock(res.data.dates, date, booking)
                    })
                })
            }
        } else if (usage == 'book') {
            setArrayDateRangeDisplayData({
                dateFormat: 'dd MMM',
                dateRange: 'date-range-book',
                relativeCol: '',
                row: 'w-100',
                cover: 'col-12 d-flex flex-column',
                inputTitle: 'inputTitle',
                inputValueWithDate: 'inputValueWithDate d-flex align-items-start justify-content-center',
                inputValueWithoutDate: 'inputValueWithoutDate d-flex align-items-start justify-content-center',
                svg: 'ml-auto',
                separator: 'separatorSearchBar',
                input: ' dateAbsolu',
                dateOptions: { month: 'short', day: 'numeric' }
            })
            instanceOfAxios.get('/apartment/' + apartment.id +'/datePickerDateBlock')
            .then( res => {
                setModifiers({
                    block: date => verifyDateBlock(res.data.dates, date),
                    disabled: date => verifyDateBlock(res.data.dates, date)
                })
            })
        }
    }, [])

    // dans cette fonction on regarde si les dates affichées dans le date picker sont déjà prise par une réservation / bloquage
    function verifyDateBlock(datesBlock, date, booking = null){
        let beginBooking = null;
        let endBooking = null;

        if(booking){
            beginBooking = new Date(booking.begin_date_hour.replace(/\s/, 'T'));
            endBooking = new Date(booking.end_date_hour.replace(/\s/, 'T'));
            if(booking.re_rent_at){
                endBooking = new Date(booking.re_rent_at.replace(/\s/, 'T'));
            }
            beginBooking.setHours(12);
            endBooking.setHours(12);
        }

        for(let i = 0; i < datesBlock.length; i++){
            let begin = new Date(datesBlock[i].begin_date_hour.replace(/\s/, 'T'));
            let end = new Date(datesBlock[i].end_date_hour.replace(/\s/, 'T'));
            if(datesBlock[i].re_rent_at){
                end = new Date(datesBlock[i].re_rent_at.replace(/\s/, 'T'));
            }
            let verifyDate = new Date(date);
            verifyDate.setHours(12);

            if(beginBooking && endBooking && verifyDate.getTime() >= beginBooking.getTime() && verifyDate.getTime() <= endBooking.getTime()){
                return false;
            } else if (verifyDate > begin && verifyDate <= end){
                return true;
            } else if (datesBlock[i + 1]){
                let nextBegin = new Date(datesBlock[i + 1].begin_date_hour.replace(/\s/, 'T'));
                nextBegin.setHours(12);
                end.setHours(12);
                if(nextBegin.getTime() === end.getTime() && verifyDate.getTime() === nextBegin.getTime()){
                    return true;
                }
            }
        }
    }

    let minDate = new Date();
    minDate.setDate(minDate.getDate() + 2);
    
    //verif du role au cas ou un admin utilise la recherche sur le front-end
    if (role === 'super-admin' || role === 'admin' && !adminUsage) {
        minDate.setDate(minDate.getDate() - 2);
    }

    //on verifie si le picker est call dans le back office ou non 
    if (adminUsage) {
        minDate = null;
    }

    return (
        <DateRangePicker
            startDate={begin_date}
            endDate={end_date}
            onStartDateChange={(e) => dispatch(setBeginDateParam(e))}
            onEndDateChange={(e) => dispatch(setEndDateParam(e))}
            minimumDate={minDate}
            format={arrayDateRangeDisplayData.dateFormat}
            locale={fr}
            modifiers={modifiers}
            modifiersClassNames={modifiersClassNames}
            >
            {({ startDateInputProps, endDateInputProps, focus }) => {
                return (
                    <div className={arrayDateRangeDisplayData.dateRange}>
                        <Row noGutters className={arrayDateRangeDisplayData.row}>
                            <Col xs={12} md={6} className={arrayDateRangeDisplayData.relativeCol}>
                                <div className={arrayDateRangeDisplayData.cover} >
                                    <p className={arrayDateRangeDisplayData.inputTitle}>Arrivée</p>
                                    {begin_date ?
                                            <p className={arrayDateRangeDisplayData.inputValueWithDate}>
                                            {begin_date.toLocaleDateString('fr-CA', {day: 'numeric' })}
                                            <small>
                                                {begin_date && begin_date.toLocaleDateString('fr-CA', { month: 'short' })}
                                            </small>
                                        </p>
                                        :
                                    <p className={arrayDateRangeDisplayData.inputValueWithoutDate}>Jour /<br/> Mois</p>
                                    }
                                    <svg className={arrayDateRangeDisplayData.svg} xmlns="http://www.w3.org/2000/svg" width="40" height="50" viewBox="0 0 53.557 61.208">
                                        <path id="picto_arrivee" d="M0,55.469a5.74,5.74,0,0,0,5.738,5.738h42.08a5.74,5.74,0,0,0,5.738-5.738V22.953H0ZM38.255,32.038A1.439,1.439,0,0,1,39.689,30.6h4.782a1.439,1.439,0,0,1,1.435,1.435V36.82a1.439,1.439,0,0,1-1.435,1.435H39.689a1.439,1.439,0,0,1-1.435-1.435Zm0,15.3a1.439,1.439,0,0,1,1.435-1.435h4.782a1.439,1.439,0,0,1,1.435,1.435v4.782a1.439,1.439,0,0,1-1.435,1.435H39.689a1.439,1.439,0,0,1-1.435-1.435Zm-15.3-15.3A1.439,1.439,0,0,1,24.387,30.6h4.782A1.439,1.439,0,0,1,30.6,32.038V36.82a1.439,1.439,0,0,1-1.435,1.435H24.387a1.439,1.439,0,0,1-1.435-1.435Zm0,15.3a1.439,1.439,0,0,1,1.435-1.435h4.782A1.439,1.439,0,0,1,30.6,47.34v4.782a1.439,1.439,0,0,1-1.435,1.435H24.387a1.439,1.439,0,0,1-1.435-1.435Zm-15.3-15.3A1.439,1.439,0,0,1,9.086,30.6h4.782A1.439,1.439,0,0,1,15.3,32.038V36.82a1.439,1.439,0,0,1-1.435,1.435H9.086A1.439,1.439,0,0,1,7.651,36.82Zm0,15.3a1.439,1.439,0,0,1,1.435-1.435h4.782A1.439,1.439,0,0,1,15.3,47.34v4.782a1.439,1.439,0,0,1-1.435,1.435H9.086a1.439,1.439,0,0,1-1.435-1.435ZM47.818,7.651H42.08V1.913A1.918,1.918,0,0,0,40.168,0H36.342a1.918,1.918,0,0,0-1.913,1.913V7.651h-15.3V1.913A1.918,1.918,0,0,0,17.215,0H13.389a1.918,1.918,0,0,0-1.913,1.913V7.651H5.738A5.74,5.74,0,0,0,0,13.389v5.738H53.557V13.389A5.74,5.74,0,0,0,47.818,7.651Z" fill="#b7a57e" />
                                    </svg>
                                    <div className={arrayDateRangeDisplayData.separator}></div>
                                </div>
                                <input
                                    className={(focus === START_DATE ? ' -focused' : '') + arrayDateRangeDisplayData.input}
                                    {...startDateInputProps}
                                    value={begin_date ? begin_date.toLocaleDateString('fr-CA', arrayDateRangeDisplayData.dateOptions) : 'Jour / Mois'}
                                    
                                />
                            </Col>
                            <Col xs={12} md={6} className={arrayDateRangeDisplayData.relativeCol}>
                                <div className={arrayDateRangeDisplayData.cover}>
                                    <p className={arrayDateRangeDisplayData.inputTitle}>Départ</p>
                                    {end_date ?
                                            <p className={arrayDateRangeDisplayData.inputValueWithDate}>
                                            {end_date.toLocaleDateString('fr-CA', {day: 'numeric' })}
                                            <small>
                                                {end_date && end_date.toLocaleDateString('fr-CA', { month: 'short' })}
                                            </small>
                                        </p>
                                        :
                                    <p className={arrayDateRangeDisplayData.inputValueWithoutDate}>Jour /<br/> Mois</p>
                                    }
                                    <svg className={arrayDateRangeDisplayData.svg} xmlns="http://www.w3.org/2000/svg" width="40" height="50" viewBox="0 0 53.557 61.208">
                                        <path id="picto_arrivee" d="M0,55.469a5.74,5.74,0,0,0,5.738,5.738h42.08a5.74,5.74,0,0,0,5.738-5.738V22.953H0ZM38.255,32.038A1.439,1.439,0,0,1,39.689,30.6h4.782a1.439,1.439,0,0,1,1.435,1.435V36.82a1.439,1.439,0,0,1-1.435,1.435H39.689a1.439,1.439,0,0,1-1.435-1.435Zm0,15.3a1.439,1.439,0,0,1,1.435-1.435h4.782a1.439,1.439,0,0,1,1.435,1.435v4.782a1.439,1.439,0,0,1-1.435,1.435H39.689a1.439,1.439,0,0,1-1.435-1.435Zm-15.3-15.3A1.439,1.439,0,0,1,24.387,30.6h4.782A1.439,1.439,0,0,1,30.6,32.038V36.82a1.439,1.439,0,0,1-1.435,1.435H24.387a1.439,1.439,0,0,1-1.435-1.435Zm0,15.3a1.439,1.439,0,0,1,1.435-1.435h4.782A1.439,1.439,0,0,1,30.6,47.34v4.782a1.439,1.439,0,0,1-1.435,1.435H24.387a1.439,1.439,0,0,1-1.435-1.435Zm-15.3-15.3A1.439,1.439,0,0,1,9.086,30.6h4.782A1.439,1.439,0,0,1,15.3,32.038V36.82a1.439,1.439,0,0,1-1.435,1.435H9.086A1.439,1.439,0,0,1,7.651,36.82Zm0,15.3a1.439,1.439,0,0,1,1.435-1.435h4.782A1.439,1.439,0,0,1,15.3,47.34v4.782a1.439,1.439,0,0,1-1.435,1.435H9.086a1.439,1.439,0,0,1-1.435-1.435ZM47.818,7.651H42.08V1.913A1.918,1.918,0,0,0,40.168,0H36.342a1.918,1.918,0,0,0-1.913,1.913V7.651h-15.3V1.913A1.918,1.918,0,0,0,17.215,0H13.389a1.918,1.918,0,0,0-1.913,1.913V7.651H5.738A5.74,5.74,0,0,0,0,13.389v5.738H53.557V13.389A5.74,5.74,0,0,0,47.818,7.651Z" fill="#b7a57e" />
                                    </svg>
                                    <div className={arrayDateRangeDisplayData.separator}></div>
                                </div>
                                <input
                                    className={(focus === START_DATE ? ' -focused' : '') + arrayDateRangeDisplayData.input}
                                    {...endDateInputProps}
                                    value={end_date ? end_date.toLocaleDateString('fr-CA', arrayDateRangeDisplayData.dateOptions) : 'Jour / Mois'} />
                            </Col>
                        </Row>
                    </div>
                )
            }}
        </DateRangePicker>
    )
}

const mapStateToProps = state => {
    return {
        begin_date: getBeginDate(state),
        end_date: getEndDate(state),
    };
}

export default connect(mapStateToProps)(DateRange);