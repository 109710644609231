import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { Col, Card, Row } from 'react-bootstrap';

export default function SkeletonCard(props){
    return(
        <Col lg={12} xl={12} md={12} sm={12} xs={12}>
            <Card className='marginCard card-max-width shadowForCard'>
                <Card.Header className='noReturn'>
                    <Row>
                        <Col xs={10} className='noReturn'>
                            <Typography variant="body1">
                                <Skeleton animation='wave'/>
                            </Typography> 
                        </Col>
                        <Col xs={2}>
                            <Typography variant="body1">
                                <Skeleton animation='wave'/>
                            </Typography> 
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={3}>
                            <Card.Text className='noReturn'>
                                <Typography variant="h4">
                                    <Skeleton animation='wave'/>
                                </Typography> 
                            </Card.Text>
                        </Col>
                        <Col md={3}>
                            <Card.Text className='noReturn card-text'>
                                <Typography variant="body1">
                                    <Skeleton animation='wave'/>
                                </Typography> 
                            </Card.Text>
                        </Col>
                        <Col md={3}>
                            <Card.Text className='noReturn'>
                                <Typography variant="body1">
                                    <Skeleton animation='wave'/>
                                </Typography> 
                            </Card.Text>
                        </Col>
                        <Col md={3}>
                            <Row>
                                <Col xs={2} sm={2} md={2} lg={2}>
                                    <Typography variant="h4">
                                        <Skeleton animation='wave'/>
                                    </Typography> 
                                 </Col>
                                <Col xs={2} sm={2} md={2} lg={2}>
                                    <Typography variant="h4">
                                        <Skeleton animation='wave'/>
                                    </Typography> 
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2}>
                                    <Typography variant="h4">
                                        <Skeleton animation='wave'/>
                                    </Typography> 
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}