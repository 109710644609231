import React, { useEffect, useState } from 'react';
import { Container, Button, Row, Alert, Col } from 'react-bootstrap';
import instanceOfAxios from '../../app/axios/axios';
import ContactTab from '../../components/user/contactTab/contactTab';
import BookingTab from '../../components/user/bookingTab/bookingTab';
import ApartmentTab from '../../components/user/apartmentTab/apartmentTab';
import './UserAccount.scss';
import { useDispatch } from 'react-redux';
import { show } from '../../components/admin/Alerter/alerterSlice';
import Spinner from '../../components/spinner/Spinner';
import { logout } from '../../components/header/headerSlice';

export default function UserAccount() {

    const [user, setUser] = useState();
    const [role, setRole] = useState();
    const [isSubmit, setIsSubmit] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        instanceOfAxios.get('/account')
            .then((res) => {
                setUser(res.data);
                setRole(res.data.roles[0].name);
            });
    }, [])

    function handleChange() {
        setUser();
        instanceOfAxios.get('/account')
            .then((res) => {
                setUser(res.data);
                setRole(res.data.roles[0].name);
            });
    }

    function resendVerify() {
        setIsSubmit(true);
        instanceOfAxios.get('/email/resend')
            .then((res) => {
                dispatch(show({ message: 'Un e-mail de vérification à été envoyé', variant: 'success' }));
                setIsSubmit(false);
            })
            .catch(() => {
                dispatch(show({ message: 'Une erreur est survenue lors de l\'envoi de l\'email de vérification', variant: 'danger' }));
                setIsSubmit(false);
            });
    }

    return (
        <Container style={{ display: 'block', paddingBottom: '5vh', position: 'relative' }} fluid className='noPadding d-flex flex-column align-items-center justify-content-center'>
            {
                user && user.principal_contact
                    ?
                    <Row className='mt-5'>
                        <Col xs={12} className='text-center'>
                            <h2 className='welcome-title'>Bonjour, {user.principal_contact.civility} {user.principal_contact.last_name} {user.principal_contact.first_name}</h2>
                        </Col>
                        <Col xs={12} className='text-center'>
                            <a className='disconect-button' onClick={() => dispatch(logout())}>Me déconnecter</a>
                        </Col>
                    </Row>
                    :
                    <></>
            }
            {
                user && !user.email_verified_at ?
                    <Row className='mt-5'>
                        <Col xs={12} className='text-center'>
                            <Alert variant='danger'>Attention vous n'avez pas vérifié votre compte, cliquez sur le bouton ci-dessous pour recevoir un nouvel e-mail de vérification</Alert>
                        </Col>
                        <Col xs={12} className='d-flex justify-content-center'>
                            {
                                isSubmit ?
                                    <Spinner />
                                    :
                                    <Button size='sm' className='goldenHover' onClick={resendVerify} variant="primary">Vérifier mon e-mail</Button>
                            }
                        </Col>
                    </Row>
                    :
                    <></>
            }
            <Row className={user && role === 'owner' ? 'menuUser d-flex' : 'menuUser d-flex align-items-center justify-content-center'} style={{ width: '100%', minHeight: '60vh' }}>
                <Col xs={12} lg={6}>
                    <Row className='d-flex justify-content-center mt-5'>
                        <Col xs={12}>
                            <h2>Mon espace Locataire</h2>
                        </Col>
                        <ContactTab user={user} role={role} handleChange={handleChange} />
                        <BookingTab user={user} role={role} />
                    </Row>
                </Col>
                {user && role === 'owner' ?
                    <ApartmentTab user={user} role={role} />
                    :
                    <></>}
            </Row>
        </Container>
    );
}
