import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Image } from "react-bootstrap";

export default function ViewModal(props) {
    const [activeUrl, setActiveUrl] = useState();

    useEffect(() => {
        setActiveUrl(props.activeUrl);
    }, [props.activeUrl])

    function setAndScroll(activeUrl){
        setActiveUrl(activeUrl);
        let body = document.getElementById("body-img-scroll");
        body.scroll(0, 0);
    }

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            scrollable
            backdropClassName="backdropThird"
            centered
            size='xl'
            style={{ zIndex: '20002' }}
            dialogClassName='custom-dialog'
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body id='body-img-scroll' style={{scrollBehavior: 'smooth'}}>
                <Row className='d-flex justify-content-center'>
                    <Col xs={12} lg={7} className='noPadding d-flex flex-column align-items-center' style={props.images && props.images.length > 3 ? {height: '90%'} : {height: '100%'}}>
                        <h2>Cliquez sur une image pour l'afficher ci-dessous</h2>
                        {
                            <Image loading='lazy' className='imgHover' src={activeUrl} fluid alt='photos de notre logement'/>
                        }
                    </Col>
                    <Col xs={12} lg={props.images && props.images.length > 3 ? 12 : 2} className='noPadding d-flex justify-content-center align-items-center'>
                        <Row className='mb-3 d-flex justify-content-center'>
                            {
                                props.images && props.images.map(image =>
                                    <Col xs={12} md={4} lg={props.images.length > 3 ? 3 : 12} className=' imgPreviewHover noPadding ml-2 mt-2 d-flex justify-content-center'>
                                        <Image loading='lazy' className='imgHover' src={image.url} fluid onClick={() => setAndScroll(image.url)} alt='photos de notre logement'/>
                                    </Col>)
                            }
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}