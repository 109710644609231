import { useEffect, useState } from "react"
import { Button, Modal, Row, Col } from "react-bootstrap";
import { getCookie, setCookies, ParseCookieToArray } from "../cookies/CookiesFunction";
import { DataUpdate } from './dataUpdate';

export default function (props) {
    const [visibility, setVisibility] = useState(false);

    useEffect(() =>{
        if(getCookie('LastUpdateSaw') != DataUpdate[DataUpdate.length - 1]['version']){
            setVisibility(true);
        }
    }, []);

    return (
        <Modal
            show={visibility}
            onHide={() => setVisibility(false)}
            scrollable
            backdropClassName="backdropThird"
            centered
            size={'lg'}
            style={{ zIndex: '20002' }}
            animation={true}>
            <Modal.Header>
                <Modal.Title>
                    Mise à jour version {DataUpdate[DataUpdate.length - 1]['version']} de Class Appart
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    DataUpdate[DataUpdate.length - 1]['list'].map((data, index) => 
                    <>
                        <h5>{data.title}</h5>
                        <ul>
                            {
                                data.items.map(item => <li>{item}</li>)
                            }
                        </ul>
                        {
                            DataUpdate[DataUpdate.length - 1]['list'].length > 1 
                            && index < DataUpdate[DataUpdate.length - 1]['list'].length - 1 && 
                                <Row>
                                    <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                                        <div className='separator'
                                            style={{
                                                border: '1px solid #B7A57E',
                                                color: '#b7a57e',
                                                width: '90%'
                                            }}></div>
                                    </Col>
                                </Row>
                        }
                    </>)
                }
            </Modal.Body>
            <Modal.Footer>
                    <Button className='goldenHover' 
                    onClick={() => {
                        setVisibility(false);
                        setCookies('LastUpdateSaw', DataUpdate[DataUpdate.length - 1]['version'])
                    }}>
                        J'ai pris connaissance de la mise à jours
                    </Button>
            </Modal.Footer>
        </Modal>
    )
}