import { createSlice } from '@reduxjs/toolkit';

export const apartmentSlice = createSlice({
    name: 'apartmentsTab',
    initialState: {
        showCreate: false,
        showList: true,
        apartmentForUpdate: undefined
    },
    reducers: {
        ShowCreateStep2: state => {
            state.showCreate = false;
            state.showList = false;
        },
        ShowCreate: (state, value) => {
            state.showCreate = true;
            state.showList = false;
            if(value.payload){
                state.apartmentForUpdate = value.payload;
            }
        },
        ShowList: state => {
            state.showList = true;
            state.showCreate = false;
            state.apartmentForUpdate = undefined;
        },
        resetUpdater: state => {
            state.apartmentForUpdate = undefined;
        },
        setUpdater: (state, value) => {
            state.apartmentForUpdate = value.payload;
        }
    },
});

export const { ShowCreate, ShowList, resetUpdater, setUpdater } = apartmentSlice.actions;

export const getShowCreate = state => state.apartmentsTab.showCreate;
export const getShowList = state => state.apartmentsTab.showList;
export const getApartmentForUpdate = state => state.apartmentsTab.apartmentForUpdate;

export default apartmentSlice.reducer;


