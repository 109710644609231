import React, { useState } from 'react';
import { Row, Col, Button, Pagination, Modal } from 'react-bootstrap';
import ContactCard from '../../../contact/contactCard';
import './userDetails.scss';
import '../userCard/usersCard.scss'
import ContactInformations from '../../../formulaire/contactInformations';
import AddRib from './rib/addRib';
import { MdOutlineFormatListNumberedRtl } from 'react-icons/md';

export default function UserDetails(props) {
    const [visibility, setVisibility] = useState(false);
    const [activePage, setActivePage] = useState(1);

    let paginated = [];
    let newIndex = 0;
    let contacts = Object.assign([], props.user.contacts);
    let principalContact = contacts[contacts.findIndex(contact => contact.is_principal === 1)];
    contacts.splice(contacts.findIndex(contact => contact.is_principal === 1), 1);
    contacts.splice(0, 0, principalContact);
    contacts.forEach((value, index) => {
        if (index > 0 && index % 3 === 0) {
            newIndex++;
        }
        if (index % 3 === 0) {
            paginated[newIndex] = [];
        }
        paginated[newIndex].push(value);
    });

    let items = [];
    if (paginated.length > 1) {
        let loop = 1;
        if (activePage > 2) {
            loop = activePage;
        }
        for (let number = loop; number <= paginated.length; number++) {
            items.push(
                <Pagination.Item onClick={changePage} key={number} id={number} active={number === activePage}>
                    {number}
                </Pagination.Item>
            )
        }
    }

    function mapContacts() {
        if (paginated.length > 0) {
            return (paginated[activePage - 1].map((contact, i) => {
                if (contact !== undefined) {
                    return <ContactCard key={i + '/contact'} contact={contact} user={props.user} role={props.role} handleChange={props.handleChange} />
                }
                return;
            }
            ));
        } else {
            return 'Aucun contact à afficher'
        }
    }
    function showAndHide() {
        setVisibility(!visibility);
    }

    function changePage(event) {
        setActivePage(parseInt(event.target.id))
    }

    function prevPage() {
        if (activePage > 1) {
            setActivePage(parseInt(activePage) - 1)
        }
    }

    function nextPage() {
        if (activePage < paginated.length) {
            setActivePage(parseInt(activePage) + 1)
        }
    }

    function renderFormulaire() {
        if (props.user.contacts.length < 1 || props.user.is_society) {
            return (
                <Col>
                    <ContactInformations model={props.user} handleChange={props.handleChange} />
                </Col>
            )
        }
        return;
    }

    function societyInformations() {
        if (props.user.is_society) {
            return (
                <societyModal informations={props.user.society_information}></societyModal>
            );
        }
        return;
    }

    return (
        <>
            <Button variant="secondary" className="icon-button-sad whiteSVG" size="sm" onClick={showAndHide}>
                <MdOutlineFormatListNumberedRtl /> Liste de contacts
            </Button>
            <Modal centered size='xl' show={visibility} showMask={false} onHide={showAndHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Liste des Contacts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='contact-list'>
                        {props.user.roles[0].name === 'owner' && (props.role === 'admin' || props.role === 'super-admin' || props.role === 'trainee') ?
                            <Col xs={12}>
                                <AddRib account={props.user} />
                            </Col>
                            : <></>}
                        <Row className='center auto-margin mb-2 mt-2'>
                            {renderFormulaire()}
                        </Row>
                        <Row className='center auto-margin mb-2 mt-2'>
                            {societyInformations()}
                        </Row>
                        <Row className='center auto-margin justify-content-center mb-2 mt-2'>
                            {mapContacts()}
                        </Row>
                        <Row className='center auto-margin mb-2 mt-2'>
                            <Col className="centerPagination">
                                <Pagination>
                                    <Pagination.Prev id={'prev'} onClick={prevPage} />
                                    {items}
                                    <Pagination.Next id={'next'} onClick={nextPage} />
                                </Pagination>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>)
}
