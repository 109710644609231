import { Button, Modal, Tabs, Tab, Table } from "react-bootstrap";
import { useState } from "react"
import BlockedCard from "./blockedCard";
import BookCard from "./bookCard";
import { useStore } from "react-redux";

export default function BlockedList(props) {
    const [visibility, setVisibility] = useState(false);
    const role = useStore().getState().header.role;

    return (
        <>
            <Button variant='secondary' onClick={() => setVisibility(!visibility)}>Consulter les blocages / réservations</Button>
            <Modal
                dialogClassName='custom-dialog'
                backdropClassName="backdropThird"
                style={{ zIndex: '20001' }}
                scrollable
                centered
                show={visibility}
                onHide={() => setVisibility(!visibility)}>
                <Modal.Header closeButton>
                    Liste des dates bloquées en {props.year}
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="book" id="uncontrolled-tab-example">
                        <Tab eventKey="book" title="Réservations">
                            {props.dates && props.dates[0] && props.dates[0].length > 0 ?
                                <Table striped bordered hover size="sm" className='text-center' style={{ marginTop: '1vh' }}>
                                    <thead>
                                        <tr>
                                            {role === 'super-admin' || role === 'admin' || role === 'trainee' ? <th>Détails</th> : <></>}
                                            <th>Référence</th>
                                            {role === 'super-admin' || role === 'admin' || role === 'trainee' ? <th>Locataire</th> : <th>Loyer</th>}
                                            {role === 'owner' ? <th>Charges</th> : <></>}
                                            <th>Début</th>
                                            <th>Fin</th>
                                            <th>Statut</th>
                                            <></>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.dates[0].map((date) =>
                                            <BookCard date={date} handleChange={props.handleChange} role={role}></BookCard>
                                        )}
                                    </tbody>
                                </Table>
                                :
                                <div className='center'>
                                    <h2>Aucunes réservations </h2>
                                </div>}
                        </Tab>
                        <Tab eventKey="block" title="Blocages">
                            {props.dates && props.dates[1] && props.dates[1].length > 0 ?
                                <Table striped bordered hover size="sm" className='text-center'>
                                    <thead>
                                        <tr>
                                            <th>Par</th>
                                            <th>Intitulé</th>
                                            <th>Début</th>
                                            <th>Fin</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.dates[1].map((date) =>
                                            <BlockedCard date={date} handleChange={props.handleChange}></BlockedCard>
                                        )}
                                    </tbody>
                                </Table>
                                :
                                <div className='center'>
                                    <h2>Aucuns blocages en cours</h2>
                                </div>}
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='goldenHover' onClick={() => setVisibility(!visibility)}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}