import { Card, Col, Row } from 'react-bootstrap';
import UpdateOrCreatePage from '../updateOrCreatePage/UpdateOrCreatePage';
import './pageCard.scss';

export default function PageCard(props) {
    let created = new Date(props.page.created_at.replace(/\s/, 'T'));
    let updated = new Date(props.page.updated_at.replace(/\s/, 'T'));
    var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    return (
        <Col md={12}>
            <Card>
                <Card.Header>
                    <Row>
                        <Col sm={12} md={6} lg={3} className='d-flex justify-content-center justify-content-lg-start justify-content-md-center justify-content-sm-center'>
                            <Card.Title>
                                {props.page.type[0].toUpperCase() + props.page.type.slice(1)}
                            </Card.Title>
                        </Col>
                        <Col sm={12} md={6} lg={3} className='d-flex justify-content-center'>
                            Créé le : {created.toLocaleDateString('fr-FR', options)}
                        </Col>
                        <Col sm={12} md={6} lg={3} className='d-flex justify-content-center'>
                            Modifié le : {updated.toLocaleDateString('fr-FR', options)}
                        </Col>
                        <Col sm={12} md={6} lg={3} className='d-flex justify-content-center justify-content-lg-end justify-content-md-center justify-content-sm-center'>
                            <UpdateOrCreatePage page={props.page} onChange={() => props.onChange()} />
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <div class="preview_wrap">
                        <iframe src="https://www.class-appart.com/" loading='lazy' class="frame_wrap" sandbox></iframe>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    )
}