import { useEffect, useState } from "react"
import { Row, Col } from "react-bootstrap";
import instanceOfAxios from "../../../../app/axios/axios"
import AgencyCard from "./agencyCard/AgencyCard";
import EditOrCreateAgency from "../../agency/EditOrCreateAgency";
import Spinner from "../../../spinner/Spinner";

export default function AgencyTax(props){
    const [agencies, setAgencies] = useState([]);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        if(pending){
            setPending(false);
            instanceOfAxios.get('admin/agency')
            .then(res => setAgencies(res.data.succes))
        }
    }, [pending])

    return (
        <>
            <Row className='d-flex justify-content-center align-items-center'>
                <Col xs={12}>
                    <h3>Liste des agences</h3>
                </Col>
                <Col xs={12}>
                    <EditOrCreateAgency handleChange={setPending}/>
                </Col>
                {
                    !pending ? agencies.map( agency => <AgencyCard handleChange={setPending} agency={agency}/>)
                    :
                    <Col xs={12} className='d-flex justify-content-center'> <Spinner/> </Col>
                }
            </Row>
        </>
    )
}