import { useState } from "react"
import { Button, Modal, Row, Col } from "react-bootstrap";
import CommentCard from "../../card/commentCard";

export default function ListNotAttributed(props) {
    const [visibility, setVisibility] = useState(false);
    
    return (
        <>
            <Button className='goldenHover' onClick={() => setVisibility(true)}>Visualiser</Button>
            <Modal
                scrollable
                centered
                show={visibility}
                size='xl'
                onHide={() => setVisibility(!visibility)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Liste des commentaires non attribués {props.for ? 'pour les ' + props.for : ''}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} className='text-center justify-content-center'>
                            {
                                props.comments.map(comment =>
                                    <CommentCard comment={comment} displayButton={false} setReload={props.setReload}/>
                                )
                            }
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}