import React from 'react';
import { useDispatch, connect, useStore } from 'react-redux';
import { Button, Form, Col, Row } from 'react-bootstrap';
import {
    getSearchResult,
    getResult,
    getActivePage,
    getPageNumber,
    nextPage,
    prevPage,
    handleChange,
    showCreate,
    getLoading,
    search,
    getParam
} from '../bookingTabSlice';
import '../bookingTab.scss'
import './bookingList.scss'
import BookingCard from '../bookingCard/bookingCard';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import SkeletonCard from '../bookingCard/skeletonCard';
import Spinner from '../../../spinner/Spinner';
import { AiOutlineSearch } from 'react-icons/ai';

const BookingList = ({ className, resultFromSearch, activePage, pageNumber, isLoading, param }) => {
    const dispatch = useDispatch();
    const store = useStore();
    const skeleton = [{}, {}, {}, {}, {}];

    function renderBookingCards(resultFromSearch, store) {
        if (resultFromSearch !== undefined && resultFromSearch.length > 0) {
            return (
                <>
                    <Row className="justify-content-center mb-3" noGutters>
                        <Col lg={1} md={1} sm={1} xs={1} className='text-center'>
                            <Button className="goldenHover" onClick={() => dispatch(prevPage(activePage, param))} size="sm" variant="light">
                                <GrLinkPrevious />
                            </Button>
                        </Col>
                        <Col lg={10} md={10} sm={10} xs={10} className='text-center'>
                            <strong>{activePage}</strong> / {pageNumber}
                        </Col>
                        <Col lg={1} md={1} sm={1} xs={1} className='text-center'>
                            <Button className="goldenHover" onClick={() => dispatch(nextPage(activePage, pageNumber, param))} size="sm" variant="light">
                                <GrLinkNext />
                            </Button>
                        </Col>
                    </Row>
                    {
                        resultFromSearch.map((value) => {
                            return <BookingCard key={value.id} booking={value} role={store.getState().header.role}></BookingCard>
                        })
                    }
                    <Row className="justify-content-center mb-3" noGutters>
                        <Col lg={1} md={1} sm={1} xs={1} className='text-center'>
                            <Button className="goldenHover" onClick={() => dispatch(prevPage(activePage, param))} size="sm" variant="light">
                                <GrLinkPrevious />
                            </Button>
                        </Col>
                        <Col lg={10} md={10} sm={10} xs={10} className='text-center'>
                            <strong>{activePage}</strong> / {pageNumber}
                        </Col>
                        <Col lg={1} md={1} sm={1} xs={1} className='text-center'>
                            <Button className="goldenHover" onClick={() => dispatch(nextPage(activePage, pageNumber, param))} size="sm" variant="light">
                                <GrLinkNext />
                            </Button>
                        </Col>
                    </Row>
                </>
            )
        }

        return (
            <div className='d-flex justify-content-center align-items-center'>
                <h3>Aucune réservation à afficher</h3>
            </div>)
    }

    return (
        <Row className={className + ' users '} style={{ height: 'unset' }} noGutters>
            <Col xs={12}>
                <h2>Gestion des réservations</h2>
            </Col>
            <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                <div className='separator'
                    style={{
                        border: '1px solid #B7A57E',
                        color: '#b7a57e',
                        width: '90%'
                    }}></div>
            </Col>
            <Col xs={12}>
                <Row className="justify-content-center align-content-center mb-5">
                    <Button variant="secondary" onClick={() => dispatch(showCreate())} >Créer une réservation</Button>
                </Row>
            </Col>
            <Col lg={4}xs={12}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        dispatch(search(activePage, param))
                    }
                }}>
                <Row className="align-content-center searchAdminForm" >
                    <Col xs={12}>
                        <Form id='formSearchAdmin'>
                            <Form.Group>
                                <Form.Label>Référence de logement</Form.Label>
                                <Form.Control id='apartment' onChange={(e) => dispatch(handleChange(e, param))} type="text" placeholder="Référence de logement" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Référence de réservation</Form.Label>
                                <Form.Control id='booking' onChange={(e) => dispatch(handleChange(e, param))} type="text" placeholder="Référence de réservation" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Identifiant site tier</Form.Label>
                                <Form.Control id='site_tier' onChange={(e) => dispatch(handleChange(e, param))} type="text" placeholder="Identifiant" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Locataire</Form.Label>
                                <Form.Control id='tenant' onChange={(e) => dispatch(handleChange(e, param))} type="text" placeholder="Identifiant/Nom" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <Form.Control id="state" as="select" onChange={(e) => dispatch(handleChange(e, param))}>
                                    <option>toutes</option>
                                    <option>en attente</option>
                                    <option>acompte versé</option>
                                    <option>à payer</option>
                                    <option>payé</option>
                                    <option>trop perçu</option>
                                    <option>annulé</option>
                                </Form.Control>
                            </Form.Group>
                            {
                                isLoading ?
                                    <Spinner />
                                    :
                                    <Button
                                        className="mt-2 whiteSVG"
                                        onClick={() => dispatch(search(activePage, param))}
                                        variant="secondary">
                                        Rechercher <AiOutlineSearch/>
                                    </Button>
                            }
                        </Form>
                    </Col>
                </Row>
            </Col>
            <Col className="bookingList" lg={8} md={12} sm={12} xs={12}>
                <div>
                    {isLoading ?
                        <Row className='mt-5'>
                            {
                                skeleton.map(skel => {
                                    return (
                                        <SkeletonCard />
                                    )
                                })
                            }
                        </Row>
                        :
                        renderBookingCards(resultFromSearch, store)
                    }
                </div>
            </Col>
        </Row>
    )
}

function mapStateToProps(state) {
    return {
        resultFromSearch: getSearchResult(state),
        result: getResult(state),
        param: getParam(state),
        isLoading: getLoading(state),
        activePage: getActivePage(state),
        pageNumber: getPageNumber(state)
    }
}
export default connect(mapStateToProps)(BookingList);