import { useEffect, useState } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import instanceOfAxios from '../../../../app/axios/axios';
import EditOrCreateElectricity from './editOrCreateElectricity';
import { Col, Row } from 'react-bootstrap';
import DeleteModal from '../../../generiqueModal/deleteModal/deleteModal';
import { useDispatch } from 'react-redux';
import { show } from '../../Alerter/alerterSlice';

export default function ElectricityTable(props) {
    const [pending, setPending] = useState(true);
    const [data, setData] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        if (pending) {
            instanceOfAxios.get(`admin/agency/${props.agency}/electricity?type=${props.type.type}`)
                .then(res => {
                    setData(Object.assign([], res.data.electricity_values));
                })
            setPending(false);
        }
    }, [pending]);

    function deleteRow(id){
        instanceOfAxios.delete(`admin/agency/${props.agency}/electricity/${id}`)
        .then(res => {
            setPending(true);
            dispatch(show({ message: 'Tarif supprimé', variant: 'success' }));
        })
        .catch(error => {
            dispatch(show({ message: 'ESuppression impossible', variant: 'danger' }));
        })
    }

    function priceFormatter(cell, row) {
        return parseFloat(cell).toFixed(2) + ' €';
    }
    
    function EditFormateur(cell, row) {
        return (
            <EditOrCreateElectricity taxe_type_id={props.type.id} electricity={row} agency={props.agency} handleChange={setPending} for='edit' />
        );
    }

    function DeleteFormateur(cell, row) {
        return (
            <DeleteModal deleteFunction={() => deleteRow(row.id)} model={row}></DeleteModal>
        );
    }

    return (
        <>
            <Row className='mt-5'>
                <Col md={12} className='text-center'>
                    <h3>Electricité</h3>
                </Col>
            </Row>
            <Row>
                <Col md={12} className='text-center'>
                    <EditOrCreateElectricity agency={props.agency} taxe_type_id={props.type.id} handleChange={setPending} />
                </Col>
            </Row>
            <Row className='justify-content-center mt-3'>
                <Col xs={12} lg={6} className='text-center'>
                    <BootstrapTable data={data} pagination>
                        <TableHeaderColumn isKey dataField='type' width='120'>Logement</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={priceFormatter} dataField='value' width='180'>Tarif Journalier</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={EditFormateur} width='100'>Modifier</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={DeleteFormateur} width='120'>Supprimer</TableHeaderColumn>
                    </BootstrapTable>
                </Col>
            </Row>
        </>
    );
}