import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import CommentHidden from './tab/commentHidden';
import CommentOnHome from './tab/commentOnHome';
import CommentPublic from './tab/commentPublic';
import { useDispatch, useStore } from "react-redux";
import instanceOfAxios from "../../../app/axios/axios";
import { show } from '../../admin/Alerter/alerterSlice';
import { useState } from 'react';
import CommentStats from './tab/commentStats';
import CommentCreate from './tab/commentCreate';

export default function CommentTab(props) {
    const state = useStore().getState();
    const role = state.header.role;
    const dispatch = useDispatch();
    const [reload, setReload] = useState(false);

    function forPublishOrUnPublish(id, loadFunction) {
        loadFunction(true);
        instanceOfAxios.get('admin/comment/' + id + '/forPublishOrUnPublish')
            .then(res => {
                dispatch(show({ message: 'Sauvegarde effectuée', variant: 'success' }));
                setReload(true);
                loadFunction(false);
            })
            .catch(err => {
                dispatch(show({ message: 'Sauvegarde échoué', variant: 'danger' }));
                loadFunction(false);
            });
    }

    function forOnHome(id, loadFunction) {
        loadFunction(true);
        instanceOfAxios.get('admin/comment/' + id + '/forOnHome')
            .then(res => {
                dispatch(show({ message: 'Sauvegarde effectuée', variant: 'success' }));
                setReload(true);
                loadFunction(false);
            })
            .catch(err => {
                let message = err.response.status == 300 ? err.response.data.error : 'Sauvegarde échoué'
                dispatch(show({ message: message, variant: 'danger' }));
                loadFunction(false);
            });
    }

    function RemoveforOnHome(id, loadFunction) {
        loadFunction(true);
        instanceOfAxios.get('admin/comment/' + id + '/RemoveforOnHome')
            .then(res => {
                dispatch(show({ message: 'Sauvegarde effectuée', variant: 'success' }));
                setReload(true);
                loadFunction(false);
            })
            .catch(err => {
                dispatch(show({ message: 'Sauvegarde échoué', variant: 'danger' }));
                loadFunction(false);
            });
    }

    return (
        <div className='users slider-list-left slide-in-list-left'>
            <Row>
                <Col md={12} className='text-center'>
                    <h2>Gestion des commentaires</h2>
                </Col>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%'
                        }}></div>
                </Col>
            </Row>
            <Tabs defaultActiveKey="commentHidden" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="commentHidden" title="Commentaires à valider / cachés">
                    <CommentHidden setReload={setReload} reload={reload} forPublishOrUnPublish={forPublishOrUnPublish} forOnHome={forOnHome} RemoveforOnHome={RemoveforOnHome} />
                </Tab>
                <Tab eventKey="commentPublic" title="Commentaires affichés sur logement">
                    <CommentPublic setReload={setReload} reload={reload} forPublishOrUnPublish={forPublishOrUnPublish} forOnHome={forOnHome} RemoveforOnHome={RemoveforOnHome} />
                </Tab>
                <Tab eventKey="commentOnHome" title="Commentaires sur page accueil">
                    <CommentOnHome setReload={setReload} reload={reload} forPublishOrUnPublish={forPublishOrUnPublish} forOnHome={forOnHome} RemoveforOnHome={RemoveforOnHome} />
                </Tab>
                {
                    role === 'super-admin' ?
                        <Tab eventKey="commentstats" title="Statistiques">
                            <CommentStats setReload={setReload} reload={reload} />
                        </Tab>
                        :
                        <></>
                }
                <Tab eventKey="createComment" title="Création">
                    <CommentCreate setReload={setReload} />
                </Tab>
            </Tabs>
        </div>
    )
}