import { Badge, Col, Row, Modal, Button } from 'react-bootstrap';
import AddFile from './addFile';
import DateViewer from './dateViewer';
import { useState } from 'react';
import AwaitBooking from '../awaitBooking/awaitBooking';
import Info from './Info';
import AccountingModal from '../AccountingModal/AccountingModal';

export default function ApartmentCard(props) {
    const [visibility, setVisibility] = useState(false);

    return (
        <>
            <Col
                xs={12}
                sm={6}
                className='menuItem d-flex justify-content-center align-items-end'
                onClick={() => setVisibility(true)}>
                <div 
                style={{ background: 'url(' + (props.apartment.principal_picture_url ? props.apartment.principal_picture_url.medium : '') + ') center' , backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}
                className='container-text d-flex align-items-center justify-content-center'>
                    <h2>Logement : {props.apartment.reference}</h2>
                </div>
            </Col>
            <Modal 
                backdropClassName="backdrop"
                show={visibility}
                onHide={() => setVisibility(false)}
                centered
                scrollable
                size='xl'
                style={{zIndex: '20000'}}>
                <Modal.Header closeButton>
                    <Row style={{ width: '100%' }} className='d-flex justify-content-around'>
                        <Col xs={9}>
                            <Modal.Title>Logement : {props.apartment.reference}</Modal.Title>
                        </Col>
                        <Col xs={3} className='d-flex align-items-center'>
                            <div>
                                {props.apartment.supplementary_information && props.apartment.supplementary_information.is_publish ?
                                    <Badge pill variant="success">Publié</Badge>
                                    :
                                    <Badge pill variant="danger">Non publié</Badge>}
                            </div>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Row className='d-flex justify-content-center'>
                        <DateViewer type='card' apartment={props.apartment} handleChange={props.handleChange}/>
                        <AddFile apartment={props.apartment}/>
                        <Info apartment={props.apartment}/>
                        <AccountingModal apartment={props.apartment}/>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-around'>
                    <AwaitBooking apartment={props.apartment}/>
                    <DateViewer apartment={props.apartment} handleChange={props.handleChange}/>
                </Modal.Footer>
            </Modal>
        </>
    )
}