import { Col, Row } from 'react-bootstrap';
import { BsPersonFill } from 'react-icons/bs';
import { MdOutlineBedroomParent } from 'react-icons/md';
import './selectionCard.scss';

export default function SelectionCard(props) {
    function nbRoom(apartment) {
        let nbRoom = apartment.nb_room - 1
        if (nbRoom == 0) {
            return 'Studio';
        } else {
            return nbRoom > 1 ? nbRoom + '\nChambres' : nbRoom + '\nChambre';
        }
    }

    return (
        <div className={'selectionCard ' + props.className} name='apart'>
            <img alt={`photo logement ${props.apartment.reference} pour permettre la location saisonière ou courte durée`} src={props.apartment.principal_picture_url.medium} loading="lazy"/>
            <div style={{ height: '32vh' }}>
                <div className='selectionContentCard'>
                       <div className='shortDescriptionSelection'>
                            <h3 className='apartTitleSelection'>{props.apartment.type == 'Villa' ? 'MAISON' : 'APPART'} : {props.apartment.reference}</h3>
                            <Row>
                                <Col xs={6} className='reducePadding'>
                                    <Col xs={12} className="d-flex justify-content-center" >
                                        <BsPersonFill style={{ height: '25px', width: '25px', color: '#B7A57E' }} />
                                    </Col>
                                    <Col xs={12} className="d-flex justify-content-center" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <p className='text-center'>
                                            {props.apartment.nb_person} {props.apartment.nb_person > 1 ? 'Personnes' : 'Personne'}
                                        </p>
                                    </Col>
                                </Col>
                                <Col xs={6} className='reducePadding'>
                                    <Col xs={12} className="d-flex justify-content-center test">
                                        <MdOutlineBedroomParent style={{ height: '25px', width: '40px', color: '#B7A57E' }} />
                                    </Col>
                                    <Col xs={12} className="d-flex justify-content-center" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <p className='text-center'>
                                            {nbRoom(props.apartment)}
                                        </p>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <p className='shortDescritption'>
                                        {props.apartment.short_description}
                                    </p>
                                </Col>
                            </Row>
                        </div>
                      <div className='d-flex flex-column justify-content-center align-items-center discoverButton absoluteSelection'>
                            <a href={'/apartment/' + props.apartment.id} className='d-flex flex-column justify-content-center align-items-center' id="searchbutton" size="lg" >DÉCOUVRIR</a>
                        </div>
                </div> 
            </div>
        </div>
    )
}