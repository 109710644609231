import { useEffect, useState } from "react";
import instanceOfAxios from "../../../app/axios/axios";
import BookingCard from './bookingCard';
import Spinner from '../../spinner/Spinner';
import { Modal, Button, Row } from 'react-bootstrap';

export default function AwaitBooking(props) {
    const [bookings, setBookings] = useState([]);
    const [pending, setPending] = useState(true);
    const [visibility, setVisibility] = useState(false);

    useEffect(() => {
        instanceOfAxios.get('/owner/bookings?ref=' + props.apartment.reference)
        .then((res) => {
            setPending(false);
            setBookings(res.data.bookings);
        })
    },[]);

    function handleChange(id) {
        setPending(true);
        instanceOfAxios.get('/owner/bookings?ref=' + props.apartment.reference)
            .then((res) => {
                setPending(false);
                res = res.data.bookings.filter((booking) => booking.id != id);
                setBookings(res);
            })
    }

    function mapBookings() {
        if (bookings.length == 0) {
            return (
                <div className='center' style={{ padding: '1vh' }}>
                    <h2>Aucune Réservation à afficher</h2>
                </div>
            )
        } else {
            return bookings.map((booking) => <BookingCard booking={booking} handleChange={handleChange} />)
        }
    }

    return (
        <>
            <Button className='goldenHover' onClick={() => setVisibility(true)}>
                Réservations à confirmer
            </Button>
            <Modal
                show={visibility}
                onHide={() => setVisibility(false)}
                centered
                scrollable
                size='xl'
                backdropClassName="backdrop"
                style={{ zIndex: '20000' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Réservation en attente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="d-flex justify-content-center aligns-items-center">
                        {pending ?
                            <Spinner />
                            :
                            mapBookings()
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-around'>
                    <Button className='goldenHover' onClick={() => setVisibility(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}