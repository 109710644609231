import { useState } from "react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Button, Col, Modal, Row } from "react-bootstrap";
import InvoiceModal from "./invoices/invoiceModal";

export default function AccountingDetail(props) {
    const [visibility, setVisibility] = useState(false);

    function createCustomExportCSVButton(onClick) {
        return (
            <Button className='goldenHover' onClick={onClick}>Exporter au format CSV</Button>
        );
    }

    function priceFormatter(cell, row) {
        return parseFloat(cell).toFixed(2) + ' €';
    }

    function dateFormatter(cell, row) {
        const date = new Date(cell.replace(/\s/, 'T'));
        var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return date.toLocaleDateString('fr-CA', options);
    }

    const customBtn = {
        exportCSVBtn: createCustomExportCSVButton
    };

    function getArray() {
        let total = 0;
        props.booking.map(data => total += data.price + data.electricity);
        props.options.map(data => total -= data.amount);
        return (
            <>
                <Col xs={12} className='d-flex justify-content-end align-items-end'>
                    <InvoiceModal id={props.id} month={props.monthIndex}/>
                </Col>
                <Col lg={12}>
                    <h5 className='text-center'>Loyers</h5>
                    <BootstrapTable data={props.booking} options={customBtn} hover exportCSV csvFileName='Loyers.csv'>
                        <TableHeaderColumn dataField='begin' csvHeader='Début' dataFormat={dateFormatter} className='text-center'>Début</TableHeaderColumn>
                        <TableHeaderColumn dataField='end' csvHeader='Fin' dataFormat={dateFormatter} className='text-center'>Fin</TableHeaderColumn>
                        <TableHeaderColumn isKey dataField='price' csvHeader='Loyer' dataFormat={priceFormatter} className='text-center'>Prix</TableHeaderColumn>
                        <TableHeaderColumn dataField='length' csvHeader='Nuité' className='text-center'>Nuité</TableHeaderColumn>
                        <TableHeaderColumn dataField='electricity' csvHeader='Charges' dataFormat={priceFormatter} className='text-center'>Charges</TableHeaderColumn>
                    </BootstrapTable>
                </Col>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%',
                            marginBottom: '20px',
                            marginTop: '20px'
                        }}></div>
                </Col>
                <Col lg={12}>
                    <h5 className='text-center'>Ajout ou retenues</h5>
                    <BootstrapTable data={props.options} options={customBtn} hover exportCSV csvFileName='Retenues.csv'>
                        <TableHeaderColumn isKey dataField='reason' csvHeader='Raison' className='text-center'>Raison</TableHeaderColumn>
                        <TableHeaderColumn dataField='amount' csvHeader='Montant' dataFormat={priceFormatter} className='text-center'>Montant</TableHeaderColumn>
                    </BootstrapTable>
                </Col>
                <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%',
                            marginBottom: '20px',
                            marginTop: '20px'
                        }}></div>
                </Col>
                <Col sm={12} className='d-flex justify-content-center'>
                    <p>Total : <strong>{(total).toFixed(2)}€</strong></p>
                </Col>
            </>
        )
    }

    return (
        <>
            <Button className='goldenHover' onClick={() => setVisibility(true)}>Détails</Button>
            <Modal
                backdropClassName="backdropthird"
                show={visibility}
                onHide={() => setVisibility(false)}
                centered
                scrollable
                size='xl'
                style={{ zIndex: '20003' }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Détails des loyers mois de {props.month}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='d-flex justify-content-center'>
                        <Row style={{ marginTop: '5vh' }}>
                            {
                                getArray()
                            }
                        </Row>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}