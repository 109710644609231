import React from 'react';
import { Button, Image } from 'react-bootstrap'

export default function NoMatch() {
  return (
    <div className="container-fluid">
      <div className="container-fluid">
        <div className="text-center" style={{ margin: '2vh' }}>
          La page que vous recherchez n'existe malheureusement pas.
        </div>
        <div className="text-center">
          <Button href="/" className='goldenHover' style={{ margin: '2vh' }}>Accueil</Button>
        </div>
        <div className="text-center">
          <Image loading='lazy' src="404.gif" className="center-block" alt="" fluid></Image>
        </div>
      </div>
    </div>
  );
}