import { Button, Modal, Col } from "react-bootstrap";
import { useState } from "react"
import CleaningTable from "../../../cleaning/cleaningTable";
import CommissionTable from '../../../commission/commissionTable';
import Assurance from '../../../assurance/assuranceTable';
import ElectricityTable from "../../../electricity/electricityTable";
import { FaPencilAlt, FaRegSave } from 'react-icons/fa';

export default function AgencyTaxeType(props){
    const [visibility, setVisibility] = useState(false);

    return (
        <Col xs={12} className='d-flex justify-content-center align-items-center'>
            <Button variant='secondary' className='whiteSVG mt-3' onClick={() => setVisibility(true)}><FaPencilAlt /> Modifier les Tarifs</Button>
            <Modal
                 dialogClassName='custom-dialog'
                 show={visibility}
                 onHide={() => setVisibility(false)}
                 scrollable
                 backdropClassName="backdropFourth"
                 centered
                 size='xl'
                 style={{ zIndex: '20004' }}
                animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Taxe de l'agence de : {props.location}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CommissionTable type={props.data} agency={props.agency}/>
                    <Assurance type={props.data} agency={props.agency}/>
                    <CleaningTable type={props.data} agency={props.agency}/>
                    <ElectricityTable type={props.data} agency={props.agency}/>
                </Modal.Body>
            </Modal>
        </Col>
    )
}