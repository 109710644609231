import React from 'react';
import { useDispatch, connect } from 'react-redux';
import StepOne from './formStep1';
import StepTwo from '../form/formStep2';
import StepThree from '../form/formStep3';
import StepFour from '../form/formStep4';
import StepFive from '../form/formStep5';
import StepSix from '../form/formStep6';
import StepSeven from '../form/formStep7';
import StepEight from '../form/formStep8';
import StepNine from '../form/formStep9';
import StepTen from '../form/formStep10';
import {
    getType,
    fetchType,
    fetchNbPersons,
    fetchOwners,
    getStep
} from './apartmentFormSlice';
import '../Apartments.scss';
import '../../users/users.scss';

const ApartmentForm = ({type, step }) => {
    const dispatch = useDispatch();
    if (type === undefined) {
        dispatch(fetchType());
        dispatch(fetchNbPersons());
        dispatch(fetchOwners());
    }

    switch(step){
        case 1:
            return <StepOne/>;
        case 2 :
            return <StepTwo/>;
        case 3 :
            return <StepThree/>;
        case 4 :
            return <StepFour/>;
        case 5 :
            return <StepFive/>;
        case 6 :
            return <StepSix/>;
        case 7 :
            return <StepSeven/>;
        case 8 :
            return <StepEight/>;
        case 9 :
            return <StepNine/>;
        case 10 :
            return <StepTen/>;
    }
}

const mapStateToProps = state => {
    return {
        type: getType(state),
        step: getStep(state)
    };
}

export default connect(mapStateToProps)(ApartmentForm);