import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import '../checkIn.scss';
import { useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap'
import DrawTextModal from './drawTextModal';
import { useEffect } from 'react';
import instanceOfAxios from '../../../../../../app/axios/axios';
import Spinner from '../../../../../spinner/Spinner';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function GenericModifyPdf(props) {
    const [pageNumber, setPageNumber] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [modalVisibility, setModalVisibility] = useState(false);
    const [positions, setPositions] = useState({ x: 0, y: 0 });
    const [file, setFile] = useState();
    const [isSubmit, setIsSubmit] = useState(false);
    const [classNamePage, setClassNamePage] = useState();

    useEffect(() => {
        instanceOfAxios.get(props.file, {
            headers: { 'Cache-Control': 'no-cache' },
            responseType: 'blob',
        })
            .then((res) => setFile(new Blob([res.data], { type: 'application/pdf' })))
    }, []);

    function hideDrawTextModal() {
        instanceOfAxios.get(props.file, {
            headers: { 'Cache-Control': 'no-cache' },
            responseType: 'blob',
        })
            .then((res) => {
                setIsSubmit(false);
                setFile(new Blob([res.data], { type: 'application/pdf' }))
                setModalVisibility(false);
            })
    }

    function next() {
        if (pageNumber < maxPage) {
            setPageNumber(pageNumber + 1);
            setClassNamePage();
        }
    }

    function prev() {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
            setClassNamePage();
        }
    }

    function onMouseMove(e) {
        let rect = e.currentTarget.getBoundingClientRect();
        let x = e.clientX - rect.left;
        let y = e.clientY - rect.top;
        setPositions({ x: x, y: y });
        setModalVisibility(true);
    };

    return (
        <>
            <Row>
                <Col xs={6} className='d-flex justify-content-center'>
                    <Button onClick={prev}>précedent</Button>
                </Col>
                <Col xs={6} className='d-flex justify-content-center'>
                    <Button onClick={next}>suivant</Button>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <p>{pageNumber}/{maxPage}</p>
            </Row>
            {
                file ?
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Document
                            className={classNamePage}
                            file={URL.createObjectURL(file)}
                            onLoadSuccess={(pdf) => {
                                setMaxPage(pdf._pdfInfo.numPages);
                                setClassNamePage('page');
                            }}
                            loading={<div className='loading d-flex justify-content-center align-items-center'><Spinner /></div>}
                        >
                            <Page pageNumber={pageNumber} onMouseDown={onMouseMove}/>
                        </Document>
                    </Row>
                    :
                    <></>
            }
            <DrawTextModal
                visibility={modalVisibility}
                positions={positions}
                file={props.file}
                pageNumber={pageNumber}
                onHide={hideDrawTextModal}
                type={props.type}
                updateUrl={props.updateUrl}
                isSubmit={isSubmit}
                setSubmit={setIsSubmit} />
        </>
    )
}