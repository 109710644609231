import React from 'react';

class Spinner extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
                <div className="spinner-border" role="status"></div>
        );
    }
}

export default Spinner;