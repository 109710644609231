import { useEffect, useState } from "react"
import instanceOfAxios from "../../../../app/axios/axios";
import { Row, Col, Form, Button } from 'react-bootstrap';
import './commentStats.scss'
import UserCleaningStatsCard from "./userStatsCard/userCleaningStatsCard";
import UserCheckInStatsCard from "./userStatsCard/userCheckInStatsCard";
import Skeleton from '@material-ui/lab/Skeleton';
import ListNotAttributed from './listNotAttributed/ListNotAttributed';
import moment from "moment/moment";
import { AiOutlineSearch } from 'react-icons/ai';

export default function CommentStats(props) {
    const [pending, setPending] = useState(true);
    const [searchParams, setSearchParams] = useState({ begin_date: moment().startOf('year').format(), end_date: moment().endOf('year').format() });
    const [dataSort, setDataSort] = useState({});

    useEffect(() => {
        if (pending) {
            let params = new URLSearchParams(searchParams).toString()
            instanceOfAxios.get('/admin/comment/indexAdmin?' + params)
                .then(res => {
                    handleSort(res.data.comments);
                })
        }
    }, [pending])

    useEffect(() => {
        if (props.reload) {
            setPending(true);
        }
    }, [props.reload]);

    function handleSort(comments) {
        let datas = {
            check_in_count_not_attributed: 0,
            check_in_not_attributed: [],
            cleaning_count_not_attributed: 0,
            cleaning_not_attributed: [],
            both_count_not_attributed: 0,
            both_not_attributed: [],
            cleaning_attributed: {},
            check_in_attributed: {},
            global_check_in: {},
            global_cleaning: {}
        };
        comments.map(comment => {
            let content = JSON.parse(comment.content);
            if (!content.check_in) {
                datas.check_in_count_not_attributed += 1;
                datas.check_in_not_attributed.push(comment);
            }
            if (!content.cleaning) {
                datas.cleaning_count_not_attributed += 1;
                datas.cleaning_not_attributed.push(comment);
            }
            if (!content.check_in && !content.cleaning) {
                datas.both_count_not_attributed += 1;
                datas.both_not_attributed.push(comment);
            }

            if (content.cleaning) {
                if (!datas.cleaning_attributed[content.cleaning]) {
                    datas.cleaning_attributed[content.cleaning] = { comments: [] };
                }
                // on calcule les stats par personne pour le ménage
                datas.cleaning_attributed[content.cleaning]['living_room'] = (datas.cleaning_attributed[content.cleaning]['living_room'] || 0) + parseInt(content.cleaning_score.living_room_score);
                datas.cleaning_attributed[content.cleaning]['kitchen'] = (datas.cleaning_attributed[content.cleaning]['kitchen'] || 0) + parseInt(content.cleaning_score.kitchen_score);
                datas.cleaning_attributed[content.cleaning]['room'] = (datas.cleaning_attributed[content.cleaning]['room'] || 0) + parseInt(content.cleaning_score.room_score);
                datas.cleaning_attributed[content.cleaning]['floor'] = (datas.cleaning_attributed[content.cleaning]['floor'] || 0) + parseInt(content.cleaning_score.floor_score);
                datas.cleaning_attributed[content.cleaning]['bathroom'] = (datas.cleaning_attributed[content.cleaning]['bathroom'] || 0) + parseInt(content.cleaning_score.bathroom_score);
                datas.cleaning_attributed[content.cleaning]['toilet'] = (datas.cleaning_attributed[content.cleaning]['toilet'] || 0) + parseInt(content.cleaning_score.toilet_score);
                datas.cleaning_attributed[content.cleaning]['outside'] = (datas.cleaning_attributed[content.cleaning]['outside'] || 0) + parseInt(content.cleaning_score.outside_score);
                datas.cleaning_attributed[content.cleaning]['total'] = (datas.cleaning_attributed[content.cleaning]['total'] || 0) + 1;
                datas.cleaning_attributed[content.cleaning].comments.push(comment);
            }

            if (content.check_in) {
                if (!datas.check_in_attributed[content.check_in]) {
                    datas.check_in_attributed[content.check_in] = { comments: [] };
                }

                // on calcule les stats par personnes pour les check-in
                datas.check_in_attributed[content.check_in]['score'] = (datas.check_in_attributed[content.check_in]['score'] || 0) + parseInt(content.check_in_score.score);
                datas.check_in_attributed[content.check_in]['total'] = (datas.check_in_attributed[content.check_in]['total'] || 0) + 1;
                datas.check_in_attributed[content.check_in].comments.push(comment);
            }

            // on calcule les stats globales pour le ménage 
            datas.global_cleaning['living_room'] = (datas.global_cleaning['living_room'] || 0) + parseInt(content.cleaning_score.living_room_score);
            datas.global_cleaning['kitchen'] = (datas.global_cleaning['kitchen'] || 0) + parseInt(content.cleaning_score.kitchen_score);
            datas.global_cleaning['room'] = (datas.global_cleaning['room'] || 0) + parseInt(content.cleaning_score.room_score);
            datas.global_cleaning['floor'] = (datas.global_cleaning['floor'] || 0) + parseInt(content.cleaning_score.floor_score);
            datas.global_cleaning['bathroom'] = (datas.global_cleaning['bathroom'] || 0) + parseInt(content.cleaning_score.bathroom_score);
            datas.global_cleaning['toilet'] = (datas.global_cleaning['toilet'] || 0) + parseInt(content.cleaning_score.toilet_score);
            datas.global_cleaning['outside'] = (datas.global_cleaning['outside'] || 0) + parseInt(content.cleaning_score.outside_score);
            datas.global_cleaning['total'] = (datas.global_cleaning['total'] || 0) + 1;

            // on calcule les stats globales pour les check-in
            datas.global_check_in['score'] = (datas.global_check_in['score'] || 0) + parseInt(content.check_in_score.score);
            datas.global_check_in['total'] = (datas.global_check_in['total'] || 0) + 1;
        });
        setDataSort(datas);
        setPending(false);
    }

    function handleChange(e) {
        let params = Object.assign({}, searchParams);
        let date = moment(e.target.value).format();
        params[e.target.name] = date;
        setSearchParams(params);
    }

    return (
        pending ?
            <Row className='d-flex justify-content-around pb-5'>
                <Col xs={12}>
                    <h2 style={{ color: 'red' }}>Plus le nombre de commentaires traités est grand, plus le temps d'affichage sera long</h2>
                </Col>
                <Col xs={12}>
                    <Skeleton variant="rectangular" width={'100%'} height={500} />
                </Col>
            </Row>
            :
            <>
                <Row>
                    <Col xs={12}>
                        <h2>Date de recherche</h2>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center justify-content-center'>
                    <Col xs={12} md={4}>
                        <Form.Group>
                            <Form.Label>Début</Form.Label>
                            <Form.Control type='date' name='begin_date' size="sm" defaultValue={searchParams.begin_date.split('T')[0]} onChange={(e) => handleChange(e)} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Group>
                            <Form.Label>Fin</Form.Label>
                            <Form.Control type='date' name='end_date' size="sm" defaultValue={searchParams.end_date.split('T')[0]} onChange={(e) => handleChange(e)} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} className='d-flex justify-content-center justify-content-center mt-3'>
                        <Button className='goldenHover whiteSVG' onClick={() => setPending(true)}>Rechercher <AiOutlineSearch/></Button>
                    </Col>
                    <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                        <div className='separator'
                            style={{
                                border: '1px solid #B7A57E',
                                color: '#b7a57e',
                                width: '90%',
                                marginBottom: '20px',
                                marginTop: '20px'
                            }}></div>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-around pb-5'>
                    <Col xs={12}>
                        <h2>Statistiques globales</h2>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-around global-not-attributed pt-5 pb-5'>
                    <Col xs={11} className='text-center'>
                        <h3>Commentaires non attribuées</h3>
                    </Col>
                    <Col xs={11} md={4} lg={2} className='total-not-attributed text-center pt-2 pb-2 m-2'>
                        <p>Commentaires sans aucune personnes attribués</p>
                        <h3><strong>{dataSort.both_count_not_attributed}</strong></h3>
                        <ListNotAttributed comments={dataSort.both_not_attributed} setReload={props.setReload} />
                    </Col>
                    <Col xs={11} md={4} lg={2} className='total-not-attributed text-center pt-2 pb-2 m-2'>
                        <p>Commentaires sans personne du ménage attribués</p>
                        <h3><strong>{dataSort.cleaning_count_not_attributed}</strong></h3>
                        <ListNotAttributed comments={dataSort.cleaning_not_attributed} setReload={props.setReload} for={'ménages'} />
                    </Col>
                    <Col xs={11} md={4} lg={2} className='total-not-attributed text-center pt-2 pb-2 m-2'>
                        <p>Commentaires sans personne du check-in attribués</p>
                        <h3><strong>{dataSort.check_in_count_not_attributed}</strong></h3>
                        <ListNotAttributed comments={dataSort.check_in_not_attributed} setReload={props.setReload} for={'check-in'} />
                    </Col>
                    <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                        <div className='separator'
                            style={{
                                border: '1px solid #B7A57E',
                                color: '#b7a57e',
                                width: '90%',
                                marginBottom: '20px',
                                marginTop: '20px'
                            }}></div>
                    </Col>
                    <Col xs={11} className='text-center'>
                        <h3>Moyenne des scores de ménages</h3>
                    </Col>
                    <Col xs={12} className='text-center justify-content-center'>
                        <h5>Moyenne calculé sur un total de {dataSort.global_cleaning.total} commentaires</h5>
                    </Col>
                    <Col xs={11} md={2} className='text-center total-not-attributed m-2 pt-3 pb-3 d-flex flex-column justify-content-center'>
                        <p>Salon :</p>
                        <strong>{dataSort.global_cleaning ? (dataSort.global_cleaning.living_room / dataSort.global_cleaning.total).toFixed(2) : ' '}/5</strong>
                    </Col>
                    <Col xs={11} md={2} className='text-center total-not-attributed m-2 pt-3 pb-3 d-flex flex-column justify-content-center'>
                        <p>Cuisine :</p>
                        <strong>{dataSort.global_cleaning ? (dataSort.global_cleaning.kitchen / dataSort.global_cleaning.total).toFixed(2) : ' '}/5</strong>
                    </Col>
                    <Col xs={11} md={2} className='text-center total-not-attributed m-2 pt-3 pb-3 d-flex flex-column justify-content-center'>
                        <p>Chambre :</p>
                        <strong>{dataSort.global_cleaning ? (dataSort.global_cleaning.room / dataSort.global_cleaning.total).toFixed(2) : ' '}/5</strong>
                    </Col>
                    <Col xs={11} md={2} className='text-center total-not-attributed m-2 pt-3 pb-3 d-flex flex-column justify-content-center'>
                        <p>toilette :</p>
                        <strong>{dataSort.global_cleaning ? (dataSort.global_cleaning.toilet / dataSort.global_cleaning.total).toFixed(2) : ' '}/5</strong>
                    </Col>
                    <Col xs={11} md={2} className='text-center total-not-attributed m-2 pt-3 pb-3 d-flex flex-column justify-content-center'>
                        <p>Salle de bain :</p>
                        <strong>{dataSort.global_cleaning ? (dataSort.global_cleaning.bathroom / dataSort.global_cleaning.total).toFixed(2) : ' '}/5</strong>
                    </Col>
                    <Col xs={11} md={2} className='text-center total-not-attributed m-2 pt-3 pb-3 d-flex flex-column justify-content-center'>
                        <p>Sol :</p>
                        <strong>{dataSort.global_cleaning ? (dataSort.global_cleaning.floor / dataSort.global_cleaning.total).toFixed(2) : ' '}/5</strong>
                    </Col>
                    <Col xs={11} md={2} className='text-center total-not-attributed m-2 pt-3 pb-3 d-flex flex-column justify-content-center'>
                        <p>Exterieur :</p>
                        <strong>{dataSort.global_cleaning ? (dataSort.global_cleaning.outside / dataSort.global_cleaning.total).toFixed(2) : ' '}/5</strong>
                    </Col>
                    <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                        <div className='separator'
                            style={{
                                border: '1px solid #B7A57E',
                                color: '#b7a57e',
                                width: '90%',
                                marginBottom: '20px',
                                marginTop: '20px'
                            }}></div>
                    </Col>
                    <Col xs={11} className='text-center'>
                        <h3>Moyenne des scores de check-in</h3>
                    </Col>
                    <Col xs={12} className='text-center justify-content-center'>
                        <h5>Moyenne calculé sur un total de {dataSort.global_check_in.total} commentaires</h5>
                    </Col>
                    <Col xs={11} md={2} className='text-center total-not-attributed m-2 pt-3 pb-3 d-flex flex-column justify-content-center'>
                        <p>Indice de satisfaction :</p>
                        <strong>{dataSort.global_check_in ? (dataSort.global_check_in.score / dataSort.global_check_in.total).toFixed(2) : ' '}/10</strong>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-around pt-5 pb-5'>
                    <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                        <div className='separator'
                            style={{
                                border: '1px solid #B7A57E',
                                color: '#b7a57e',
                                width: '90%',
                                marginBottom: '20px',
                                marginTop: '20px'
                            }}></div>
                    </Col>
                    <Col xs={12}>
                        <h2>Statistiques par personnes renseignées pour les ménages</h2>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-around global-not-attributed pt-5 pb-5'>
                    {
                        dataSort.cleaning_attributed &&
                        Object.keys(dataSort.cleaning_attributed).map(key =>
                            <UserCleaningStatsCard dataSort={dataSort} index={key} setReload={props.setReload} />
                        )
                    }
                </Row>
                <Row className='d-flex justify-content-around pt-5 pb-5'>
                    <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                        <div className='separator'
                            style={{
                                border: '1px solid #B7A57E',
                                color: '#b7a57e',
                                width: '90%',
                                marginBottom: '20px',
                                marginTop: '20px'
                            }}></div>
                    </Col>
                    <Col xs={12}>
                        <h2>Statistiques par personnes renseignées pour les check-in</h2>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-around global-not-attributed pt-5 pb-5'>
                    {
                        dataSort.check_in_attributed &&
                        Object.keys(dataSort.check_in_attributed).map(key =>
                            <UserCheckInStatsCard dataSort={dataSort} index={key} setReload={props.setReload} />
                        )
                    }
                </Row>
            </>
    )
}