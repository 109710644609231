import { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap";
import instanceOfAxios from "../../../../app/axios/axios";
import InvoiceList from '../../../admin/invoice/invoiceList';
import { MdOutlineFormatListNumberedRtl } from 'react-icons/md';

export default function InvoiceModal(props) {
    const [visibility, setVisibility] = useState(false);
    const [pending, setPending] = useState(true);
    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        if (pending) {
            instanceOfAxios.get(`admin/year_rent/${props.id}/getInvoiceUrl/${props.month}`)
                .then(res => {
                    setPending(false);
                    setInvoices(res.data.urls)
                })
        }
    }, [pending]);

    return (
        <>
            <Button variant='secondary' className='whiteSVG' onClick={() => setVisibility(!visibility)}><MdOutlineFormatListNumberedRtl /> Liste des factures</Button>
            <Modal
                scrollable
                centered
                backdropClassName="backdropFourth"
                style={{ zIndex: '20004' }}
                size='lg'
                show={visibility}
                onHide={() => setVisibility(!visibility)}>
                <Modal.Header closeButton>
                    <Modal.Title className='d-flex justify-content-around' style={{ width: '100%' }}>
                        Liste des factures
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InvoiceList invoices={invoices} />
                </Modal.Body>
            </Modal>
        </>
    )
}