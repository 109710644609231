import { useState } from "react";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
export default function ShareButton(props) {
    const [uncollapse, setUncollapse] = useState(false);
    const url = `www.class-appart.com/${window.location.pathname}`;
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="52.412" height="52.412" viewBox="0 0 52.412 52.412" className="shareButton svgDisplay" onClick={() => setUncollapse(!uncollapse)}>
                <g id="Picto_partage" transform="translate(-1399 -467)">
                    <g id="Ellipse_11" data-name="Ellipse 11" transform="translate(1399 467)" fill="#b7a57e" stroke="#fff" stroke-width="2">
                        <circle cx="26.206" cy="26.206" r="26.206" stroke="none" />
                    </g>
                    <path id="Icon_ionic-md-share" data-name="Icon ionic-md-share" d="M29.3,26.386a4.52,4.52,0,0,0-3.235,1.239L14.2,20.77a5.554,5.554,0,0,0,.166-1.157,5.551,5.551,0,0,0-.166-1.157L25.9,11.684A4.963,4.963,0,1,0,24.32,8.05a5.521,5.521,0,0,0,.166,1.157L12.793,15.979a4.992,4.992,0,0,0-3.4-1.321A4.91,4.91,0,0,0,4.5,19.613a4.993,4.993,0,0,0,8.376,3.635L24.652,30.1a4.143,4.143,0,0,0-.166,1.074,4.81,4.81,0,1,0,4.81-4.79Z" transform="translate(1405.82 473.906)" fill="#fff" />
                </g>
            </svg>
            <div className='position-absolute shareList transition-easy d-flex justify-content-md-end justify-content-center'>
                <FacebookShareButton
                    url={url}>
                    <FacebookIcon width={32} height={uncollapse ? 32 : 0} className='transition-easy' round/>
                </FacebookShareButton>

                <EmailShareButton
                    url={url}>
                    <EmailIcon width={32} height={uncollapse ? 32 : 0} className='transition-easy' round/>
                </EmailShareButton>

                <LinkedinShareButton
                    url={url}>
                    <LinkedinIcon width={32} height={uncollapse ? 32 : 0} className='transition-easy' round/>
                </LinkedinShareButton>

                <TwitterShareButton
                    url={url}>
                    <TwitterIcon width={32} height={uncollapse ? 32 : 0} className='transition-easy' round/>
                </TwitterShareButton>

                <WhatsappShareButton
                    url={url}>
                    <WhatsappIcon width={32} height={uncollapse ? 32 : 0} className='transition-easy' round/>
                </WhatsappShareButton>
            </div>
        </div>
    )
}