import { MdAccountCircle } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../headerSlice';
import '../header.scss';
import FormulaireConnexion from '../../connexion/FormulaireConnexion';
import FormulaireAccount from '../../formulaire/account/formulaireAccount';

export default function MyAccountButton(props) {
    const [visibility, setVisibility] = useState(false);
    const dispatch = useDispatch();

    const optionsFields = () => {
        if (props.role === 'super-admin' || props.role === 'admin' || props.role === 'trainee' || props.role === 'accountant') {
            return <li><a href="/AdminPannel">Menu Administrateur</a></li>
        }
    }

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('connectOrReset') && urlParams.get('actionType')) {
            setVisibility(true);
        }
    }, [])

    return (
        <div>
            <MdAccountCircle className="logo-account" onClick={() => setVisibility(!visibility)} />
            {
                visibility &&
                <div className='navItemDropDownContent d-flex justify-content-end'>
                    {
                        !props.isConnected ?
                            <>
                                <li>
                                    <FormulaireConnexion />
                                </li>
                                <li>
                                    <FormulaireAccount />
                                </li>
                            </>
                            :
                            <>
                                <>
                                    <li>
                                        <a href="/MonCompte">Mon compte</a>
                                    </li>
                                    {
                                        optionsFields()
                                    }
                                    <li>
                                        <p onClick={() => dispatch(logout())}>Déconnexion</p>
                                    </li>
                                </>
                            </>
                    }
                </div>
            }
        </div>
    )
}