import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { Col, Card, Row } from 'react-bootstrap';

export default function SkeletonCard(props) {
    return (
        <Card style={{ marginBottom: '10px' }}>
            <Row>
                <Col xs={12} sm={12} md={12} lg={7} className="d-flex align-items-center justify-content-center paddingRightImg">
                    <Skeleton animation='wave' variant="rect" width="100%" className="apartment-list-image">
                        <div style={{ paddingTop: '57%' }} />
                    </Skeleton>
                </Col>
                <Col xs={12} md={7} sm={12} lg={5}>
                    <Row>
                        <Typography variant="body1" style={{ width: "100%" }}>
                            <Skeleton animation='wave' />
                        </Typography>
                    </Row>
                    <Row>
                        <Typography variant="body1" style={{ width: "100%" }}>
                            <Skeleton animation='wave' />
                        </Typography>
                    </Row>
                    <Row>
                        <Typography variant="body1" style={{ width: "100%" }}>
                            <Skeleton animation='wave' />
                        </Typography>
                    </Row>
                    <Row>
                        <Typography variant="body1" style={{ width: "100%" }}>
                            <Skeleton animation='wave' />
                        </Typography>
                    </Row>
                    <Row>
                        <Typography variant="h3" style={{ width: "30%" }}>
                            <Skeleton animation='wave' />
                        </Typography>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}