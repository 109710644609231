import { Button, Modal, Row, Form, Col } from "react-bootstrap";
import DateRange from '../../../searchBar/dateRange/dateRange';
import { FaPencilAlt } from 'react-icons/fa'
import { useEffect, useState } from "react";
import { useDispatch, connect } from 'react-redux';
import {
    getBeginDate,
    getEndDate,
    resetDate
} from '../../../searchBar/dateRange/dateRangeSlice';
import moment from "moment";
import instanceOfAxios from "../../../../app/axios/axios";
import { show } from '../../Alerter/alerterSlice';
import Spinner from '../../../spinner/Spinner';

function EditBlocked(props) {
    const [visibility, setVisibility] = useState(false);
    const [pending, setPending] = useState(false);
    const [calendar, setCalendar] = useState(props.date);
    const dispatch = useDispatch();
    let beginHour = new Date(props.date.begin_date_hour.replace(/\s/, 'T')).getHours();
    let endHour = new Date(props.date.end_date_hour.replace(/\s/, 'T')).getHours();
    let error = [];

    useEffect(() => {
        let data = Object.assign({}, calendar);
        data.end_date_hour = props.end_date ? props.end_date : new Date(props.date.end_date_hour.replace(/\s/, 'T'));
        setCalendar(data);
    }, [props.end_date]);

    useEffect(() => {
        let data = Object.assign({}, calendar);
        data.begin_date_hour = props.begin_date ? props.begin_date : new Date(props.date.begin_date_hour.replace(/\s/, 'T'));
        setCalendar(data);
    }, [props.begin_date]);

    function handleCalendarChange(event) {
        let data = Object.assign({}, calendar);
        data[event.target.name] = event.target.value;
        setCalendar(data);
    }

    function handleCalendarSubmit() {
        setPending(true);
        let beginHour = new Date(props.date.begin_date_hour.replace(/\s/, 'T')).getHours();
        let endHour = new Date(props.date.end_date_hour.replace(/\s/, 'T')).getHours();

        let data = Object.assign({}, calendar);

        data.begin_date_hour = moment(new Date(calendar.begin_date_hour).setHours(data.begin_hour ? data.begin_hour : beginHour)).format('YYYY-MM-DD HH:MM:SS');
        data.end_date_hour = moment(new Date(calendar.end_date_hour).setHours(data.end_hour ? data.end_hour : endHour)).format('YYYY-MM-DD HH:MM:SS');

        data._method = 'PATCH'
        instanceOfAxios.post('/apartment/' + props.date.apartment_id + '/calendar/' + props.date.id, data)
        .then( res => {
            dispatch(show({ message: 'Sauvegarde effectuée', variant: 'success' }));
            props.handleChange();
            setVisibility(false);
            dispatch(resetDate());
            setPending(false);
        })
        .catch( err => {
            dispatch(show({ message: 'Erreur lors de la sauvegarde', variant: 'danger' }));
            setPending(false);
        })
    }

    return (
        <>
            <Button className='goldenHover' onClick={() => setVisibility(!visibility)}><FaPencilAlt /></Button>
            <Modal
                style={{ zIndex: '20001' }}
                size='xl'
                aria-labelledby="example-custom-modal-styling-title"
                centered
                backdropClassName="backdropThird"
                show={visibility}
                onHide={() => {
                    setVisibility(!visibility);
                    dispatch(resetDate());
                    setCalendar(props.date);
                    setPending(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Modifier un blocage
                    </Modal.Title>
                </Modal.Header>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Row>
                                <Form.Group as={Col} lg={4} md={12}>
                                    <Form.Label style={{ color: error['endtitle'] ? "red" : "" }}>Message *</Form.Label>
                                    <Form.Control id='endtitle' name='endtitle' size="sm" placeholder="Préparer du linge de lit" defaultValue={props.date && props.date.endtitle} onChange={(e) => handleCalendarChange(e)} />
                                </Form.Group>
                                <Form.Group as={Col} lg={4} md={12}>
                                    <Form.Label style={{ color: error['begin_hour'] ? "red" : "" }}>Heure d'arrivée *</Form.Label>
                                    <Form.Control size="sm" name="begin_hour" as="select" value={calendar && calendar.begin_hour ? calendar.begin_hour : beginHour} onChange={(e) => handleCalendarChange(e)}>
                                            <option>Selectionner une heure</option>
                                            <option>16</option>
                                            <option>17</option>
                                            <option>18</option>
                                            <option>19</option>
                                            <option>20</option>
                                            <option>21</option>
                                            <option>22</option>
                                            <option>23</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} lg={4} md={12}>
                                    <Form.Label style={{ color: error['end_hour'] ? "red" : "" }}>Heure de départ *</Form.Label>
                                    <Form.Control size="sm" name="end_hour" as="select" value={calendar && calendar.end_hour ? calendar.end_hour : endHour} onChange={(e) => handleCalendarChange(e)}>
                                        <option>Selectionner une heure</option>
                                        <option>9</option>
                                        <option>10</option>
                                        <option>11</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>
                        <Col sm={12} lg={12}>
                            {error['date'] && <label style={{ color: "red" }}><small>Les dates sont requises</small></label>}
                            <DateRange adminUsage usage='admin' id='dateRangeBooking' minLength={1} booking={props.date} apartment={props.apartment}/>
                        </Col>
                        <Col sm={12} lg={12} className='d-flex justify-content-end mt-3'>
                            * champ obligatoire
                        </Col>
                    </Row>
                    <Modal.Footer>
                        { pending ? <Spinner/> : <Button variant="secondary" onClick={() => handleCalendarSubmit()}>Sauvegarder</Button> }
                    </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
    return {
        begin_date: getBeginDate(state),
        end_date: getEndDate(state),
    };
}

export default connect(mapStateToProps)(EditBlocked);