import { useEffect, useState } from "react";
import { Row, Button, Col, Form, Card } from 'react-bootstrap';
import Spinner from '../../../spinner/Spinner';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import instanceOfAxios from "../../../../app/axios/axios";
import { show } from '../../Alerter/alerterSlice';
import { useDispatch } from "react-redux";
import CreateOrEditArticle from "../createOrEdit/createOrEditArticle";
import './listArticle.scss';
import ArticleCard from "./articleCard";
import { AiOutlineSearch } from 'react-icons/ai';

export default function ListArticle(props) {
    const [articles, setArticles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [pending, setPending] = useState(true);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(0);
    const [searchParams, setSearchParams] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (pending) {
            let query = new URLSearchParams(searchParams).toString();
            let pageIndex = page;
            if (query) {
                setPage(1);
                pageIndex = 1;
            }
            instanceOfAxios.get('/Articles/categories')
                .then(res => {
                    setCategories(res.data.categories);
                    instanceOfAxios.get('/admin/Articles?page=' + pageIndex + '&is_public=' + props.publish + '&' + query)
                        .then(res => {
                            setArticles(res.data.articles.data);
                            setMaxPage(res.data.articles.last_page);
                            setPending(false);
                        })
                        .catch(err => {
                            setPending(false);
                            dispatch(show({ message: 'Une erreur est survenue lors de la récupération des articles', variant: 'danger' }));
                        })
                })
                .catch(err => {
                    setPending(false);
                    dispatch(show({ message: 'Une erreur est survenue lors de la récupération des catégories', variant: 'danger' }));
                })
        }
    }, [pending]);

    function handleChange(e) {
        let params = Object.assign({}, searchParams);
        if (e.target.value === '' || e.target.value === 'Selectionner une catégorie') {
            delete params[e.target.name];
        } else {
            params[e.target.name] = e.target.value;
        }
        setSearchParams(params);
    }

    function nextPage() {
        if (page < maxPage) {
            setPage(page + 1);
            setPending(true);
        }
    }

    function prevPage() {
        if (page > 1) {
            setPage(page - 1);
            setPending(true);
        }
    }

    return (
        <Row className='d-flex justify-content-center'>
            <Col xs={12} className='d-flex justify-content-center'>
                <CreateOrEditArticle />
            </Col>
            <Col xs={12} md={4}>
                <Row className="align-content-center searchAdminForm" style={{ top: '40vh' }}>
                    <Col xs={12}>
                        <div id='formSearchAdmin'>
                            <Form.Group className='mb-2'>
                                <Form.Label>Mot clés</Form.Label>
                                <Form.Control type='text' name='key_word' onChange={(e) => handleChange(e)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Catégorie</Form.Label>
                                <Form.Control name="category" as="select" onChange={(e) => handleChange(e)}>
                                    <option>Selectionner une catégorie</option>
                                    {
                                        categories.map( category => <option>{category.name}</option>)
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Button
                                className='mt-2 whiteSVG'
                                variant='secondary'
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        setPending(true);
                                    }
                                }}
                                onClick={(() => setPending(true))}>Rechercher <AiOutlineSearch/></Button>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={8} style={{ minHeight: '70vh' }} className='d-flex flex-column align-items-center justify-content-center'>
                {
                    !pending ?
                        <>
                            {
                                articles.length == 0 ?
                                    <h2>Aucun articles à afficher </h2>
                                    :
                                    <>
                                        {
                                            maxPage > 1 ?
                                                <Row className="justify-content-center mb-3 w-100" noGutters>
                                                    <Col lg={1} md={1} sm={1} xs={1} className='text-center'>
                                                        <Button className="goldenHover" onClick={() => prevPage()} size="sm" variant="light">
                                                            <GrLinkPrevious />
                                                        </Button>
                                                    </Col>
                                                    <Col lg={10} md={10} sm={10} xs={10} className='text-center'>
                                                        <strong>{page}</strong> / {maxPage}
                                                    </Col>
                                                    <Col lg={1} md={1} sm={1} xs={1} className='text-center'>
                                                        <Button className="goldenHover" onClick={() => nextPage()} size="sm" variant="light">
                                                            <GrLinkNext />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                :
                                                <></>
                                        }
                                        <Row className='w-100'>
                                            {
                                                articles.map(article => <ArticleCard article={article} reload={setPending} categories={categories} />)
                                            }
                                        </Row>
                                        {
                                            maxPage > 1 ?
                                                <Row className="justify-content-center mb-3 w-100" noGutters>
                                                    <Col lg={1} md={1} sm={1} xs={1} className='text-center'>
                                                        <Button className="goldenHover" onClick={() => prevPage()} size="sm" variant="light">
                                                            <GrLinkPrevious />
                                                        </Button>
                                                    </Col>
                                                    <Col lg={10} md={10} sm={10} xs={10} className='text-center'>
                                                        <strong>{page}</strong> / {maxPage}
                                                    </Col>
                                                    <Col lg={1} md={1} sm={1} xs={1} className='text-center'>
                                                        <Button className="goldenHover" onClick={() => nextPage()} size="sm" variant="light">
                                                            <GrLinkNext />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                :
                                                <></>
                                        }
                                    </>
                            }
                        </>
                        :
                        <Col xs={12} className='d-flex justify-content-center align-items-center'>
                            <Spinner />
                        </Col>
                }
            </Col>
        </Row>
    )
}