import { Row, Col } from 'react-bootstrap';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

export default function ArticleSkeletonCard() {
    return (
        <Col xs={12} lg={9} className='mt-2'>
            <Row>
                <Col xs={12} lg={8} className='d-flex flex-column justify-content-center '>
                    <Typography variant="h2">
                        <Skeleton animation='wave' />
                    </Typography>
                    <Typography variant="body1">
                        <Skeleton animation='wave' />
                    </Typography>
                    <Typography variant="body1">
                        <Skeleton animation='wave' />
                    </Typography>
                    <Typography variant="body1">
                        <Skeleton animation='wave' />
                    </Typography>
                    <Typography variant="body1">
                        <Skeleton animation='wave' />
                    </Typography>
                </Col>
                <Col xs={12} lg={4} className='d-flex align-items-center'>
                    <Skeleton animation='wave' variant="rect" width="100%" className="apartment-list-image">
                        <div style={{ paddingTop: '57%' }} />
                    </Skeleton>
                </Col>
            </Row>
        </Col>
    )
}