import React from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap'
import { GiButterfly, GiReceiveMoney, GiHouseKeys, GiMagicBroom, GiTwoCoins } from 'react-icons/gi'
import { MdSecurity } from 'react-icons/md'
import { RiUserStarFill, RiHomeSmile2Line } from 'react-icons/ri'
import { GoLaw } from 'react-icons/go'
import { FaHands } from 'react-icons/fa'
import { AiOutlineFileProtect } from 'react-icons/ai'
import { VscAccount } from 'react-icons/vsc'
import './statics.scss'
import './owner.scss'

export default function Owner() {
    return (
        <Container style={{ paddingTop: '5vh', paddingBottom: '50px', maxWidth: '80vw', height: 'unset' }} className='noPadding'>
            <Row>
                <Col xs={12}>
                    <p style={{ color: '#B7A57E' }}><a href='/' className='golden'>Accueil</a> > <a href='/Proprietaires' className='golden'>Propriétaires</a></p>
                </Col>
                <Col xs={12} sm={12}>
                    <Row className='d-flex justify-content-center'>
                        <Row className='d-flex justify-content-center'>
                            <Col xs={12} md={8}>
                                <h2 className="centered">Prendre soin de votre patrimoine, Notre métier !</h2>
                            </Col>
                            <Col xs={12} md={10} className='text-center mt-3 mb-3'>
                                <p>Vous êtes propriétaire d’un ou plusieurs biens à Montpellier et ses environs. Vous souhaitez en optimiser la location. Class Appart est le spécialiste de la location courte et moyenne durée de logements meublés (appartements et villas) de charme sur Montpellier et ses alentours.</p>
                                <p>Notre service est 100% gratuit.</p>
                                <p>Profitez de nombreux avantages en nous confiant votre bien.</p>
                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-center w-100'>
                            <Col xs={12} lg={4} xl={2} className='shadow-advantage'>
                                <GiButterfly size='50' />
                                <p className="centered"><strong>Liberté </strong> Vous disposerez de votre logement quand vous le souhaitez pour l’occuper ponctuellement ou le récupérer définitivement.</p>
                            </Col>
                            <Col xs={12} lg={4} xl={2} className='shadow-advantage'>
                                <MdSecurity size='50' />
                                <p className="centered"><strong>Sécurité </strong> Class Appart est responsable de l’état de votre bien et vous garanti le paiement des loyers.</p>
                            </Col>
                            <Col xs={12} lg={4} xl={2} className='shadow-advantage'>
                                <GiReceiveMoney size='50' />
                                <p className="centered"><strong>Rentabilité </strong>Grâce à notre multi-diffusion, notre expertise et notre réseau, nous optimiserons votre taux d’occupation annuel.</p>
                            </Col>
                            <Col xs={12} lg={4} xl={2} className='shadow-advantage'>
                                <FaHands size='50' />
                                <p className="centered"><strong>Sérénité </strong> Une agence à taille humaine à votre écoute et disponible 6 jours sur 7 pour plus de réactivité.</p>
                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-center w-100'>
                            <Col xs={12} lg={4} xl={2} className='shadow-advantage'>
                                <GiMagicBroom size='50' />
                                <p className="centered"><strong>Entretien </strong> Une gestion complète et efficace, en passant par le ménage, la blanchsserie et les petits travaux d'entretiens </p>
                            </Col>
                            <Col xs={12} lg={4} xl={2} className='shadow-advantage'>
                                <GiTwoCoins size='50' />
                                <p className="centered"><strong>Fiscalité </strong> Class Appart est là pour vous conseiller et vous faire découvrir la fiscalité avantageuse du meublé (statut LMNP, LMP, meublé de tourisme...)</p>
                            </Col>
                            <Col xs={12} lg={4} xl={2} className='shadow-advantage'>
                                <GoLaw size='50' />
                                <p className="centered"><strong>Règlementation </strong>Vous accompagner dans les démarches adminsitratives locales afin de respecter la legislation en cours et louer en toute serenité.</p>
                            </Col>
                            <Col xs={12} lg={4} xl={2} className='shadow-advantage'>
                                <RiUserStarFill size='50' />
                                <p className="centered"><strong>Personnalisation </strong> De l'acquisition, en passant par les travaux, la décoration et la revente, Class Appart vous procure un accompagnement sur mesure </p>
                            </Col>
                        </Row>
                    </Row>
                </Col>
                <Col xs={12} className="d-flex justify-content-center mt-5">
                    <a href="/Contact">
                        <Button className='big-button'>Proposer un logement</Button>
                    </a>
                </Col>
                <Col xs={12} sm={12}>
                    <a id="avantages">
                        <hr className="page-separator" />
                    </a>
                </Col>
                <Col sm={12}>
                    <Row className="d-flex justify-content-center">
                        <Col sm={12} className='mb-5 mt-5'>
                            <h2 className="centered">Devenez propriétaire partenaire</h2>
                        </Col>
                        <Col xs={12} md={8} className="d-flex flex-column-reverse flex-lg-column align-items-end">
                            <div className="d-flex align-items-center step">
                                <AiOutlineFileProtect size='85' className='noDisplayOwner' />
                                <div className='step-content-right'>
                                    <p>Remplissez le formulaire de contact avec pour objet <strong><a href="/Contact">« proposition de bien »</a></strong></p>
                                    <p className='step-number'>1</p>
                                </div>
                            </div>
                            <strong className='step-name'>FORMULAIRE</strong>
                        </Col>
                        <Col xs={12} md={8} className="d-flex flex-column-reverse flex-lg-column justify-content-start">
                            <div className="d-flex align-items-center step">
                                <div className='step-content-left'>
                                    <p className='step-number'>2</p>
                                    <p>Nous verifions ensemble si votre bien peut correspondre à nos critères de sélections et vous conseillons pour l'optimiser au mieux en respectant vos besoins</p>
                                </div>
                                <RiHomeSmile2Line size='85' className='noDisplayOwner' />
                            </div>
                            <strong className='step-name'>SELECTION ET CONSEILS</strong>
                        </Col>
                        <Col xs={12} md={8} className="d-flex flex-column-reverse flex-lg-column align-items-end">
                            <div className="d-flex align-items-center step">
                                <GiHouseKeys size='85' className='noDisplayOwner' />
                                <div className='step-content-right'>
                                    <p>Shooting photo, communication, élaboration de prix, ménage, collecte des loyers, accueil etc... Class Appart se charge de tout !</p>
                                    <p className='step-number'>3</p>
                                </div>
                            </div>
                            <strong className='step-name'>MISE EN LOCATION</strong>
                        </Col>
                        <Col xs={12} md={8} className="d-flex flex-column-reverse flex-lg-column justify-content-start">
                            <div className="d-flex align-items-center step">
                                <div className='step-content-left'>
                                    <p className='step-number'>4</p>
                                    <p>Grâce à vos identifiants, vous pourrez suivre vos réservations en toute transparence et mettre à jour votre planning pour vos besoins personnels.</p>
                                </div>
                                <VscAccount size='85' className='noDisplayOwner' />
                            </div>
                            <strong className='step-name'>ESPACE PERSONEL</strong>
                        </Col>
                        <Col xs={12} className="d-flex justify-content-center mt-5">
                            <a href="/Contact">
                            <Button className='big-button'>Proposer un logement</Button>
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
