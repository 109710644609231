import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import instanceOfAxios from '../../app/axios/axios';
import { show } from '../../components/admin/Alerter/alerterSlice';
import ArticleCard from './Articles/articleCard';
import './Magazine.scss';
import ArticleSearchBar from './ArticleSearchBar/articleSearchBar';
import ArticleSkeletonCard from './Articles/articleSkeletonCard';
import Categories from './categories/categories';

export default function Magazine() {
    const [articles, setArticles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [pending, setPending] = useState(true);
    const [keyWord, setKeyWord] = useState();
    const skeleton = [<ArticleSkeletonCard />, <ArticleSkeletonCard />, <ArticleSkeletonCard />, <ArticleSkeletonCard />,];
    const dispatch = useDispatch();

    useEffect(() => {
        if (pending) {
            document.getElementsByTagName('meta')["short_description"].content = "Class appart vous tient informé des actualités montpelliéraines";
            document.getElementsByTagName('meta')["title"].content = "Magazine de class appart";

            let query = keyWord ? new URLSearchParams({ key_word: keyWord }).toString() : '';
            instanceOfAxios.get('/Articles/articlesByCategories')
                .then(res => {
                    setCategories(Object.assign([], res.data.articles));
                    instanceOfAxios.get('/Articles?' + query)
                        .then(res => {
                            setArticles(res.data.articles.data);
                            setPending(false);
                        })
                        .catch(err => {
                            dispatch(show({ message: 'Erreur lors de la récupération des articles', variant: 'danger' }));
                            setPending(false);
                        })
                })
                .catch(err => {
                    setPending(false);
                    dispatch(show({ message: 'Une erreur est survenue lors de la récupération des catégories', variant: 'danger' }));
                })
        }
    }, [pending])

    return (
        <Container style={{ paddingTop: '5vh', paddingBottom: '50px', maxWidth: '90vw', minHeight: '70vh', height: 'unset' }}>
            <Row>
                <Col xs={12}>
                    <p style={{ color: '#B7A57E' }}><a href='/' className='golden'>Accueil</a> > <a href='/Magazine' className='golden'>Magazine</a></p>
                </Col>
                <Col xs={12} className='dancing-script-text'>
                    <h2>Magazine</h2>
                </Col>
                <Col xs={12} className='d-flex justify-content-md-end justify-content-center'>
                    <ArticleSearchBar
                        setPending={setPending}
                        setKeyWord={setKeyWord}
                        keyWord={keyWord} />
                </Col>
            </Row>
            <Row className='w-100'>
                <Col xs={0} lg={2} className='noPadding'>
                    <Row className='d-flex justify-content-start noDisplay'>
                        {
                            Object.keys(categories).map(category =>
                                <ul>
                                    <li>
                                        {
                                            category
                                        }
                                        <Categories category={categories[category]}/>
                                    </li>
                                </ul>
                            )
                        }
                    </Row>
                </Col>
                <Col xs={12} lg={10} className='noPadding'>
                    <Row className='d-flex justify-content-center'>
                        {
                            pending ?
                                skeleton.map(skeletonCard => skeletonCard)
                                :
                                articles.map(article => <ArticleCard article={article} />)
                        }
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}