import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import instanceOfAxios from "../../../../app/axios/axios";
import Spinner from "../../../spinner/Spinner";
import { show } from "../../Alerter/alerterSlice";
import { FaRegSave } from 'react-icons/fa';

export default function GlobalDocument(props) {
    const [formData, setFormData] = useState(new FormData());
    const [label, setLabel] = useState('Format PDF');
    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [references, setReferences] = useState([]);
    const [selectedReferences, setSelectedReferences] = useState([]);
    const [mergable, setMergable] = useState(0);
    const [isSubmit, setIsSubmit] = useState(false);
    const [validate, setValidate] = useState(false);
    const [errors, setErrors] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (validate) {
            getAllReference();
        }
    }, [validate])

    function handleFileChange(e) {
        let newFormData = new FormData();
        newFormData.set('name', name.split(' ').join('_'));
        newFormData.set('description', description);
        newFormData.set('mergedPdf', e.target.files[0]);
        setLabel(e.target.files[0].name);
        setFormData(newFormData);
    }

    function handleNameChange(value) {
        setName(value);
    }

    function handleDescriptionChange(value) {
        setDescription(value);
    }

    function checkAllReference(e) {
        setSelectedReferences(e.target.checked ? references : []);
    }

    function checkOneReference(e) {
        if (e.target.checked) {
            let selectedReferenceFilter = selectedReferences.filter(value => value != e.target.name);
            selectedReferenceFilter.push(e.target.name)
            setSelectedReferences(selectedReferenceFilter);
        } else {
            setSelectedReferences(selectedReferences.filter(value => value != e.target.name));
        }
    }

    function getAllReference() {
        instanceOfAxios.get('/admin/apartment/getAllReference')
            .then(res => {
                let references = [];
                res.data.references.map(apartment => {
                    references.push(apartment.reference);
                })
                setReferences(references)
            })
            .catch(err => {
                dispatch(show({ message: err.response.data.error, variant: 'danger' }));
            })
    }

    function handleSubmit() {
        setIsSubmit(true);
        setErrors([]);
        formData.set('references[]', selectedReferences);
        formData.set('is_mergable', mergable);
        instanceOfAxios.post('/admin/apartment/addFileOnReference', formData)
            .then(res => {
                setIsSubmit(false);
                dispatch(show({ message: res.data.references, variant: 'success' }));
            })
            .catch(err => {
                if (err.response.status == 400) {
                    setErrors(Object.assign([], err.response.data.error));
                    dispatch(show({ message: 'Erreur lors de l\'enregistrement', variant: 'danger' }));
                } else {
                    dispatch(show({ message: err.response.data.error, variant: 'danger' }));
                }
                setIsSubmit(false);
            })
    }

    function reset() {
        setName();
        setDescription();
        setFormData(new FormData());
        setSelectedReferences([]);
        setMergable(0);
        setValidate(false);
        setLabel('Format PDF');
    }

    return (
        <div className='w-100 d-flex flex-column align-items-center mt-3'>
            <Col xs={12} className='d-flex justify-content-end'>
                <Button className='goldenHover' onClick={reset}>
                    Réinitialiser le formulaire
                </Button>
            </Col>
            <Col xs={12} lg={4} className="text-center d-flex flex-column mt-2 mb-2">
                <small>
                    Grâce à cette interface vous pouvez ajouter un document sur la fiche de bienvenue de plusieurs logements en même temps.
                </small>
                <small style={{ color: 'red' }}>
                    Attention la sauvegarde peut prendre plusieurs minutes
                </small>
            </Col>
            <Col xs={12} lg={4}>
                <Form className='text-center'>
                    <InputGroup className="mb-3">
                        <Form.Control type="text" placeholder='Titre du document (attention il apparaitra dans le sommaire)' disabled={validate} name='name' onChange={(e) => handleNameChange(e.target.value)} />
                    </InputGroup>
                    {
                        name ?
                            <Form.Group className="mb-3">
                                <Form.Control type="text" as='textarea' placeholder='Description (attention il apparaitra dans le sommaire)' disabled={validate} name='name' onChange={(e) => handleDescriptionChange(e.target.value)} />
                                <small>Maximum 200 caractères</small>
                            </Form.Group>
                            :
                            <></>
                    }
                    {
                        !validate ?
                            <Button className='goldenHover' onClick={() => setValidate(true)}>Valider</Button>
                            :
                            <Button className='goldenHover' onClick={() => {
                                setFormData(new FormData());
                                setSelectedReferences([]);
                                setMergable(0);
                                setValidate(false);
                                setLabel('Format PDF');
                            }}>{'Modifier'}</Button>
                    }
                    {
                        validate &&
                        <>
                            <Form.Group className='mt-3'>
                                <Form.File
                                    id="custom-file-translate-scss"
                                    label={label}
                                    lang="fr"
                                    custom
                                    accept='document/pdf'
                                    data-browse="Fichier"
                                    name='data'
                                    onChange={(e) => handleFileChange(e)}
                                />
                            </Form.Group>
                        </>
                    }
                </Form>
            </Col>
            {
                formData.get('mergedPdf') && validate && references.length > 0 &&
                <Row className='d-flex flex-column align-items-center'>
                    <Col xs={4} className='d-flex align-items-center justify-content-center'>
                        <Form.Group controlId="formBasicCheckbox" className='mt-3'>
                            <Form.Check type="checkbox" label='Ajouter à tous les logements (le doc sera dans le back office mais non rattaché dans la fiche de bienvenue / à faire manuellement dans un second temps)' checked={selectedReferences.length == references.length} onClick={(e) => checkAllReference(e)} />
                        </Form.Group>
                    </Col>
                    <Row className='w-100 d-flex justify-content-center'>
                        {
                            references.map(reference =>
                                <Col className='shadowForCard m-2 d-flex align-items-center justify-content-center' xs={6} md={4} lg={3}>
                                    <Form.Group controlId="formBasicCheckbox" className='mt-3'>
                                        <Form.Check
                                            type="checkbox"
                                            name={reference}
                                            label={reference}
                                            checked={selectedReferences.includes(reference)}
                                            onClick={(e) => checkOneReference(e)} />
                                    </Form.Group>
                                </Col>)
                        }
                    </Row>
                    <Col xs={4} className='d-flex align-items-center justify-content-center'>
                        <Form.Group controlId="formBasicCheckbox" className='mt-3'>
                            <Form.Check type="checkbox" label='Ajouter à toutes les fiches de bienvenue (le doc va être rattaché dans la fiche de bienvenue, par default à la derniere page)' onClick={(e) => setMergable(e.target.checked ? 1 : 0)} />
                        </Form.Group>
                    </Col>
                </Row>
            }
            {
                Object.keys(errors).length > 0 &&
                <Row>
                    <ul>
                        {
                            Object.keys(errors).map(error => errors[error].map(text => <li style={{ color: 'red' }}>{text}</li>))
                        }
                    </ul>
                </Row>
            }
            <Col xs={12} className='d-flex justify-content-center mb-5'>
                {
                    formData.get('mergedPdf') && validate &&
                    <>
                        {
                            !isSubmit ?
                                <Button className='whiteSVG' variant='secondary' onClick={handleSubmit}><FaRegSave/> Enregistrer</Button>
                                :
                                <Spinner />
                        }
                    </>
                }
            </Col>
        </div>
    )
}