import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { Row, Col } from 'react-bootstrap';
import './skeletonContent.scss'

export default function SkeletonContent(props) {
    return (
        <Col xs={12}>
            <Row>
                <Col xs={12} md={4} className='d-flex justify-content-center align-items-center'>
                    <Typography variant="body1" style={{ width: "80%" }}>
                        <Skeleton animation='wave' />
                    </Typography>
                </Col>
                <Col xs={12} md={4} className='d-flex justify-content-center'>
                    <Typography variant="h3" style={{ width: "30%" }}>
                        <Skeleton animation='wave' />
                    </Typography>
                </Col>
                <Col xs={12} md={4} className='d-flex justify-content-center align-items-center'>
                    <Typography variant="body1" style={{ width: "100%" }}>
                        <Skeleton animation='wave' />
                    </Typography>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} className='d-flex justify-content-center align-items-center'>
                    <Typography variant="body1" style={{ width: "80%" }}>
                        <Skeleton animation='wave' />
                    </Typography>
                </Col>
                <Col xs={12} md={4} className='d-flex justify-content-center'>
                    <Typography variant="h3" style={{ width: "30%" }}>
                        <Skeleton animation='wave' />
                    </Typography>
                </Col>
                <Col xs={12} md={4} className='d-flex justify-content-center align-items-center'>
                    <Typography variant="body1" style={{ width: "100%" }}>
                        <Skeleton animation='wave' />
                    </Typography>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} className='d-flex justify-content-center align-items-center'>
                    <Typography variant="body1" style={{ width: "80%" }}>
                        <Skeleton animation='wave' />
                    </Typography>
                </Col>
                <Col xs={12} md={4} className='d-flex justify-content-center'>
                    <Typography variant="h3" style={{ width: "30%" }}>
                        <Skeleton animation='wave' />
                    </Typography>
                </Col>
                <Col xs={12} md={4} className='d-flex justify-content-center align-items-center'>
                    <Typography variant="body1" style={{ width: "60%" }}>
                        <Skeleton animation='wave' />
                    </Typography>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={8}>
                    <Skeleton className="skeletonTabRect" animation='wave' variant='rect' />
                    <Skeleton className="skeletonDepRect" animation='wave' variant='rect' />
                </Col>
                <Col xs={12} md={4} className='d-flex justify-content-center align-items-center'>
                    <Typography variant="h2" style={{ width: "30%" }}>
                        <Skeleton animation='wave' />
                    </Typography>
                    <Typography variant="h2" style={{ width: "30%", marginLeft: '1vw' }}>
                        <Skeleton animation='wave' />
                    </Typography>
                </Col>
            </Row>
        </Col>
    )
}