import { useDispatch } from 'react-redux';
import { hideAndShowCreate } from '../quoteSlice';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import QuoteCard from './quoteCard';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import instanceOfAxios from '../../../../app/axios/axios';
import Spinner from '../../../spinner/Spinner';
import { AiOutlineSearch } from 'react-icons/ai';

export default function ListQuote(props) {
    const dispatch = useDispatch();
    const [activePage, setActivePage] = useState(1);
    const [pageNumber, setPageNumber] = useState(0);
    const [quotes, setQuotes] = useState();
    const [pending, setPending] = useState(true);
    const [searchParam, setSearchParam] = useState([]);

    useEffect(() => {
        if(pending){
            let query = '';
            for (const [key, value] of Object.entries(searchParam)) {
                query += key + '=' + value + '&';
            }
            instanceOfAxios.get('/admin/quotes?' + query)
            .then((res) => {
                setPending(false);
                setQuotes(res.data.quotes.data);
                setPageNumber(res.data.quotes.last_page);
            });
        }
    }, [pending]);


    function prevPage(active = null) {
        if (active > 1) {
            return setActivePage(parseInt(active) - 1);
        }
    }

    function nextPage(active = null, arrayLength = 0) {
        if (active < arrayLength) {
            return setActivePage(parseInt(active) + 1);
        }
    }

    function handleChange(event) {
        searchParam[event.target.id] = event.target.value;
        setSearchParam(searchParam);
    }

    return (
        <div className={'verticalAlign content-slider' + props.className}>
            <Col xs={12}>
                <h2>Gestion des devis</h2>
            </Col>
            <Col sm={12} lg={12} className='d-flex justify-content-center justify-content-lg-end mt-3 mb-3'>
                    <div className='separator'
                        style={{
                            border: '1px solid #B7A57E',
                            color: '#b7a57e',
                            width: '90%'
                        }}></div>
            </Col>
            <Col md={12} className='d-flex justify-content-center'>
                <Button variant="secondary" onClick={() => dispatch(hideAndShowCreate())}>Simuler/Envoyer un Devis</Button>
            </Col>
            <Row className='mt-3'>
                <Col xs={12} lg={4} className="align-items-center">
                    <div className="searchAdminForm">
                        <Form id='formSearchAdmin'
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                setPending(true);
                            }
                        }}>
                            <Form.Group>
                                <Form.Label>Référence de logement</Form.Label>
                                <Form.Control id='apartment' onChange={(e) => handleChange(e)} type="text" placeholder="Référence de logement" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Locataire</Form.Label>
                                <Form.Control id='contact' onChange={(e) => handleChange(e)} type="text" placeholder="Identifiant/Nom" />
                            </Form.Group>
                            <Button
                                variant='secondary'
                                className='whiteSVG'
                                onClick={() => setPending(true)}>
                                Rechercher <AiOutlineSearch/>
                            </Button>
                        </Form>
                    </div>
                </Col>
                <Col xs={12} lg={8}>
                    <Col xs={12} className='d-flex mt-3'>
                        <Col xs={1} className='text-center'>
                            <Button className="goldenHover" onClick={() => prevPage(activePage)} size="sm" variant="light">
                                <GrLinkPrevious />
                            </Button>
                        </Col>
                        <Col xs={10} className='text-center'>
                            <strong>{activePage}</strong> / {pageNumber}
                        </Col>
                        <Col xs={1} className='text-center'>
                            <Button className="goldenHover" onClick={() => nextPage(activePage, pageNumber)} size="sm" variant="light">
                                <GrLinkNext />
                            </Button>
                        </Col>
                    </Col>
                    <Col xs={12} style={{ minHeight: '70vh' }} className='d-flex align-items-center justify-content-center'>
                        <Row className="mt-2">
                        {
                            pending ?
                                <Col xs={12} style={{ minHeight: '70vh' }} className='d-flex align-items-center justify-content-center'>
                                    <Spinner />
                                </Col>
                                :
                                <>
                                    {
                                        quotes && quotes.length > 0 ?
                                            quotes.map((quote) => {
                                                return <QuoteCard quote={quote} setReload={setPending}/>
                                            }) 
                                        :
                                            <h2>Aucun devis à afficher </h2>
                                    }
                                </>
                        }
                        </Row>
                    </Col>
                    <Col xs={12} className='d-flex mb-3 mt-3'>
                        <Col xs={1} className='text-center'>
                            <Button className="goldenHover" onClick={() => prevPage(activePage)} size="sm" variant="light">
                                <GrLinkPrevious />
                            </Button>
                        </Col>
                        <Col xs={10} className='text-center'>
                            <strong>{activePage}</strong> / {pageNumber}
                        </Col>
                        <Col xs={1} className='text-center'>
                            <Button className="goldenHover" onClick={() => nextPage(activePage, pageNumber)} size="sm" variant="light">
                                <GrLinkNext />
                            </Button>
                        </Col>
                    </Col>
                </Col>
            </Row>
        </div>)
}