import { MdOpenInNew } from 'react-icons/md';
import { Card, Row, Col, Form } from 'react-bootstrap';
import instanceOfAxios from '../../../../../../app/axios/axios';
import { setUpdater } from '../../../apartmentSlice'
import { show } from '../../../../Alerter/alerterSlice';
import { useDispatch } from 'react-redux';
import DeleteModal from '../../../../../generiqueModal/deleteModal/deleteModal';

export default function FileCard(props) {
    const dispatch = useDispatch();

    function handleDelete() {
        props.reloadPdfPreview(true);
        instanceOfAxios.delete('/admin/apartment/' + props.apartment.id + '/' + props.name)
            .then(res => {
                dispatch(setUpdater(res.data.success));
                dispatch(show({ message: 'Document supprimé', variant: 'success' }));
                props.reloadPdfPreview(false);
            })
            .catch(err => {
                dispatch(show({ message: 'Erreur lors de la suppression', variant: 'danger' }));
            });
    }

    return (
        <Card className='mt-3 mb-3 shadowForCard' key={props.name}>
            <Card.Body>
                <Row className='d-flex align-items-center justify-content-center'>
                    <Col xs={12} md={6} lg={4} className='d-flex align-items-center justify-content-center'>
                        <p className='text-center'>Document : {props.name}</p>
                    </Col>
                    <Col xs={12} md={6} lg={4} className='text-center'>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Ordre</Form.Label>
                            <Form.Control type="number" className='text-center' onChange={(event) => props.handleOrderChange(props.name, event)} defaultValue={props.file && props.file.order} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4} className='d-flex align-items-center justify-content-center'>
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Concaténer le document" onClick={(event) => props.handleConcatChange(props.name, event)} defaultChecked={props.file && props.file.is_mergable} />
                        </Form.Group>
                    </Col>
                    <Col sm={7} lg={7} className='d-flex justify-content-center justify-content-lg-end mt-1 mb-1'>
                        <div className='separator'
                            style={{
                                border: '1px solid #B7A57E',
                                color: '#b7a57e',
                                width: '90%',
                            }}></div>
                    </Col>
                    <Col xs={12} md={10} lg={7} className='d-flex flex-column align-items-center justify-content-center noPadding'>
                        <Form.Group className='text-center w-100'>
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" as='textarea' row={4} className='text-center' onChange={(event) => props.handleDescriptionChange(props.name, event)} defaultValue={props.file.description} />
                            <small>Maximum 200 caractères</small>
                        </Form.Group>
                    </Col>
                    <Col sm={7} lg={7} className='d-flex justify-content-center justify-content-lg-end mt-1 mb-3'>
                        <div className='separator'
                            style={{
                                border: '1px solid #B7A57E',
                                color: '#b7a57e',
                                width: '90%',
                            }}></div>
                    </Col>
                    <Col xs={12} md={6} className='text-center'>
                        <a target='_blank' href={props.file && props.file.url} className='text-center'>Visualiser <MdOpenInNew /></a>
                    </Col>
                    <Col xs={12} md={6} className='text-center'>
                        <DeleteModal deleteFunction={handleDelete} />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}