 import { useState } from "react"
import instanceOfAxios from "../../../../app/axios/axios";
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import SkeletonCard from "../../booking/bookingCard/skeletonCard";
import { show } from "../../Alerter/alerterSlice";
import CardTab from "./card/cardTab";
import { AiOutlineReload } from 'react-icons/ai';
import { FaRegSave } from 'react-icons/fa';

export default function TabRecallPayment(props) {
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [pageNumber, setPageNumber] = useState(0);
    const [report, setReport] = useState([]);
    const [permaReport, setPermaReport] = useState([]);
    const skeleton = [{}, {}, {}, {}, {}, {}];
    const dispatch = useDispatch();

    if (pending) {
        fetchBooking(activePage);
    }

    function fetchBooking(page) {
        setLoading(true);
        instanceOfAxios.get('admin/bookingListGonnaBeRecall?page=' + page)
            .then(res => {
                setData(res.data.booking_auto_recallable.data);
                setPageNumber(res.data.booking_auto_recallable.last_page);
                setLoading(false);
            })
        setPending(false);
    }

    function next() {
        if (activePage < pageNumber) {
            setActivePage(parseInt(activePage) + 1);
            fetchBooking(parseInt(activePage) + 1)
        }
    }

    function prev() {
        if (activePage > 1) {
            setActivePage(parseInt(activePage) - 1);
            fetchBooking(parseInt(activePage) - 1)
        }
    }

    function changeReport(event, booking) {
        let ids = Object.assign([], report);

        if (event.target.checked) {
            ids.push(booking.id);
        } else {
            ids = ids.filter(id => id != booking.id);
        }
        setReport(ids);
    }

    function changePermaReport(event, booking) {
        let ids = Object.assign([], permaReport);
        if (event.target.checked) {
            ids.push(booking.id);
        } else {
            ids = ids.filter(id => id != booking.id);
        }
        setPermaReport(ids);
    }

    function handleReportSubmit() {
        instanceOfAxios.post('/admin/reportRecallableBooking', { report_payment: report, is_recallable_payment: permaReport })
            .then(res => {
                dispatch(show({ message: 'Changement sauvegardés', variant: 'success' }));
                
                fetchBooking();
            })
            .catch(err => {
                dispatch(show({ message: 'Erreur lors de l\'action', variant: 'danger' }));
                
            })
    }

    return (
        <>
            <Row >
                <Col md={6} className='text-center mt-2'>
                    <Button variant='secondary' className='whiteSVG' onClick={() => fetchBooking(1)}>Recharger <AiOutlineReload/></Button>
                </Col>
                <Col md={6} className='text-center mt-2'>
                    <Button variant='secondary' className='whiteSVG' onClick={() => handleReportSubmit()}><FaRegSave/> Enregistrer</Button>
                </Col>
            </Row>
            <Row className='d-flex justify-content-between mt-2 mb-2'>
                {data.length > 0 ?
                    <>
                        <Col xs={1} className='text-center'>
                            <Button variant='light' onClick={(e) => prev(e)} size="sm">
                                <GrLinkPrevious />
                            </Button>
                        </Col>
                        <Col xs={4} className='text-center'>
                            <strong>{activePage}</strong> / {pageNumber}
                        </Col>
                        <Col xs={1} className='text-center'>
                            <Button variant='light' onClick={(e) => next(e)} size="sm">
                                <GrLinkNext />
                            </Button>
                        </Col>
                    </>
                    :
                    <></>}
            </Row>
            <Col xs={12}>
                <div>
                    {loading ? skeleton.map(skel => {
                        return (
                            <SkeletonCard />
                        )
                    })
                        :
                        data.map(booking => {
                            return (
                                <CardTab
                                tabType='report'
                                booking={booking} 
                                firstChecked={report.includes(booking.id)}
                                secondChecked={permaReport.includes(booking.id)}
                                firstText={'Report de 3 jours'} 
                                secondText={'Désactiver les rappels'} 
                                firstChange={changeReport} 
                                secondChange={changePermaReport}/>
                            )
                        })
                    }
                </div>
            </Col>
        </>
    )
}